<template>
    <section>
        <div class="container-wrapper latest-news relative mb-144">
            <img class="bottom-decor-img" loading="lazy" src="../assets/img/decor.png" alt="">
            <div class="flex justify-between latest-heading align-center">
                <content-title class="heading-margin" data-aos="fade-up" :colored-title="true" :title="$store.state.main.selected_menu.title" />

                <!-- Calendar -->
                <div class="calendar-margin relative" data-aos="fade-up">
                    <div class="date-picker-button"  @click="openDrop()">
                        <div class="flex date-picker-content" >
                            <svg class="calendar-svg" xmlns="http://www.w3.org/2000/svg" width="23.6" height="26" viewBox="0 0 23.6 26">
                                <g id="Icon_feather-calendar" data-name="Icon feather-calendar" transform="translate(-3.5 -2)">
                                    <path id="Path_598" data-name="Path 598" d="M6.9,6H23.7a2.4,2.4,0,0,1,2.4,2.4V25.2a2.4,2.4,0,0,1-2.4,2.4H6.9a2.4,2.4,0,0,1-2.4-2.4V8.4A2.4,2.4,0,0,1,6.9,6Z" transform="translate(0 -0.6)" fill="none" stroke="#81c7bd" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                    <path id="Path_599" data-name="Path 599" d="M24,3V7.8" transform="translate(-3.9)" fill="none" stroke="#81c7bd" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                    <path id="Path_600" data-name="Path 600" d="M12,3V7.8" transform="translate(-1.5)" fill="none" stroke="#81c7bd" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                    <path id="Path_601" data-name="Path 601" d="M4.5,15H26.1" transform="translate(0 -2.4)" fill="none" stroke="#81c7bd" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                </g>
                            </svg>
                            <p v-if="value === null " class="font-13 regular">
                                {{$fn.tr('Pick a date')}}
                            </p>
                            <p v-if="value !== null" @click="value = null" class="font-13 regular">
                                {{$fn.tr('Clear calendar')}}
                            </p>
                        </div>
                        <svg class="arrow" :class="isOpen ? 'carret-rotate' : ''" xmlns="http://www.w3.org/2000/svg" width="11.144" height="20" viewBox="0 0 11.144 20">
                            <path id="Path_331" data-name="Path 331" d="M213.584,780.046a1.144,1.144,0,0,0,.809-1.953l-8.047-8.047L214.393,762a1.144,1.144,0,1,0-1.618-1.618l-8.856,8.856a1.144,1.144,0,0,0,0,1.618l8.856,8.856A1.14,1.14,0,0,0,213.584,780.046Z" transform="translate(-203.584 -760.046)" fill="#435059"/>
                        </svg>
                    </div>
                    <!-- <range-calendar /> -->
                    <v-date-picker color="teal" :model-config="modelConfig" class="calendar" :class="isOpen ? 'isShown' : '' " v-model="value" is-range></v-date-picker>
                </div>
            </div>

            <div class="flex card-list">
                <router-link class="news-shown" v-for="(item, index) in primaryList.slice(0,2)" :key="index" :to="$store.getters['main/getSingleUrl'](item, 1)">
                    <LatestNewsCard :cardData="item" data-aos="fade-up" data-aos-delay="200" />
                </router-link>
            </div>
            <div class="grid mt-144">
                <router-link  v-for="(item, index) in primaryList.slice(2, primaryList.length)" :key="index" :to="$store.getters['main/getSingleUrl'](item, 1)">
                    <NewsCard :cardData="item" data-aos="fade-up" :data-aos-delay="100" />
                </router-link>
            </div>
            <div class="bold uppercase font-34 text-center" data-aos="fade-up" data-aos-delay="200" v-if="!primaryList.length">
                {{$fn.tr('News not found between selected dates')}}
            </div>
        </div>

        <Pagination
            class="mb-144 container-wrapper"
            :dataLength="_.get(this.$store.state.main.current_content, 'primary.data.listCount')"
            :perPage="perPage"
            :pageRange="12"
            @set-current="setCurrent"
            v-if="_.get(this.$store.state.main.current_content, 'primary.data.listCount') > perPage && !value"
        />

        <CartInfo  />
    </section>
</template>
<script>
import LatestNewsCard from "../components/LatestNewsCard"
import NewsCard from "../components/LatestNews"
import ContentTitle from "../components/ContentTitle"
import CartInfo from "../components/CartInfo"
import Calendar from "../components/Caldenar"
import Pagination from "../components/Pagination"
import _ from "lodash"
import moment from "moment"
import func from '../helpers/func'

export default {
    components: {
        NewsCard,
        LatestNewsCard,
        ContentTitle,
        CartInfo,
        Pagination,
        Calendar
    },
    data(){
        return{
            title: 'Latest news',
            currentPage: 1,
            perPage: 12,
            isOpen: false,
            value: null,
            modelConfig: {
                start: {
                    timeAdjust: '00:00:00',
                },
                end: {
                    timeAdjust: '23:59:59',
                },
            },
        }
    },
    watch: {
        value: {
            handler(val){
                this.isOpen = !this.isOpen
            },
        },
        '$route.query.page': {
            immediate: true,
            handler(val) {
                if (val)
                    this.goToNextPage(parseInt(val));
            }
        }
    },
    computed: {
        primaryList(){
            let data = _.get(this.$store.state.main.current_content, 'primary.data.list')

            if(this.value){

                let filteredData = []

                let start = moment().diff(this.value.start, 'DD MMM, YYYY')

                let end = moment().diff(this.value.end, 'DD MMM, YYYY')

                for (let index = 0; index < data.length; index++) {

                    if(moment().diff(_.get(data[index], 'date'), 'DD MMM, YYYY') <= start && moment().diff(_.get(data[index], 'date'), 'DD MMM, YYYY') >= end){
                        filteredData.push(data[index])
                    }
                    // const element = data[index];

                }

                return filteredData

            }else{
                return data
            }

        },
    },
    methods: {
        setCurrent(current) {
            this.currentPage = current;
            this.goToNextPage(current);
            document.documentElement.scrollTop = 0
        },
        async goToNextPage(page) {
            let filter = { id:_.get(this.$store.state.main, 'selected_menu.id'),
                placeName: 'primary',
                searchText: '',
                searchDate: [],
                searchTerms: _.get(this.$store.state.main.selected_menu, 'primary_content.term'),
                pageNumber: page,
                perPage: parseInt(_.get(this.$store.state.main, 'selected_menu.primary_content.page_count'))?parseInt(this.$store.state.main.selected_menu.primary_content.page_count) : 12,
                taxonomy: 'news_category'
            };
            await this.$store.dispatch('main/getPagedContent', filter );
            this.currentPage = page;

        },
        openDrop() {
            this.isOpen = !this.isOpen
        },
    },

    mounted() {
        this.$store.commit('main/setState', {key: "breadcrumbList", val: [{title: 'News', url: `/${this.$store.state.main.locale}/news` }]})
    },
    created() {
        let self = this;

        window.addEventListener('click', function(e){
            if (!self.$el.contains(e.target)){
                self.isOpen = false
            }
        })
    }
}
</script>

<style>
/* .vc-highlight.vc-highlight-base-middle{
    background: #81C7BD !important;
} */
.vc-nav-popover-container{
    background-color: #81c7bd !important;
    border-color: #84c8bf !important;
}
.vc-nav-arrow:hover{
    background: none !important;
}
</style>

<style scoped>
    .text-center{
        text-align: center;
    }
    .grid{
        position: relative;
        display: grid;
        grid-template-columns: repeat(3, minmax(300px, 1fr));
        gap: 144px 30px;
    }
    .mb-144{
        margin-bottom: 144px;
    }
    .latest-heading a{
        color: #435059;
        transition: 0.3s;
    }
    .latest-heading a:hover{
        color: #81C7BD;
        transition: 0.3s;
    }
    .card-list{
        justify-content: space-between;
    }
    .news-shown{
        width: calc(50% - 15px);
        cursor: pointer;
    }

    .date-picker-button{
        width: 248px;
        height: 44px;
        border-radius: 10px;
        border: 1px solid #81C7BD;
        display: flex;
        justify-content: space-between;
        padding: 13px 23px 10px 20px;
        cursor: pointer;
    }
    .date-picker-button .arrow{
        transform: rotate(-90deg);
        transition: 0.4s;
    }
    .calendar-svg{
        margin-top: -3px;
    }
    .date-picker-content p{
        margin-left: 18px;
        padding-left: 14px;
        border-left: 2px solid #D9DCDE;
        overflow: hidden;
    }
    .carret-rotate {
        transform: rotate(90deg)!important;
        transition: 0.4s;
    }
    .carret-rotate path{
        fill: #81C7BD;
    }
    .calendar{
        position: absolute;
        z-index: 2;
        left: -6px;
        top: 50px;
        transform: translateY(-20px);
        opacity: 0;
        transition: 0.4s;
        visibility: hidden;
    }
    .calendar.isShown{
        transform: translateY(10px);
        opacity: 1;
        border-color: #81c7bd;
        transition: 0.4s;
        visibility: visible;
    }
    .calendar-margin{
        margin-bottom: 50px;
    }

    @media (max-width: 1365px){
        .grid{
            grid-template-columns: repeat(2, minmax(300px, 1fr));
            gap: 75px 30px;
        }
        .mb-144{
            margin-bottom: 75px;
        }
        .calendar-margin{
            margin-bottom: 0px;
        }
    }
    @media (max-width: 1023px){
        .mt-144{
            margin-top: 75px;
        }
    }
     @media (max-width: 767px){
         .card-list{
            justify-content: space-between;
            flex-direction: column;
        }
        .latest-heading{
            flex-direction: column;
            align-items: flex-start;
        }
        .calendar-margin{
            margin-bottom: 21px;
        }
        .news-shown{
            width: 100%;
            margin-bottom: 30px;
        }
         .mt-144{
            margin-top: 0;
        }
        .mb-144{
            margin-bottom: 35px;
        }
         .grid{
            grid-template-columns: repeat(1, minmax(280px, 1fr));
            gap: 35px 30px;
        }
    }

</style>
