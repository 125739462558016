<template>
  <div>
    <div class="side-menu" :class="{ open: menuIsOpen }">
      <div class="side-menu-content">
        <div class="close" @click="$emit('closeMenu', false)">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15.424"
            height="15.424"
            viewBox="0 0 15.424 15.424"
          >
            <path
              id="Icon_metro-cancel"
              data-name="Icon metro-cancel"
              d="M22.815,9.64l-4.82,4.82-4.82-4.82-2.892,2.892,4.82,4.82-4.82,4.82,2.892,2.892,4.82-4.82,4.82,4.82,2.892-2.892-4.82-4.82,4.82-4.82Z"
              transform="translate(-10.283 -9.64)"
              fill="#81c7bd"
            />
          </svg>
        </div>
        <div class="menu-list" @click="$emit('closeMenu', false)" :class="{ change: langChange }">
          <template v-for="(item, index) in menuList">
            <router-link
              tag="div"
              v-if="item.css[0] !== 'hide-from-menu'"
              :to="item.full_url"
              class="menu-item"
              :class="{ active: $route.name === item.title }"
              :key="index"
            >
              {{ item.title }}
            </router-link>
          </template>
        </div>
      </div>
      <div class="side-menu-bottom">
        <div class="menu-bottom-row" :class="{ change: langChange }">
          <div class="row-link">
            {{ $fn.tr('Terms & Conditions') }}
          </div>
          <div class="row-link">{{ $fn.tr('Privacy Policy') }}</div>
        </div>
        <div class="menu-bottom-row">
          <div class="language-button" data-aos="fade-right" data-aos-delay="200">
            <div v-if="$route.meta.url">
              <router-link
                tag="span"
                class="language-state"
                v-if="_.get($store.state.main, 'locale') == 'en'"
                @click.native="changeLanguage('/' + $route.meta.url + '/ge')"
                :to="''"
              >
                ქართულად
              </router-link>
              <router-link
                tag="span"
                v-else-if="_.get($store.state.main, 'locale') !== 'en'"
                @click.native="changeLanguage('/' + $route.meta.url + '/en')"
                :to="''"
                class="language-state"
              >
                English
              </router-link>
            </div>
            <div v-else>
              <router-link
                tag="span"
                v-if="
                  _.get($store.state.main, 'locale') == 'en' &&
                    _.get($store.state.main.selected_menu_locales, 'ge')
                "
                @click.native="changeLanguage(_.get($store.state.main.selected_menu_locales, 'ge'))"
                :to="''"
                class="language-state"
              >
                ქართულად
              </router-link>
              <router-link
                tag="span"
                v-else-if="
                  _.get($store.state.main, 'locale') !== 'en' &&
                    _.get($store.state.main.selected_menu_locales, 'en')
                "
                @click.native="changeLanguage(_.get($store.state.main.selected_menu_locales, 'en'))"
                :to="''"
                class="language-state"
              >
                English
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="outside-menu"
      :class="{ active: menuIsOpen }"
      @click.prevent="$emit('closeMenu', false)"
    ></div>
    <div class="background-filter" v-if="menuIsOpen"></div>
    <div class="custom-scrollbar">
      <div class="scrollbar-thumb"></div>
    </div>
  </div>
</template>

<script>
import mainMixin from '../mixins/main';

export default {
  mixins: [mainMixin],

  props: {
    menuIsOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menuList: {},
      langChange: false,
    };
  },
  watch: {
    '$store.state.main.indx.menus': {
      immediate: true,

      handler: function(val) {
        this.index();
      },
    },
    menuIsOpen(val) {
      let root = document.getElementsByTagName('html')[0];
      if (val) {
        root.setAttribute('class', 'disable-scroll');
        document.querySelector('.outside-menu').style
          ? (document.querySelector('.outside-menu').style.height =
              document.body.scrollHeight + 'px')
          : '';
      } else {
        root.removeAttribute('class', 'disable-scroll');
      }
    },
  },

  methods: {
    index() {
      this.menuList = this.getMenuFlat('main', 0);
    },
    changeLanguage(lang) {
      this.langChange = true;
      setTimeout(() => {
        this.langChange = false;
      }, 700);
      setTimeout(() => {
        this.$router.push(lang);
      }, 750);
    },
  },
  mounted() {},
};
</script>

<style scoped>
.outside-menu {
  opacity: 0;
  visibility: hidden;
  max-width: calc(100vw - 460px);
  width: 100vw;
  height: 10000px;
  position: absolute;
  max-height: 0;
  right: 0;
  top: 0;
  /*background-color: rgba(67,80,89,.3);*/
  z-index: -1;
}
.menu-bottom-row,
.menu-list {
  opacity: 1;
  visibility: visible;
  transition: 0.8s ease;
}
.menu-bottom-row.change,
.menu-list.change {
  opacity: 0;
  visibility: hidden;
}
.outside-menu.active {
  opacity: 1;
  visibility: visible;
  max-height: unset;
  z-index: 9991;
}
.side-menu {
  width: 40vw;
  max-width: 530px;
  height: 100vh;
  background-color: #ffffff;
  border-radius: 10px;
  position: fixed;
  z-index: 9999;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transform: translateX(-1000px);
  transition: 1s ease;
}
.side-menu.open {
  transform: translateX(-10px);
  transition: 0.7s ease;
}
.side-menu .close {
  border-radius: 10px;
  width: 44px;
  height: 44px;
  margin-bottom: 40px;
  background: var(--light-green);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.background-filter {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 999;
  background-color: rgba(67, 80, 89, 0.3);
}
.side-menu-content {
  padding-left: 25%;
  margin: 20px;

  overflow: auto;
}
.side-menu-content::-webkit-scrollbar {
  width: 10px;
  transition: 0.5s;
}
.side-menu-content::-webkit-scrollbar-track {
  background: #f1f1f1;
  transition: 0.5s;
}
.side-menu-content::-webkit-scrollbar-thumb {
  background: var(--green);
  transition: 1s;
  border-radius: 50px;
}
.menu-item {
  font-family: var(--noto-bold);
  font-size: 21px;
  margin-bottom: 35px;
  cursor: pointer;
  text-transform: capitalize;
}
.menu-item.active,
.menu-item:hover {
  color: var(--green);
}
.language-button span {
  background-color: #d9eeeb;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 11px 15px;
  color: #81c7bd;
  transition: 0.3s ease !important;
  cursor: pointer;
  font-size: 13px;
}
.language-button:hover span {
  background-color: var(--green);
  color: #ffffff;
  transition: 0.3s ease important;
}
.row-link {
  font-size: 13px;
  font-family: var(--noto-semibold);
  text-decoration: underline;
  text-transform: uppercase;
  cursor: pointer;
}
.row-link:first-child {
  margin-bottom: 20px;
}
.side-menu-bottom {
  display: none;
}

@media screen and (max-width: 1024px) {
  .side-menu {
    width: 60vw;
  }
  .outside-menu {
    max-width: calc(100vw - 400px);
  }
}

@media screen and (max-width: 767px) {
  .side-menu {
    width: 100%;
    max-width: unset;
    border-radius: unset;
  }

  .side-menu.open {
    transform: translateX(0);
  }

  .side-menu-content {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    padding-left: 0;
    padding-top: 15px;
  }
  .menu-list {
    max-height: 95vh;
    overflow-y: auto;
  }
  .menu-list::-webkit-scrollbar {
    display: none;
  }
  .menu-item {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .side-menu .close {
    width: 34px;
    height: 34px;
  }
  .side-menu-bottom {
    display: flex;
    margin: 0 20px 20px;
    padding-top: 30px;
    justify-content: space-between;
    border-top: 1px solid #f7f7f7;
  }
  .language-button {
    display: block;
  }
}
</style>

<style>
@media screen and (max-width: 767px) {
  .disable-scroll {
    overflow: hidden !important;
  }
}
</style>
