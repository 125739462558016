<template>
    <div class="user-room-container">
        <section class="container-wrapper user-room">
            <div
                class="user-top-row"
                data-aos="fade-right"
                data-aos-delay="100"
            >
                <div class="user-top-row-text">
                    <img
                        loading="lazy"
                        src="../assets/img/user-icon.svg"
                        alt="user"
                    />
                    <h1 class="user-name">
                        {{
                            !getAuthData
                                ? $fn.tr("Please Attach Order Invoice")
                                : getAuthData.fullname
                        }}
                    </h1>
                </div>
                <div v-if="getAuthData" class="button-content center-flex">
                    <div class="button-content-row"></div>
                    <div class="button-content-row" @click="logOut">
                        <img
                            loading="lazy"
                            src="../assets/img/log-out.svg"
                            alt="logout"
                        />
                        <div class="button-text">{{ $fn.tr("Log Out") }}</div>
                    </div>
                </div>
                <div v-else class="guest-button-row button-content center-flex">
                    <div class="button-content-row">
                        <div class="button-text">
                            {{
                                $fn.tr(
                                    "For Order Confirmation you need to attache order invoice"
                                )
                            }}
                        </div>
                        <div class="guest-button-plus center-flex">
                            <img
                                loading="lazy"
                                src="../assets/img/cross2.svg"
                                alt="cross"
                            />
                        </div>
                    </div>
                    <!--  pdf -->
                    <!--                    <div class="button-content-row">-->
                    <!--                        <img loading="lazy" class="pdf-guest" src="../assets/img/pdf-white.svg" alt="pdf">-->
                    <!--                        <div class="button-text">Download Invoice <br>-->
                    <!--                            <span>Order32901399010001.pdf</span>-->
                    <!--                        </div>-->
                    <!--                    </div>-->
                </div>
            </div>
            <div class="user-rows-container">
                <div
                    data-aos="fade-right"
                    data-aos-delay="200"
                    v-if="getAuthData"
                    class="user-aside-menu"
                >
                    <div
                        class="menu-item"
                        @click="
                            $router.replace(
                                `/${$store.state.main.indx.locale}/user-room/1`
                            )
                        "
                        :class="{ active: paramId === 1 }"
                    >
                        {{ $fn.tr("Personal Information") }}
                    </div>
                    <div
                        class="menu-item"
                        @click="
                            $router.replace(
                                `/${$store.state.main.indx.locale}/user-room/2`
                            )
                        "
                        :class="{ active: paramId === 2 }"
                    >
                        {{ $fn.tr("Change Password") }}
                    </div>
                    <div
                        class="menu-item"
                        @click="
                            $router.replace(
                                `/${$store.state.main.indx.locale}/user-room/3`
                            )
                        "
                        :class="{ active: paramId === 3 }"
                    >
                        {{ $fn.tr("Order History") }}
                    </div>
                    <div
                        class="menu-item"
                        @click="
                            $router.replace(
                                `/${$store.state.main.indx.locale}/user-room/4`
                            )
                        "
                        :class="{ active: paramId === 4 }"
                    >
                        {{ $fn.tr("Shipping Address") }}
                    </div>
                </div>
                <div class="dropdown-menu" :class="{ open: openDropdown }">
                    <div
                        class="dropdown-menu-container"
                        @click="openDropdown = !openDropdown"
                    >
                        <div class="menu-item active">
                            {{ $fn.tr(menuState) }}
                        </div>
                        <div class="center-flex">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                :class="{
                                    'active-image-dropdown': openDropdown
                                }"
                            >
                                <g
                                    id="Group_3596"
                                    data-name="Group 3596"
                                    transform="translate(0 24) rotate(-90)"
                                >
                                    <rect
                                        id="Rectangle_48"
                                        data-name="Rectangle 48"
                                        width="24"
                                        height="24"
                                        fill="rgba(255,0,0,0)"
                                    />
                                    <g
                                        id="Group_820"
                                        data-name="Group 820"
                                        transform="translate(5.856 2)"
                                    >
                                        <path
                                            id="Path_331"
                                            data-name="Path 331"
                                            d="M213.584,780.046a1.144,1.144,0,0,0,.809-1.953l-8.047-8.047L214.393,762a1.144,1.144,0,1,0-1.618-1.618l-8.856,8.856a1.144,1.144,0,0,0,0,1.618l8.856,8.856A1.14,1.14,0,0,0,213.584,780.046Z"
                                            transform="translate(-203.584 -760.046)"
                                            fill="#435059"
                                        />
                                    </g>
                                </g>
                            </svg>
                        </div>
                    </div>
                    <div class="dropdown-list">
                        <div
                            class="menu-item"
                            v-for="menu in menuOptions"
                            @click="selectDropdownItem(menu)"
                            :key="menu.id"
                        >
                            {{ $fn.tr(menu.title) }}
                        </div>
                    </div>
                </div>

                <div
                    class="user-content personal-info"
                    data-aos="fade-left"
                    data-aos-delay="200"
                    v-if="paramId === 1 && getAuthData"
                >
                    <div class="personal-info-main-wrapp">
                        <div
                            style="display: flex; flex-direction: column; width: 100%;"
                        >
                            <AddressItem
                                v-if="getAuthData"
                                data-aos="fade"
                                data-aos-duration="1000"
                                @editAddress="editAddress"
                                data-aos-delay="200"
                                class="personal-info-address"
                                editText="edit Information"
                                :address="activeAddress"
                            />
                        </div>

                        <div
                            data-aos="fade"
                            data-aos-duration="1000"
                            data-aos-delay="200"
                            class="total-orders center-flex"
                        >
                            <img
                                loading="lazy"
                                src="../assets/img/feather-package.svg"
                                alt="package"
                            />
                            <div class="total-orders-text">
                                {{ $fn.tr("Total Orders") }}:
                            </div>
                            <div class="total-order-number">
                                {{ _.size(getOrderHistory) || 0 }}
                            </div>
                        </div>
                    </div>
                    <div
                        class="form"
                        id="form-element"
                        data-aos="fade-down"
                        v-if="editAddressForm"
                    >
                        <div class="content-top-title margin-b-0">
                            {{ $fn.tr("Edit Personal Information") }}
                        </div>
                        <div class="form-container">
                            <div class="form-input-item">
                                <div class="label">
                                    {{ $fn.tr("Personal number") }}
                                    <span>*</span>
                                </div>
                                <input
                                    v-model="
                                        editAddressFormData.personal_number
                                    "
                                    type="number"
                                />
                            </div>
                            <div class="form-input-item">
                                <div class="label">
                                    {{ $fn.tr("First & Last Name") }}
                                    <span>*</span>
                                </div>
                                <input
                                    v-model="editAddressFormData.fullname"
                                    type="text"
                                />
                            </div>
                            <div class="form-input-item">
                                <div class="label">
                                    {{ $fn.tr("Phone Number") }}<span>*</span>
                                </div>
                                <input
                                    v-model="editAddressFormData.phone"
                                    type="number"
                                    placeholder="XXX - XXX - XXX"
                                />
                            </div>
                            <div class="form-input-item">
                                <div class="label">
                                    {{ $fn.tr("Email") }} <span>*</span>
                                </div>
                                <input
                                    type="email"
                                    v-model="editAddressFormData.email"
                                />
                            </div>
                            <div class="form-input-item">
                                <div class="label">
                                    {{ $fn.tr("City") }} <span>*</span>
                                </div>
                                <input
                                    type="text"
                                    v-model="editAddressFormData.city"
                                    :placeholder="$fn.tr('Choose City')"
                                />
                            </div>
                            <div class="form-input-item">
                                <div class="label">
                                    {{ $fn.tr("Address") }}<span>*</span>
                                </div>
                                <input
                                    type="text"
                                    v-model="editAddressFormData.address"
                                />
                            </div>
                            <div class="form-input-buttons">
                                <div
                                    class="form-button center-flex"
                                    @click="cancelAddress"
                                >
                                    {{ $fn.tr("CANCEL") }}
                                </div>
                                <div
                                    class="form-button center-flex"
                                    @click="submitEditPersonalInfo"
                                >
                                    {{ $fn.tr("Save Information") }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="form"
                        id="form-element3"
                        data-aos="fade-down"
                        v-if="addNewPerson && !addNewAddress"
                    >
                        <div class="content-top-title margin-b-0">
                            {{ $fn.tr("Add New Person") }}
                        </div>
                        <div class="form-container">
                            <div class="form-input-item">
                                <div class="label">
                                    {{ $fn.tr("Personal Number") }}
                                    <span>*</span>
                                </div>
                                <input
                                    :class="{
                                        'border-error':
                                            (!$v.newPersonFormData
                                                .personal_number.required ||
                                                !$v.newPersonFormData
                                                    .personal_number
                                                    .minLength) &&
                                            isClicked
                                    }"
                                    v-model="
                                        $v.newPersonFormData.personal_number
                                            .$model
                                    "
                                    type="number"
                                />
                                <div
                                    class="label red"
                                    v-if="
                                        !$v.newPersonFormData.personal_number
                                            .required && isClicked
                                    "
                                >
                                    {{ $fn.tr("Personal Number is required") }}
                                </div>
                            </div>
                            <div class="form-input-item">
                                <div class="label">
                                    {{ $fn.tr("First & Last Name") }}
                                    <span>*</span>
                                </div>

                                <input
                                    :class="{
                                        'border-error':
                                            (!$v.newPersonFormData.fullname
                                                .required ||
                                                !$v.newPersonFormData.fullname
                                                    .minLength) &&
                                            isClicked
                                    }"
                                    v-model="
                                        $v.newPersonFormData.fullname.$model
                                    "
                                    type="text"
                                />
                                <div
                                    class="label red"
                                    v-if="
                                        !$v.newPersonFormData.fullname
                                            .required && isClicked
                                    "
                                >
                                    {{
                                        $fn.tr(
                                            "First Name & Last Name is required"
                                        )
                                    }}
                                </div>
                            </div>
                            <div class="form-input-item">
                                <div class="label">
                                    {{ $fn.tr("Phone Number") }}<span>*</span>
                                </div>
                                <input
                                    :class="{
                                        'border-error':
                                            (!$v.newPersonFormData.phone
                                                .required ||
                                                !$v.newPersonFormData.phone
                                                    .minLength) &&
                                            isClicked
                                    }"
                                    v-model="$v.newPersonFormData.phone.$model"
                                    placeholder="XXX - XXX - XXX"
                                    type="number"
                                />
                                <div
                                    class="label red"
                                    v-if="
                                        !$v.newPersonFormData.phone.required &&
                                            isClicked
                                    "
                                >
                                    {{ $fn.tr("Phone is required") }}
                                </div>
                            </div>
                            <div class="form-input-item">
                                <div class="label">
                                    {{ $fn.tr("Email") }}<span>*</span>
                                </div>
                                <input
                                    :class="{
                                        'border-error':
                                            (!$v.newPersonFormData.email
                                                .required ||
                                                !$v.newPersonFormData.phone
                                                    .minLength ||
                                                !$v.newPersonFormData.email
                                                    .minLength) &&
                                            isClicked
                                    }"
                                    v-model="$v.newPersonFormData.email.$model"
                                    placeholder="XXX - XXX - XXX"
                                    type="text"
                                />
                                <div
                                    class="label red"
                                    v-if="
                                        !$v.newPersonFormData.email.required &&
                                            isClicked
                                    "
                                >
                                    {{ $fn.tr("Email is required") }}
                                </div>
                                <div
                                    class="label red"
                                    v-if="
                                        !$v.newPersonFormData.email.email &&
                                            isClicked
                                    "
                                >
                                    {{
                                        $fn.tr(
                                            "Email is Not Formatted Correctly"
                                        )
                                    }}
                                </div>
                            </div>
                            <div class="form-input-item">
                                <div class="label">
                                    {{ $fn.tr("City") }} <span>*</span>
                                </div>
                                <input
                                    :class="{
                                        'border-error':
                                            (!$v.newPersonFormData.city
                                                .required ||
                                                !$v.newPersonFormData.city
                                                    .minLength) &&
                                            isClicked
                                    }"
                                    v-model="$v.newPersonFormData.city.$model"
                                    :placeholder="$fn.tr('Choose City')"
                                    type="text"
                                />
                                <div
                                    class="label red"
                                    v-if="
                                        !$v.newPersonFormData.city.required &&
                                            isClicked
                                    "
                                >
                                    {{ $fn.tr("City is required") }}
                                </div>
                            </div>
                            <div class="form-input-item">
                                <div class="label">
                                    {{ $fn.tr("Address") }}<span>*</span>
                                </div>
                                <input
                                    :class="{
                                        'border-error':
                                            (!$v.newPersonFormData.address
                                                .required ||
                                                !$v.newPersonFormData.address
                                                    .minLength) &&
                                            isClicked
                                    }"
                                    v-model="
                                        $v.newPersonFormData.address.$model
                                    "
                                    type="text"
                                />
                                <div
                                    class="label red"
                                    v-if="
                                        !$v.newPersonFormData.address
                                            .required && isClicked
                                    "
                                >
                                    {{ $fn.tr("Address is required") }}
                                </div>
                            </div>
                            <div class="form-input-buttons">
                                <div
                                    class="form-button center-flex"
                                    @click="cancelAddress"
                                >
                                    {{ $fn.tr("CANCEL") }}
                                </div>
                                <div
                                    class="form-button center-flex"
                                    @click="submitAddPerson"
                                >
                                    {{ $fn.tr("Save Changes") }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="paramId === 2 && getAuthData">
                    <div
                        class="user-content form"
                        data-aos="fade"
                        data-aos-duration="1000"
                        data-aos-delay="200"
                    >
                        <div
                            data-aos="fade"
                            data-aos-duration="1000"
                            data-aos-delay="200"
                        >
                            <div class="content-top-title margin-b-0">
                                {{ $fn.tr("Change Password") }}
                            </div>
                        </div>

                        <form class="form-container">
                            <div
                                class="form-input-item"
                                data-aos="fade"
                                data-aos-duration="1000"
                                data-aos-delay="200"
                            >
                                <div class="label">
                                    {{ $fn.tr("Old Password") }} <span>*</span>
                                </div>
                                <input
                                    :class="{
                                        'border-error':
                                            (!$v.changePassword.oldPassword
                                                .required ||
                                                !$v.changePassword.oldPassword
                                                    .minLength) &&
                                            isClicked
                                    }"
                                    v-model="
                                        $v.changePassword.oldPassword.$model
                                    "
                                    type="password"
                                />
                                <div
                                    class="label red"
                                    v-if="
                                        !$v.changePassword.oldPassword
                                            .required && isClicked
                                    "
                                >
                                    {{ $fn.tr("Old Password is required") }}
                                </div>
                                <div
                                    class="label red"
                                    v-if="
                                        $v.changePassword.oldPassword.$model &&
                                            !$v.changePassword.oldPassword
                                                .minLength &&
                                            isClicked
                                    "
                                >
                                    {{
                                        $fn.tr(
                                            "Password must be at least 8 characters"
                                        )
                                    }}
                                </div>
                            </div>
                            <div
                                class="form-input-item"
                                data-aos="fade"
                                data-aos-duration="1000"
                                data-aos-delay="200"
                            >
                                <div class="label">
                                    {{ $fn.tr("New Password") }} <span>*</span>
                                </div>
                                <input
                                    :class="{
                                        'border-error':
                                            (!$v.changePassword.newPassword
                                                .required ||
                                                !$v.changePassword.newPassword
                                                    .minLength) &&
                                            isClicked
                                    }"
                                    v-model="
                                        $v.changePassword.newPassword.$model
                                    "
                                    type="password"
                                />
                                <div
                                    class="label red"
                                    v-if="
                                        !$v.changePassword.newPassword
                                            .required && isClicked
                                    "
                                >
                                    {{ $fn.tr("New Password is required") }}
                                </div>
                                <div
                                    class="label red"
                                    v-if="
                                        $v.changePassword.newPassword.$model &&
                                            !$v.changePassword.newPassword
                                                .minLength &&
                                            isClicked
                                    "
                                >
                                    {{
                                        $fn.tr(
                                            "Password must be at least 8 characters"
                                        )
                                    }}
                                </div>
                            </div>
                            <div
                                class="form-input-item"
                                data-aos="fade"
                                data-aos-duration="1000"
                                data-aos-delay="200"
                            >
                                <div class="label">
                                    {{ $fn.tr("Confirm New Password ")
                                    }}<span>*</span>
                                </div>
                                <input
                                    :class="{
                                        'border-error':
                                            (!$v.changePassword.confirm
                                                .required ||
                                                !$v.changePassword.confirm
                                                    .sameAsPassword) &&
                                            isClicked
                                    }"
                                    v-model="$v.changePassword.confirm.$model"
                                    type="password"
                                />
                                <div
                                    class="label red"
                                    v-if="
                                        !$v.changePassword.confirm
                                            .sameAsPassword && isClicked
                                    "
                                >
                                    {{ $fn.tr("Confirm your password") }}
                                </div>
                                <div
                                    class="label red"
                                    v-if="
                                        !$v.changePassword.confirm.required &&
                                            isClicked
                                    "
                                >
                                    {{ $fn.tr("Confirm password is required") }}
                                </div>
                            </div>
                            <div class="form-input-buttons">
                                <div
                                    class="form-button center-flex"
                                    data-aos="fade"
                                    data-aos-duration="1000"
                                    data-aos-delay="200"
                                >
                                    {{ $fn.tr("CANCEL") }}
                                </div>
                                <div
                                    @click="saveChanges"
                                    class="form-button center-flex"
                                    data-aos="fade"
                                    data-aos-duration="1000"
                                    data-aos-delay="200"
                                >
                                    {{ $fn.tr("Save Changes") }}
                                </div>
                                <div class="label red" v-if="errorPassword">
                                    {{ errorPassword }}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div
                    class="user-content order-history"
                    v-if="paramId === 3 || !getAuthData"
                >
                    <div
                        data-aos="fade"
                        data-aos-duration="1000"
                        data-aos-delay="200"
                        class="content-top-title a"
                    >
                        {{ $fn.tr("Order History") }}
                    </div>

                    <div
                        data-aos="fade"
                        data-aos-duration="1000"
                        data-aos-delay="200"
                        class="order-history-table"
                    >
                        <div class="order-history-table-header">
                            <div class="order-history-row">
                                {{ $fn.tr("Order Date") }}
                            </div>
                            <div class="order-history-row">
                                {{ $fn.tr("Order Number") }}
                            </div>
                            <div class="order-history-row">
                                {{ $fn.tr("Items Count") }}
                            </div>
                            <div class="order-history-row">
                                {{ $fn.tr("Total Price") }}
                            </div>
                            <div class="order-history-row">
                                {{ $fn.tr("Shipping Status") }}
                            </div>
                            <div class="order-history-row">
                                {{ $fn.tr("Print") }}
                            </div>
                        </div>
                        <div class="order-history-table-content">
                            <order-history-item
                                :active="activeHistoryId === order.id"
                                v-for="(order, indx) in getOrderHistory"
                                :key="indx"
                                :order="order"
                                @clickedOrder="historyClicked"
                            />
                        </div>
                    </div>
                </div>

                <div
                    class="user-content personal-info"
                    data-aos="fade"
                    data-aos-delay="200"
                    v-if="paramId === 4 && getAuthData"
                >
                    <div
                        class="personal-info-main-wrapp"
                        style="display: flex; flex-direction: column; width: 100%;"
                    >
                        <div
                            v-for="(address,
                            index) in getAuthDataAddressesSorted"
                            :key="index"
                            data-aos="fade"
                            data-aos-duration="1000"
                        >
                            <AddressItem
                                v-if="getAuthData"
                                @editAddress="editAddress"
                                data-aos-delay="200"
                                :isShippingAddress="true"
                                @editAddressFromAuth="editAddressFromAuth"
                                :addressFromAuth="true"
                                class="personal-info-address"
                                editText="edit Shipping"
                                :address="address"
                            />
                        </div>
                    </div>
                    <div class="action-buttons" data-aos="fade">
                        <label class="attach margin-t-50">
                            <div
                                class="form-file-attach"
                                v-if="!addNewAddress && !editAddressFormAuth"
                                @click="addNewAddress = !addNewAddress"
                            >
                                <div
                                    class="form-file-attach-button center-flex"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="15.424"
                                        height="15.424"
                                        viewBox="0 0 15.424 15.424"
                                    >
                                        <path
                                            id="Icon_metro-cancel"
                                            data-name="Icon metro-cancel"
                                            d="M22.815,9.64l-4.82,4.82-4.82-4.82-2.892,2.892,4.82,4.82-4.82,4.82,2.892,2.892,4.82-4.82,4.82,4.82,2.892-2.892-4.82-4.82,4.82-4.82Z"
                                            transform="translate(-10.283 -9.64)"
                                            fill="#ffffff"
                                        />
                                    </svg>
                                </div>
                                <div class="form-file-attach-title">
                                    {{ $fn.tr("Add New Address") }}
                                </div>
                            </div>
                        </label>
                    </div>
                    <div
                        class="form"
                        id="form-element2"
                        data-aos="fade-down"
                        v-if="editAddressFormAuth"
                    >
                        <div class="content-top-title margin-b-0">
                            {{ $fn.tr("Edit Shipping Information") }}
                        </div>
                        <div class="form-container">
                            <div class="form-input-item">
                                <div class="label">
                                    {{ $fn.tr("Place name") }} <span>*</span>
                                </div>
                                <input
                                    type="text"
                                    v-model="editAddressFormData.name"
                                />
                            </div>
                            <div class="form-input-item">
                                <div class="label">
                                    {{ $fn.tr("City") }} <span>*</span>
                                </div>
                                <input
                                    type="text"
                                    v-model="editAddressFormData.city"
                                    :placeholder="$fn.tr('Choose City')"
                                />
                            </div>
                            <div class="form-input-item">
                                <div class="label">
                                    {{ $fn.tr("Address") }}<span>*</span>
                                </div>
                                <input
                                    type="text"
                                    v-model="editAddressFormData.address"
                                />
                            </div>
                            <div class="form-input-buttons">
                                <div
                                    class="form-button center-flex"
                                    @click="cancelAddress"
                                >
                                    {{ $fn.tr("CANCEL") }}
                                </div>
                                <div
                                    class="form-button center-flex"
                                    @click="submitEditAddress"
                                >
                                    {{ $fn.tr("Save Information") }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="form"
                        id="form-element4"
                        data-aos="fade-down"
                        v-if="!addNewPerson && addNewAddress"
                    >
                        <div class="content-top-title margin-b-0">
                            {{ $fn.tr("Add New Address") }}
                        </div>
                        <div class="form-container">
                            <div class="form-input-item">
                                <div class="label">
                                    {{ $fn.tr("Place Name") }} <span>*</span>
                                </div>
                                <input
                                    :class="{
                                        'border-error':
                                            (!$v.newAddressFormData.name
                                                .required ||
                                                !$v.newAddressFormData.name
                                                    .minLength) &&
                                            isClicked
                                    }"
                                    v-model="$v.newAddressFormData.name.$model"
                                    type="text"
                                />
                                <div
                                    class="label red"
                                    v-if="
                                        !$v.newAddressFormData.name.required &&
                                            isClicked
                                    "
                                >
                                    {{ $fn.tr("Place name is required") }}
                                </div>
                            </div>
                            <div class="form-input-item">
                                <div class="label">
                                    {{ $fn.tr("City") }} <span>*</span>
                                </div>
                                <input
                                    :class="{
                                        'border-error':
                                            (!$v.newAddressFormData.city
                                                .required ||
                                                !$v.newAddressFormData.city
                                                    .minLength) &&
                                            isClicked
                                    }"
                                    v-model="$v.newAddressFormData.city.$model"
                                    :placeholder="$fn.tr('Choose City')"
                                    type="text"
                                />
                                <div
                                    class="label red"
                                    v-if="
                                        !$v.newAddressFormData.city.required &&
                                            isClicked
                                    "
                                >
                                    {{ $fn.tr("City is required") }}
                                </div>
                            </div>
                            <div class="form-input-item">
                                <div class="label">
                                    {{ $fn.tr("Address") }}<span>*</span>
                                </div>
                                <input
                                    :class="{
                                        'border-error':
                                            (!$v.newAddressFormData.address
                                                .required ||
                                                !$v.newAddressFormData.address
                                                    .minLength) &&
                                            isClicked
                                    }"
                                    v-model="
                                        $v.newAddressFormData.address.$model
                                    "
                                    type="text"
                                />
                                <div
                                    class="label red"
                                    v-if="
                                        !$v.newAddressFormData.address
                                            .required && isClicked
                                    "
                                >
                                    {{ $fn.tr("Address is required") }}
                                </div>
                            </div>
                            <div class="form-input-buttons">
                                <div
                                    class="form-button center-flex"
                                    @click="cancelAddress"
                                >
                                    {{ $fn.tr("CANCEL") }}
                                </div>
                                <div
                                    class="form-button center-flex"
                                    @click="addNewAddressAction"
                                >
                                    {{ $fn.tr("Save Changes") }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <img
            class="decor-img"
            loading="lazy"
            src="../assets/img/decor.png"
            alt="decor"
        />
        <CartInfo
            :data="
                _.get(
                    this.$store.state.main.current_content,
                    'secondary.InnerPagesLinksBlock.data.list'
                )
            "
        />
        <Notification
            :active="passwordChangeNotification"
            @hide="passwordChangeNotification = false"
        >
            {{ $fn.tr("Password changed successfully!") }}
        </Notification>
    </div>
</template>

<script>
import AddressItem from "../components/checkout/AddressItem";
import CartInfo from "../components/CartInfo";
import orderHistoryItem from "../components/orderHistoryItem";
import { validationMixin } from "vuelidate";
import { required, minLength, sameAs, email } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import Vue from "vue";
import Notification from "../components/Notification";

export default {
    mixins: [validationMixin],
    components: {
        AddressItem,
        CartInfo,
        orderHistoryItem,
        Notification
    },
    validations: {
        changePassword: {
            oldPassword: {
                required,
                minLength: minLength(8)
            },
            newPassword: {
                required,
                minLength: minLength(8)
            },

            confirm: {
                required,
                sameAsPassword: sameAs("newPassword")
            }
        },
        newPersonFormData: {
            personal_number: {
                required,
                minLength: minLength(11)
            },
            fullname: {
                required,
                minLength: minLength(3)
            },
            phone: {
                required,
                minLength: minLength(3)
            },
            email: {
                required,
                minLength: minLength(3),
                email
            },
            city: {
                required,
                minLength: minLength(3)
            },
            address: {
                required,
                minLength: minLength(3)
            }
        },
        newAddressFormData: {
            name: {
                required,
                minLength: minLength(3)
            },
            city: {
                required,
                minLength: minLength(3)
            },
            address: {
                required,
                minLength: minLength(3)
            }
        }
    },
    data() {
        return {
            changePassword: {
                oldPassword: "",
                newPassword: "",
                confirm: ""
            },
            isClicked: false,
            passwordChangeNotification: false,
            personalInfo: {
                id: 123123123,
                name: "giorgi khathcapuridze",
                address: "Machavariani Str. 59a",
                city: "Tbilisi, Georgia",
                phone: "595 394 239",
                email: "ada@gmail.com"
            },
            openDropdown: false,
            menuState: "Personal Information",
            activeHistoryId: null,
            orders: [
                {
                    id: 1,
                    date: "20 DEC 2020",
                    phone: 2575345545,
                    count: 2,
                    price: "$ 80 ",
                    shippingStatus: "delivered"
                },
                {
                    id: 2,
                    date: "20 DEC 2020",
                    phone: 2575345545,
                    count: 2,
                    price: "$ 812 ",
                    shippingStatus: "pending"
                },
                {
                    id: 3,
                    date: "20 DEC 2020",
                    phone: 2575345545,
                    count: 12,
                    price: "$ 91",
                    shippingStatus: "shipped"
                }
            ],
            menuOptions: [
                {
                    id: 1,
                    title: "Personal Information"
                },
                {
                    id: 2,
                    title: "Change Password"
                },
                {
                    id: 3,
                    title: "Order History"
                }
            ],
            guest: false,
            editAddressForm: false,
            editAddressFormAuth: false,
            addNewPerson: false,
            addNewAddress: false,
            editAddressFormData: {},
            newPersonFormData: {
                personal_number: "",
                fullname: "",
                phone: "",
                email: "",
                city: "",
                address: ""
            },
            newAddressFormData: {
                name: "",
                city: "",
                address: ""
            },
            paramId: 1,
            errorPassword: ""
        };
    },
    computed: {
        ...mapGetters({
            getAuthData: "user/getAuthData",
            getOrderHistory: "user/getOrderHistory",
            getAuthDataAddresses: "user/getAuthDataAddresses"
        }),
        getAuthDataAddressesSorted() {
            let addresses = this.getAuthDataAddresses.sort(
                (a, b) => b.sort - a.sort
            );
            // if (!addresses.filter(r => r.sort === 1)[0].name)
            //     addresses.filter(
            //         r => r.sort === 1
            //     )[0].name = this.getAuthData.fullname;
            return addresses;
        },
        activeAddress() {
            let personalInfo = { ...this.getAuthData };
            let shippingAddress = this.getAuthDataAddresses.filter(
                err => err.sort === 1 && err.address_type === "shipping"
            )[0];
            personalInfo.address = _.get(shippingAddress, "address");
            personalInfo.city = _.get(shippingAddress, "city");
            return personalInfo;
        }
    },
    watch: {
        "$route.params.id": {
            immediate: true,
            handler(val) {
                this.paramId = val ? parseInt(val) : 1;
                this.cancelAddress();
            }
        },
        addNewAddress(val) {
            if (val) {
                this.addNewPerson = !val;
                this.editAddressForm = false;
                this.editAddressFormAuth = false;
            }
        },
        addNewPerson(val) {
            if (val) {
                this.addNewAddress = !val;
                this.editAddressForm = false;
                this.editAddressFormAuth = false;
            }
        },
        editAddressForm(val) {
            if (val) {
                this.addNewAddress = false;
                this.addNewPerson = false;
                this.editAddressFormAuth = false;
            }
        },
        editAddressFormAuth(val) {
            if (val) {
                this.addNewAddress = false;
                this.editAddressForm = false;
                this.addNewPerson = false;
            }
        }
    },
    methods: {
        animateScrollHeader() {
            let header = document.querySelector("#scrollHeader");
            setTimeout(() => {
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
            }, 250);
            setTimeout(() => {
                header.classList.remove("transform-top-class");
            }, 650);
            header ? header.classList.add("transform-top-class") : "";
            header ? header.classList.remove("hide-top-navbar") : "";
        },
        editAddress(address) {
            this.editAddressFormData = { ...address };
            this.editAddressForm = true;
            setTimeout(() => {
                let element = document.getElementById("form-element");
                window.scrollTo({
                    top: element.offsetTop,
                    behavior: "smooth"
                });
            }, 500);
        },
        editAddressFromAuth(address) {
            this.editAddressFormData = { ...address };
            this.editAddressFormAuth = true;
            setTimeout(() => {
                let element2 = document.getElementById("form-element2");
                let element3 = document.getElementById("form-element3");
                window.scrollTo({
                    top: element2 ? element2.offsetTop : element3.offsetTop,
                    behavior: "smooth"
                });
            }, 500);
        },
        clearUserToken() {
            if (this.$cookies.get("userToken")) {
                this.$cookies.remove("userToken");
            } else if (sessionStorage.userToken) {
                sessionStorage.removeItem("userToken");
            }
        },
        logOut() {
            this.clearUserToken();
            Vue.set(this.$store.state.user, "authData", null);
            this.$router.push(`/${this.$store.state.main.locale}/home`);
        },
        cancelAddress() {
            this.editAddressForm = false;
            this.addNewAddress = false;
            this.addNewPerson = false;
            this.editAddressFormAuth = false;
            this.isClicked = false;
            this.newAddressFormData = {
                name: "",
                city: "",
                address: ""
            };
            this.newPersonFormData = {
                personal_number: "",
                fullname: "",
                phone: "",
                email: "",
                city: "",
                address: ""
            };
            this.animateScrollHeader();
        },
        submitEditAddress(person) {
            if (!person) this.editAddressFormData.address_type = "person";
            this.$store
                .dispatch("user/EditAddress", {
                    data: this.editAddressFormData,
                    token: this.$cookies.get("userToken")
                })
                .then(() => {
                    this.editAddressForm = false;
                    this.animateScrollHeader();
                    this.cancelAddress();
                });
        },
        submitEditPersonalInfo() {
            this.$store
                .dispatch("user/changePersonalData", {
                    data: this.editAddressFormData,
                    token: this.$cookies.get("userToken"),
                    isPersoanlInfo: true
                })
                .then(() => {
                    this.editAddressForm = false;
                    this.animateScrollHeader();
                    this.cancelAddress();
                });

            // this.submitEditAddress(true);
        },
        submitAddPerson() {
            this.isClicked = true;
            if (
                !this.$v.newPersonFormData.personal_number.required ||
                !this.$v.newPersonFormData.personal_number.minLength ||
                !this.$v.newPersonFormData.phone.required ||
                !this.$v.newPersonFormData.phone.minLength ||
                !this.$v.newPersonFormData.fullname.required ||
                !this.$v.newPersonFormData.fullname.minLength ||
                !this.$v.newPersonFormData.email.required ||
                !this.$v.newPersonFormData.email.minLength ||
                !this.$v.newPersonFormData.email.email ||
                !this.$v.newPersonFormData.city.required ||
                !this.$v.newPersonFormData.city.minLength ||
                !this.$v.newPersonFormData.address.required ||
                !this.$v.newPersonFormData.address.minLength
            ) {
                return;
            }
            this.newPersonFormData.address_type = "person";
            this.$store
                .dispatch("user/addAddress", {
                    data: this.newPersonFormData,
                    token: this.$cookies.get("userToken")
                })
                .then(() => {
                    this.cancelAddress();
                });
        },
        addNewAddressAction() {
            this.isClicked = true;
            if (
                !this.$v.newAddressFormData.name.required ||
                !this.$v.newAddressFormData.name.minLength ||
                !this.$v.newAddressFormData.city.required ||
                !this.$v.newAddressFormData.city.minLength ||
                !this.$v.newAddressFormData.address.required ||
                !this.$v.newAddressFormData.address.minLength
            ) {
                return;
            } else {
                this.newAddressFormData.email = this.getAuthData.email;
                this.$store
                    .dispatch("user/addAddress", {
                        token: this.$cookies.get("userToken"),
                        data: this.newAddressFormData
                    })
                    .then(() => {
                        this.cancelAddress();
                    });
            }
        },
        historyClicked(val) {
            this.activeHistoryId === val
                ? (this.activeHistoryId = null)
                : (this.activeHistoryId = val);
        },
        selectDropdownItem(val) {
            this.menuState = val.title;
            this.openDropdown = false;
            this.$router.replace(
                `/${this.$store.state.main.indx.locale}/user-room/${val.id}`
            );
        },
        saveChanges() {
            this.isClicked = true;
            if (
                !this.$v.changePassword.oldPassword.required ||
                !this.$v.changePassword.oldPassword.minLength ||
                !this.$v.changePassword.newPassword.required ||
                !this.$v.changePassword.newPassword.minLength ||
                !this.$v.changePassword.confirm.required ||
                !this.$v.changePassword.confirm.sameAsPassword
            ) {
                return;
            } else {
                this.$store
                    .dispatch("user/changePassword", {
                        data: this.changePassword,
                        token: this.$cookies.get("userToken")
                    })
                    .then(res => {

                        if (res.status === 200) {
                            this.isClicked = false;
                            this.errorPassword = "";
                            this.passwordChangeNotification = true;
                        }
                        if (res.status === 201) {
                            this.errorPassword = res.data;
                        }
                    });
            }
        }
    },

    mounted() {
        if (this.$route.params.id)
            this.paramId = parseInt(this.$route.params.id);

        this.$store.commit("main/setState", {
            key: "breadcrumbList",
            val: [{ title: "User room" }]
        });
    }
};
</script>

<style scoped>
.user-room {
    min-height: 867px;
}

.user-room-container {
    position: relative;
    margin-top: -25px;
    z-index: 9;
}

.action-buttons {
    margin-bottom: auto;
}

.margin-t-50 {
    margin-top: 50px;
}

.personal-info-main-wrapp {
    width: 100%;
    display: flex;
}

.user-rows-container {
    display: flex;
    margin-bottom: 185px;
}

.user-top-row {
    background-color: #f7f7f7;
    height: 110px;
    border-radius: 10px;
    width: 100%;
    display: flex;
    align-items: center;
}

.user-aside-menu {
    min-width: 250px;
    max-width: 250px;
    height: 300px;
    border-right: 1px solid #f7f7f7;
    text-align: left;
}

.form-file-attach {
    display: flex;
    align-items: center;
    margin: 20px 0;
    cursor: pointer;
}

.form-file-attach-button {
    width: 44px;
    height: 44px;
    background-color: #fec55e;
    border-radius: 10px;
    cursor: pointer;
}

.form-file-attach-button svg {
    transform: rotate(45deg);
}

.form-file-attach-title {
    color: #435059;
    font-family: var(--noto-bold);
    font-size: 21px;
    margin-left: 20px;
    transition: 0.3s ease;
}

.form-file-attach:hover .form-file-attach-title {
    color: var(--orange);
    transition: 0.3s ease;
}

.form .margin-b-0 {
    margin-bottom: 0;
}

.form .margin-b-t-0 {
    margin-bottom: 0;
    margin-top: 0;
}

.dropdown-menu {
    display: none;
}

.dropdown-menu svg {
    transition: 0.2s ease;
}

.user-aside-menu,
.personal-info {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
}

.active-image-dropdown {
    transform: rotate(180deg);
    transition: 0.2s ease;
}

.active-image-dropdown path {
    fill: var(--green);
    transition: 0.2s ease;
}

.menu-item {
    font-family: var(--noto-regular);
    font-size: 16px;
    margin-bottom: 30px;
    cursor: pointer;
}

.menu-item:hover {
    color: var(--green);
}

.menu-item.active {
    color: var(--green);
    font-family: var(--noto-bold);
}

.total-orders {
    flex-direction: column;
    width: 305px;
    height: 230px;
    background-color: var(--light-green);
    border-radius: 10px;
    margin-left: 30px;
}

.user-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: 30px;
}

.personal-info-address {
    width: 100%;
    background-color: #ffffff;
}

.flex-column > div {
    width: 100%;
}

.flex-column {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.user-top-row-text {
    display: flex;
    align-items: center;
    margin-left: 40px;
}

.user-name {
    font-size: 34px;
    font-family: var(--noto-bold);
    padding-left: 50px;
}

.button-content-row {
    display: flex;
    flex-direction: column;
}

.guest-button-row.button-content {
    width: 370px;
    padding-left: 30px;
}

.pdf-guest {
    margin-right: 20px;
}

.guest-button-plus {
    width: 71px;
    height: 71px;
    border-radius: 10px;
    border: 1px solid #ffffff;
    margin-right: 10px;
}

.guest-button-row.button-content .button-content-row {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.guest-button-row.button-content .button-text {
    width: 200px;
    margin-top: unset;
    margin: auto auto auto 0;
    line-height: 20px;
}

.button-text span {
    font-family: var(--noto-bold);
    font-size: 13px;
}

.total-orders-text {
    font-family: var(--noto-bold);
    margin-top: 17px;
    margin-bottom: 3px;
    font-size: 19px;
    text-align: center;
    padding: 0 5px;
}

.total-order-number {
    color: var(--orange);
    font-size: 34px;
    font-family: var(--noto-bold);
}

.button-content {
    display: flex;
    width: 89px;
    height: 89px;
    flex-direction: column;
    background-color: #fec55e;
    border-radius: 10px;
    cursor: pointer;
    margin-right: 10px;
    transition: 0.5s ease;
    margin-left: auto;
}

.button-text {
    margin-top: 10px;
    color: #ffffff;
    font-family: var(--noto-bold);
    font-size: 13px;
    line-height: 15px;
    text-transform: capitalize;
}

.decor-img {
    position: absolute;
    bottom: -10%;
    left: -25%;
    z-index: -1;
}

/*form*/
.form {
    display: flex;
    flex-direction: column;
}

.form-input-item {
    margin-bottom: 30px;
}

.form-input-item .label {
    color: #435059;
    font-family: var(--noto-regular);
    font-size: var(--normalFontSize);
    padding: 0 0 10px;
    display: flex;
}

.form-input-item .label span {
    color: #ff7675;
    font-size: 12px;
    margin-left: 5px;
}

.form-input-item input,
.form-input-item textarea {
    width: 100%;
    border: unset;
    border-radius: 10px;
    padding: 11px;
    background-color: #f7f7f7;
    font-size: 16px;
}

.form-input-checkbox-label {
    font-family: var(--noto-regular);
    font-size: 16px;
}

.form-input-buttons {
    margin-top: 60px;
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
    position: relative;
}
.form-input-buttons .red {
    position: absolute;
    bottom: -25px;
    right: 0;
}

.form-button {
    border-radius: 10px;
    cursor: pointer;
    font-family: var(--noto-bold);
    transition: 0.3s ease !important;
}
.form-button:hover {
    transition: 0.3s ease !important;
}

.form-button:first-child {
    background-color: #f7f7f7;
    width: 140px;
    height: 44px;
    font-size: 13px;
}

/*.form-button:first-child:hover {*/
/*    background-color: #435059;*/
/*    color: #F7F7F7;*/
/*    transition: .2s ease !important;*/
/*}*/

/*.form-button:nth-of-type(2) {*/
/*    background-color: var(--light-green);*/
/*    color: var(--green);*/
/*    width: 200px;*/
/*    height: 44px;*/
/*    transition: .2s ease;*/
/*}*/

/*.form-button:nth-of-type(2):hover {*/
/*    background-color: var(--green);*/
/*    color: #FFFFFF;*/
/*    transition: .2s ease !important;*/
/*}*/

.form-container .border-error {
    border: 1px solid #ff7675;
}

.form-container .red {
    font-size: 13px;
    padding-top: 5px;
    color: #ff7675;
    padding-bottom: 0;
}

.form-container {
    border: 1px solid #ecf0f1;
    background-color: #ffffff;
    padding: 30px;
    border-radius: 10px;
    width: 810px;
}

.content-top-title {
    font-family: var(--noto-bold);
    font-size: 21px;
    margin-bottom: 33px;
    margin-top: 55px;
    padding-left: 30px;
}

.order-history {
    display: flex;
    flex-direction: column;
    justify-content: unset;
}

/*table*/
.order-history-row {
    font-family: var(--noto-regular);
    font-size: 16px;
}

.order-history-table-header {
    display: flex;
    padding-left: 30px;
}

.order-history-row {
    width: 14%;
    text-align: center;
}

.order-history-row:nth-of-type(1) {
    text-align: left;
}

.order-history-row:nth-of-type(3) {
    width: 23%;
}

.order-history-row:nth-of-type(4) {
    width: 10%;
}

.order-history-row:nth-of-type(5) {
    width: 23%;
}

.order-history-row:nth-of-type(6) {
    width: 15%;
}

.dropdown-menu {
    border: 1px solid #f7f7f7;
    height: 80px;
}
.button-content img {
    width: 25px;
    margin: auto;
}

@media screen and (max-width: 1599px) {
    .decor-img {
        display: none;
    }

    .user-room {
        min-height: unset;
    }

    .user-rows-container {
        margin-bottom: 150px;
    }
}

@media screen and (max-width: 1355px) {
    .user-top-row-text img {
        width: 48px;
    }

    .user-top-row-text {
        margin-left: 25px;
    }

    .user-aside-menu {
        min-width: 167px;
        max-width: 167px;
    }

    .user-name {
        padding-left: 26px;
    }

    .order-history-row:nth-of-type(6) {
        text-align: left;
    }

    .container-wrapper {
        padding-left: 34px;
        padding-right: 25px;
    }

    .user-aside-menu,
    .personal-info {
        margin-top: 35px;
    }

    .menu-item,
    .user-aside-menu {
        width: 167px;
    }

    .user-content {
        margin-left: 50px;
    }

    .content-top-title {
        margin-bottom: 32px;
        margin-top: 36px;
    }

    .order-history-row {
        width: 16%;
    }

    .order-history-row:nth-of-type(3) {
        width: 18%;
    }

    .order-history-row:nth-of-type(5) {
        width: 23%;
    }

    .order-history-row:nth-of-type(4) {
        width: 11%;
    }

    .order-history-row:nth-of-type(3) {
        width: 18%;
        /*text-align: left;*/
    }

    .order-history-table-header {
        padding-left: 22px;
    }

    .guest-button-row.button-content {
        width: 300px;
    }
}

@media screen and (max-width: 1023px) {
    .personal-info-main-wrapp {
        flex-direction: column-reverse;
    }

    .user-rows-container {
        display: flex;
        flex-direction: column;
    }

    .user-room-container {
        margin-top: 0;
    }

    .user-top-row-text {
        margin-left: 35px;
    }

    .container-wrapper {
        padding-left: 33px;
        padding-right: 23px;
    }

    .user-content {
        margin-left: unset;
    }

    .user-aside-menu {
        display: none;
    }

    .dropdown-menu {
        display: block;
        margin-top: 20px;
        border-radius: 10px;
    }

    .content-top-title {
        padding-left: 20px;
    }

    .dropdown-menu .menu-item {
        margin-bottom: unset;
    }

    .dropdown-list {
        max-height: 0;
        overflow: hidden;
        position: relative;
        background: #f7f7f7;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .dropdown-list .menu-item {
        margin: 20px;
    }

    .dropdown-menu.open .dropdown-list {
        max-height: 300px;
        z-index: 99;
        margin-top: -2px;
    }

    .dropdown-menu-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 80px;
        padding: 0 20px;
        cursor: pointer;
    }

    .order-history-row {
        font-size: 15px;
    }

    .order-history-row:nth-of-type(6) {
        text-align: center;
        padding-right: 15px;
    }

    .personal-info {
        display: flex;
        flex-direction: column;
    }

    .total-orders {
        width: 100%;
        margin-left: 0;
        height: 200px;
        flex-direction: row;
    }

    .form-container {
        width: 100%;
    }

    .total-orders-text {
        margin: 0 30px;
    }

    .dropdown-menu-container .menu-item.active {
        font-size: 21px;
        width: unset;
    }
}

@media screen and (max-width: 767px) {
    .user-name {
        font-size: 13px;
        padding-left: 10px;
    }

    .user-room-container {
        margin-top: 3px;
    }

    .guest-button-row.button-content {
        width: 100%;
        height: 42px;
    }

    .guest-button-plus {
        width: unset;
        height: unset;
        border-radius: unset;
        border: unset;
    }

    .guest-button-row.button-content .button-text {
        line-height: 16px;
    }

    .user-top-row {
        display: flex;
        flex-direction: column;
    }

    .button-content-row {
        display: flex;
        flex-direction: row;
    }

    .button-text {
        margin-top: unset;
    }

    .user-top-row {
        height: 121px;
        justify-content: center;
        align-items: flex-start;
        padding: 0 10px;
    }

    .user-top-row-text {
        margin-bottom: 10px;
    }

    .user-top-row-text img {
        width: 40px;
    }

    .user-top-row-text {
        margin-left: 5px;
    }

    .button-text {
        margin-left: 10px;
    }

    .dropdown-menu-container,
    .dropdown-menu {
        height: 64px;
    }

    .form-container {
        padding: 20px;
    }

    .form-button {
        font-size: 13px;
        width: 45% !important;
    }

    .total-order-number {
        font-size: 21px;
    }

    .total-orders-text {
        font-size: 16px;
        margin: 0 20px;
    }

    .order-history-row:nth-of-type(3),
    .order-history-row:nth-of-type(4),
    .order-history-row:nth-of-type(5),
    .order-history-row:nth-of-type(6) {
        display: none;
    }

    .order-history-row {
        width: 42%;
    }

    .open-history {
        margin: 11px 0 20px;
    }

    .button-content {
        width: 100%;
        /*margin-top: 15px;*/
        height: 37px;
        flex-direction: row;
    }

    .button-content img {
        width: 20px;
    }

    .container-wrapper {
        padding-left: 20px;
        padding-right: 11px;
    }

    .content-top-title {
        margin-top: 25px;
    }

    .dropdown-menu-container .menu-item.active {
        font-size: 16px;
        width: unset;
    }
}
@media screen and (max-width: 510px) {
    .total-orders {
        flex-direction: column;
        margin-bottom: 21px;
    }
    .total-orders img {
        margin-bottom: 10px;
    }
    .menu-item {
        width: 85%;
    }
}
</style>

<style>
.user-rows-container .user-content .checkbox-default-radio {
    display: none;
}

.user-rows-container .personal-info-wrapper {
    padding: 25px 30px;
    height: fit-content;
}
</style>
