<template>
    <section>
        <div class="container-wrapper">
            <Parts
                :data="_.get(getProductData, 'relations')"
                :filterShow="filterShow"
                v-on:close-aside="closeAside"
            />

            <div class="product-content" :class="{ change: animationChange }">
                <swiper
                    class="product-slider"
                    ref="mySwiper"
                    :options="verticalSwiperOptions"
                    data-aos="fade-up"
                    data-aos-delay="400"
                >
                    <swiper-slide
                        v-for="(item, index) in _.get(getProductData, 'images')"
                        :key="index"
                    >
                        <picture>
                            <source
                                media="(max-width: 767px)"
                                loading="lazy"
                                :srcset="_.get(item, 'devices.mobile')"
                            />
                            <source
                                media="(max-width: 1023px)"
                                loading="lazy"
                                :srcset="_.get(item, 'devices.tablet')"
                            />
                            <img
                                :src="_.get(item, 'devices.desktop')"
                                loading="lazy"
                                alt="product-image"
                            />
                        </picture>
                    </swiper-slide>
                </swiper>

                <div class="product-info">
                    <div
                        class="product-name"
                        data-aos="fade-up"
                        data-aos-delay="100"
                    >
                        <div>
                            <div class="product-title font-21 bold">
                                {{ _.get(getProductData, "title") }}
                            </div>
                            <div
                                class="product-brand font-16"
                                v-if="getPorductBrandName"
                            >
                                {{ $fn.tr("Brand") }}:
                                <a
                                    target="_blank"
                                    rel="noopener"
                                    :href="getPorductBrandName.url_en"
                                    class="bold primary-color"
                                    >{{ getPorductBrandName.title }}</a
                                >
                            </div>
                        </div>
                        <div>
                            <div
                                v-if="!alreadyInWishList"
                                @click="addToWishList(getProductData)"
                            >
                                <svg
                                    style="cursor: pointer"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="44"
                                    height="44"
                                    viewBox="0 0 44 44"
                                >
                                    <g
                                        id="Group_3812"
                                        data-name="Group 3812"
                                        transform="translate(-378 -1115)"
                                    >
                                        <circle
                                            id="Ellipse_3"
                                            data-name="Ellipse 3"
                                            cx="22"
                                            cy="22"
                                            r="22"
                                            transform="translate(378 1115)"
                                            fill="#ffeece"
                                        />
                                        <path
                                            id="Icon_feather-heart"
                                            data-name="Icon feather-heart"
                                            d="M17.989,5.806a4.467,4.467,0,0,0-6.318,0l-.861.861L9.95,5.806a4.468,4.468,0,1,0-6.318,6.318l.861.861L10.81,19.3l6.318-6.318.861-.861a4.467,4.467,0,0,0,0-6.318Z"
                                            transform="translate(389.549 1125.348)"
                                            fill="none"
                                            stroke="#fec55e"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                        />
                                    </g>
                                </svg>
                            </div>
                            <div
                                v-if="alreadyInWishList"
                                @click="removeWishElement(getProductData)"
                            >
                                <svg
                                    style="cursor: pointer"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="44"
                                    height="44"
                                    viewBox="0 0 30 30"
                                >
                                    <g
                                        id="Group_14"
                                        data-name="Group 14"
                                        transform="translate(-378 -1115)"
                                    >
                                        <circle
                                            id="Ellipse_3"
                                            data-name="Ellipse 3"
                                            cx="15"
                                            cy="15"
                                            r="15"
                                            transform="translate(378 1115)"
                                            fill="#fec55e"
                                        />
                                        <path
                                            id="Icon_feather-heart"
                                            data-name="Icon feather-heart"
                                            d="M13,5.39a3.045,3.045,0,0,0-4.308,0l-.587.587L7.523,5.39A3.046,3.046,0,1,0,3.215,9.7l.587.587L8.11,14.592l4.308-4.308L13,9.7A3.045,3.045,0,0,0,13,5.39Z"
                                            transform="translate(385.135 1120.625)"
                                            fill="none"
                                            stroke="#fff"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                        />
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <SlicedSlider
                        class="mobile-product-slider"
                        :sliderData="_.get(getProductData, 'images')"
                    />
                    <div
                        class="price-margin"
                        data-aos="fade-up"
                        data-aos-delay="400"
                    >
                        <div class="mobile-picker">
                            <div class="product-details border-bottom">
                                <div class="product-price bold font-34">
                                    <div
                                        class="bold font-21 sale"
                                        v-if="_.get(getProductData, 'oldprice')"
                                    >
                                        <span></span>
                                        {{
                                            formatPrice(
                                                _.get(
                                                    getProductData,
                                                    "oldprice"
                                                )
                                            )
                                        }}
                                    </div>
                                    {{
                                        formatPrice(
                                            _.get(getProductData, "stock_price")
                                        )
                                    }}
                                    ₾
                                </div>
                                <div
                                    class="product-id font-16"
                                    v-if="_.get(getProductData, 'product_code')"
                                >
                                    {{ $fn.tr("Product ID") }}:
                                    <span class="bold">{{
                                        _.get(getProductData, "product_code")
                                    }}</span>
                                </div>
                            </div>
                            <div
                                class="color-picker border-bottom"
                                v-if="getProductColorList.length > 0"
                            >
                                <div class="font-13 bold">
                                    {{ $fn.tr("Choose Color") }}
                                </div>
                                <ul>
                                    <template
                                        v-for="(item,
                                        index) in getProductColorList"
                                    >
                                        <div
                                            :key="index"
                                            v-if="
                                                _.get(
                                                    getProductColorList,
                                                    '[0].special_color'
                                                ) == 'multicolor'
                                            "
                                            class="multi-color"
                                        >
                                            <img
                                                src="../assets/img/Multicolor.png"
                                                alt="colors"
                                            />
                                        </div>
                                        <li
                                            v-else
                                            class="pointer"
                                            :key="index"
                                            @click="chooseColor(item)"
                                            :class="{
                                                active:
                                                    currentId ===
                                                    _.get(item, 'id')
                                            }"
                                        >
                                            <div
                                                :style="{
                                                    background: _.get(
                                                        item,
                                                        'color'
                                                    )
                                                }"
                                                class="background"
                                            ></div>
                                        </li>
                                    </template>
                                </ul>
                            </div>
                        </div>
                        <div class="add-item border-bottom">
                            <div
                                class="incriment bold font-16"
                                v-if="
                                    _.get(getProductData, 'stock_price') &&
                                        _.get(getProductData, 'stock_quantity')
                                "
                            >
                                <span @click="decreaseCount" class="decrement">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="8.939"
                                        height="8.3"
                                        viewBox="0 0 8.939 2.235"
                                    >
                                        <path
                                            id="Icon_open-plus"
                                            data-name="Icon open-plus"
                                            d="M3.352,3.352H0V5.587H8.939V3.352H3.352Z"
                                            transform="translate(0 -3.352)"
                                            fill="#435059"
                                        />
                                    </svg>
                                </span>
                                {{ count }}
                                <span @click="increaseCount" class="increment">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="10"
                                        height="10"
                                        viewBox="0 0 8.939 8.939"
                                    >
                                        <path
                                            id="Icon_open-plus"
                                            data-name="Icon open-plus"
                                            d="M3.352,0V3.352H0V5.587H3.352V8.939H5.587V5.587H8.939V3.352H5.587V0Z"
                                            fill="#435059"
                                        />
                                    </svg>
                                </span>
                            </div>
                            <button
                                v-if="
                                    _.get(getProductData, 'stock_price') &&
                                        _.get(
                                            getProductData,
                                            'stock_quantity'
                                        ) > 1
                                "
                                class="font-13 bold"
                                @click="addToCart(getProductData, count)"
                            >
                                {{ $fn.tr("Add to bag") }}
                            </button>

                            <div class="out-of-stock bold font-18" v-else>
                                {{ $fn.tr("Out of Stock") }}
                            </div>
                        </div>
                        <div
                            class="out-of-stock bold font-18"
                            v-if="
                                $store.state.shop.showCartError.value &&
                                    $store.state.shop.showCartError.id ==
                                        getProductData.id
                            "
                        >
                            {{ $fn.tr("Out of Stock") }}
                        </div>
                        <div class="delivery-info border-bottom">
                            <a
                                rel="noopener"
                                :href="
                                    _.get(getProductData, 'instruction[0].url')
                                "
                                target="_blank"
                                class="product-pdf bold font-13 underline"
                                v-if="_.get(getProductData, 'instruction[0]')"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="44"
                                    height="44"
                                    viewBox="0 0 44 44"
                                >
                                    <g
                                        id="Group_3817"
                                        data-name="Group 3817"
                                        transform="translate(-1114 -368)"
                                    >
                                        <rect
                                            id="Rectangle_56"
                                            data-name="Rectangle 56"
                                            width="44"
                                            height="44"
                                            rx="10"
                                            transform="translate(1114 368)"
                                            fill="#d9eeeb"
                                        />
                                        <path
                                            id="Icon_metro-file-pdf"
                                            data-name="Icon metro-file-pdf"
                                            d="M22.223,24.375a18.18,18.18,0,0,0,2.007,1.338,23.712,23.712,0,0,1,2.8-.167q3.513,0,4.229,1.171a1.155,1.155,0,0,1,.048,1.243.069.069,0,0,1-.024.048l-.048.048v.024q-.143.908-1.7.908a9.857,9.857,0,0,1-2.748-.478,17.424,17.424,0,0,1-3.106-1.266,43.026,43.026,0,0,0-9.367,1.983q-3.656,6.26-5.783,6.26a1.39,1.39,0,0,1-.669-.167l-.573-.287q-.024-.024-.143-.119A.987.987,0,0,1,7,34.052a5.189,5.189,0,0,1,1.338-2.186,11.548,11.548,0,0,1,3.154-2.306.349.349,0,0,1,.55.143.138.138,0,0,1,.048.1q1.243-2.031,2.557-4.707a36.47,36.47,0,0,0,2.485-6.26,19.316,19.316,0,0,1-.729-3.811,9.291,9.291,0,0,1,.155-3.047q.263-.956,1-.956h.526a1.008,1.008,0,0,1,.836.358A1.908,1.908,0,0,1,19.141,13a.518.518,0,0,1-.1.191.622.622,0,0,1,.024.191V14.1a31.29,31.29,0,0,1-.335,4.588,12.089,12.089,0,0,0,3.489,5.687ZM8.46,34.2q1.243-.573,3.274-3.775a13.621,13.621,0,0,0-2.091,2.007A7.942,7.942,0,0,0,8.46,34.2Zm9.51-21.983a7.105,7.105,0,0,0-.048,3.154q.024-.167.167-1.051,0-.072.167-1.027a.538.538,0,0,1,.1-.191.069.069,0,0,1-.024-.048.048.048,0,0,0-.012-.036.048.048,0,0,1-.012-.036,1.375,1.375,0,0,0-.311-.86.069.069,0,0,1-.024.048v.048ZM15.007,28.007a35.048,35.048,0,0,1,6.786-1.935,3.6,3.6,0,0,1-.311-.227,4.279,4.279,0,0,1-.382-.323,12.657,12.657,0,0,1-3.035-4.205,31.931,31.931,0,0,1-1.983,4.707q-.717,1.338-1.075,1.983Zm15.436-.382q-.573-.573-3.345-.573a9.038,9.038,0,0,0,2.963.669,2.318,2.318,0,0,0,.43-.024q0-.024-.048-.072Z"
                                            transform="translate(1117.007 367.184)"
                                            fill="#81c7bd"
                                        />
                                    </g>
                                </svg>
                                {{ _.get(getProductData, "instruction_title") }}
                            </a>
                            <i
                                v-if="_.get(getProductData, 'instruction[0]')"
                            ></i>
                            <div class="bold font-13 border">
                                {{ $fn.tr("Will arrive in 5 days") }}
                            </div>
                            <i></i>
                            <div
                                class="parts bold font-13"
                                @click="filterShow = true"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="44"
                                    height="44"
                                    viewBox="0 0 44 44"
                                >
                                    <g
                                        id="Group_3818"
                                        data-name="Group 3818"
                                        transform="translate(-1670 -681)"
                                    >
                                        <rect
                                            id="Rectangle_424"
                                            data-name="Rectangle 424"
                                            width="44"
                                            height="44"
                                            rx="10"
                                            transform="translate(1670 681)"
                                            fill="#f7f7f7"
                                        />
                                        <path
                                            id="Icon_material-info-outline"
                                            data-name="Icon material-info-outline"
                                            d="M14.391,21.984h2.531V14.391H14.391ZM15.656,3A12.656,12.656,0,1,0,28.313,15.656,12.661,12.661,0,0,0,15.656,3Zm0,22.781A10.125,10.125,0,1,1,25.781,15.656,10.138,10.138,0,0,1,15.656,25.781ZM14.391,11.859h2.531V9.328H14.391Z"
                                            transform="translate(1675.943 687.344)"
                                            fill="#435059"
                                        />
                                    </g>
                                </svg>
                                {{ $fn.tr("Parts") }}
                            </div>
                        </div>
                    </div>
                    <div data-aos="fade-up" data-aos-delay="650">
                        <div
                            v-if="_.get(getProductData, 'description')"
                            class="description-acordion"
                            :class="{ active: desc }"
                        >
                            <div class="flexbox" @click="showDesc">
                                <div class="bold font-13">
                                    {{ $fn.tr("Description") }}
                                </div>
                                <div class="relative acordion-span">
                                    <span></span>
                                    <span :class="{ active: desc }"></span>
                                </div>
                            </div>
                            <slide-up-down
                                class="description-text"
                                :active="desc"
                                :duration="500"
                            >
                                <div
                                    v-html="
                                        _.get(getProductData, 'description')
                                    "
                                />
                            </slide-up-down>
                        </div>
                        <div
                            class="details-acordion"
                            :class="{ active: details }"
                        >
                            <div class="flexbox" @click="showDetails">
                                <div class="bold font-13">
                                    {{ $fn.tr("Product Details") }}
                                </div>
                                <div class="relative acordion-span">
                                    <span></span>
                                    <span :class="{ active: details }"></span>
                                </div>
                            </div>
                            <slide-up-down
                                class="details-text"
                                :active="details"
                                :duration="500"
                            >
                                <div
                                    class="title"
                                    v-if="_.get(getProductData, 'size')"
                                >
                                    {{ $fn.tr("Size") }}:
                                    <span class="bold font-16">
                                        {{ _.get(getProductData, "size") }}
                                    </span>
                                </div>
                                <div
                                    class="title"
                                    v-if="_.get(getProductData, 'weight')"
                                >
                                    {{ $fn.tr("Weight") }}:
                                    <span class="bold font-16">
                                        {{ _.get(getProductData, "weight") }}
                                    </span>
                                </div>
                                <div class="title" v-if="getProductMaterial">
                                    {{ $fn.tr("Material") }}:
                                    <span class="bold font-16">
                                        {{ _.get(getProductMaterial, "title") }}
                                    </span>
                                </div>
                            </slide-up-down>
                        </div>
                    </div>
                    <div data-aos="fade-up" data-aos-delay="750">
                        <div class="delivery-ad border-bottom">
                            <div class="delivey-content">
                                <div class="truck-svg">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="54.204"
                                        height="43.041"
                                        viewBox="0 0 54.204 43.041"
                                    >
                                        <g
                                            id="Group_1205"
                                            data-name="Group 1205"
                                            transform="translate(0 0)"
                                        >
                                            <g
                                                id="Group_1205-2"
                                                data-name="Group 1205"
                                                transform="translate(30.711 12.866)"
                                            >
                                                <path
                                                    id="Path_594"
                                                    data-name="Path 594"
                                                    d="M601.291,624.074H583.446a2.825,2.825,0,0,1-2.824-2.825V603.4a2.824,2.824,0,0,1,2.824-2.825h10.947a9.733,9.733,0,0,1,9.722,9.723v10.946A2.824,2.824,0,0,1,601.291,624.074Zm-15.02-5.649h12.2V610.3a4.078,4.078,0,0,0-4.073-4.074h-8.122Z"
                                                    transform="translate(-580.622 -600.58)"
                                                    fill="#81c7bd"
                                                />
                                            </g>
                                            <g
                                                id="Group_1206"
                                                data-name="Group 1206"
                                            >
                                                <path
                                                    id="Path_595"
                                                    data-name="Path 595"
                                                    d="M570.928,618.829H540.217A2.824,2.824,0,0,1,537.393,616V585.293a2.823,2.823,0,0,1,2.824-2.824h30.711a2.824,2.824,0,0,1,2.825,2.824V616A2.825,2.825,0,0,1,570.928,618.829Zm-27.886-5.649H568.1V588.118H543.042Z"
                                                    transform="translate(-537.393 -582.469)"
                                                    fill="#81c7bd"
                                                />
                                            </g>
                                            <g
                                                id="Group_1207"
                                                data-name="Group 1207"
                                                transform="translate(6.637 24.595)"
                                            >
                                                <circle
                                                    id="Ellipse_42"
                                                    data-name="Ellipse 42"
                                                    cx="6.398"
                                                    cy="6.398"
                                                    r="6.398"
                                                    transform="translate(2.824 2.825)"
                                                    fill="#fff"
                                                />
                                                <path
                                                    id="Path_596"
                                                    data-name="Path 596"
                                                    d="M555.958,635.535a9.223,9.223,0,1,1,9.223-9.223A9.233,9.233,0,0,1,555.958,635.535Zm0-12.8a3.575,3.575,0,1,0,3.574,3.575A3.578,3.578,0,0,0,555.958,622.737Z"
                                                    transform="translate(-546.735 -617.089)"
                                                    fill="#fec55e"
                                                />
                                            </g>
                                            <g
                                                id="Group_1208"
                                                data-name="Group 1208"
                                                transform="translate(28.783 24.595)"
                                            >
                                                <circle
                                                    id="Ellipse_43"
                                                    data-name="Ellipse 43"
                                                    cx="6.398"
                                                    cy="6.398"
                                                    r="6.398"
                                                    transform="translate(2.824 2.825)"
                                                    fill="#fff"
                                                />
                                                <path
                                                    id="Path_597"
                                                    data-name="Path 597"
                                                    d="M587.132,635.535a9.223,9.223,0,1,1,9.222-9.223A9.232,9.232,0,0,1,587.132,635.535Zm0-12.8a3.575,3.575,0,1,0,3.573,3.575A3.578,3.578,0,0,0,587.132,622.737Z"
                                                    transform="translate(-577.909 -617.089)"
                                                    fill="#fec55e"
                                                />
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <div class="delivery-ad-info">
                                    <div class="bold font-13">
                                        {{
                                            $fn.tr(
                                                "Free Delivery Over 200 $ Orders"
                                            )
                                        }}
                                    </div>
                                    <a
                                        :href="
                                            `tel:${_.get(
                                                $store.state.main
                                                    .current_content,
                                                'secondary.HotLine.data.list[0].additional'
                                            )}`
                                        "
                                    >
                                        <div class="font-13 bold mt5">
                                            <span
                                                class="primary-color font-13 bold mt5"
                                            >
                                                {{
                                                    _.get(
                                                        $store.state.main
                                                            .current_content,
                                                        "secondary.HotLine.data.list[0].title"
                                                    ) + ": "
                                                }}
                                            </span>
                                            {{
                                                _.get(
                                                    $store.state.main
                                                        .current_content,
                                                    "secondary.HotLine.data.list[0].additional"
                                                )
                                            }}
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <a
                                :href="
                                    `tel:${_.get(
                                        $store.state.main.current_content,
                                        'secondary.HotLine.data.list[0].additional'
                                    )}`
                                "
                            >
                                <div class="call-button">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                    >
                                        <path
                                            id="Icon_material-phone"
                                            data-name="Icon material-phone"
                                            d="M7.718,11.424a13.465,13.465,0,0,0,5.858,5.858l1.956-1.956a.884.884,0,0,1,.907-.213,10.14,10.14,0,0,0,3.173.507.892.892,0,0,1,.889.889v3.1a.892.892,0,0,1-.889.889A15.11,15.11,0,0,1,4.5,5.389.892.892,0,0,1,5.389,4.5H8.5a.892.892,0,0,1,.889.889A10.1,10.1,0,0,0,9.9,8.562a.892.892,0,0,1-.222.907L7.718,11.424Z"
                                            transform="translate(-4.5 -4.5)"
                                            fill="#fff"
                                        />
                                    </svg>
                                    <div
                                        class="font-13 bold text-center white mt5"
                                    >
                                        {{ $fn.tr("Call Now !") }}
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="share">
                            <div
                                class="font-13 bold partners-text"
                                @click="passData()"
                            >
                                {{ $fn.tr("Share Product") }}
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14.458"
                                    height="16"
                                    viewBox="0 0 14.458 16"
                                >
                                    <path
                                        id="Icon_material-share"
                                        data-name="Icon material-share"
                                        d="M16.548,14.309a2.339,2.339,0,0,0-1.574.618L9.247,11.594a2.223,2.223,0,0,0,0-1.124l5.663-3.3a2.4,2.4,0,1,0-.771-1.759,2.629,2.629,0,0,0,.072.562l-5.663,3.3a2.41,2.41,0,1,0,0,3.518l5.719,3.341a2.266,2.266,0,0,0-.064.522,2.345,2.345,0,1,0,2.345-2.345Z"
                                        transform="translate(-4.5 -3)"
                                        fill="#81c7bd"
                                    />
                                </svg>
                            </div>
                            <div
                                @click="$router.back()"
                                class="font-13 bold partners-text"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="13.714"
                                    viewBox="0 0 16 13.714"
                                >
                                    <path
                                        id="Icon_metro-arrow-up"
                                        data-name="Icon metro-arrow-up"
                                        d="M13.38,6.049,7.665.335a1.143,1.143,0,0,0-1.616,0L.335,6.049A1.143,1.143,0,0,0,1.951,7.665L5.714,3.9V14.857a1.143,1.143,0,0,0,2.286,0V3.9l3.763,3.763A1.143,1.143,0,0,0,13.38,6.049Z"
                                        transform="translate(0 13.714) rotate(-90)"
                                        fill="#81c7bd"
                                    />
                                </svg>
                                {{ $fn.tr("Back") }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="custom-container-wrapper"
            v-if="
                _.get(
                    $store.state.main.current_content,
                    'primary.data[0].add_on_products_collection.list'
                )
            "
        >
            <div class="mt-144 relative tablet-border">
                <content-title
                    class="inner-title"
                    :colored-title="true"
                    :title="$fn.tr('Add on items')"
                    data-aos="fade-up"
                    data-aos-delay="100"
                />

                <img
                    class="bottom-decor-img"
                    src="../assets/img/decor.png"
                    alt=""
                    data-aos="fade-left"
                    data-aos-delay="200"
                />

                <Slider
                    :sliderData="
                        _.get(
                            $store.state.main.current_content,
                            'primary.data[0].add_on_products_collection.list'
                        )
                    "
                    data-aos="fade-up"
                    data-aos-delay="250"
                />
            </div>
        </div>
        <div class="mt-144">
            <ADSection
                class="ADmargin"
                :ADdata="
                    _.get(
                        this.$store.state.main.current_content,
                        'secondary.ProductBannerInner.data.list[0]'
                    )
                "
            />
        </div>
        <div
            class="custom-container-wrapper mt-85"
            v-if="
                _.get(
                    $store.state.main.current_content,
                    'primary.data[0].similar_products.list'
                )
            "
        >
            <div class="mt-144 relative">
                <content-title
                    class="similar"
                    :colored-title="true"
                    :title="$fn.tr('Very similar items')"
                    data-aos="fade-up"
                    data-aos-delay="100"
                />
                <img
                    class="decor-img-rotated"
                    src="../assets/img/decor.png"
                    alt=""
                    data-aos="fade-in"
                    data-aos-delay="200"
                />

                <!-- <Slider class="mobile-vertical" :sliderData="hoverSlider" data-aos="fade-up" data-aos-delay="250" /> -->

                <VerticalSlider
                    class="desc-vertical"
                    :sliderData="
                        _.get(
                            $store.state.main.current_content,
                            'primary.data[0].similar_products.list'
                        )
                    "
                    data-aos="fade-up"
                    data-aos-delay="250"
                />
            </div>
        </div>
        <CartInfo class="mt-144" />
        <Notification :active="notification" @hide="notification = false">
            {{ $fn.tr("Added Successfully!") }}
        </Notification>
        <ShareModal
            @closeModal="shareModal = false"
            :shareData="shareData"
            :pageURL="getURL"
            :modalActive="shareModal"
        />
    </section>
</template>

<script>
import CartInfo from "../components/CartInfo";
import Slider from "../components/ProductSlider";
import ADSection from "../components/ADSection";
import ContentTitle from "../components/ContentTitle";
import VerticalSlider from "../components/VerticalSlider";
import SlicedSlider from "../components/ProductsInnerSlider";
import Parts from "../components/PartsAside";
import Incriment from "../components/Incriment";
import addToCartMixin from "../mixins/addToCartMixin";
import attributesMixin from "../mixins/attributesMixin";
import ShareModal from "../components/ShareModal";
import commaNum from "@/helpers/commaNum";
import { mapGetters } from "vuex";

export default {
    components: {
        CartInfo,
        ContentTitle,
        Slider,
        ADSection,
        VerticalSlider,
        SlicedSlider,
        Parts,
        Incriment,
        ShareModal
    },
    mixins: [addToCartMixin, attributesMixin],
    data() {
        return {
            count: 1,
            title: "Add on Items",
            active: false,
            desc: false,
            details: false,
            filterShow: false,
            isFollow: false,
            currentId: null,
            shareModal: false,
            alreadyInWishList: false,
            animationChange: false,
            verticalSwiperOptions: {
                speed: 400,
                freeMode: true,
                mousewheel: true,
                breakpoints: {
                    1024: {
                        slidesPerView: 1.78,
                        spaceBetween: 20
                    },
                    1366: {
                        slidesPerView: 1.37
                    },
                    1600: {
                        slidesPerView: 1.275
                    },
                    1900: {
                        slidesPerView: 1.09
                    }
                },
                direction: "vertical",
                spaceBetween: 30
            }
        };
    },
    watch: {
        modalData: {
            immediate: true,

            handler(val) {
                if (val) {
                    this.attributesData = val;
                }
            }
        },
        getWishListItems: {
            immediate: true,
            handler(val) {
                if (val && val.data) {
                    val.data?.list?.findIndex(
                        res =>
                            res.id ===
                            _.get(
                                this.$store.state.main.current_content,
                                "primary.data[0].id"
                            )
                    ) !== -1
                        ? (this.alreadyInWishList = true)
                        : (this.alreadyInWishList = false);
                } else {
                    this.alreadyInWishList = false;
                }
            }
        }
    },
    computed: {
        ...mapGetters({
            getCartItems: "shop/getCartItems"
        }),
        getProductData() {
            !this.currentId
                ? (this.currentId = _.get(
                      this.$store.state.main.current_content,
                      "primary.data[0].attributes.color[0]"
                  ))
                : "";

            let result = _.get(
                this.$store.state.main.current_content,
                "primary.data"
            );
            return this.currentId
                ? result.find(res =>
                      res.attributes.color.includes(this.currentId)
                  )
                : result[0];
        },
        getURL() {
            const pageURL = window.location.href;

            return pageURL;
        },
        shareData() {
            const data = _.get(this.getProductData, "list[0].title");

            return data;
        },
        getPorductBrandName() {
            if (
                _.get(this.getProductData, "attributes.brands[0]") &&
                _.get(this.$store.state.main.indx, "attributes.brands")
            ) {
                let brandId = _.get(
                    this.getProductData,
                    "attributes.brands[0]"
                );
                let brands = _.get(
                    this.$store.state.main.indx,
                    "attributes.brands"
                );
                return brands.find(brand => brand.id === brandId);
            }
        },
        getProductColorList() {
            let color = [];
            if (
                _.get(
                    this.$store.state.main.current_content,
                    "primary.data[0].attributes.color"
                )
            ) {
                for (let i of _.get(
                    this.$store.state.main.current_content,
                    "primary.data"
                )) {
                    let list = _.get(
                        this.$store.state.main.indx,
                        "attributes.color"
                    ).find(item => item.id === _.get(i.attributes, "color[0]"));
                    color.push(list);
                }
            }
            return color;
        },
        getProductMaterial() {
            if (
                _.get(this.getProductData, "attributes.material[0]") &&
                _.get(this.$store.state.main.indx, "attributes.material")
            ) {
                let materialId = _.get(
                    this.getProductData,
                    "attributes.material[0]"
                );
                let materials = _.get(
                    this.$store.state.main.indx,
                    "attributes.material"
                );
                return materials.find(material => material.id === materialId);
            }
        },
        getArriveTime() {
            if (
                _.get(this.getProductData, "attributes.arriving_Time[0]") &&
                _.get(this.$store.state.main.indx, "attributes.arriving_Time")
            ) {
                let arriveTime = _.get(
                    this.getProductData,
                    "attributes.arriving_Time[0]"
                );
                let arrives = _.get(
                    this.$store.state.main.indx,
                    "attributes.arriving_Time"
                );
                return arrives.find(arrive => arrive.id === arriveTime);
            }
        }
    },
    methods: {
        passData() {
            this.shareData;
            this.shareModal = true;
        },
        closeAside() {
            this.filterShow = !this.filterShow;
        },
        acordion() {
            this.active = !this.active;
        },
        chooseColor(item) {
            if (item.id !== this.currentId) {
                this.animationChange = true;
                this.count = 1;
                setTimeout(() => {
                    this.currentId = item.id;
                    this.animationChange = false;
                }, 700);
            }
        },
        showDesc() {
            this.desc = !this.desc;
        },
        showDetails() {
            this.details = !this.details;
        },
        modalActive() {
            document.querySelector("html").style.overflow = "auto";
        },
        increaseCount() {
            if (this.getProductData.stock_quantity - 1 > this.count)
                this.count++;
        },
        decreaseCount() {
            if (this.count > 1) {
                this.count--;
            }
        },
        formatPrice(price) {
            return price ? commaNum(Number(price)) : 0;
        }
    },
    mounted() {
        this.modalActive();
        this.$store.commit("main/setState", {
            key: "breadcrumbList",
            val: [
                {
                    title: "Category",
                    url: `/${this.$store.state.main.locale}/category`
                },
                {
                    title: "Products",
                    url: `/${this.$store.state.main.locale}/products`
                }
            ]
        });
    }
};
</script>
<style>
.multi-slider .swiper-wrapper {
    flex-direction: row !important;
    align-items: flex-end;
}
</style>

<style scoped>
.out-of-stock {
    display: flex;
    align-items: center;
}
.mt-85 {
    margin-top: 85px;
}
.product-content {
    opacity: 1;
    /*visibility: visible;*/
    transition: 0.6s;
}
.product-content.change {
    opacity: 0;
    /*visibility: hidden;*/
}
.text-center {
    text-align: center;
}
.follow path {
    fill: #fff;
}
i {
    height: 30px;
    width: 2px;
    background: #f7f7f7;
}
.slider-slide {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    transition-property: transform;
    padding-top: 100%;
    overflow: hidden;
}
.product-slider .swiper-slide img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    border-radius: 30px;
}
.relative {
    position: relative;
}
.product-price {
    display: flex;
    align-items: center;
}
.product-price .sale {
    margin-right: 7px;
    position: relative;
}
.product-price .sale span {
    position: absolute;
    transform: rotate(-17deg);
    top: 48%;
    left: 0;
    width: 100%;
    height: 2px;
    background: #ff7675;
}
.mt5 {
    margin-top: 5px;
}
.mt-144 {
    margin-top: 144px;
}
.product-slider {
    width: 49%;
    height: 880px;
    margin-left: 0;
}
.mobile-product-slider {
    display: none;
}
.product-info {
    width: 40.5%;
}
.product-name {
    display: flex;
    justify-content: space-between;
}
.product-content {
    display: flex;
    justify-content: space-between;
    /* transform: translateY(-30px); */
    position: relative;
    z-index: 9;
}
.product-title {
    margin-bottom: 10px;
    margin-top: 10px;
}
.price-margin {
    margin-top: 20px;
}
.decor-img-rotated {
    position: absolute;
    z-index: -1;
    left: -42%;
    top: -26%;
    transform: rotate(290deg);
}
.border-bottom {
    padding-bottom: 20px;
    border-bottom: 1px solid #f7f7f7;
}
.inner-title {
    padding-bottom: 15px;
    border-bottom: 1px solid #f7f7f7;
    margin-bottom: 55px;
}
.similar {
    padding-bottom: 15px;
    border-bottom: 1px solid #f7f7f7;
    margin-bottom: 30px;
    max-width: 70%;
}
.product-details {
    display: flex;
    align-items: center;
}
.product-id {
    padding-left: 30px;
    margin-left: 30px;
    border-left: 1px solid #f7f7f7;
}
.color-picker {
    display: flex;
    align-items: center;
    margin-top: 20px;
}
.multi-color {
    cursor: pointer;
}
.multi-color img {
    width: 25px;
    height: 25px;
}
.color-picker ul {
    display: flex;
    margin-left: 15px;
}
.color-picker ul li {
    margin-right: 6px;
    padding: 2px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
}
.color-picker ul li.active {
    border: 1px solid #435059;
}
.color-picker ul li .background {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    box-shadow: 0px 0px 1.5px 0px #888888;
}
.add-item {
    display: flex;
    margin-top: 22px;
}
.add-item button {
    width: 100%;
    height: 44px;
    background: #81c7bd;
    border-radius: 10px;
    color: #fff;
    text-transform: uppercase;
}
.incriment {
    width: 145px;
    border-radius: 10px;
    border: 1px solid #f7f7f7;
    padding: 10px 25px 10px 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 20px;
    cursor: pointer;
}
.delivery-info {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.product-pdf {
    display: flex;
    align-items: center;
}
.flex-box {
    align-items: center;
}
.parts {
    display: flex;
    align-items: center;
    cursor: pointer;
}
.product-pdf svg {
    margin-right: 10px;
}
.parts svg {
    margin-right: 10px;
}
.border {
    height: 44px;
    align-items: center;
    display: flex;
}
.description-acordion {
    margin-top: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
    border-bottom: 1px solid #f7f7f7;
    max-height: 40px;
    overflow: hidden;
    transition: 0.5s;
}
.details-acordion {
    margin-top: 20px;
    padding-right: 20px;
    max-height: 40px;
    overflow: hidden;
    transition: 0.5s;
    padding-bottom: 11px;
    border-bottom: 1px solid #f7f7f7;
}
.details-acordion.active {
    max-height: 400px;
}
.description-acordion.active {
    max-height: 400px;
}
.flexbox {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    /* padding-bottom: 20px; */
}
.acordion-span span:nth-child(1) {
    position: absolute;
    top: 8px;
    right: 0;
    width: 10px;
    height: 2px;
    background: black;
    z-index: 4;
    border-radius: 40px;
}
.acordion-span span:nth-child(2) {
    position: absolute;
    top: 8px;
    right: 0;
    width: 10px;
    height: 2px;
    background: black;
    z-index: 4;
    border-radius: 40px;
    transform: rotate(90deg);
    transition: 0.5s;
}
.acordion-span span.active:nth-child(2) {
    transition: 0.5s;
    transform: rotate(0deg);
}
.description-text {
    line-height: 22px;
}
.details-text {
    display: flex;
    flex-wrap: wrap;
}
.details-text span {
    padding-left: 25px;
}
.details-text .title {
    margin-right: 80px;
    padding-top: 10px;
    transition: 0.4s;
}
.delivery-ad {
    width: 100%;
    height: 80px;
    padding: 10px;
    border-radius: 10px;
    background: #f7f7f7;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}
.truck-svg {
    /* padding: 0 35px; */
    padding: 3px 45px 0 30px;
}
.delivey-content {
    display: flex;
    align-items: center;
}
.call-button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #fec55e;
    height: 100%;
    padding: 5px;
    width: 90px;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.4s;
}
.call-button:hover {
    background: #ecb44f;
}
.share {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.partners-text {
    display: flex;
    align-items: center;
}
.partners-text svg {
    margin: 0 10px;
}
.mobile-picker {
    display: flex;
    flex-direction: column;
}
.mobile-vertical {
    display: none;
}
.bottom-decor-img {
    bottom: -45%;
}
@media (max-width: 1919px) {
    .product-slider {
        width: 48.5%;
    }
    .product-info {
        width: 48%;
    }
    .product-info {
        padding: 0 10px 0 0;
    }
    .inner-title {
        padding-top: 10px;
        padding-bottom: 15px;
        border-bottom: 1px solid #f7f7f7;
        margin-bottom: 60px;
    }
    .similar {
        margin-bottom: 45px;
    }
}
@media (max-width: 1599px) {
    .product-info {
        width: 49.2%;
    }
    .product-slider {
        height: 840px;
    }
    .mt-144 {
        margin-top: 75px;
    }
    .inner-title {
        padding-top: 0px;
    }
}
@media (max-width: 1365px) {
    .product-slider {
        width: 48%;
    }
    .product-info {
        width: 49%;
    }
    .truck-svg {
        padding: 0 15px;
    }
    .truck-svg svg {
        width: 41px;
    }
    .description-text {
        font-size: 13px;
    }
    .border {
        padding-left: 15px;
    }
    .parts {
        padding-left: 15px;
    }
    .inner-title {
        padding-bottom: 28px;
    }
    .similar {
        padding-bottom: 27px;
        margin-bottom: 45px;
    }
    .decor-img-rotated {
        left: -60%;
    }
}
@media (max-width: 1023px) {
    .decor-img-rotated {
        display: none;
    }
    .mt-144 {
        margin-top: 55px;
    }
    .cart-info {
        margin-top: 10px;
    }
    .product-content {
        flex-direction: column;
        margin-top: 0px;
    }
    .product-info {
        width: 100%;
    }
    .product-slider {
        display: none;
    }
    .mobile-product-slider {
        display: block;
        margin: 28px 0px 0 0;
    }
    .price-margin {
        margin: 0;
    }
    .mobile-picker {
        display: flex;
        flex-direction: column-reverse;
    }
    .border-bottom {
        margin-top: 21px;
    }
    .delivery-info {
        padding-top: 20px;
        border-top: 1px solid #f7f7f7;
        margin-top: 34px;
    }
    .tablet-border {
        border-top: 1px solid #f7f7f7;
        padding-top: 30px;
    }
}
@media (max-width: 767px) {
    .mt-144 {
        margin-top: 35px;
    }
    .similar {
        padding-bottom: 25px;
        margin-bottom: 30px;
    }
    .mobile-vertical {
        display: block;
    }
    .mobile-product-slider {
        margin: 17px 0px 0 0;
    }
    .filter-aside {
        padding: 60px 20px;
        border-radius: 0;
        width: 100%;
    }
    .product-details {
        flex-direction: column;
        align-items: flex-start;
    }
    .product-id {
        padding-left: 0px;
        margin: 12px 0 0 0;
    }
    i {
        display: none;
    }
    .inner-title {
        padding-bottom: 24px;
        margin-bottom: 35px;
    }
    .delivery-info {
        padding-top: 12px;
        border-top: 1px solid #f7f7f7;
        margin-top: 15px;
    }
    .delivery-ad {
        height: auto;
        flex-direction: column;
    }
    .truck-svg {
        padding: 0 15px 0 0;
    }
    .truck-svg svg {
        width: 37px;
        height: 29px;
    }
    .call-button {
        margin-top: 10px;
        padding: 12px;
        width: 100%;
        flex-direction: row;
        height: 37px;
    }
    .call-button .mt5 {
        margin: 0;
        margin-left: 10px;
    }
    .delivery-info {
        flex-wrap: wrap;
    }
    .border {
        padding-left: 0;
        margin: 10px 0 0 0;
    }
    .parts {
        padding-left: 0;
        margin: 10px 0 0 0;
    }
    .details-text .title {
        margin-bottom: 8px;
    }
}
</style>
