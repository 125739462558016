<template>
    <section class="category-list">

        <div class="container-wrapper">

            <content-title
                :colored-title="true"
                :title="_.get($store.state.main.current_content, 'primary.conf.header.title')"
                data-aos="fade-up"
            />
            <div class="grid border-top">
                <div class="category-card" v-for="(item, index) in parentCat" :key="index" data-aos="fade-up" data-aos-delay="150" >
                    <router-link
                        tag="a"
                        :to="`/${$store.state.main.indx.locale}/products?category=${item.slug}`"
                        >
                        <!--  @click="$router.push({ path: `${$store.state.main.indx.locale}/products`, query: { category: item }})" -->
                        <div class="category-card-img">
                            <picture>
                                <source
                                    media="(max-width: 767px)"
                                    loading="lazy"
                                    :srcset="(`${_.get(item.image,'[0].devices.mobile')}`)"
                                />
                                <source
                                    media="(max-width: 1023px)"
                                    loading="lazy"
                                    :srcset="(`${ _.get(item.image,'[0].devices.tablet')}`)"
                                />
                                <img
                                    :src="(`${ _.get(item.image,'[0].devices.desktop')}`)"
                                    loading="lazy"
                                />
                            </picture>
                        </div>
                        <h2 class="category-title semibold font-16">{{ item.title }}</h2>
                    </router-link>
                </div>
            </div>
        </div>
        <CartInfo
           class="mt-144"
        />
    </section>
</template>

<script>
import contentTitle from "../components/ContentTitle"
import CartInfo from "../components/CartInfo"
import attributesMixin from "../mixins/attributesMixin"

export default {
    components: {
        contentTitle,
        CartInfo
    },
    mixins: [attributesMixin],
    mounted() {
        this.$store.commit('main/setState', {key: "breadcrumbList", val: [{title: 'Category', url: `/${this.$store.state.main.locale}/category` }]})
    }
}
</script>

<style scoped>
    .border-top{
        border-top: 1px solid #F7F7F7;
        padding-top: 55px;
    }
    .mt-144{
        margin-top: 163px;
    }
    .grid{
        position: relative;
        display: grid;
        grid-template-columns: repeat(5, minmax(0px, 1fr));
        gap: 75px 30px;
    }
    .content-title{
        margin: 10px 0 0 0;
        padding-bottom: 35px;
    }
    .category-card{
        cursor: pointer;
        transition: 0.4s;
    }
    .category-card-img{
        position: relative;
        padding-top: 100%;
        margin-bottom: 20px;
        overflow: hidden;
    }
    .category-card-img img{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        backface-visibility: hidden;
        transition: 0.5s;
    }
    .category-card-img img:hover{
        transition: 0.5s;
        transform: scale(1.1);
    }
    .category-title{
        color: #435059;
        text-align: center;
        transition: 0.4s;
    }
    .category-card:hover .category-title{
        color: #81C7BD;
        transition: 0.4s;
    }
    /* .category-card-mobile{
        display: none;
    } */
    @media (max-width: 1919px){
        .category-card-img{
            padding-right: 100%;
        }
    }
    @media(max-width: 1599px){
        .category-card-img{
            padding-top: 80%;
        }
        .grid{
            grid-template-columns: repeat(4, minmax(0px, 1fr));
            gap: 75px 30px;
        }
    }
    @media (max-width: 1364px){
        .grid{
            position: relative;
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            gap: 60px 30px;
        }
        .content-title{
            padding-bottom: 20px;
        }
        .border-top{
            border-top: 1px solid #F7F7F7;
            padding-top: 30px;
        }
    }
    @media (max-width: 1023px){
        .mt-144{
            margin-top: 75px;
        }
        .grid{
            position: relative;
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(300, 1fr));
            gap: 30px 30px;
        }
        /* .category-card{
            display: none;
        }
        .category-card-mobile{
            display: block;
        } */
    }
    @media (max-width: 767px){
        .grid{
            grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
        }
    }

</style>
