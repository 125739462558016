<template>
    <div class="product-item" :class="{'hide': classActive}" >
        <div @click="closePopup" class="product-item-row">
            <div class="product-image">
                <div class="cart-item-numbers">
                    <span>{{product.quantity ? product.quantity : 0}}</span>
                </div>
                <picture>
                    <source
                        media="(max-width: 767px)"
                        loading="lazy"
                        :srcset="_.get(product,'images[0].devices.mobile')"
                    />
                    <source
                        media="(max-width: 1023px)"
                        loading="lazy"
                        :srcset="_.get(product,'images[0].devices.tablet')"
                    />
                    <img

                        :src="_.get(product,'images[0].url')"
                        loading="lazy"
                        alt="image"
                    />
                </picture>

            </div>

            <div  class="product-item-desc">
                <div id="page-html-content">
                    <p>{{isSearch ? product.title[$store.state.main.indx.locale] : product.title}}</p>
                </div>
                <div id="with-colored-word">
                    <p>{{isSearch ? product.title[$store.state.main.indx.locale] : product.title}}</p>                </div>
                <div class="price">
                    <div v-if="product.oldprice && product.oldprice > 0" class="sale vertical-product-oldprice bold font-16"><span></span>
                        {{ formatPrice(product.oldprice) }} ₾
                    </div>
                    <p>{{formatPrice(product.stock_price)}} ₾</p>
                </div>
            </div>
        </div>



        <div class="product-item-row">
            <div class="close" @click="$emit('removeCartItem',product.id)">
                <svg xmlns="http://www.w3.org/2000/svg" width="15.424" height="15.424" viewBox="0 0 15.424 15.424">
                    <path id="Icon_metro-cancel" data-name="Icon metro-cancel" d="M22.815,9.64l-4.82,4.82-4.82-4.82-2.892,2.892,4.82,4.82-4.82,4.82,2.892,2.892,4.82-4.82,4.82,4.82,2.892-2.892-4.82-4.82,4.82-4.82Z" transform="translate(-10.283 -9.64)" fill="#81c7bd"/>
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import commaNum from "@/helpers/commaNum";
    export default {
        props: ["product","classActive", "isSearch"],
        data() {
            return {
                removeProduct: false
            }
        },
        methods: {
            closePopup() {
                this.$store.commit('shop/setState',{key:'cartPopupIsOpen', val: false})
                this.$router.push(`/${this.$store.state.main.locale}/products/productview/${_.get(this.product, 'id')}-${_.get(this.product, 'slug')}`);
                this.isSearch ? this.$emit('close'): '';

            },
            formatPrice(price) {
                return price  ? commaNum(Number(price)) : 0
            }
        }

    }
</script>

<style scoped>
    .cart-item-numbers {
        display: flex;
        align-items: center;
    }
    .cart-item-numbers {
        position: absolute;
        top: -6px;
        right: -6px;
        background-color: #FF7675;
        border-radius: 50%;
        display: flex;
        align-items: center;
        width: 17px;
        height: 17px;
        justify-content: center;

    }

    .cart-item-numbers span {
        color: #ffffff;
        font-size: 11px;
    }
    /*    product item*/
    .product-item {
        display: flex;
        width: 85%;
        opacity: 1;
        visibility: visible;
        margin-bottom: 25px;
        max-height: 200px;
        padding-top: 10px;
        /*transition: .5s ease;*/
    }
    .product-item-desc {
        margin-left: 20px;
    }
    .product-item-row {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .product-item-row:first-child {
        justify-content: flex-start;
        width: 100%;
    }
    .product-image {
        max-width: 100px;
        min-width: 100px;
        height: 90px;
        position: relative;
    }
    .product-item-row img {
        border-radius: 10px;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .product-item-desc p:first-child {
        font-family: var(--noto-regular);
        margin-bottom: 10px;
        font-size: 13px;
    }
    .product-item-desc .price p {
        font-family: var(--noto-bold);
        color: #181818;
        font-size: 16px;
    }
    .product-item-desc p {
        font-family: var(--noto-bold);
        color: #181818;
        font-size: 16px;
    }
    .close{
        border-radius: 10px;
        width: 44px;
        height: 44px;
        background: var(--light-green);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .right-content .sale {
        position: relative;
        margin-right: 7px;
    }
    .vertical-product-oldprice {
        position: relative;
        margin-right: 10px;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        display: flex;
        align-items: center;
    }

    .price {
        display: flex;
    }

    .price .sale span {
        position: absolute;
        transform: rotate(-17deg);
        top: 48%;
        left: 0;
        width: 100%;
        height: 2px;
        background: #FF7675;
    }
    .product-item.hide {
        opacity: 0;
        visibility: hidden;
        transform: scaleY(0);
        margin-bottom: 0;
        max-height: 0;
        transition: .5s ease, transform .01ms ease;
    }
    #with-colored-word {
        display: none
    }
    @media screen and (max-width: 1365px){
        .product-item {
            width: 95%;
            justify-content: space-between;
        }
        .product-item-row:first-child {
            width: 85%;
        }
    }
    @media screen and (max-width: 520px){
        .item-list .product-item-row:last-child {
            align-items: unset !important;
        }
        .item-list .product-item-desc {
            margin-left: unset;
            margin-top: 10px;
        }
        .product-item-desc {
            width: 111%
        }
        .product-item-desc p:first-child {
            margin-bottom: 5px;
        }
        .product-item-row:first-child {
            flex-direction: column;
            align-items: flex-start;
        }
    }
</style>
