<script>
export default {
    data() {
        return {
            attributesData: null
        };
    },
    computed: {
        attributes() {
            return _.get(this.$store.state.main.indx, "attributes");
        },
        getColorList(attributesData) {
            let color = [];
            if (_.get(this.attributesData, "attributes.color")) {
                for (let i of _.get(this.attributesData, "attributes.color")) {
                    let list = _.get(this.attributes, "color").find(
                        item => item.id === i
                    );
                    color.push(list);
                }
            }

            return color;
        },
        getMateial(attributesData) {
            let material = [];
            if (_.get(this.attributesData, "attributes.material")) {
                for (let i of _.get(
                    this.attributesData,
                    "attributes.material"
                )) {
                    let list = _.get(this.attributes, "material").find(
                        item => item.id === i
                    );
                    material.push(list);
                }
            }
            return material;
        },
        getArrivingTime(attributesData) {
            let arriving_time = [];
            if (_.get(this.attributesData, "attributes.arriving_time")) {
                for (let i of _.get(
                    this.attributesData,
                    "attributes.arriving_time"
                )) {
                    let list = _.get(this.attributes, "arriving_time").find(
                        item => item.id === i
                    );
                    arriving_time.push(list);
                }
            }

            return arriving_time;
        },
        getBrandsList(attributesData) {
            let brands = [];
            if (_.get(this.attributesData, "attributes.brands")) {
                for (let i of _.get(this.attributesData, "attributes.brands")) {
                    let list = _.get(this.attributes, "brands").find(
                        item => item.id === i
                    );
                    brands.push(list);
                }
            }
            return brands;
        },
        getCategoryList(attributesData) {
            let categories = [];
            if (_.get(this.attributesData, "attributes.products_category")) {
                for (let i of _.get(
                    this.attributesData,
                    "attributes.products_category"
                )) {
                    let list = _.get(this.attributes, "products_category").find(
                        item => item.id === i
                    );
                    categories.push(list);
                }
            }
            return categories;
        },
        parentCat() {
            if (
                !_.get(
                    this.$store.state.main.indx,
                    "attributes.products_category"
                )
            )
                return false;
            let arr = _.get(
                this.$store.state.main.indx,
                "attributes.products_category"
            );

            let cat = [];

            for (let i in arr) {
                if (arr[i].pid == 0 && arr[i].hide_from_categories != "yes") {
                    cat.push(arr[i]);
                }
            }

            return cat;
        }
    }
};
</script>
