<template>
    <div>
        <div class="container-wrapper contact-page">
            <content-title
                class="contact-title"
                :title="
                    _.get(
                        $store.state.main.current_content,
                        'primary.conf.header.title'
                    )
                "
                data-aos="fade-up"
            />
            <div class="contact-info">
                <div
                    class="contact-info-row"
                    data-aos="fade-up"
                    data-aos-delay="300"
                >
                    <div
                        class="contact-info-item"
                        :key="index"
                        v-for="(item, index) in _.get(
                            $store.state.main.current_content,
                            'primary.data.list[0].contact_info.rowTitles'
                        ).slice(0, 3)"
                    >
                        <div class="contact-info-text">{{ $fn.tr(item) }}</div>
                        <div class="contact-info-text">
                            {{
                                _.get(
                                    $store.state.main.current_content,
                                    "primary.data.list[0].contact_info.data"
                                )[index][0]
                            }}
                        </div>
                    </div>

                    <div class="contact-info-item">
                        <div class="contact-info-text">
                            {{ $fn.tr("Social Network") }}:
                        </div>
                        <div class="contact-info-text">
                            <a
                                :href="social.url"
                                rel="noopener"
                                target="_blank"
                                :key="index"
                                v-for="(social, index) in _.get(
                                    $store.state.main.current_content,
                                    'primary.data.list[0].social'
                                )"
                            >
                                <div
                                    v-if="social.svg"
                                    v-html="social.svg"
                                ></div>
                                <img
                                    v-else
                                    :src="social.image"
                                    loading="lazy"
                                    :alt="social.title"
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <div
                    class="contact-info-row"
                    data-aos="fade-up"
                    data-aos-delay="300"
                >
                    <div class="contact-info-item">
                        <div class="contact-info-text">
                            {{
                                _.get(
                                    $store.state.main.current_content,
                                    "primary.data.list[0].contact_info.rowTitles[3]"
                                )
                            }}
                        </div>
                        <div class="contact-info-text">
                            {{
                                _.get(
                                    $store.state.main.current_content,
                                    "primary.data.list[0].contact_info.data[3][0]"
                                )
                            }}
                        </div>
                    </div>
                    <div class="contact-info-item">
                        <div class="contact-info-text"></div>
                        <div class="contact-info-text">
                            {{
                                _.get(
                                    $store.state.main.current_content,
                                    "primary.data.list[0].contact_info.data[4][0]"
                                )
                            }}
                        </div>
                    </div>
                    <div class="contact-info-item">
                        <div class="contact-info-text"></div>
                        <div class="contact-info-text">
                            {{
                                _.get(
                                    $store.state.main.current_content,
                                    "primary.data.list[0].contact_info.data[5][0]"
                                )
                            }}
                        </div>
                    </div>
                </div>
                <div
                    :style="[
                        openForm ? { 'z-index': '99' } : { 'z-index': '1' }
                    ]"
                    data-aos="fade-up"
                    data-aos-delay="300"
                    class="email-send-container-wrapp"
                >
                    <div
                        class="email-send-container"
                        :class="{ open: openForm }"
                        @click="!booked ? (openForm = !openForm) : ''"
                    >
                        <img
                            src="../assets/img/email-send.svg"
                            loading="lazy"
                            alt="email-send"
                        />
                        <div v-if="!booked" class="email-send-text">
                            <p>{{ $fn.tr("For Additional information") }}</p>
                            <p>{{ $fn.tr("Please write us email") }}</p>
                        </div>
                        <div v-else class="email-send-text">
                            <p>{{ $fn.tr("Thank You") }},</p>
                            <p>
                                {{ $fn.tr("We will contact you shortly") }} (
                                {{ $fn.tr("aprox. 24 Hours") }} )
                            </p>
                        </div>
                        <div
                            v-if="!booked"
                            class="email-send-button center-flex"
                        >
                            <div v-if="!openForm">
                                {{ $fn.tr("Email contact form") }} <br />
                                {{ $fn.tr("Form contact form") }}
                            </div>
                            <div v-else>
                                <img
                                    src="../assets/img/cross2.svg"
                                    loading="lazy"
                                    alt="cross"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="form-wrapper" :class="{ open: openForm }">
                        <div
                            class="form-input-item"
                            :class="{
                                'input-error':
                                    (!$v.firstName.required ||
                                        !$v.firstName.minLength) &&
                                    isClicked
                            }"
                        >
                            <div class="label">
                                {{ $fn.tr("First Name") }}<span>*</span>
                            </div>
                            <input v-model="$v.firstName.$model" type="text" />
                        </div>
                        <div
                            class="form-input-item"
                            :class="{
                                'input-error':
                                    (!$v.company.required ||
                                        !$v.company.minLength) &&
                                    isClicked
                            }"
                        >
                            <div class="label">
                                {{ $fn.tr("Company") }}<span>*</span>
                            </div>
                            <input v-model="$v.company.$model" type="text" />
                        </div>
                        <div
                            class="form-input-item"
                            :class="{
                                'input-error':
                                    (!$v.phone.required ||
                                        !$v.phone.minLength) &&
                                    isClicked
                            }"
                        >
                            <div class="label">
                                {{ $fn.tr("Phone Number") }}
                            </div>
                            <input v-model="$v.phone.$model" type="number" />
                        </div>
                        <div
                            class="form-input-item"
                            :class="{
                                'input-error':
                                    (!$v.message.required ||
                                        !$v.message.minLength) &&
                                    isClicked
                            }"
                        >
                            <div class="label">{{ $fn.tr("Message") }}</div>
                            <textarea v-model="$v.message.$model" rows="5" />
                        </div>

                        <div class="form-file-send">
                            <div class="recaptcha"></div>
                            <div v-if="attachable" class="green-button">
                                {{ $fn.tr("SEND") }}
                            </div>
                            <div @click="bookForm" v-else class="green-button">
                                {{ $fn.tr("BOOK") }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="dark-background" :class="{ open: openForm }"></div>
            </div>
            <div
                :style="[openForm ? { 'z-index': '99' } : { 'z-index': '1' }]"
                data-aos="fade-up"
                data-aos-delay="600"
                class="email-send-container-wrapp mobile-show"
            >
                <div
                    class="email-send-container"
                    :class="{ open: openForm }"
                    @click="openForm = !openForm"
                >
                    <div class="email-send-container-desc">
                        <img
                            src="../assets/img/email-send.svg"
                            loading="lazy"
                            alt="email-send"
                        />
                        <div v-if="!booked" class="email-send-text">
                            <p>{{ $fn.tr("For Additional information") }}</p>
                            <p>{{ $fn.tr("Please write us email") }}</p>
                        </div>
                        <div v-else class="email-send-text">
                            <p>{{ $fn.tr("Thank You") }},</p>
                            <p>
                                {{ $fn.tr("We will contact you shortly") }} (
                                {{ $fn.tr("aprox. 24 Hours") }} )
                            </p>
                        </div>
                    </div>

                    <div v-if="!booked" class="email-send-button center-flex">
                        <div v-if="!openForm">
                            {{ $fn.tr("Email contact form") }} <br />
                            {{ $fn.tr("Form contact form") }}
                        </div>
                        <div v-else>
                            <img
                                src="../assets/img/cross2.svg"
                                loading="lazy"
                                alt="cross"
                            />
                        </div>
                    </div>
                </div>
                <div class="form-wrapper" :class="{ open: openForm }">
                    <div
                        class="form-input-item"
                        :class="{
                            'input-error':
                                (!$v.firstName.required ||
                                    !$v.firstName.minLength) &&
                                isClicked
                        }"
                    >
                        <div class="label">
                            {{ $fn.tr("First Name") }}<span>*</span>
                        </div>
                        <input v-model="$v.firstName.$model" type="text" />
                    </div>
                    <div
                        class="form-input-item"
                        :class="{
                            'input-error':
                                (!$v.company.required ||
                                    !$v.company.minLength) &&
                                isClicked
                        }"
                    >
                        <div class="label">
                            {{ $fn.tr("Company") }}<span>*</span>
                        </div>
                        <input v-model="$v.company.$model" type="text" />
                    </div>
                    <div
                        class="form-input-item"
                        :class="{
                            'input-error':
                                (!$v.phone.required || !$v.phone.minLength) &&
                                isClicked
                        }"
                    >
                        <div class="label">
                            {{ $fn.tr("Phone Number") }}<span>*</span>
                        </div>
                        <input v-model="$v.phone.$model" type="number" />
                    </div>
                    <div
                        class="form-input-item"
                        :class="{
                            'input-error':
                                (!$v.message.required ||
                                    !$v.message.minLength) &&
                                isClicked
                        }"
                    >
                        <div class="label">
                            {{ $fn.tr("Message") }}<span>*</span>
                        </div>
                        <textarea v-model="$v.message.$model" rows="5" />
                    </div>

                    <div class="form-file-send">
                        <div class="recaptcha"></div>
                        <div v-if="attachable" class="green-button">
                            {{ $fn.tr("SEND") }}
                        </div>
                        <div @click="bookForm" v-else class="green-button">
                            {{ $fn.tr("BOOK") }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="container-wrapper mysa-map"
            data-aos="fade-up"
            data-aos-delay="900"
        >
            <gmap-map
                v-if="
                    _.get(
                        $store.state.main.current_content,
                        'primary.data.list[0].map_info.data[0]'
                    )
                "
                ref="mapRef"
                :center="{
                    lat: parseFloat(
                        _.get(
                            $store.state.main.current_content,
                            'primary.data.list[0].map_info.data[0][0]'
                        )
                    ),
                    lng: parseFloat(
                        _.get(
                            $store.state.main.current_content,
                            'primary.data.list[0].map_info.data[1][0]'
                        )
                    )
                }"
                :zoom="
                    parseInt(
                        _.get(
                            $store.state.main.current_content,
                            'primary.data.list[0].map_info.data[2]'
                        )
                    )
                "
                style="width: 100%; height: 610px"
                class="google-map"
            >
                <picture>
                    <source
                        media="(max-width: 767px)"
                        :srcset="
                            _.get(
                                $store.state.main.current_content,
                                'primary.data.list[0].pin_image[0].devices.mobile'
                            )
                        "
                    />
                    <source
                        media="(max-width: 1023px)"
                        :srcset="
                            _.get(
                                $store.state.main.current_content,
                                'primary.data.list[0].pin_image[0].devices.tablet'
                            )
                        "
                    />
                    <gmap-marker
                        :position="{
                            lat: parseFloat(
                                _.get(
                                    $store.state.main.current_content,
                                    'primary.data.list[0].map_info.data[0][0]'
                                )
                            ),
                            lng: parseFloat(
                                _.get(
                                    $store.state.main.current_content,
                                    'primary.data.list[0].map_info.data[1][0]'
                                )
                            )
                        }"
                        :icon="
                            _.get(
                                $store.state.main.current_content,
                                'primary.data.list[0].pin_image[0].devices.desktop'
                            )
                        "
                    ></gmap-marker>
                </picture>
            </gmap-map>

            <img
                class="decor-img-rotated"
                loading="lazy"
                src="../assets/img/decor.png"
                alt=""
                data-aos="fade-in"
                data-aos-delay="200"
            />
        </div>

        <div
            class="dark-background"
            @click="openForm = false"
            :class="{ open: openForm }"
        ></div>
        <!--        <div class="email-send-container mobile-show">-->
        <!--            <img src="../assets/img/email-send.svg" alt="email-send">-->
        <!--            <div class="email-send-text">-->
        <!--                <p>For Additional information</p>-->
        <!--                <p>Please write us email</p>-->
        <!--            </div>-->
        <!--            <div class="email-send-button center-flex">-->
        <!--                Email <br> Form-->
        <!--            </div>-->
        <!--        </div>-->
        <CartInfo />
    </div>
</template>

<script>
import ContentTitle from "../components/ContentTitle";
import CartInfo from "../components/CartInfo";
import { validationMixin } from "vuelidate";
import { required, minLength, email } from "vuelidate/lib/validators";
export default {
    name: "contact",
    components: {
        ContentTitle,
        CartInfo
    },
    watch: {
        openForm(val) {
            if (!val) this.isClicked = false;
        }
    },
    data() {
        return {
            openForm: false,
            attachable: false,
            booked: false,
            firstName: "",
            company: "",
            message: "",
            phone: "",
            isClicked: false
        };
    },
    mixins: [validationMixin],
    validations: {
        firstName: {
            required,
            minLength: minLength(3)
        },
        company: {
            required,
            minLength: minLength(3)
        },
        message: {
            required,
            minLength: minLength(3)
        },
        phone: {
            required,
            minLength: minLength(9)
        }
    },
    methods: {
        bookForm() {
            this.isClicked = true;
            if (
                !this.$v.firstName.required ||
                !this.$v.firstName.minLength ||
                !this.$v.company.required ||
                !this.$v.company.minLength ||
                !this.$v.message.required ||
                !this.$v.message.minLength ||
                !this.$v.phone.required ||
                !this.$v.phone.minLength
            ) {
                return;
            }
            this.$store
                .dispatch("user/contactForm", {
                    firstName: this.firstName,
                    company: this.company,
                    message: this.message,
                    phone: this.phone
                })
                .then(res => {
                    if (res.status === 200) {
                        this.booked = true;
                        this.openForm = false;
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        }
    },
    mounted() {
        this.$store.commit("main/setState", {
            key: "breadcrumbList",
            val: [{ title: "Contact" }]
        });
    }
};
</script>

<style scoped>
.contact-page {
    margin-top: 5px;
}
.contact-info-row {
    width: 480px;
}
.contact-title {
    margin-bottom: 49px;
}
.email-send-container-wrapp {
    position: relative;
    border-radius: 10px;
    display: flex;
    height: 146px;
    justify-content: space-between;
    align-items: center;
    width: 32%;
}
.form-file-send {
    display: flex;
    justify-content: space-between;
}

.recaptcha {
    width: 45%;
    background-color: #f5f5f5;
    height: 40px;
}

.green-button {
    width: 50%;
    padding: 20px;
}
.contact-info {
    display: flex;
    justify-content: space-between;
}
.form-input-item.input-error .label {
    color: #ff7675;
}
.form-input-item.input-error input,
.form-input-item.input-error textarea {
    border: 1px solid #ff7675;
}
.contact-info-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    font-size: 16px;
}
.contact-info-item .contact-info-text {
    width: 85%;
    text-align: left;
    display: flex;
    align-items: flex-start;
}
.contact-info-text:first-child {
    width: 51%;
}
.contact-info-text a {
    display: flex;
}
.contact-info-text a:not(:first-child) {
    margin-left: 10px;
}
.contact-info-item .contact-info-text:last-child {
    font-family: var(--noto-bold);
}

.email-send-container {
    background-color: #f7f7f7;
}
.email-send-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    padding: 10px 5px 10px 40px;
}
.email-send-text {
    display: flex;
    flex-direction: column;
    font-size: 13px;
    margin-right: auto;
    margin-left: 15px;
}
.email-send-text p:not(:last-child) {
    margin-bottom: 10px;
    font-family: var(--noto-bold);
}

.email-send-button {
    background-color: #fec55e;
    border-radius: 10px;
    font-size: 13px;
    color: #ffffff;
    font-family: var(--noto-bold);
    height: 100%;
    width: auto;
    max-width: 115px;
    min-width: 89px;
    text-align: center;
    line-height: 23px;
    text-transform: uppercase;
    padding: 0 24px;
    cursor: pointer;
    transition: 0.5s ease;
}
.email-send-button div {
    color: #ffffff;
    font-family: var(--noto-bold);
}
.email-send-button img {
    transform: rotate(45deg);
}
.mysa-map {
    margin-top: 30px;
    margin-bottom: 150px;
    position: relative;
}
.email-send-container-wrapp.mobile-show {
    display: none;
}
.dark-background {
    position: absolute;
}
.dark-background.open {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: #435059;
    opacity: 0.3;
    z-index: 9;
}
.decor-img-rotated {
    position: absolute;
    z-index: -1;
    right: -35%;
    top: -5%;
    transform: rotate(267deg) !important;
}
.form-wrapper {
    background-color: #f7f7f7;
    width: 100%;
    max-height: 0;
    z-index: 2;
    top: 138px;
    position: absolute;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    overflow: hidden;
    /*transform: translateY(0px);*/
    transition: 0.5s ease;
}

.form-wrapper.open {
    max-height: 587px;
    padding: 20px;
    /*transform: translateY(0%);*/
    /*transform: scaleY(1);*/
    transition: 0.5s ease;
}
.form-input-item {
    margin-bottom: 15px;
}
.form-input-item .border-error {
    border: 1px solid #ff7675;
}
.form-input-item .label {
    color: #435059;
    font-family: var(--noto-regular);
    font-size: var(--normalFontSize);
    padding: 10px 0;
    display: flex;
}

.form-input-item .label span {
    color: #ff7675;
    font-size: 12px;
    margin-left: 5px;
}

.form-input-item input,
.form-input-item textarea {
    width: 100%;
    border: unset;
    border-radius: 10px;
    padding: 10px;
    background-color: #ffffff;
}
.email-send-container .email-send-container-desc {
    display: flex;
}

@media screen and (max-width: 1919px) {
    .mysa-map {
        margin-top: 35px;
        margin-bottom: 85px;
    }
    .contact-title {
        margin-bottom: 60px;
    }
}
@media screen and (max-width: 1599px) {
    .email-send-container-wrapp {
        height: 144px;
        width: 30%;
    }
    .email-send-container-wrapp {
        width: 34%;
    }
    .contact-page {
        z-index: 97;
        margin-top: -15px;
        position: relative;
    }
    .decor-img-rotated {
        display: none;
    }
    .form-wrapper {
        top: 135px;
    }
    .email-send-container {
        margin-bottom: auto;
        padding: 10px 10px 10px 20px;
    }

    .email-send-text > p {
        font-size: 13px;
    }
    .mysa-map {
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 1365px) {
    .email-send-container-wrapp {
        width: 40%;
    }
    .contact-info-item {
        flex-direction: column;
    }
    .email-send-container {
        height: 100%;
    }
    .contact-info-row {
        width: 30%;
    }
    .contact-info-row:nth-of-type(2) {
        margin-left: 15px;
    }
    .email-send-button > div {
        font-size: 13px;
    }
    .email-send-text {
        margin-left: 10px;
    }
    .contact-info-item {
        margin-bottom: 12px;
    }
    .mysa-map {
        margin-top: 15px;
        margin-bottom: 53px;
    }
}

/*@media screen and (max-width: 1023px){*/

/*}*/

@media screen and (max-width: 1023px) {
    .email-send-container-wrapp {
        display: none;
    }
    .email-send-container-wrapp.mobile-show {
        display: flex;
        width: 100%;
        margin: 0 auto;
    }
    .email-send-container-wrapp.mobile-show .form-wrapper {
        top: 115px;
    }
    .form-wrapper {
        top: 133px;
    }

    .contact-info {
        flex-wrap: wrap;
    }
    .contact-info-row {
        width: 100%;
    }
    .contact-info-row:nth-of-type(2) {
        margin-top: 30px;
    }
    .email-send-container-wrapp.mobile-show .email-send-button {
        padding: 46px 24px;
    }
    .mysa-map .vue-map-container.google-map {
        height: 380px;
    }
    .email-send-text {
        margin-right: auto;
        margin-left: 50px;
    }
    .email-send-container-desc img {
        margin-left: 20px;
    }
    .contact-info-item {
        flex-direction: unset;
    }
    .contact-info-row:nth-of-type(2) {
        margin-top: unset;
        width: 41%;
    }
    .contact-info-row:nth-of-type(2) .contact-info-item {
        flex-direction: column;
    }
    .contact-info-row {
        width: 55%;
    }
    .email-send-container {
        margin-top: 55px;
    }
    .contact-page {
        z-index: unset;
        margin-top: 15px;
    }
    .mysa-map {
        margin-top: 90px;
    }
}
@media screen and (max-width: 767px) {
    .contact-info {
        flex-direction: column;
    }
    .email-send-container-wrapp.mobile-show {
        margin-top: 20px;
    }
    .contact-info-row:nth-of-type(2) {
        margin-top: 30px;
    }
    .contact-info-item .contact-info-text {
        width: 240px;
        margin-right: auto;
    }
    .email-send-container-wrapp.mobile-show .email-send-button {
        padding: 0;
        height: 37px;
    }
    .email-send-container-wrapp.mobile-show .email-send-button br {
        display: none;
    }
    .email-send-text {
        margin-right: auto;
        margin-left: 10%;
    }
    .contact-info-item {
        flex-direction: unset;
        flex-wrap: wrap;
    }
    .contact-info-row:nth-of-type(2) {
        margin-top: 15px;
        width: 100%;
        margin-left: unset;
    }
    .contact-info-row:nth-of-type(2) .contact-info-item {
        flex-direction: unset;
    }
    .contact-info-row {
        width: 100%;
    }
    .email-send-container {
        margin-top: 5px;
    }
    .email-send-container-desc,
    .email-send-button {
        width: 100%;
        max-width: unset;
    }
    .mysa-map {
        margin-bottom: 30px;
        margin-top: 20px;
    }
    .email-send-button {
        margin-top: 15px;
    }
    .email-send-container {
        flex-direction: column;
        padding: 10px;
    }
    .email-send-container-wrapp {
        height: unset;
    }
    .form-wrapper {
        top: 110px;
    }
}
@media screen and (max-width: 400px) {
    .email-send-container-wrapp.mobile-show .form-wrapper {
        top: 117px;
    }
}
</style>

<style>
.mysa-map .vue-map {
    border-radius: 30px;
}
@media screen and (max-width: 1365px) {
    .mysa-map .vue-map-container.google-map {
        height: 421px !important;
    }
}
@media screen and (max-width: 1023px) {
    .mysa-map .vue-map-container.google-map {
        height: 405px !important;
    }
}
@media screen and (max-width: 767px) {
    .mysa-map .vue-map-container.google-map {
        height: 200px !important;
    }
    .mysa-map .vue-map {
        border-radius: 10px;
    }
}
</style>
