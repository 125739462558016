<template>
    <section class="relative">
        <div class="container-wrapper mb-144 ">
            <img class="bottom-decor-img" loading="lazy" src="../assets/img/decor.png" alt="image">
            <div class="vacancy-main">
                <div class="left-content">

                    <content-title class="heading-margin" :colored-title="false" :title="_.get($store.state.main.current_content, 'primary.data.list[0].title')" data-aos="fade-up" />

                    <div class="vacancy-content remove-text"
                        data-aos="fade-up" data-aos-delay="400"
                        v-html="_.get($store.state.main.current_content, 'primary.data.list[0].content')">
                    </div>

                </div>
                <div class="right-content" data-aos="fade-down"
                     v-if="_.get(this.$store.state.main.current_content,'secondary.ContactBlock.data.list[0]]')">
                    <InformationBookingCard
                        :email="_.get(this.$store.state.main.current_content,'secondary.ContactBlock.data.list[0].email')"
                        :attachable="true"
                        title="Book Call" :data="_.get(this.$store.state.main.current_content,'secondary.ContactBlock.data.list[0].contact_info')" />
                </div>
            </div>
            <div class="b2b-partners" data-aos="fade-up" >
                <h2 class="font-13 bold partners-text" @click="passData()" >{{$fn.tr('Share Vacancy')}}
                    <svg xmlns="http://www.w3.org/2000/svg" width="14.458" height="16" viewBox="0 0 14.458 16">
                        <path id="Icon_material-share"
                              data-name="Icon material-share"
                              d="M16.548,14.309a2.339,2.339,0,0,0-1.574.618L9.247,11.594a2.223,2.223,0,0,0,0-1.124l5.663-3.3a2.4,2.4,0,1,0-.771-1.759,2.629,2.629,0,0,0,.072.562l-5.663,3.3a2.41,2.41,0,1,0,0,3.518l5.719,3.341a2.266,2.266,0,0,0-.064.522,2.345,2.345,0,1,0,2.345-2.345Z"
                              transform="translate(-4.5 -3)" fill="#81c7bd"/>
                    </svg>
                </h2>
            </div>
            <div class="mobile-right-content" data-aos="fade-up">
                <InformationBookingCard
                    :data="_.get(this.$store.state.main.current_content,'secondary.ContactBlock.data.list[0].contact_info')"
                    v-if="_.get(this.$store.state.main.current_content,'secondary.ContactBlock.data.list[0]')"
                    :cardInputs="cardInputs" :attachable="true" />
            </div>
        </div>

        <ShareModal @closeModal="shareModal= false" :shareData="shareData" :pageURL="getURL" :modalActive="shareModal" />

        <CartInfo  />

    </section>
</template>

<script>
import ContentTitle from "../components/ContentTitle";
import InformationBookingCard from "../components/MediaAndMarketing/InformationBookingCard";
import CartInfo from "../components/CartInfo"
import ShareModal from "../components/ShareModal"

export default {

    components: {
        ContentTitle,
        InformationBookingCard,
        ShareModal,
        CartInfo
    },

    data() {
        return {
            title: 'Graphic Designer',
            cardInputs: [
                {
                    name: "First Name",
                    type: "text",
                    required: true,
                },
                {
                    name: "Last Name",
                    type: "text",
                    required: true,
                },
                {
                    name: "Phone Number",
                    type: "tel",
                    required: false,
                },
            ],
            shareModal: false,
        }
    },
    computed: {
        getURL(){
            const pageURL = window.location.href

            return pageURL
        },
        shareData(){
            const data = _.get(this.$store.state.current_content, 'primary.data.list[0].title')

            return data
        }
    },
    methods: {
        passData(){
            this.shareData
            this.shareModal = true
        },

    },
    mounted() {
        this.$store.commit('main/setState', {key: "breadcrumbList", val: [{title: 'Career', url: `/${this.$store.state.main.locale}/career`}]})
    }

}
</script>

<style scoped>
    .heading-margin {
        margin-bottom: 55px;
        margin-top: 5px;
    }
    .vacancy-main{
        display: flex;
        justify-content: space-between;
        margin: 0 0 35px;
    }
    .mb-144{
        margin-bottom: 144px;
    }
    .mb-30{
        margin-bottom: 30px;
    }
    .left-content{
        width: 57%;
        margin-right: 80px;
    }
    .right-content {
        width: 32%;
    }
    .bottom-decor-img{
        right: -30%;
        bottom: -5%;
    }
    .mobile-right-content{
        display: none;
    }
    .vacancy-content span{
        color: #FF7675;
        margin-right: 5px;
    }
    .vacancy-content ul li{
        display: flex;
        margin-bottom: 5px;
        line-height: 22px;
    }
    .vacancy-content ul li p{
        line-height: 22px;
    }
    .content-heading{
        margin-bottom: 30px;
        color: #81C7BD;
    }
    .partners-text{
        display: flex;
        align-items: center;
    }
    .partners-text svg{
        margin-left: 10px;
    }
    .b2b-partners-swiper{
        position: relative;
        padding-top: 130px;
    }
    .b2b-partners-swiper img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        position: absolute;
        top: 0;
        left: 0;
    }
    @media(max-width: 1599px){
        .heading-margin{
            margin: -16px 0 55px 0;
            z-index: 10;
            position: relative;
        }
    }
    @media screen and (max-width: 1365px) {
        .left-content {
            width: 55%;
        }
        .right-content {
            width: 45%;
        }
        .heading-margin{
            margin: -16px 0 34px 0;
        }
        .pdf-wrapper, .book-cal {
            width: 100%;
        }
        .mb-144{
            margin-bottom: 75px;
        }
    }

    @media screen and (max-width: 1024px){
        .right-content, .left-content {
            width: 45%;
        }
        .content-heading{
            margin-bottom: 21px;
        }
    }
    @media screen and (max-width: 1023px){
        .vacancy-main{
            margin: 0 0 10px;
        }
        .right-content {
            display: none;
        }
        .content-heading{
            margin-bottom: 15px;
        }
        .mb-30{
            margin-bottom: 20px;
        }
        .mobile-right-content{
            margin-top: 45px;
            width: 100%;
            display: block;
        }
        .left-content {
            width: 100%;
            margin-right: 0;
        }
        .heading-margin {
            margin: 15px 0 34px 0;
        }
    }
    @media (max-width: 767px){
        .mobile-right-content{
            margin-top: 35px;
        }
        .heading-margin{
            margin: 0px 0 21px 0;
        }
    }
</style>
