<template>
    <div class="incriment bold font-16">
        <span @click="decrement" class="decrement">
            <svg xmlns="http://www.w3.org/2000/svg" width="8.939" height="8.3" viewBox="0 0 8.939 2.235">
                <path id="Icon_open-plus" data-name="Icon open-plus" d="M3.352,3.352H0V5.587H8.939V3.352H3.352Z" transform="translate(0 -3.352)" fill="#435059"/>
            </svg>
        </span>
            {{ itemCount }}
        <span @click="increment" class="increment">
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 8.939 8.939">
                <path id="Icon_open-plus" data-name="Icon open-plus" d="M3.352,0V3.352H0V5.587H3.352V8.939H5.587V5.587H8.939V3.352H5.587V0Z" fill="#435059"/>
            </svg>
        </span>
    </div>
</template>

<script>

    export default {
        props: ['itemCount'],
        
        methods: {
            increment () {
                this.itemCount++;
            },
            decrement () {
                if(this.itemCount > 1){
                    this.itemCount-- ;
                }
            }
        }
    }
</script>

<style scoped>
    .incriment{
        width: 120px;
        border-radius: 10px;
        border: 1px solid #F7F7F7;
        background: #fff;
        padding: 10px 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 30px;
        cursor: pointer;
    }
    .decrement:hover svg path{
        fill: #FEC55E;
        transition: 0.3s;
    }
    .increment:hover svg path{
        fill: #FEC55E;
        transition: 0.3s;
    }
    @media(max-width: 767px){
        .incriment{
            margin-right: 10px;
        }
    }
</style>