

<template>
<div class="relative">
	<div class="swiper-button-next swiper-next"></div>
    <div class="swiper-button-prev swiper-prev"></div>
	
	<swiper ref="mySwiper" class="swiper-view" :options="swiperOptions" >
        <swiper-slide v-for="(item, index) in sliderData" :key="index">
			<BrandSlider :sliderData="item" />
        </swiper-slide>
    </swiper>
</div>
    
</template>

<script>
import BrandSlider from "./BrandSliderCard"

export default {

	props: ['sliderData'],
	
	components:{
		BrandSlider
	},

    data(){
        return{
			active: false,
            swiperOptions: {
    			speed: 400,
				watchOverflow: true,
				navigation: {
                    nextEl: '.swiper-next',
                    prevEl: '.swiper-prev',
                },
    			breakpoints: {
					320:{
						slidesPerView: 1.1,
						spaceBetween: 15,
					},
    				768: {
			            spaceBetween: 15,
			            slidesPerView: 2.3
		          	},
		          	1024: {
			            spaceBetween: 15,
			            slidesPerView: 3
                    },
                    1600: {
                        spaceBetween: 15,
			            slidesPerView: 3
                    },
                    1920: {
                        spaceBetween: 30,
			            slidesPerView: 3
                    }
    			}
            },
        }
	},
}

</script>

<style scoped>
	.relative{
		position: relative;
	}
    .flex{
        display: flex;
    }
    .align-center{
        align-items: center;
    }
    .active path {
        fill: #fff
    }
	.swiper-button-prev{
        background: #f7f7f7;
        color: #435059;
        border-radius: 10px;
        width: 44px;
        height: 44px;
        left: -74px;
		top: 41%;
    }
    .swiper-button-next{
        background: #f7f7f7;
        color: #435059;
        border-radius: 10px;
        width: 44px;
        height: 44px;
        right: -74px;
		top: 41%;
		left: auto;
    }
    .swiper-button-next::after{
        font-size: 22px;
    }
    .swiper-button-prev::after{
        font-size: 22px;
    }
	@media (max-width: 1599px){
		.swiper-button-next {
			right: 0px;
			top: -85px;
			left: auto;
		}
		.swiper-button-prev {
			left: auto;
			right: 66px;
			top: -85px;
		}
	}
	@media (max-width: 1365px){
		.swiper-button-next {
			top: -80px;
		}
		.swiper-button-prev {
			top: -80px;
		}
	}
	@media (max-width: 1023px){
		.swiper-button-next{
			top: -60px;
		}
		.swiper-button-prev{
			top: -60px;
		}
	}
	@media (max-width: 767px){
		.swiper-button-next{
			right: 10px;
			width: 33px;
        	height: 33px;
			top: -48px;
		}
		.swiper-button-prev{
			right: 50px;
			width: 33px;
        	height: 33px;
			top: -48px;
		}
	}
</style>