<template>
    <div>
        <div
            id="app-content"
            v-if="
                _.get($store.state.main.indx, 'locale') &&
                    _.get($store.state.main.indx, 'menus').length
            "
        >
            <Header v-if="$route.meta.url !== 'print'" />
            <div class="content" data-aos="fade" data-aos-delay="500">
                <router-view />
            </div>
            <Footer v-if="$route.meta.url !== 'print'" />
            <portal-target name="destination" />
        </div>
    </div>
</template>

<script>
import Header from "./components/modules/Header";
import Footer from "./components/modules/Footer";

export default {
    components: {
        Header,
        Footer
    },
    data() {
        let currentQuery = null;
        return {
            totalPageHeight: 0,
            currentQuery
        };
    },

    metaInfo() {
        let categoryTitle = null;
        let categoryDescription = null;
        let categoryKeywords = null;

        // const isSingle = Object.values(this.$route.params).includes(
        //     "productview"
        // );

        // if (true) {
        //     categoryTitle = this.$store.state.main.current_content?.metaData
        //         ?.placeFillers?.meta?.title;
        //     categoryDescription = this.$store.state.main.current_content
        //         ?.metaData?.placeFillers?.meta?.description;
        //     categoryKeywords = this.$store.state.main.current_content?.metaData
        //         ?.placeFillers?.meta?.keywords;
        // }

        categoryTitle = this.$store.state.main.current_content?.metaData
            ?.placeFillers?.meta?.title;
        categoryDescription = this.$store.state.main.current_content?.metaData
            ?.placeFillers?.meta?.description;
        categoryKeywords = this.$store.state.main.current_content?.metaData
            ?.placeFillers?.meta?.keywords;

        if (this.currentQuery?.category && this.currentQuery.category != null) {
            const category = this.currentQuery?.category.split(",")?.[0];
            const currentCategory = this.$store.state.main.indx?.attributes?.products_category?.find(
                item => item?.slug == category && item.pid == 0
            );
            categoryTitle =
                currentCategory?.seo_title || currentCategory?.title;
            categoryDescription = currentCategory?.seo_description;
            categoryKeywords = currentCategory?.seo_keywords;
        }

        const innerSeo = _.get(
            this.$store.state.main.current_content,
            `primary.data.list[0].seo_title_${this.$store.state.main.locale}`
        );
        const website_title = _.get(
            this.$store.state.main.indx,
            "siteSettings.website_title"
        );
        let contentTitle =
            innerSeo ||
            _.get(this.$store.state.main.selected_menu, "seo.title") ||
            categoryTitle ||
            _.get(this.$store.state.main.selected_menu, "title");
        if (
            !innerSeo &&
            Object.values(this.$route.params).includes("singleview")
        ) {
            contentTitle = _.get(
                this.$store.state.main.current_content,
                "primary.data.list[0].title"
            );
        }

        const description =
            _.get(this.$store.state.main.selected_menu, [
                "seo",
                "description"
            ]) ||
            categoryDescription ||
            _.get(
                this.$store.state.main.indx,
                "siteSettings.website_meta_description"
            );
        const keyword =
            _.get(this.$store.state.main.selected_menu, ["seo", "keywords"]) ||
            categoryKeywords ||
            _.get(
                this.$store.state.main.indx,
                "siteSettings.website_meta_keywords"
            );
        // if no subcomponents specify a metaInfo.title, this title will be used

        return {
            title: contentTitle,
            // all titles will be injected into this template
            // titleTemplate: "%s | " + website_title,
            titleTemplate: "%s",
            meta: [
                {
                    vmid: "description",
                    name: "description",
                    content: description
                },
                {
                    vmid: "keyword",
                    name: "keyword",
                    content: keyword
                }
            ]
        };
    },

    methods: {
        authorizationHandler() {
            // if exists token or sessionid, request user data
            if (
                Boolean(this.$cookies.get("userToken")) ||
                Boolean(sessionStorage.userToken)
            ) {
                this.$store.dispatch("user/getUserData", {
                    token:
                        this.$cookies.get("userToken") ||
                        sessionStorage.userToken
                });
            }
        },
        getCartAndWishListItems() {
            this.$store.dispatch("shop/getCartItems");
            this.$store.dispatch("shop/getWishList");
        }
    },

    watch: {
        "$store.state.selected_menu": {
            deep: true,
            immediate: true,
            handler() {
                this.$store.commit("main/setCurrentMenuLocalePaths");
            }
        },
        $route: {
            deep: true,
            immediate: false,
            handler() {
                this.currentQuery = Object.assign({}, this.$route?.query);
            }
        }
    },

    mounted() {
        // if (this.$route.query.token) {
        //     localStorage.userToken = this.$route.query.token;
        // }
        this.authorizationHandler();
        this.getCartAndWishListItems();
        let query = Object.assign({}, this.$route.query);
        // delete query.page;
        this.$router.replace({ query }).catch(() => {});
        this.currentQuery = Object.assign({}, this.$route?.query);
    }
};
</script>

<style scoped>
#app-conten,
.content {
    min-height: 80vh;
    /*transition: 2s ease;*/
}
</style>

<style>
#progressBar,
#progressBarContainer {
    z-index: 1000;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
 {
    opacity: 0;
}

@media screen and (max-width: 767px) {
    #progressBar,
    #progressBarContainer {
        display: none;
    }
}
</style>
