<template>
    <div class="open-history active print-order">
        <div
            class="order-history-item"
            @click="$emit('clickedOrder', order.id)"
        >
            <div class="order-history-row">
                {{ moment(getOrderInfo.updated_at).format("DD MMM YYYY") }}
            </div>
            <div class="order-history-row">{{ getOrderInfo.id }}</div>
            <div class="order-history-row">{{ getOrderInfo.quantity }}</div>
            <div class="order-history-row">{{ getOrderInfo.total_price }}</div>
            <div class="order-history-row" :class="getOrderInfo.status">
                {{ $fn.tr(getOrderInfo.status) }}
            </div>
            <div class="order-history-row">
                <div @click="printOrder" class="print center-flex">
                    <img
                        src="../assets/img/print.svg"
                        loading="lazy"
                        alt="print"
                    />
                </div>
            </div>
            <div class="center-flex"></div>
        </div>
        <div class="opened-content open">
            <div class="mobile-order-info">
                <div class="order-history-row">
                    <span>{{ $fn.tr("Items Count") }}</span>
                    {{ getOrderInfo.quantity }}
                </div>
                <div class="order-history-row">
                    <span>{{ $fn.tr("Total Price") }}</span
                    >{{ getOrderInfo.total_price }}
                </div>
                <div class="order-history-row">
                    <span>{{ $fn.tr("Print") }}</span>
                    <div class="print center-flex">
                        <img
                            src="../assets/img/print.svg"
                            loading="lazy"
                            alt="print"
                        />
                    </div>
                </div>
                <div class="order-history-row" :class="getOrderInfo.status">
                    <span>{{ $fn.tr("Shipping Status") }}</span
                    >{{ $fn.tr(getOrderInfo.status) }}
                </div>
            </div>
            <div class="item-list">
                <CartProductItem
                    v-if="getOrderInfo.order_data"
                    :product="product"
                    v-for="product in getOrderInfo.order_data"
                />
            </div>

            <div class="shipping-info-wrapper">
                <div class="shipping-info-title">
                    {{ $fn.tr("Shipping Information") }}:
                </div>
                <div class="shipping-info">
                    <div class="shipping-info-row">
                        <div class="shipping-info-row-item">
                            <div class="row-label">
                                {{ $fn.tr("First & Last Name") }}:
                            </div>
                            <div class="row-label">
                                {{ _.get(getOrderInfo, "user_data.fullname") }}
                            </div>
                        </div>
                        <div class="shipping-info-row-item">
                            <div class="row-label">{{ $fn.tr("Email") }}:</div>
                            <div class="row-label">
                                {{ _.get(getOrderInfo, "user_data.email") }}
                            </div>
                        </div>
                        <div
                            class="shipping-info-row-item"
                            v-if="_.get(getOrderInfo, 'address.city')"
                        >
                            <div class="row-label">{{ $fn.tr("City") }}:</div>
                            <div class="row-label">
                                {{ _.get(getOrderInfo, "address.city") }}
                            </div>
                        </div>
                        <div
                            class="shipping-info-row-item"
                            v-if="_.get(getOrderInfo, 'address.address')"
                        >
                            <div class="row-label">
                                {{ $fn.tr("Address") }}:
                            </div>
                            <div class="row-label">
                                {{ _.get(getOrderInfo, "address.address") }}
                            </div>
                        </div>
                        <div class="shipping-info-row-item">
                            <div class="row-label">
                                {{ $fn.tr("Phone Number") }}:
                            </div>
                            <div class="row-label">
                                {{ _.get(getOrderInfo, "user_data.phone") }}
                            </div>
                        </div>
                    </div>
                    <div class="shipping-info-row">
                        <div class="shipping-info-row-item">
                            <div class="row-label">
                                {{ $fn.tr("Subtotal") }}:
                            </div>
                            <div class="row-label">
                                {{ getOrderInfo.total_price }} ₾
                            </div>
                        </div>
                        <div class="shipping-info-row-item">
                            <div class="row-label">
                                {{ $fn.tr("Shipping") }} :
                            </div>
                            <div class="row-label">
                                {{
                                    getOrderInfo.shipping_price
                                        ? getOrderInfo.shipping_price
                                        : 0
                                }}
                                ₾
                            </div>
                        </div>
                        <div class="shipping-info-row-item">
                            <div class="row-label">{{ $fn.tr("Sale") }} :</div>
                            <div class="row-label">
                                {{ getOrderInfo.sale_price || 0 }} ₾
                            </div>
                        </div>
                        <div class="shipping-info-row-item">
                            <div class="row-label total">
                                {{ $fn.tr("Order Total") }} :
                            </div>
                            <div class="row-label total">
                                {{ getOrderInfo.total_price }} ₾
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CartProductItem from "../components/checkout/CartProductItem";
import { mapGetters } from "vuex";
export default {
    name: "order-history-item",
    components: {
        CartProductItem
    },
    computed: {
        ...mapGetters({ getOrderHistory: "user/getOrderHistory" }),
        getOrderInfo() {
            return this.getOrderHistory.find(
                order => order.id === parseInt(this.$route.query.orderId)
            );
        }
    },
    data() {
        return {
            open: false,
            shippingStatus: "delivered",
            openHistory: false,
            order: {
                id: 1,
                date: "20 DEC 2020",
                phone: 2575345545,
                count: 2,
                price: "$ 80 ",
                shippingStatus: "delivered"
            },
            itemList: [
                {
                    name: "Mitzi Nightstand - Antique Rose",
                    price: 455
                },

                {
                    name: "Mitzi Nightstand - Antique Rose",
                    price: 455
                },
                {
                    name: "Mitzi Nightstand - Antique Rose",
                    price: 455
                }
            ]
        };
    },
    methods: {
        printOrder() {
            window.print();
        }
    },
    mounted() {
        this.$store.commit("main/setState", {
            key: "breadcrumbList",
            val: [{ title: "Print" }]
        });
    }
};
</script>

<style scoped>
.active-image-dropdown {
    transform: rotate(180deg);
    transition: 0.2s ease;
}

.active-image-dropdown path {
    fill: var(--green);
    transition: 0.2s ease;
}

.order-history-row {
    font-family: var(--noto-regular);
    font-size: 16px;
}

.order-history-item {
    display: flex;
    height: 80px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 10px;
    cursor: pointer;
}

.order-history-row {
    width: 15%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--noto-bold);
    font-size: 13px;
}

.order-history-row:nth-of-type(1) {
    text-align: left;
    justify-content: flex-start;
}

.order-history-row:nth-of-type(3) {
    width: 25%;
}
.order-history-row:nth-of-type(4) {
    width: 10%;
}
.order-history-row:nth-of-type(5) {
    width: 26%;
    text-transform: capitalize;
}
.order-history-row:nth-of-type(6) {
    width: 15%;
}

.order-history-item svg {
    margin: auto;
    transition: 0.2s ease;
    cursor: pointer;
}

.print {
    background-color: var(--light-green);
    width: 44px;
    height: 44px;
    border-radius: 10px;
    margin: 0 30px 0 auto;
}
/*shipping status classes*/
.shipped {
    color: #435059;
}
.pending {
    color: var(--orange);
}

.delivered {
    color: var(--green);
}

.item-list {
    /*min-width: 500px;*/
    margin-top: 55px;
    max-height: 350px;
    overflow-y: auto;
    margin-bottom: 67px;
}
.item-list::-webkit-scrollbar {
    width: 3px;
    background-color: #d9dcde;
}
.item-list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #435059;
}
.open-history {
    border: 1px solid #f7f7f7;
    transition: 0.4s ease;
}
.open-history.active {
    border: 1px solid #81c7bd;
    max-height: 1000px;
    overflow: unset;
    transition: 0.4s ease;
}

.open-history.active .order-history-item {
    /*border: unset;*/
    border-bottom: 1px solid #f7f7f7;
    border-radius: unset;
    /*transition:  .4s ease;*/
}

.opened-content {
    padding-left: 30px;
    padding-right: 30px;
    max-height: 0;
    overflow: hidden;
    transition: 0.7s;
}
.opened-content.open {
    max-height: 1000px;
    transition: 0.7s;
}

.open-history {
    border-radius: 10px;
    width: 90%;
    margin: 24px auto;
    background-color: #ffffff;
    max-height: 80px;
    overflow: hidden;
    transition: max-height 0.7s ease, background-color 0.7s ease;
}

.shipping-info-wrapper {
    padding-bottom: 35px;
    padding-top: 12px;
    border-top: 1px solid #f7f7f7;
}

.shipping-info {
    display: flex;
    justify-content: space-between;
}

.shipping-info-title {
    color: var(--green);
    font-size: 21px;
    font-family: var(--noto-bold);
    margin-bottom: 25px;
}
.shipping-info-row {
    width: 40%;
}
.row-label {
    width: 48%;
    font-family: var(--noto-regular);
    line-height: 30px;
}
.row-label:nth-of-type(2) {
    font-family: var(--noto-bold);
}
.shipping-info-row:nth-of-type(2) {
    width: unset;
    margin-top: 28px;
}
.shipping-info-row:nth-of-type(2) .row-label {
    text-align: right;
    width: 100%;
}
.shipping-info-row:nth-of-type(2) .row-label:nth-of-type(2) {
    width: 80px;
    text-align: left;
    margin-left: 20px;
}
.shipping-info-row-item {
    display: flex;
    font-size: 16px;
}
.total {
    font-size: 21px;
    font-family: var(--noto-bold);
}
.mobile-order-info {
    display: none;
}

@media screen and (max-width: 1355px) {
    .order-history-row:nth-of-type(6) {
        width: 10%;
    }
    .print {
        margin: 0 auto 0 0;
    }

    .order-history-row {
        width: 18%;
    }
    .order-history-item {
        padding-left: 25px;
        padding-right: 13px;
    }
    .shipping-info-wrapper {
        padding-top: 35px;
    }
    .row-label {
        line-height: 32px;
    }
    .item-list {
        margin-bottom: 40px;
    }
}
@media screen and (max-width: 1023px) {
    .print {
        margin: 0 12px 0 auto;
        width: 34px;
        height: 34px;
    }
    .order-history-item {
        padding-left: 20px;
        padding-right: 17px;
    }
}
@media screen and (max-width: 768px) {
    .order-history-item .order-history-row:nth-of-type(3),
    .order-history-item .order-history-row:nth-of-type(4),
    .order-history-item .order-history-row:nth-of-type(5),
    .order-history-item .order-history-row:nth-of-type(6) {
        display: none;
    }
    .order-history-row {
        width: 42%;
    }

    .order-history-row + .center-flex {
        width: 15%;
    }

    .mobile-order-info {
        display: flex;
        flex-wrap: wrap;
        margin-top: 15px;
    }
    .mobile-order-info .order-history-row {
        width: 45%;
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 10px;
    }
    .mobile-order-info .order-history-row span {
        font-size: 13px;
        font-family: var(--noto-regular);
        margin-bottom: 15px;
        margin-top: 5px;
    }
    .print {
        margin: auto;
        margin-left: unset;
    }
    .shipping-info-row,
    .row-label {
        width: 100%;
    }
    .shipping-info-row:nth-of-type(2) {
        border-top: 1px solid #f7f7f7;
        padding-top: 22px;
        margin-top: 5px;
    }
    .shipping-info-row:nth-of-type(2) .shipping-info-row-item {
        flex-direction: row;
        height: 32px;
        justify-content: space-between;
    }
    .shipping-info-row:nth-of-type(2) .row-label:nth-of-type(2) {
        margin-left: unset;
        width: unset;
    }
    .open-history.active,
    .opened-content.open {
        max-height: 1600px;
    }
    .shipping-info-row:nth-of-type(2) .row-label {
        text-align: unset;
    }
    .shipping-info,
    .shipping-info-row-item {
        flex-direction: column;
    }
    .opened-content {
        padding-left: 20px;
        padding-right: 20px;
    }
    .item-list {
        margin-top: 30px;
        margin-bottom: 15px;
    }
    .row-label {
        line-height: 25px;
    }
    .shipping-info-wrapper {
        padding-top: 24px;
    }
    .shipping-info-row-item {
        height: 66px;
    }
    .shipping-info-wrapper {
        padding-bottom: 17px;
    }
    .shipping-info-row:nth-of-type(2) .row-label {
        width: unset;
    }
}
</style>

<style>
.print-order .product-item-row .close {
    display: none;
}

@media screen and (max-width: 520px) {
    .order-history-table .product-item {
        width: 100%;
    }
    .order-history-table .product-item-row:first-child {
        flex-direction: unset;
        width: 100%;
    }
    .order-history-table .item-list .product-item-desc {
        margin-left: 20px;
    }
}
</style>
