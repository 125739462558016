<template>
    <div class="open-history" :class="{ active: active }">
        <div
            class="order-history-item"
            @click="$emit('clickedOrder', order.id)"
        >
            <div class="order-history-row">
                {{ moment(order.updated_at).format("DD MMM YYYY") }}
            </div>
            <div class="order-history-row">{{ order.id }}</div>
            <div class="order-history-row">{{ order.quantity }}</div>
            <div class="order-history-row">{{ order.total_price }}</div>
            <div class="order-history-row" :class="order.status">
                {{ $fn.tr(order.status) }}
            </div>
            <div class="order-history-row">
                <router-link
                    :to="
                        `/${$store.state.main.locale}/print?orderId=${order.id}`
                    "
                    tag="div"
                    class="print center-flex"
                >
                    <img
                        src="../assets/img/print.svg"
                        loading="lazy"
                        alt="print"
                    />
                </router-link>
                <div class="center-flex" v-if="!upload">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        :class="{ 'active-image-dropdown': active }"
                    >
                        <g
                            id="Group_3596"
                            data-name="Group 3596"
                            transform="translate(0 24) rotate(-90)"
                        >
                            <rect
                                id="Rectangle_48"
                                data-name="Rectangle 48"
                                width="24"
                                height="24"
                                fill="rgba(255,0,0,0)"
                            />
                            <g
                                id="Group_820"
                                data-name="Group 820"
                                transform="translate(5.856 2)"
                            >
                                <path
                                    id="Path_331"
                                    data-name="Path 331"
                                    d="M213.584,780.046a1.144,1.144,0,0,0,.809-1.953l-8.047-8.047L214.393,762a1.144,1.144,0,1,0-1.618-1.618l-8.856,8.856a1.144,1.144,0,0,0,0,1.618l8.856,8.856A1.14,1.14,0,0,0,213.584,780.046Z"
                                    transform="translate(-203.584 -760.046)"
                                    fill="#435059"
                                />
                            </g>
                        </g>
                    </svg>
                </div>
            </div>
        </div>
        <div class="opened-content" :class="{ open: active }">
            <div class="mobile-order-info">
                <div class="order-history-row">
                    <span>{{ $fn.tr("Items Count") }}</span>
                    {{ order.quantity }}
                </div>
                <div class="order-history-row">
                    <span>{{ $fn.tr("Total Price") }}</span
                    >{{ order.total_price }}
                </div>

                <div class="order-history-row">
                    <span>{{ $fn.tr("Print") }}</span>

                    <router-link
                        :to="
                            `/${$store.state.main.locale}/print?orderId=${order.id}`
                        "
                        tag="div"
                        class="print center-flex pointer"
                    >
                        <img
                            src="../assets/img/print.svg"
                            loading="lazy"
                            alt="print"
                        />
                    </router-link>
                </div>
                <div class="order-history-row" :class="order.status">
                    <span>{{ $fn.tr("Shipping Status") }}</span
                    >{{ $fn.tr(order.status) }}
                </div>
            </div>
            <div class="item-list">
                <template
                    v-for="(product, index) in checkValJson(order.order_data)"
                >
                    <CartProductItem
                        :key="index"
                        v-if="order.order_data"
                        :product="product"
                    />
                </template>
            </div>

            <div class="shipping-info-wrapper">
                <div class="shipping-info-title">
                    {{ $fn.tr("Shipping Information") }}:
                </div>
                <div class="shipping-info">
                    <div class="shipping-info-row">
                        <div class="shipping-info-row-item">
                            <div class="row-label">
                                {{ $fn.tr("First & Last Name") }}:
                            </div>
                            <div class="row-label">
                                {{ checkValJson(order.user_data).fullname }}
                            </div>
                        </div>
                        <div class="shipping-info-row-item">
                            <div class="row-label">{{ $fn.tr("Email") }}:</div>
                            <div class="row-label">
                                {{ checkValJson(order.user_data).email }}
                            </div>
                        </div>
                        <div
                            class="shipping-info-row-item"
                            v-if="checkValJson(order.address).city"
                        >
                            <div class="row-label">{{ $fn.tr("City") }}:</div>
                            <div class="row-label">
                                {{ checkValJson(order.address).city }}
                            </div>
                        </div>
                        <div
                            class="shipping-info-row-item"
                            v-if="checkValJson(order.address).address"
                        >
                            <div class="row-label">
                                {{ $fn.tr("Address") }}:
                            </div>
                            <div class="row-label">
                                {{ checkValJson(order.address).address }}
                            </div>
                        </div>
                        <div class="shipping-info-row-item">
                            <div class="row-label">
                                {{ $fn.tr("Phone Number") }}:
                            </div>
                            <div class="row-label">
                                {{ checkValJson(order.user_data).phone }}
                            </div>
                        </div>
                    </div>
                    <div class="shipping-info-row">
                        <div class="shipping-info-row-item">
                            <div class="row-label">
                                {{ $fn.tr("Subtotal") }}:
                            </div>
                            <div class="row-label">{{ getFirstPrice }} ₾</div>
                        </div>
                        <div class="shipping-info-row-item">
                            <div class="row-label">
                                {{ $fn.tr("Shipping") }} :
                            </div>
                            <div class="row-label">
                                {{ order.shipping_price }} ₾
                            </div>
                        </div>
                        <div class="shipping-info-row-item">
                            <div class="row-label">{{ $fn.tr("Sale") }} :</div>
                            <div class="row-label">
                                {{ order.sale_price }} ₾
                            </div>
                        </div>
                        <div class="shipping-info-row-item">
                            <div class="row-label total">
                                {{ $fn.tr("Order Total") }} :
                            </div>
                            <div class="row-label total">
                                {{ order.total_price }} ₾
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CartProductItem from "../components/checkout/CartProductItem";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
    name: "order-history-item",
    components: {
        CartProductItem
    },
    props: ["order", "active", "orderInfo", "upload"],
    computed: {
        ...mapGetters({
            getCartList: "shop/getCartList",
            getCartItems: "shop/getCartItems"
        }),
        getFirstPrice() {
            let price = 0;
            this.checkValJson(this.order.order_data).forEach(res => {
                price += res.stock_price * res.quantity;
            });
            return price;
        },
        orderItems() {
            return typeof this.order.order_data === "string"
                ? JSON.parse(this.order.order_data)
                : this.order.order_data;
        }
    },
    data() {
        return {
            open: false,
            shippingStatus: "delivered",
            openHistory: false,
            itemList: [
                {
                    name: "Mitzi Nightstand - Antique Rose",
                    price: 455
                },

                {
                    name: "Mitzi Nightstand - Antique Rose",
                    price: 455
                },
                {
                    name: "Mitzi Nightstand - Antique Rose",
                    price: 455
                }
            ]
        };
    },
    methods: {
        checkValJson(val) {
            return typeof val === "string" ? JSON.parse(val) : val;
        }
    }
};
</script>

<style scoped>
.active-image-dropdown {
    transform: rotate(180deg);
    transition: 0.2s ease;
}

.active-image-dropdown path {
    fill: var(--green);
    transition: 0.2s ease;
}

.order-history-row {
    font-family: var(--noto-regular);
    font-size: 16px;
}

.order-history-row.Fail {
    color: orangered;
}

.order-history-row.Complete {
    color: #81c7bd;
}

.order-history-item {
    display: flex;
    height: 80px;
    padding-left: 30px;
    padding-right: 15px;
    /*border: 1px solid #F7F7F7;*/
    border-radius: 10px;
    cursor: pointer;
}

.order-history-row {
    width: 15%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--noto-bold);
    font-size: 13px;
}

.order-history-row:nth-of-type(1) {
    text-align: left;
    justify-content: flex-start;
}

.order-history-row:nth-of-type(3) {
    width: 25%;
}

.order-history-row:nth-of-type(4) {
    width: 10%;
}

.order-history-row:nth-of-type(5) {
    width: 26%;
    text-transform: capitalize;
}

.order-history-row:nth-of-type(6) {
    width: 15%;
}

.order-history-item svg {
    margin: auto;
    transition: 0.2s ease;
    cursor: pointer;
}

.print {
    background-color: var(--light-green);
    width: 44px;
    height: 44px;
    border-radius: 10px;
    margin: 0 30px 0 auto;
}

/*shipping status classes*/
.shipped {
    color: #435059;
}

.pending {
    color: var(--orange);
}

.delivered,
.Shipped {
    color: var(--green);
}

.item-list {
    /*min-width: 500px;*/
    margin-top: 55px;
    max-height: 350px;
    overflow-y: auto;
    margin-bottom: 67px;
}

.item-list::-webkit-scrollbar {
    width: 3px;
    background-color: #d9dcde;
}

.item-list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #435059;
}

.open-history {
    border: 1px solid #f7f7f7;
    transition: 0.4s ease;
}

.open-history.active {
    border: 1px solid #81c7bd;
    max-height: 1000px;
    overflow: unset;
    transition: 0.4s ease;
}

.open-history.active .order-history-item {
    /*border: unset;*/
    border-bottom: 1px solid #f7f7f7;
    border-radius: unset;
    /*transition:  .4s ease;*/
}

.opened-content {
    padding-left: 30px;
    padding-right: 30px;
    max-height: 0;
    overflow: hidden;
    transition: 0.7s;
}

.opened-content.open {
    max-height: 1000px;
    transition: 0.7s;
}

.open-history {
    border-radius: 10px;
    margin: 24px 0;
    background-color: #ffffff;
    max-height: 80px;
    overflow: hidden;
    transition: max-height 0.7s ease, background-color 0.7s ease;
}

.shipping-info-wrapper {
    padding-bottom: 35px;
    padding-top: 12px;
    border-top: 1px solid #f7f7f7;
}

.shipping-info {
    display: flex;
    justify-content: space-between;
}

.shipping-info-title {
    color: var(--green);
    font-size: 21px;
    font-family: var(--noto-bold);
    margin-bottom: 25px;
}

.shipping-info-row {
    width: 380px;
}

.row-label {
    width: 48%;
    font-family: var(--noto-regular);
    line-height: 30px;
}

.row-label:nth-of-type(2) {
    font-family: var(--noto-bold);
}

.shipping-info-row:nth-of-type(2) {
    width: unset;
    margin-top: 28px;
}

.shipping-info-row:nth-of-type(2) .row-label {
    text-align: right;
    width: 100%;
}

.shipping-info-row:nth-of-type(2) .row-label:nth-of-type(2) {
    width: 80px;
    text-align: left;
    margin-left: 20px;
}

.shipping-info-row-item {
    display: flex;
    font-size: 16px;
}

.total {
    font-size: 21px;
    font-family: var(--noto-bold);
}

.mobile-order-info {
    display: none;
}

@media screen and (max-width: 1355px) {
    /*.order-history-row:nth-of-type(6) {*/
    /*    width: 10%;*/
    /*}*/
    .print {
        margin: 0 auto 0 0;
    }

    .open-history {
        margin: 20px 0 30px;
    }

    .order-history-row {
        width: 16%;
    }

    .order-history-item {
        padding-left: 25px;
        padding-right: 13px;
    }

    .shipping-info-wrapper {
        padding-top: 35px;
    }

    .row-label {
        line-height: 32px;
    }

    .item-list {
        margin-bottom: 40px;
    }
}

@media screen and (max-width: 1023px) {
    .print {
        margin: 0 15px 0 auto;
        width: 34px;
        height: 34px;
    }

    .order-history-item {
        padding-left: 20px;
        padding-right: 17px;
    }
}

@media screen and (max-width: 767px) {
    .order-history-item .order-history-row:nth-of-type(3),
    .order-history-item .order-history-row:nth-of-type(4),
    .order-history-item .order-history-row:nth-of-type(5),
    .order-history-item .order-history-row:nth-of-type(6) {
        display: none;
    }

    .order-history-row {
        width: 42%;
    }

    .order-history-row + .center-flex {
        width: 15%;
    }

    .mobile-order-info {
        display: flex;
        flex-wrap: wrap;
        margin-top: 15px;
    }

    .mobile-order-info .order-history-row {
        width: 45%;
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 10px;
        text-align: left;
    }

    .mobile-order-info .order-history-row span {
        font-size: 13px;
        font-family: var(--noto-regular);
        margin-bottom: 15px;
        margin-top: 5px;
    }

    .print {
        margin: auto;
        margin-left: unset;
    }

    .shipping-info-row,
    .row-label {
        width: 100%;
    }

    .shipping-info-row:nth-of-type(2) {
        border-top: 1px solid #f7f7f7;
        padding-top: 22px;
        margin-top: 5px;
    }

    .shipping-info-row:nth-of-type(2) .shipping-info-row-item {
        flex-direction: row;
        height: 32px;
        justify-content: space-between;
    }

    .shipping-info-row:nth-of-type(2) .row-label:nth-of-type(2) {
        margin-left: unset;
        width: unset;
    }

    .open-history.active,
    .opened-content.open {
        max-height: 1600px;
    }

    .shipping-info-row:nth-of-type(2) .row-label {
        text-align: unset;
    }

    .shipping-info,
    .shipping-info-row-item {
        flex-direction: column;
    }

    .opened-content {
        padding-left: 20px;
        padding-right: 20px;
    }

    .item-list {
        margin-top: 30px;
        margin-bottom: 15px;
    }

    .row-label {
        line-height: 25px;
    }

    .shipping-info-wrapper {
        padding-top: 24px;
    }

    .shipping-info-row-item {
        height: 66px;
    }

    .shipping-info-wrapper {
        padding-bottom: 17px;
    }

    .shipping-info-row:nth-of-type(2) .row-label {
        width: unset;
    }
}
</style>

<style>
.order-history .product-item-row:last-child {
    display: none;
}

@media screen and (max-width: 520px) {
    .order-history-table .product-item {
        width: 100%;
    }

    .order-history-table .product-item-row:first-child {
        flex-direction: unset;
        width: 100%;
    }

    .order-history-table .item-list .product-item-desc {
        margin-left: 20px;
    }
}
</style>
