import _ from 'lodash';

export default {
    getCartList(state) {
        return typeof state.cartList === 'string' ? JSON.parse(state.cartList) : state.cartList
    },
    getCartItems(state) {
        return state.cartList;
    },
    getWishListItems(state) {
        return state.wishList;
    },
    cartPopupStatus(state) {
        return state.cartPopupIsOpen;
    },
    orderData(state) {
        return state.orderData;
    }
}
