<template>
    <div>
        <div class="media-and-marketing-top container-wrapper">
            <div class="left-content" data-aos="fade-up">
                <content-title class="media-title"  :title="_.get($store.state.main.current_content, 'primary.data.list[0].title')" :colored-title="false" />
                <div class="media-and-marketing">
                    <div class="content-description" v-html="_.get($store.state.main.current_content, 'primary.data.list[0].content.tabs[0].content')" />

                </div>
            </div>
            <div class="right-content" data-aos="fade-down" data-aos-delay="200">
                <InformationBookingCard :email="_.get(this.$store.state.main.current_content,'secondary.ContactBlock.data[0].email')" title="Book Call" :data="_.get(this.$store.state.main.current_content,'secondary.ContactBlock.data.list[0].contact_info')" />
            </div>
        </div>
        <div data-aos="fade-up" class="media-and-marketing-pdf-download container-wrapper">
            <div class="press-releases-card-icon">
                <img src="../assets/icons/pdf.svg" loading="lazy" alt="pdf">
            </div>
            <a  rel="noopener" :href="_.get(this.$store.state.main.current_content, 'primary.data.list[0].file[0].url')" target="_blank" class="press-releases-card-link-title underline">{{_.get(this.$store.state.main.current_content, 'primary.data.list[0].file[0].title') ? _.get(this.$store.state.main.current_content, 'primary.data.list[0].file[0].title') : 'Commercial.pdf' }}</a>
        </div>

        <LightGallery
            :images="_.get($store.state.main.current_content, 'primary.data.list[0].images')"
            :index="index"
            :disable-scroll="true"
            @close="index = null"
        />

        <div class="gallery container-wrapper" data-aos="fade-up" data-aos-delay="400">
            <div class="gallery-title"  v-if="_.get($store.state.main.current_content, 'primary.data.list[0].images').length">
                {{$fn.tr('Gallery')}}
            </div>
            <swiper class="usefulToolsSlider" ref="usefulToolsSlider" :options="swiperOptions" data-aos="fade-up">
                <swiper-slide :key="thumbIndex" v-for="(thumb, thumbIndex) in _.get($store.state.main.current_content, 'primary.data.list[0].images')" >
                    <div class="image-wrapper" @click="photoIndex(thumbIndex)" >

                            <img
                                :src="_.get(thumb,'devices.desktop')"
                                loading="lazy"
                                alt="gallery"
                            />
                            <div class="image-wrapper-hover"></div>
                            <img class="cross" src="../assets/icons/cross.svg" loading="lazy" alt="cross">
<!--                        <img :src="image.url" loading="lazy" alt="gallery">-->
                    </div>
                </swiper-slide>

            </swiper>
        </div>



        <div data-aos="fade-up" class="mobile-right-content container-wrapper" data-aos-delay="400">
            <InformationBookingCard :email="_.get(this.$store.state.main.current_content,'secondary.ContactBlock.data[0].email')" title="Book Call" :data="_.get(this.$store.state.main.current_content,'secondary.ContactBlock.data.list[0].contact_info')" />
        </div>

        <div class="flex justify-between container-wrapper mb-195">
            <div class="b2b-partners" data-aos="fade-up">
                <div @click="passData()" class="font-13 bold partners-text">{{$fn.tr('Share News')}}
                    <svg xmlns="http://www.w3.org/2000/svg" width="14.458" height="16" viewBox="0 0 14.458 16">
                        <path id="Icon_material-share" data-name="Icon material-share" d="M16.548,14.309a2.339,2.339,0,0,0-1.574.618L9.247,11.594a2.223,2.223,0,0,0,0-1.124l5.663-3.3a2.4,2.4,0,1,0-.771-1.759,2.629,2.629,0,0,0,.072.562l-5.663,3.3a2.41,2.41,0,1,0,0,3.518l5.719,3.341a2.266,2.266,0,0,0-.064.522,2.345,2.345,0,1,0,2.345-2.345Z" transform="translate(-4.5 -3)" fill="#81c7bd"/>
                    </svg>
                </div>
            </div>
            <div class="b2b-partners" data-aos="fade-up" @click="$router.back()">
                <div class="font-13 bold back-text">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="13.714" viewBox="0 0 16 13.714">
                        <path id="Icon_metro-arrow-up" data-name="Icon metro-arrow-up" d="M13.38,6.049,7.665.335a1.143,1.143,0,0,0-1.616,0L.335,6.049A1.143,1.143,0,0,0,1.951,7.665L5.714,3.9V14.857a1.143,1.143,0,0,0,2.286,0V3.9l3.763,3.763A1.143,1.143,0,0,0,13.38,6.049Z" transform="translate(0 13.714) rotate(-90)" fill="#81c7bd"/>
                    </svg>
                    {{$fn.tr('Back')}}
                </div>
            </div>
        </div>

        <ShareModal @closeModal="shareModal= false" :shareData="shareData" :pageURL="getURL" :modalActive="shareModal" />

        <CartInfo  />
    </div>
</template>

<script>
    import ContentTitle from "../components/ContentTitle";
    import CartInfo from '../components/CartInfo'
    import InformationBookingCard from "../components/MediaAndMarketing/InformationBookingCard";
    import ShareModal from "../components/ShareModal"

    export default {
        components: {
            ContentTitle,
            InformationBookingCard,
            CartInfo,
            ShareModal
        },
        data() {
            return {
                title: 'New commercial made with CONNECT is live now to see',
                swiperOptions: {
                    speed: 800,
                    watchOverflow: true,
                    breakpoints: {
                        320: {
                            spaceBetween: 20,
                            slidesPerView: 1.5,
                        },
                        450: {
                            spaceBetween: 20,
                            slidesPerView: 2,
                        },
                        560: {
                            spaceBetween: 20,
                            slidesPerView: 3,
                        },
                        768: {
                            spaceBetween: 20,
                            slidesPerView: 3.5,
                        },
                        1024: {
                            spaceBetween: 40,
                            slidesPerView: 4,
                        },
                        1366: {
                            spaceBetween: 40,
                            slidesPerView: 5,
                        },
                        1599: {
                            spaceBetween: 40,
                            slidesPerView: 6,
                        },
                    },
                },
                shareModal: false,
                index: null,
            }
        },
        computed: {
            swiper() {
                return this.$refs.usefulToolsSlider.$swiper;
            },
            getURL(){
                const pageURL = window.location.href

                return pageURL
            },
            shareData(){
                const data = _.get(this.$store.state.current_content, 'primary.data.list[0].title')

                return data
            }
        },
        methods: {
            passData(){
                this.shareData
                this.shareModal = true
            },
            photoIndex(thumbIndex){
                this.index = thumbIndex
            },
        },
        mounted() {
            this.$store.commit('main/setState', {key: "breadcrumbList", val: [{title: 'Media & Marketing', url: `/${this.$store.state.main.locale}/media-and-marketing`}]})
        }
    }
</script>

<style scoped>
    .media-and-marketing-top {
        display: flex;
        justify-content: space-between;
        backface-visibility: hidden;
        transition-duration: .8s;
    }

    .left-content {
        margin-right: 80px;
        width: 55%;
    }

    .right-content {
        width: 32%;
    }

    .media-and-marketing-pdf-download {
        display: flex;
        align-items: center;
        margin-top: 60px;
    }

    .press-releases-card-icon {
        width: 44px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        background-color: var(--light-green);
    }

    .press-releases-card-link-title {
        font-family: var(--noto-bold);
        font-size: var(--normalFontSize);
        margin-left: 10px;
    }

    .image-wrapper {
        width: 100%;
        position: relative;
        display: flex;
        cursor: pointer;
        height: 116px;
    }
    .image-wrapper picture {
        position: relative;
    }

    .image-wrapper img {
        border-radius: 10px;
        /*position: absolute;*/
        top: 0;
        left: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;

    }
    .partners-text, .back-text {
        display: flex;
        align-items: center;
    }

    .back-text svg {
        margin-right: 6px;
    }
    .partners-text svg {
        margin-left: 6px;
    }

    .image-wrapper-hover {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        transition: .5s ease;
    }
    .image-wrapper .cross{
        position: absolute;
        width: 50px;
        height: 50px;
        top: 50%;
        left: 50%;
        opacity: 0;
        visibility: hidden;
        transform: translate(-50%, -50%);
        transition: .5s ease;
    }

    .image-wrapper img {
        width: 100%;
    }

    .gallery {
        /*overflow-x: hidden;*/
        padding-left: 20px;
        padding-right: 0;
    }

    .usefulToolsSlider {
        margin-bottom: 64px;
    }

    .gallery-title {
        font-family: var(--noto-bold);
        /*font-size: var(--normalFontSize);*/
        font-size: 21px;
        margin-top: 100px;
        margin-bottom: 15px;
    }

    .image-wrapper:hover .image-wrapper-hover{
        background-color: #81C7BD;
        background-repeat: no-repeat;
        background-position: left;
        background-size: cover;
        opacity: .7;
        transition: .5s ease;
    }

    .image-wrapper:hover .cross {
        opacity: 1;
        visibility: visible;
        transition: .5s ease;
    }

    .mobile-right-content {
        display: none;
    }

    .content-description {
        font-size: 13px;
        font-family: var(--noto-regular);
        line-height: 22px;
    }
    .mb-195{
        margin-bottom: 195px;
    }
    @media screen and (max-width: 1919px) {
        .media-and-marketing-top {
            padding: 0 12px 0 20px;
        }
    }
    @media screen and (max-width: 1599px){
        .media-and-marketing-top {
            width: 92%;
            padding: 0 0 0 7px;
        }
        .left-content {
            margin-right: 0;
            width: 60%;
        }
        .media-and-marketing-pdf-download {
            margin-top: 12px;
            width: 91%;
        }
        .gallery-title {
            margin-top: 72px;
            margin-bottom: 20px;
        }
        .gallery {
            padding-left: 0;
        }
        .image-wrapper {
            height: 102px;
        }
        .usefulToolsSlider {
            margin-bottom: 60px;
        }
        .mb-195{
            margin-bottom: 144px;
        }
    }


    @media screen and (max-width: 1365px) {
        .right-content, .left-content {
            width: 49%;
            margin-right: unset;
        }
        .media-and-marketing-top {
            width: 95% !important;
        }
        .media-and-marketing-pdf-download {
            width: 100%;
        }
        .gallery {
            width: 93%;
        }
        .mb-195{
            margin-bottom: 75px;
        }
    }

    @media screen and (max-width: 1023px){
        .right-content {
            display: none;
        }
        .media-and-marketing-pdf-download {
            margin-top: 45px;
        }

        .right-content, .left-content {
            width: 45%;
            margin-right: unset;
        }
        .usefulToolsSlider {
            margin-bottom: 50px;
        }
        .mobile-right-content {
            display: block;
            margin-bottom: 95px;
        }
        .media-title {
            margin-bottom: 50px;
            margin-left: 5px;
        }

        .left-content {
            width: 100%;
            margin-right: 0;
        }

        .gallery-title {
            margin-top: 61px;
            margin-bottom: 21px;
        }
        .image-wrapper {
            height: 120px
        }

        .pdf-symbol {
            margin-left: 0;
        }
        .mb-195{
            margin-bottom: 40px;
        }
    }

    @media screen and (max-width: 767px){
        .usefulToolsSlider {
            margin-bottom: 45px;
        }

        .mobile-right-content {
            padding: 0 20px;
        }
        .mb-195{
            margin-bottom: 25px;
        }
    }

</style>

<style>
    .media-title h1{
        line-height: 66px;
        text-transform: unset;
        margin-bottom: 70px;
    }
    @media screen and (max-width: 1599px){
        .media-title, .media-title h1 {
            margin-bottom: -20px;
        }
        .media-title.content-title h1, .media-title.content-title h1 span {
            text-transform: unset;
        }
    }

    @media screen and (max-width: 1365px) {
        .media-title h1{
            line-height: 41px;
        }
    }

    @media screen and (max-width: 767px) {
        .media-title h1{
            line-height: 25px;
        }
    }
</style>
