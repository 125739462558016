<template>
    <div>

        <div class="pdf-wrapper">
            <div class="pdf-symbol">
                <img src="../../assets/icons/phone-call.svg"  loading="lazy" alt="pdf">
                <div class="text">
                    <div class="pdf-text-title" v-if="data && data.title">{{data.title}}</div>
                    <div class="pdf-component-row" >
                        <div>{{_.get(this.data,'rowTitles[0]')}}:</div><span>{{_.get(this.data,'data[0][0]')}}</span>
                    </div>
                    <div class="pdf-component-row">
                        <div>{{_.get(this.data,'rowTitles[1]')}}:</div><a  :href="'tel:'+ _.get(this.data,'data[1][0]')">{{_.get(this.data,'data[1][0]')}}</a>
                    </div>
                </div>

            </div>
        </div>
        <div>
            <div :style="[openForm ? {'z-index': '99'} : {'z-index': '2'}]" style="position: relative" ref="bookCard">
                <div  class="book-cal pdf-symbol" :class="{'open': openForm && !book}"  @click="openFormClick">
                    <img src="../../assets/img/thank-you.svg"  loading="lazy" alt="pdf" v-if="book">
                    <div class="book-cal-title" v-if="!book">
                        {{$fn.tr(title)}}
                    </div>
                    <div class="text" v-if="book" style="width: 68%;">
                        <div class="pdf-text-title thank-you-text">{{$fn.tr('Thank You')}},</div>
                        <div class="pdf-component-row thank-you-text"> {{$fn.tr('We received your request on position')}}</div>
                    </div>
                    <div class="book-cal-dropdown center-flex" v-if="!book">
                        <svg class="book-cal-dropdown-icon" id="Group_820" data-name="Group 820" xmlns="http://www.w3.org/2000/svg" width="11.144" height="20" viewBox="0 0 11.144 20">
                            <path id="Path_331" data-name="Path 331" d="M10,0a1.144,1.144,0,0,1,.809,1.953L2.762,10l8.047,8.047a1.144,1.144,0,0,1-1.618,1.618L.335,10.809a1.144,1.144,0,0,1,0-1.618L9.191.335A1.14,1.14,0,0,1,10,0Z" fill="#435059"/>
                        </svg>
                    </div>
                </div>
                <div class="form-wrapper" :class="{'open': openForm}" v-closable="{ exclude: ['bookCard'], handler: 'handlerClose' }">
                    <form @submit.prevent="bookForm">
                        <div class="form-input-item" :class="{ 'input-error': (!$v.firstName.required || !$v.firstName.minLength) && isClicked }">
                            <div class="label">{{$fn.tr('First Name')}}<span>*</span></div>
                            <input
                                v-model="$v.firstName.$model"
                                type="text">
                        </div>
                        <div class="form-input-item" :class="{ 'input-error': (!$v.lastName.required || !$v.lastName.minLength) && isClicked }">
                            <div class="label">{{$fn.tr('Last Name')}}<span>*</span></div>
                            <input
                                v-model="$v.lastName.$model"
                                type="text">
                        </div>
                        <div class="form-input-item"  :class="{ 'input-error': (!$v.phone.required || !$v.phone.minLength) && isClicked }">
                            <div class="label">{{$fn.tr('Phone Number')}}<span >*</span></div>
                            <input
                                v-model="$v.phone.$model"
                                type="number">
                        </div>
                        <div>
                            <div class="form-file-container" :class="{'attached': fileAttached}">
                                <div class="form-file-title">{{fileName}}</div>
                                <div class="form-file-button center-flex" @click="removeAttachedFile">
                                    <img loading="lazy"    src="../../assets/icons/cross2.svg" alt="cross">
                                </div>
                            </div>
                        </div>

                        <input class="pdf-input" type="file" ref="file" @change="getFileName" id="pdf-upload" name="file_attach" accept=".pdf">

                        <label class="attach" for="pdf-upload">
                            <div v-if="attachable" class="form-file-attach">
                                <div class="form-file-attach-button center-flex">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15.424" height="15.424" viewBox="0 0 15.424 15.424">
                                        <path id="Icon_metro-cancel" data-name="Icon metro-cancel" d="M22.815,9.64l-4.82,4.82-4.82-4.82-2.892,2.892,4.82,4.82-4.82,4.82,2.892,2.892,4.82-4.82,4.82,4.82,2.892-2.892-4.82-4.82,4.82-4.82Z" transform="translate(-10.283 -9.64)" fill="#ffffff"/>
                                    </svg>
                                </div>
                                <div class="form-file-attach-title">{{$fn.tr('Attach File')}}</div>
                            </div>
                        </label>



                        <div class="form-file-send" >
                            <div class="recaptcha"></div>
                            <button type="submit"  v-if="attachable"  class="green-button">
                                {{$fn.tr('SEND')}}
                            </button>
                            <button type="submit"  v-else class="green-button">
                                {{$fn.tr('BOOK')}}
                            </button>
                        </div>
                    </form>
                </div>


            </div>
            <div class="dark-background" @click="handlerClose" :class="{'open': openForm}"></div>
        </div>
    </div>


</template>

<script>
import { type } from 'os';
import { closable } from "@/directives/Custom"
import { validationMixin } from 'vuelidate'
import { required, minLength, email } from "vuelidate/lib/validators"
import _ from "lodash";

    export default {
        mixins: [validationMixin],
        directives: {
            closable
        },
        props: {
            icon: {
                type: String,
                default: ''
            },
            title: {
                type: String,
                default: 'Book Call'
            },
            email: {
                type: String,
                default: 'test@gmail.com'
            },
            attachable: {
                type: Boolean,
                default: false
            },
            cardInputs: {
                type: Array,
                default: () => [
                    {
                        name: "First Name",
                        type: "text",
                        vModel: '',
                        inputName: 'firstName',
                        required: true,
                    },
                    {
                        name: "Last Name",
                        type: "text",
                        inputName: 'firstName',
                        vModel: '',
                        required: true,
                    },
                    {
                        name: "Enter Mobile",
                        type: "phone",
                        inputName: 'phone',
                        vModel: '',
                        required: false,
                    },
                ],
            },
            cardContent: {
                type: Array,
                default: () => [
                    {
                        role:' PR & Marketing Head',
                        name:'George Smith'
                    },
                    {
                        role: 'Phone Number',
                        name: '595 242 096'
                    }
                ],
            },
            data: {
                type: [Array, Object],
            }
        },
        data() {
            return {
                openForm: false,
                fileAttached: false,
                book: false,
                isClicked: false,
                firstName: '',
                lastName: '',
                phone: '',
                file: '',
                fileName: '',
                formData: null,
            }
        },
        validations: {
            firstName: {
                required,
                minLength: minLength(3)

            },
            lastName: {
                required,
                minLength: minLength(3)
            },
            phone: {
                required,
                minLength: minLength(9),
            },
        },
        methods: {
            getFileName(){
                this.fileName = this.$refs.file.files[0].name
                this.fileAttached = true
            },
            removeAttachedFile(){
                document.querySelector('#pdf-upload').value = '';
                this.fileAttached = false
            },
            handlerClose() {
                this.openForm = false
                this.book = false;
            },
            bookForm() {
                this.isClicked = true;
                if(!this.$v.firstName.required || !this.$v.firstName.minLength ||
                    !this.$v.phone.required || !this.$v.phone.minLength ||
                    !this.$v.lastName.required || !this.$v.lastName.minLength ) {
                    return
                }

                this.file = this.$refs.file.files[0]
                this.formData = new FormData();
                this.formData.append('file', this.file);

                let firstTitle = this._.get(this.data,'rowTitles[0]');
                let secondTitle = this._.get(this.data,'rowTitles[1]');
                let additionalInformation = {}
                additionalInformation[firstTitle] = this._.get(this.data,'data[0][0]');
                additionalInformation[secondTitle] = this._.get(this.data,'data[1][0]');
                this.$store.dispatch('user/bookCall', {firstName: this.firstName, uploadedFile: this.formData, lastName: this.lastName, phone: this.phone, email: this.email, info: additionalInformation})
                    .then((res) => {
                        if (res.status === 200 ) {
                            this.book = true;
                            this.openForm = false;
                            this.removeAttachedFile()
                        }
                    })
                    .catch(err=> {
                        console.log(err)
                    })
            },
            openFormClick() {
                if (!this.book) {
                    this.openForm = !this.openForm
                }
            }
        },
        watch: {
            openForm(val) {
                if (val) {
                    document.querySelector('.header').style.zIndex = '1'
                }else {
                    document.querySelector('.header').style.zIndex = null;
                    this.isClicked = false;
                    this.firstName = '';
                    this.lastName = '';
                    this.phone = '';
                }
            },
            book(val) {
                if (val) {
                    setTimeout(() => {
                        this.book = false;
                        this.isClicked = false;
                        this.firstName = '';
                        this.lastName = '';
                        this.phone = '';
                    },7000)
                }
            }
        }
    }
</script>

<style scoped>

    /*    top-box*/
    .pdf-wrapper {
        padding: 30px;
        background-color: #F7F7F7;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .pdf-text-title {
        color: #81C7BD;
        font-size: 13px;
        font-family: var(--noto-bold);
        margin-bottom: 5px;
    }

    .pdf-symbol {
        display: flex;
    }

    .pdf-symbol .text, .pdf-symbol {
        margin-left: 20px;
    }

    .pdf-title {
        color: #435059;
        font-size: 13px;
        font-weight: bold;
        font-family: 'Noto Sans Georgian', sans-serif;
        margin-top: 10px;
    }
    .pdf-input{
        opacity: 0;
        height: 0;
        visibility: hidden;
        position: absolute;
        top: -9999999%;
        left: -9999999%;

    }
    /*    book cal*/
    .book-cal {
        background-color: #81C7BD;
        border-radius: 10px;
        margin-left: unset;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        /*padding: 31px 110px;*/
        padding: 31px 40px;
        margin-top: 15px;
        cursor: pointer;
        transition: 0.5s;
    }

    .book-cal.open {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    .book-cal.open .book-cal-dropdown svg{
        transform: rotate(90deg);
    }

    .book-cal-title {
        color: #FFFFFF;
        font-size: 21px;
        font-weight: bold;
        font-family: var(--noto-bold);
        text-transform: uppercase;
    }

    .book-cal-dropdown {
        background-color: #D9EEEB;
        width: 69px;
        height: 69px;
        border-radius: 10px;
        position: absolute;
        right: 10px;
    }

    .book-cal-dropdown-icon {
        transform: rotate(-90deg);
        transition: .2s ease;
    }

    .book-cal-dropdown-icon path{
        fill: var(--green);
    }

    .pdf-component-row {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        font-family: 'Noto Sans Georgian', sans-serif;
        margin-bottom: 10px;
        font-size: 13px;
        width: 110%;
    }

    .pdf-component-row:last-child span {
        margin-right: 18px;
    }

    .pdf-component-row span,  .pdf-component-row  a {
        color: #435059;
        font-family: 'Noto Sans Georgian', sans-serif;
        font-weight: bold;
        font-size: 13px;
    }

    .download-btn {
        background-color: #FEC55E;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 90px;
        height: 100%;
    }
    .label.error {
        font-size: 10px !important;
    }

    /*form*/

    .form-wrapper {
        background-color: #F7F7F7;
        width: 100%;
        max-height: 0;
        z-index: 2;
        position: absolute;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        overflow: hidden;
        /*transform: translateY(0px);*/
        transition: .5s ease .3s;


    }

    .form-wrapper.open {
        max-height: 587px;
        padding: 20px;
        /*transform: translateY(0%);*/
        /*transform: scaleY(1);*/
        transition: .5s ease .2s;
    }
    .attach {
        opacity: 0;
        visibility: hidden;
        transition: .2s ease;
    }
    .form-wrapper.open .form-input-item,  .form-wrapper.open .form-file-send, .form-wrapper.open .attach{
        opacity: 1;
        visibility: visible;
        transition: .2s ease;
    }


    .form-input-item {
        margin-bottom: 15px;
        opacity: 0;
        visibility: hidden;
        transition: .2s ease;
    }

    .form-input-item .border-error {
        border: 1px solid #FF7675;
    }

    .form-input-item .label {
        color: #435059;
        font-family: var(--noto-regular);
        font-size: var(--normalFontSize);
        padding: 10px 0;
        display: flex;
    }

    .form-input-item .label span{
        color: #FF7675;
        font-size: 12px;
        margin-left: 5px;
    }

    .form-input-item input {
        width: 100%;
        border:unset;
        border-radius: 10px;
        padding: 10px;
        background: #ffffff;
    }

    .form-file-container {
        padding: 0;
        border-bottom: 1px solid #E5E6E7;
        display: flex;
        align-items: center;
        justify-content: space-between;
        opacity: 0;
        max-height: 0;
        transition: 0.2s ease-out;
    }

    .form-file-container.attached {
        opacity: 1;
        max-height: 200px;
        padding: 20px 0;
        transition: 0.2s ease-out;
    }

    .form-file-title {
        color: #435059;
        font-family: var(--noto-bold);
        font-size: 15px;
    }

    .form-file-button {
        width: 44px;
        height: 44px;
        background-color: var(--light-green);
        border-radius: 10px;
        cursor: pointer;
    }
    .thank-you-text {
        color: #ffffff;
    }
    .pdf-symbol .text {
        margin-left: 32px;
    }

    .form-file-attach {
        display: flex;
        align-items: center;
        margin: 20px 0;
    }

    .form-file-attach-button {
        width: 44px;
        height: 44px;
        background-color: #FEC55E;
        border-radius: 10px;
        cursor: pointer;
    }

    .form-file-attach-button svg {
        transform: rotate(45deg);
    }

    .form-file-attach-title {
        color: #435059;
        font-family: var(--noto-bold);
        font-size: 21px;
        margin-left: 20px;
    }

    .form-file-send {
        display: flex;
        justify-content: space-between;
        opacity: 0;
        visibility: hidden;
        transition: .2s ease;
    }

    .recaptcha {
        width: 45%;
        background-color: #F5F5F5;
        height: 40px;
    }

    .green-button {
        width: 50%;
        padding: 20px;
    }

    .dark-background.open {
        position: fixed;
        width: 100vw;
        height: 100vh;
        left: 0;
        top: 0;
        background-color: #435059;
        opacity: .3;
        z-index: 9;

    }


    .fade-out-leave {
        opacity: 1;
    }

    .fade-out-leave-active {
        opacity: 0;
        transition: opacity 0.5s ease-out;
    }
    .book-cal.pdf-symbol {
        padding-right: 100px;
    }
    @media screen and (max-width: 1919px) {
        .pdf-symbol .text {
            margin-left: 47px;
            margin-top: 10px;
        }
    }
    @media screen and (max-width: 1599px){
        .pdf-symbol .text {
            margin-top: 5px;
            margin-left: 40px;
        }
        .pdf-symbol:not(.book-cal) {
            margin-left: 5px;
        }

        .book-cal {
            margin-top: 7px;
        }
    }
    @media screen and (max-width: 1365px) {
        /*.pdf-symbol {*/
        /*    margin-left: 40px;*/
        /*}*/
    }

    @media screen and (max-width: 1023px){

        .book-cal {
           padding: 30px
        }

        .pdf-symbol {
            width: 100%;
            justify-content: space-evenly;
        }

        .pdf-symbol .text {
            margin-right: 20%;
        }
        .pdf-symbol.book-cal,  .pdf-symbol.book-cal .text {
            margin-left: 0;
            padding-right: unset;
        }
        .book-cal.pdf-symbol .text{
            width: unset !important;
        }

    }

    @media screen and (max-width: 625px){
        .pdf-symbol {
            margin-left: unset;
        }
        .pdf-symbol img {
            display: none;
        }

        .pdf-text-title {
            margin-bottom: 10px
        }

        .pdf-component-row {
            width: unset;
        }

        .pdf-symbol .text {
            margin-left: 0;
            margin-right: 0;
            width: 80%;
        }
    }

    @media screen and (max-width: 425px){
        .book-cal-title {
            position: absolute;
            left: 10%;
        }

        .book-cal-dropdown {
            background-color: #D9EEEB;
            width: 49px;
            height: 49px;
        }

        .pdf-wrapper {
            padding: 30px 0;
        }
    }

    .input-error .label {
        color: #FF7675;
    }
    .input-error input {
        border: 1px solid #FF7675;
    }

</style>
