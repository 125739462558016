import Vue from 'vue'
import VueRouter from 'vue-router'
import VueBodyClass from 'vue-body-class';

import Home from '../views/Home'

import store from '../store'

import MediaAndMarketing2 from "../views/MediaAndMarketingInner";
import MediaAndMarketing from "../views/MediaAndMarketing";
import Contact from "../views/Contact";
import Registration from "../views/Registration";

import B2B from "../views/B2B";

import Vacancy from "../views/VacancyInner"
import VacancyList from "../views/VacancyList";

import Cafe from "../views/Cafe"

import About from "../views/AboutMysa"
import UserRoom from "../views/UserRoom";
import ChooseCategory from "../views/ChooseCategory"
import Products from "../views/Products"
import ProductsInner from "../views/ProductsInner"
import CartCheckout from "../views/CartCheckout";
import Sales from "../views/SalesOffers"

import Wishlist from "../views/Wishlist"
import CartList from "../views/Cart"
import Brand from "../views/Brands"
import News from "../views/News"
import NewsInner from "../views/NewsInner";
import NewPassword from "../views/NewPassword";
import Print from "../views/Print";

import Terms from "../views/Terms"
import Privacy from "../views/Privacy"
import UploadInvoice from "../views/UploadInvoice"
import PaymentSuccess from "../views/PaymentSuccess";
import PaymentFail from "../views/PaymentFail";

import PageViewRouter from "../views/view-router/page"
// import DefaultPage from "../views/view-router/DefaultPage"


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        alias: '/site',
        name: 'home',
        meta: {bodyClass: 'page'},
        component: Home,
        props: true
    },
    {
        path: '/en/checkout',
        name: 'en',
        meta: {bodyClass: 'page',url: 'checkout',lang: 'en'},
        component: CartCheckout,
        props: true
    },
    {
        path: '/en/print',
        name: 'en',
        meta: {bodyClass: 'page',url: 'print',lang: 'en'},
        component: Print,
        props: true
    },
    {
        path: '/ge/print',
        name: 'ge',
        meta: {bodyClass: 'page',url: 'print',lang: 'ge'},
        component: Print,
        props: true
    },
    {
        path: '/ge/checkout',
        name: 'ge',
        meta: {bodyClass: 'page', url: 'checkout',lang: 'ge'},
        component: CartCheckout,
        props: true
    },
    {
        path: '/news',
        name: 'news',
        meta: {bodyClass: 'page'},
        component: News,
        props: true
    },
    {
        path: '/news/:slug',
        name: 'news detail',
        meta: {bodyClass: 'page'},
        component: NewsInner,
        props: true
    },

    {
        path: '/products',
        name: 'products',
        meta: {bodyClass: 'page'},
        component: Products,
        props: true
    },
    {
        path: '/ge/cartlist',
        name: 'ge',
        meta: {bodyClass: 'page',url: 'cartlist',lang: 'ge'},
        component: CartList,
        props: true
    },
    {
        path: '/en/cartlist',
        name: 'en',
        meta: {bodyClass: 'page',url: 'cartlist',lang: 'en'},
        component: CartList,
        props: true
    },
    {
        path: '/sales',
        name: 'sales offers',
        meta: {bodyClass: 'page'},
        component: Sales,
        props: true
    },
    {
        path: '/brands',
        name: 'brands',
        meta: {bodyClass: 'page'},
        component: Brand,
        props: true
    },
    {
        path: '/terms',
        name: 'terms',
        meta: {bodyClass: 'page'},
        component: Terms,
        props: true
    },
    {
        path: '/privacy',
        name: 'privacy',
        meta: {bodyClass: 'page'},
        component: Privacy,
        props: true
    },
    {
        path: '/category',
        name: 'category',
        meta: {bodyClass: 'page'},
        component: ChooseCategory,
        props: true
    },
    {
        path: '/en/wishlist',
        name: 'en',
        meta: {bodyClass: 'page',url: 'wishlist',lang: 'en'},
        component: Wishlist,
        props: true
    },
    {
        path: '/ge/wishlist',
        name: 'ge',
        meta: {bodyClass: 'page', url: 'wishlist',lang: 'ge'},
        component: Wishlist,
        props: true
    },
    {
        path: '/products/:slug',
        name: 'products detail',
        meta: {bodyClass: 'page'},
        component: ProductsInner,
        props: true
    },
    {
        path: '/contact',
        name: 'contact',
        meta: {bodyClass: 'page'},
        component: Contact,
        props: true
    },
    {
        path: '/ge/registration',
        name: 'ge',
        meta: {bodyClass: 'page', url: 'registration',lang: 'ge'},
        component: Registration,
        props: true
    },
    {
        path: '/en/registration',
        name: 'en',
        meta: {bodyClass: 'page', url: 'registration',lang: 'en'},
        component: Registration,
        props: true
    },
    {
        path: '/mysa-b2b',
        name: 'mysa-b2b',
        meta: {bodyClass: 'page'},
        component: B2B,
        props: true
    },
    {
        path: '/about-mysa',
        name: 'ge',
        meta: {bodyClass: 'page'},
        component: About,
        props: true
    },
    {
        path: '/ge/upload/order/:id/:token/',
        name: 'ge',
        component: UploadInvoice,
        props: true
    },
    {
        path: '/en/upload/order/:id/:token/',
        name: 'en',
        component: UploadInvoice,
        props: true
    },
    {
        path: '/ge/paymentsuccess',
        name: 'ge',
        meta: {bodyClass: 'page', url: 'paymentsuccess',lang: 'ge'},
        component: PaymentSuccess,
        props: true
    },
    {
        path: '/en/paymentsuccess',
        name: 'en',
        meta: {bodyClass: 'page', url: 'paymentsuccess',lang: 'en'},
        component: PaymentSuccess,
        props: true
    },
    {
        path: '/ge/paymentfail',
        name: 'ge',
        meta: {bodyClass: 'page', url: 'paymentfail',lang: 'ge'},
        component: PaymentFail,
        props: true
    },
    {
        path: '/en/paymentfail',
        name: 'en',
        meta: {bodyClass: 'page', url: 'paymentfail',lang: 'en'},
        component: PaymentFail,
        props: true
    },
    {
        path: '/vacancy',
        name: 'vacancy',
        meta: {bodyClass: 'page'},
        component: VacancyList,
        props: true
    },
    {
        path: '/vacancy/:slug',
        name: 'vacancy detail',
        meta: {bodyClass: 'page'},
        component: Vacancy,
        props: true
    },
    {
        path: '/mysa-cafe',
        name: 'mysa-cafe',
        meta: {bodyClass: 'page'},
        component: Cafe,
        props: true
    },
    {
        path: '/media-and-marketing',
        name: 'media-and-marketing',
        meta: {bodyClass: 'page'},
        component: MediaAndMarketing2,
        props: true
    },
    {
        path: '/media-and-marketing-apply',
        name: 'media-and-marketing-apply',
        meta: {bodyClass: 'page'},
        component: MediaAndMarketing,
        props: true
    },
    {
        path: '/ge/user-room/:id?',
        name: 'ge',
        meta: {bodyClass: 'page', url: 'user-room/1', lang: 'ge'},
        component: UserRoom,
        props: true
    },
    {
        path: '/en/user-room/:id?',
        name: 'en',
        meta: {bodyClass: 'page', url: 'user-room/1', lang: 'en'},
        component: UserRoom,
        props: true
    },
    {
        path: '/newpassword/:token?',
        name: 'ge',
        meta: {bodyClass: 'page'},
        component: NewPassword,
        props: true
    },
    {
        path: '/en/:path1?/:path2?/:path3?/:path4?/:path5?/:path6?/:path7?/:path8?/:path9?/',
        name: 'en',
        meta: {bodyClass: 'page'},
        component: PageViewRouter,
        props: true
    },
    {
        path: '/ge/:path1?/:path2?/:path3?/:path4?/:path5?/:path6?/:path7?/:path8?/:path9?/',
        name: 'ge',
        meta: {bodyClass: 'page'},
        component: PageViewRouter,
        props: true
    },


]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    store,
    scrollBehavior(to, from, savedPosition) {
        setTimeout(() => {
            header ? header.classList.remove('transform-top-class') : ''
        }, 600)

        let header = document.querySelector('#scrollHeader');
        header ? header.classList.add('transform-top-class') : ''
        // store.commit('setState', {key: 'offset', val: 0});
        if (to.hash) {
            return {
                selector: to.hash,
                offset: {x: 0, y: 0}

            }
        } else {
            return {x: 0, y: 0}
        }
    },
});




export default router
