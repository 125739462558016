<template>
  <div style="height: 100%">
    <div class="product-card">
      <div class="product-margin">
        <div class="product-card-img">
          <div class="label-position" v-if="labelData">
            <template v-for="(item, index) in labelData.slice(0, 1)">
              <div
                class="label-info bold font-13 last-call"
                :key="index"
                :style="{ background: item.color }"
              >
                {{ item.title }}
              </div>
            </template>
          </div>

          <router-link
            tag="picture"
            :to="
              `/${$store.state.main.indx.locale}/products/productview/${sliderData.id}-${sliderData.slug}`
            "
          >
            <source
              class="img"
              loading="lazy"
              media="(max-width: 767px)"
              :srcset="`${_.get(sliderData, 'coverimage[0].devices.mobile')}`"
              alt="main-image"
            />
            <source
              class="img"
              loading="lazy"
              media="(max-width: 1023px)"
              :srcset="`${_.get(sliderData, 'coverimage[0].devices.tablet')}`"
              alt="main-image"
            />
            <img
              class="img"
              loading="lazy"
              :src="`${_.get(sliderData, 'coverimage[0].devices.desktop')}`"
              alt="main-image"
            />
            <img
              @click="
                $router.push(
                  `/${_.get($store.state.main.indx, 'locale')}/products/productview/${_.get(
                    sliderData,
                    'id'
                  )}-${_.get(sliderData, 'slug')}`
                )
              "
              class="hover-img"
              loading="lazy"
              :src="
                `${
                  _.get(sliderData, 'coverimage[1].devices.desktop')
                    ? _.get(sliderData, 'coverimage[1].devices.desktop')
                    : _.get(sliderData, 'coverimage[0].devices.desktop')
                }`
              "
              alt="main-image"
            />
          </router-link>
          <div v-if="!active" @click="addToWishList(sliderData)">
            <svg
              style="cursor: pointer"
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 44 44"
            >
              <g id="Group_3812" data-name="Group 3812" transform="translate(-378 -1115)">
                <circle
                  id="Ellipse_3"
                  data-name="Ellipse 3"
                  cx="22"
                  cy="22"
                  r="22"
                  transform="translate(378 1115)"
                  fill="#ffeece"
                />
                <path
                  id="Icon_feather-heart"
                  data-name="Icon feather-heart"
                  d="M17.989,5.806a4.467,4.467,0,0,0-6.318,0l-.861.861L9.95,5.806a4.468,4.468,0,1,0-6.318,6.318l.861.861L10.81,19.3l6.318-6.318.861-.861a4.467,4.467,0,0,0,0-6.318Z"
                  transform="translate(389.549 1125.348)"
                  fill="none"
                  stroke="#fec55e"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
              </g>
            </svg>
          </div>

          <div v-if="active" @click="removeWishElement(sliderData)">
            <svg
              style="cursor: pointer"
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 30 30"
            >
              <g id="Group_14" data-name="Group 14" transform="translate(-378 -1115)">
                <circle
                  id="Ellipse_3"
                  data-name="Ellipse 3"
                  cx="15"
                  cy="15"
                  r="15"
                  transform="translate(378 1115)"
                  fill="#fec55e"
                />
                <path
                  id="Icon_feather-heart"
                  data-name="Icon feather-heart"
                  d="M13,5.39a3.045,3.045,0,0,0-4.308,0l-.587.587L7.523,5.39A3.046,3.046,0,1,0,3.215,9.7l.587.587L8.11,14.592l4.308-4.308L13,9.7A3.045,3.045,0,0,0,13,5.39Z"
                  transform="translate(385.135 1120.625)"
                  fill="none"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
              </g>
            </svg>
          </div>

          <div
            class="quick-view font-13 bold"
            @click="$emit('openmodal', true, sliderData, active)"
          >
            {{ $fn.tr('Quick view') }}
          </div>
        </div>
        <router-link
          :to="
            `/${$store.state.main.indx.locale}/products/productview/${sliderData.id}-${sliderData.slug}`
          "
        >
          <!-- :to="$store.getters['main/getSingleUrl'](sliderData, 1)" -->
          <h2 class="product-descr">{{ sliderData.title }}</h2>
        </router-link>
      </div>

      <div class="flex align-center price-section">
        <div
          class="product-price bold"
          v-if="sliderData.stock_price && sliderData.stock_quantity > 1"
        >
          <div v-if="sliderData.oldprice" class="sale bold font-16">
            <span></span> {{ formatPrice(sliderData.oldprice) }} ₾
          </div>
          {{ formatPrice(sliderData.stock_price) }} ₾
        </div>
        <div class="out-of-stock bold font-18" v-else>
          {{ $fn.tr('Out of Stock') }}
        </div>
        <div
          class="add-to-cart"
          @click="addToCart(sliderData, 1)"
          v-if="sliderData.stock_price && sliderData.stock_quantity"
        >
          <span class="font-13 bold">
            {{ $fn.tr('Add to cart') }}
          </span>
          <div class="svg">
            <svg xmlns="http://www.w3.org/2000/svg" width="15.246" height="14.005">
              <g data-name="Group 3636">
                <g data-name="Group 282">
                  <path
                    data-name="Path 213"
                    d="M5.94 11.022a.908.908 0 0 1-.757-.689L2.718 1.588H.789a.793.793 0 0 1 0-1.587h2.523a.913.913 0 0 1 .758.694l2.426 8.552 6.725-1.407.342-2.6-6.5-.271a.786.786 0 0 1-.754-.818.8.8 0 0 1 .822-.752l7.36.316a.79.79 0 0 1 .748.891l-.526 4.022a.788.788 0 0 1-.617.669l-7.991 1.709a.771.771 0 0 1-.165.016z"
                    fill="#81c7bd"
                  />
                </g>
                <g data-name="Group 283">
                  <path
                    data-name="Path 214"
                    d="M5.934 14.005a1.163 1.163 0 1 1 1.163-1.163 1.165 1.165 0 0 1-1.163 1.163z"
                    fill="#81c7bd"
                  />
                </g>
                <g data-name="Group 284">
                  <path
                    data-name="Path 215"
                    d="M11.632 14.005a1.163 1.163 0 1 1 1.163-1.163 1.165 1.165 0 0 1-1.163 1.163z"
                    fill="#81c7bd"
                  />
                </g>
              </g>
            </svg>
          </div>
        </div>
      </div>
      <div
        class="out-of-stock bold font-18"
        v-if="
          $store.state.shop.showCartError.value &&
            $store.state.shop.showCartError.id == sliderData.id
        "
      >
        {{ $fn.tr('Out of Stock') }}
      </div>
    </div>
    <Notification :active="notification" @hide="notification = false">
      {{ $fn.tr('Added Successfully!') }}
    </Notification>
  </div>
</template>

<script>
import incriment from '../components/Incriment';
import addToCartMixin from '../mixins/addToCartMixin';
import Notification from '../components/Notification';
import commaNum from '@/helpers/commaNum';
import { mapGetters } from 'vuex';

export default {
  props: ['sliderData', 'link'],

  components: {
    incriment,
    Notification,
  },
  mixins: [addToCartMixin],
  watch: {
    getWishListItems: {
      immediate: true,
      handler(val) {
        if (val && val.data) {
          setTimeout(() => {
            val.data?.list?.findIndex((res) => res.id === this.sliderData.id) !== -1
              ? (this.active = true)
              : (this.active = false);
          }, 250);
        } else {
          this.active = false;
        }
      },
    },
  },

  data() {
    return {
      sale: false,
      alreadyInCart: false,
    };
  },
  computed: {
    ...mapGetters({
      getCartItems: 'shop/getCartItems',
    }),
    labelData() {
      let labels = _.get(this.$store.state.main.indx, 'attributes.label');
      let labelID = _.get(this.sliderData, 'attributes.label');

      let label = [];

      if (labelID) {
        for (let i of labelID) {
          let list = labels.find((item) => item.id === i);
          label.push(list);
        }
      }

      return label;
    },
    // showAddToCart() {
    //     const cartItems = _.get(this.getCartItems, "data.list");
    //     const currentProduct = cartItems?.find(
    //         product => product.id == this.sliderData?.id
    //     );
    //     if (
    //         currentProduct?.quantity &&
    //         currentProduct?.quantity >= this.sliderData.stock_quantity - 1
    //     ) {
    //         return false;
    //     }
    //     return (
    //         this.sliderData?.stock_price &&
    //         this.sliderData?.stock_quantity > 1
    //     );
    // }
  },
  methods: {
    formatPrice(price) {
      return price ? commaNum(Number(price)) : 0;
    },
  },
};
</script>

<style scoped>
.flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.product-margin {
  margin-bottom: 10px;
}

.product-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.hover-img {
  cursor: pointer;
  opacity: 0;
}

.product-card-img {
  position: relative;
  padding-top: 100%;
  border-radius: 30px;
  margin-bottom: 10px;
  overflow: hidden;
}
.product-card-img .label-position {
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  flex-direction: column;
}
.product-card-img .label-info {
  font-size: 13px;
  border-radius: 10px;
  padding: 5px 15px;
  margin-bottom: 10px;
  color: #fff;
  z-index: 1;
  text-transform: uppercase;
}

.product-card-img:hover .quick-view {
  opacity: 1;
  transition: 0.7s;
}

/* .price-section {
        flex-direction: column;
        align-items: flex-start;
    } */

.quick-view {
  opacity: 0;
  cursor: pointer;
  transition: 0.7s;
  position: absolute;
  bottom: 20px;
  right: 20px;
  background: #435059;
  color: #fff;
  border-radius: 10px;
  padding: 14px;
  text-transform: uppercase;
  z-index: 1;
}

.product-card-img .label-info.sale {
  background: #81c7bd;
}

.product-card-img .label-info.last-call {
  background: #ff7675;
}

.product-card-img svg {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}
.out-of-stock {
  padding: 0;
  padding-top: 10px;
}
@media (min-width: 1025px) {
  .product-card-img:hover .img {
    transition: 1s;
    opacity: 0;
    border-radius: 30px;
    overflow: hidden;
  }
  .product-card-img:hover .hover-img {
    opacity: 1;
  }
}

.product-card-img img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  backface-visibility: hidden;
  transition: 1s;
  border-radius: 30px;
  overflow: hidden;
}

.product-descr {
  font-size: 16px;
  color: #435059;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: 45px;
}

.product-price {
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
}
/*padding-right: 15px;*/
/*border-right: 1px solid rgba(68, 81, 90, 0.2);*/

.product-price .sale {
  position: relative;
  margin-right: 7px;
}

.product-price .sale span {
  position: absolute;
  transform: rotate(-17deg);
  top: 48%;
  left: 0;
  width: 100%;
  height: 2px;
  background: #ff7675;
}

.add-to-cart {
  margin-left: 15px;
  padding-left: 15px;
  border-left: 1px solid #d9eeeb;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
}

.add-to-cart span {
  color: #81c7bd;
}
.add-to-cart .svg {
  width: 39px;
  height: 39px;
  display: flex;
  min-width: 39px;
  min-height: 39px;
  align-items: center;
  justify-content: center;
  border: 2px solid #d9eeeb;
  border-radius: 50%;
  transition: 0.3s;
  margin-left: 10px;
}

@media (min-width: 1366px) {
  .add-to-cart:hover svg path,
  .add-to-cart.active svg path {
    fill: #fff;
    transition: 0.3s;
  }
  .add-to-cart:hover .svg,
  .add-to-cart.active .svg {
    background: #81c7bd;
    border: 2px solid #81c7bd;
    transition: 0.3s;
  }
}

@media (max-width: 1919px) {
  .product-card-img {
    padding-top: 99%;
    width: 99%;
  }
  .product-price {
    flex-direction: column-reverse;
  }
}

@media (max-width: 1365px) {
  .product-card-img {
    padding-top: 99%;
    width: 98.5%;
  }
  .add-to-cart span {
    display: none;
  }
  .product-price {
    flex-direction: row;
  }
  /* .product-price {
            padding-right: 15px;
        } */

  .add-to-cart {
    margin-left: 15px;
  }
}
@media (max-width: 320px) {
  .product-price {
    font-size: 22px;
  }
}

@media (max-width: 1023px) {
  .product-card-img img:hover {
    transition: none;
    transform: scale(1);
  }

  .product-card-img {
    transition: none;
    padding-top: 95%;
    width: 95%;
  }

  .product-card-img img {
    transition: none;
  }

  /* .product-price {
            padding-right: 30px;
        } */
  .add-to-cart span {
    display: none;
  }
}

@media (max-width: 767px) {
  .hover-img {
    display: none;
  }
  .product-card-img:hover .img {
    transition: 0.7s;
    opacity: 1;
    border-radius: 30px;
    overflow: visible;
  }
  .product-cardproduct-card-img {
    padding-top: 87%;
    width: 100%;
  }
  .product-card-img .label-position {
    top: 10px;
    left: 10px;
  }
  .product-card-img svg {
    top: 10px;
    right: 10px;
  }
  .add-to-cart .svg {
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
  }

  .product-price {
    font-size: 21px !important;
    padding-right: 17px;
  }

  .product-descr {
    font-size: 13px;
  }

  .out-of-stock {
    padding-right: 7px;
  }

  .add-to-cart {
    margin-left: 7px;
  }

  .swiper-slide {
    height: 260px !important;
  }
}
</style>
