import _ from 'lodash';

export default {
    getAuthData(state) {
        return state.authData;
    },
    getOrderHistory(state) {
        return state.orderHistory;
    },
    getAuthDataAddresses(state) {
        return state.authDataAddresses;
    }
}
