import Vue from 'vue';
import fn from '../../helpers/func';
import _ from 'lodash';
import axios from 'axios';

export default {
	registration: async function(context, data) {
		let tmp = await fn.postData('user/Registration', data);
		return tmp;
	},
	authorization: async function({ state }, data) {
		let tmp = await fn.postData('user/login', data);
		// if (tmp.status != 200) return false;
		return tmp;
	},

	verifyUser: async function({ state }, { token }) {
		let tmp = await fn.postData(`user/verify/${token}`);
		if (tmp.status != 200) return false;
		Vue.set(state, 'authData', tmp.data.user);
		return tmp;
	},
	getUserData: async function({ state }, { token }) {
		axios.defaults.headers.common['authorization'] = token;
		let tmp = await fn.postData(`user/GetUserData`, '');
		if (tmp.status != 200) return false;
		Vue.set(state, 'authData', tmp.data.user);
		Vue.set(state, 'authDataAddresses', tmp.data.address);
		Vue.set(state, 'orderHistory', tmp.data.order);
		return tmp;
	},
	changePersonalData: async function({ state }, { token, data, isPersoanlInfo }) {
		axios.defaults.headers.common['authorization'] = token;
		let tmp = await fn.postData(`user/ChangePersonalData`, data);
		if (tmp.status != 200) return false;
		Vue.set(state, 'authData', tmp.data.user);
		if (tmp.data.address && !Array.isArray(tmp.data.address) && isPersoanlInfo) {
			let list = [];
			list.push(tmp.data.address);
			Vue.set(state, 'authDataAddresses', list);
		}

		return tmp;
	},
	changePassword: async function({ state }, { token, data }) {
		axios.defaults.headers.common['authorization'] = token;
		let tmp = await fn.postData(`user/ChangePassword`, data);
		return tmp;
	},
	forgotPassword: async function({ state }, data) {
		let tmp = await fn.postData(`user/passwordRecover`, data);
		return tmp;
	},
	addAddress: async function({ state }, { token, data }) {
		axios.defaults.headers.common['authorization'] = token;
		let tmp = await fn.postData(`user/AddAddress`, data);
		if (tmp.status != 200) return false;
		Vue.set(state, 'authDataAddresses', tmp.data);
		return tmp;
	},
	EditAddress: async function({ state }, { token, data }) {
		axios.defaults.headers.common['authorization'] = token;
		let tmp = await fn.postData(`user/EditAddress`, data);
		if (tmp.status != 200) return false;
		Vue.set(state, 'authDataAddresses', tmp.data.address);
		return tmp;
	},

	bookCall: async function(context, data) {
		axios.defaults.headers['content-Type'] = 'multipart/form-data';
		let tmp = await fn.postData('main/bookcall', data);
		if (tmp.status != 200) return false;
		return tmp;
	},
	contactForm: async function(context, data) {
		let tmp = await fn.postData('main/contactform', data);
		if (tmp.status != 200) return false;
		return tmp;
	},
	subscribeEmail: async function(context, data) {
		let tmp = await fn.postData('main/subscribe', data);
		if (tmp.status != 200) return false;
		return tmp;
	},
	checkUser: async function(context, data) {
		let tmp = await fn.postData('user/checkregisteruser', data);
		return tmp;
	},
};
