<template>
    <section>
        <div class="container-wrapper">
            <content-title data-aos="fade-up" data-aos-delay="50" class="heading-margin" :colored-title="true" :title="$store.state.main.selected_menu.title" />

            <div class="about-text remove-styles13"
                 data-aos="fade-up" data-aos-delay="400"
                 v-html="_.get(this.$store.state.main.current_content, 'primary.data.list[0].content.tabs[0].content')">
            </div>
        </div>

        <SlicedSlider
            v-if="_.get(this.$store.state.main.current_content, 'secondary.AboutBannerSlider.data.list')"
            data-aos="fade-up" data-aos-delay="800"
            :sliderData="_.get(this.$store.state.main.current_content, 'secondary.AboutBannerSlider.data.list')"
        />

        <div class="container-wrapper" v-if="_.get(this.$store.state.main.current_content, 'secondary.ShopInfrastructure.data.list')">
            <div class="infrastructure-content">

                <page-title data-aos="fade-up" data-aos-delay="100" class="light margin-heading"
                    :colored-title="true"
                    :title="_.get(this.$store.state.main.current_content, 'secondary.ShopInfrastructure.conf.header.title')"
                />

                <div class="infrastructure-list">
                    <swiper ref="mySwiper" :options="listOptions" class="w-100">
                        <swiper-slide class="single-structure"
                            v-for="(item, index)
                            in sortInfrastructure"
                            :key="index" >

                            <div data-aos="fade-up" :data-aos-delay="item.id * 200">
                                <div class="img-align">
                                    <picture>
                                        <source
                                            media="(max-width: 767px)"
                                            loading="lazy"
                                            :srcset="(`${_.get(item,'image[0].devices.mobile')}`)"
                                        />
                                        <source
                                            media="(max-width: 1023px)"
                                            loading="lazy"
                                            :srcset="(`${_.get(item,'image[0].devices.tablet')}`)"
                                        />
                                        <img

                                            :src="(`${_.get(item,'image[0].devices.desktop')}`)"
                                            loading="lazy"
                                            alt="image"
                                        />
                                    </picture>
                                </div>
                                <p class="font-21">{{ item.title }}</p>
                            </div>
                        </swiper-slide>
                    </swiper>
                </div>

            </div>
        </div>

        <ADSection
            class="mt-144 mt-223" v-if="_.get(this.$store.state.main.current_content, 'secondary.MysaCardBanner.data.list[0]')"
            :ADdata="_.get(this.$store.state.main.current_content, 'secondary.MysaCardBanner.data.list[0]')"
        />

        <div class="container-wrapper our-brands mb-144" v-if="_.get($store.state.main.indx, 'attributes.brands')">
            <div class="relative border-bottom">
                <page-title
                    class="brand-margin"
                    data-aos="fade-up" data-aos-delay="100"
                    :colored-title="true"
                    :title="_.get(this.$store.state.main.current_content, 'secondary.OurBrands.conf.header.title')"
                />

                <div class="swiper-button-next"></div>
                <div class="swiper-button-prev"></div>
            </div>

            <div class="partners">
                <swiper ref="mySwiper" :options="swiperOptions">
                    <swiper-slide v-for="(item, index) in _.get($store.state.main.current_content, 'secondary.OurBrands.data.list')" :key="index">
                        <div @click="passData(item)" class="partners-swiper" data-aos="fade-up" data-aos-delay="200">
                            <picture>
                                <source
                                    media="(max-width: 767px)"
                                    :srcset="(`${ _.get(item,'image[0].devices.mobile')}`)"
                                    loading="lazy"
                                />
                                <source
                                    media="(max-width: 1023px)"
                                    :srcset="(`${ _.get(item,'image[0].devices.tablet')}`)"
                                    loading="lazy"
                                />
                                <img
                                    :src="(`${  _.get(item,'image[0].devices.desktop')}`)"
                                    loading="lazy"
                                    alt="image"
                                />
                            </picture>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
        </div>
        <BrandModal @closeModal="modalActive= false" :modalData="customData" :modalActive="modalActive" />
        <CartInfo />

    </section>
</template>

<script>
import contentTitle from "../components/ContentTitle"
import pageTitle from "../components/PageTitle"
import SlicedSlider from "../components/SlicedSlider"
import CartInfo from "../components/CartInfo"
import ADSection from "../components/ADSection"
import BrandModal from "../components/AboutBrandModal"

export default {
    components: {
        contentTitle,
        SlicedSlider,
        CartInfo,
        ADSection,
        pageTitle,
        BrandModal
    },
    data(){
        return {
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            listOptions: {
                slidesPerView: 4,
                watchOverflow: true,
                autoHeight: true,
                breakpoints: {
    				320: {
	            		slidesPerView: 1.99
                    },
                    550: {
	            		slidesPerView: 1.95
                    },
                    768: {
			            slidesPerView: 3.99
		          	},
    				900: {
			            slidesPerView: 3.95
		          	},
    			}
            },
            modalActive: false,
            swiperOptions: {
    			speed: 400,
                watchOverflow: true,
                autoHeight: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
    			breakpoints: {
    				320: {
    					spaceBetween: 15,
	            		slidesPerView: 1
    				},
    				768: {
			            spaceBetween: 30,
			            slidesPerView: 4
		          	},
                    1365: {
                        spaceBetween: 83,
			            slidesPerView: 4
                    },
                    1920: {
                        spaceBetween: 135,
			            slidesPerView: 4
                    }
    			}
            },
            customData: null
        }
    },
    computed: {
        sortInfrastructure(){
            const infrastructureSort = _.get(this.$store.state.main.current_content, 'secondary.ShopInfrastructure.data.list')

            return infrastructureSort.sort((a, b) => a.sort - b.sort)
        }
    },
    methods: {
        passData(item){
            this.customData = item
            this.modalActive = true
        }
    },

    mounted() {
        this.$store.commit('main/setState', {key: "breadcrumbList", val: [{title: 'About mysa'}]})
    }

}
</script>

<style>
    .brand-margin .content-title{
        margin-bottom: 0px;
        padding-bottom: 15px;
    }
    @media (max-width: 1919px){
        .margin-heading .content-title{
            margin-bottom: 50px;
        }
    }
    @media (max-width: 1365px){
        .margin-heading .content-title{
            margin-bottom: 34px;
        }
        .brand-margin .content-title {
            margin-bottom: 0px;
            padding-bottom: 28px;
        }
    }
    @media (max-width: 1023px){
        .brand-margin .content-title {
            margin-bottom: 0px;
            padding-bottom: 28px;
        }
    }
    @media (max-width: 767px){
        .margin-heading .content-title{
            margin-bottom: 20px;
        }
        .brand-margin .content-title {
            margin-bottom: 0px;
            padding-bottom: 22px;
        }
        .single-structure{
            padding: 0 10px;
        }
    }

</style>

<style>
.about-text p{
    column-gap: 30px;
    line-height: 22px;
    font-size: 13px;
    font-weight: var(--noto-regular);
}
</style>

<style scoped>
    .random-text{
        display: flex;
        justify-content: space-between;
    }
    .random-text i{
        width: 2px;
        height: 113px;
        flex: 0 0 auto;
        background-color: red;
        opacity: 0.1;
    }
    .relative{
        position: relative;
    }
    .mT144{
        margin-top: 144px;
    }
    .mt-144, .mT144{
        margin-top: 144px;
    }

    .mt-223{
        margin-top: 223px;
    }
    .border-i{
        width: 2px;
        height: 113px;
        background: #f7f7f7;
    }
    .border-bottom{
        border-bottom: 1px solid #f7f7f7;
        margin-bottom: 55px;
    }
    .border-bottom .content-title{
        margin: 0;
        padding-bottom: 15px;
    }
    .single-structure:last-child .border-i{
        display: none;
    }
    .swiper-wrapper{
        align-items: center;
    }
    .w-100{
        width: 100% !important;
    }
    .swiper-button-prev{
        background: #f7f7f7;
        color: #435059;
        border-radius: 10px;
        width: 44px;
        height: 44px;
        right: 74px;
        left: auto;
        top: 21px;
    }
    .swiper-button-next{
        background: #f7f7f7;
        color: #435059;
        border-radius: 10px;
        width: 44px;
        height: 44px;
        right: 0;
        left: auto;
        top: 21px;
    }
    .swiper-button-next::after{
        font-size: 22px;
    }
    .swiper-button-prev::after{
        font-size: 22px;
    }
    .mb-144{
        margin-bottom: 144px;
    }
    .about-content{
        margin: 25px 0 55px;
    }
    .infrastructure-content{
        margin: 55px 0;
    }
    .about-text{
        column-count: 2;
        column-gap: 30px;
        margin-bottom: 55px;
        line-height: 22px;
    }
    .infrastructure-list{
        display: flex;
        align-items: center;
        border-radius: 30px;
        border: 1px solid #F7F7F7;
        padding: 28px 0;
    }
    .single-structure{
        display: flex;
        justify-content: center;
        width: 100%;
        position: relative;
    }
    .single-structure::after{
        position: absolute;
        content: '';
        right: 0px;
        width: 2px;
        height: 100%;
        display: block;
        background: #F7F7F7;
    }
    .single-structure:last-child::after{
        display: none;
    }
    .single-structure .img-align{
        display: flex;
        justify-content: center;
        position: relative;
        padding-top: 130px;
        width: 130px;
        margin: 0 auto;
    }
    .single-structure .img-align img{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .single-structure p{
        text-align: center;
        max-width: 300px;
    }
    .mysa-card{
        margin-top: 173px;
        padding: 0 0 0 280px;
        height: 400px;
        background: #F7F7F7;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .card-text{
        margin: -50px 0 60px 0;
    }
    .card-img{
        z-index: 10;
        position: relative;
        margin-bottom: 98px;
    }
    .card-img img{
        width: 100%;
        height: 100%;
    }
    .our-brands{
        margin-top: 55px;
    }
    .partners-swiper{
        position: relative;
        padding-top: 130px;
        width: 248px;
    }
    .partners-swiper img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
    }
    .heading-margin {
        margin-bottom: 55px;
        margin-top: 5px;
    }
    @media(max-width: 1919px){
        .our-brands{
            margin-top: 90px;
            margin-bottom: 90px;
        }
    }
    @media (max-width: 1599px){
        .mt-223{
            margin-top: 109px;
        }
        .mb-144{
            margin-bottom: 90px;
        }
        .mysa-card{
            padding: 0 0 0 60px;
        }
        .card-img{
            width: 800px;
            margin-bottom: 0;
        }
        .heading-margin{
            margin: -16px 0 55px 0;
            z-index: 10;
            position: relative;
        }
        .our-brands{
            margin-top: 55px;
            margin-bottom: 90px;
        }
    }
    @media (max-width: 1364px){
        .infrastructure-content{
            margin: 34px 0;
        }
        .heading-margin{
            margin: -16px 0 34px 0;
        }
        .mt-144, .mT144{
            margin-top: 75px;
        }
        .card-text{
            margin: -40px 0 60px 0;
        }
        .card-img{
            width: 661px;
        }
        .single-structure p{
            font-size: 21px;
        }
        .single-structure .img-align {
            padding-top: 100px;
            width: 100px;
        }
        .mb-144{
            margin-bottom: 55px;
        }
        .about-text{
            margin-bottom: 35px;
        }
        .about-content{
            margin: 25px 0 35px;
        }
        .border-bottom {
            margin-bottom: 35px;
        }
        .our-brands{
            margin-top: 55px;
            margin-bottom: 55px;
        }
        .partners-swiper{
            width: 215px;
        }
    }
     @media (max-width: 1023px){
        .infrastructure-list{
            padding: 30px 0;
        }
        .single-structure .img-align{
            padding-top: 80px;
            width: 80px;
        }
        .card-text{
            margin: -40px 0 40px 0;
        }
        .card-img{
            transform: translateX(100px) !important;
        }
        .single-structure p{
            font-size: 21px;
        }
        .about-text{
            column-count: 1;
        }
        .heading-margin {
            margin: 15px 0 34px 0;
        }
        .partners-swiper{
            width: 150px;
        }
    }
    @media (max-width: 980px){
        .partners-swiper img{
            object-fit: cover;
        }
    }
    @media (max-width: 767px){
        .border-bottom{
            border-bottom: 1px solid #F7F7F7;
            margin-bottom: 10px;
        }
        .partners-swiper{
            width: 100%;
        }
        .heading-margin{
            margin: 0px 0 21px 0;
        }
        .mt-144, .mT144{
            margin-top: 34px;
        }
        .mb-144{
            margin-bottom: 35px;
        }
        .our-brands{
            margin-top: 62px;
            margin-bottom: 34px;
        }
        .border-bottom .content-title{
            margin: 0;
            padding-bottom: 30px;
        }
        .mysa-card{
            flex-direction: column;
            align-items: unset;
        }
        .mysa-card-content{
            margin-top: 34px;
        }
        .card-img{
            transform: translateX(0px) !important;
            margin-bottom: 30px;
        }
        .card-img{
            width: 405px;
        }
        .card-text{
            margin: -20px 0 40px 0;
        }
        .about-text{
            column-count: 1;
            margin-bottom: 34px;
        }
        .infrastructure-content{
            margin: 30px 0 35px;
        }
        .swiper-button-next{
            height: 34px;
            width: 34px;
            top: 21px;
        }
        .swiper-button-next::after{
            font-size: 16px;
        }
        .swiper-button-prev{
            height: 34px;
            width: 34px;
            top: 21px;
            right: 44px;
        }
        .swiper-button-prev::after{
            font-size: 16px;
        }
    }
</style>
