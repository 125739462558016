<template>
    <div class="container-wrapper error-cart-title">
        <content-title :title="$fn.tr('Something Went Wrong!')" data-aos="fade-up" data-aos-delay="200" />

        <div >
            <div class="invoice-container" data-aos="fade-up" data-aos-delay="200">
                <div class="error-text">
                    {{$fn.tr('Your order has not been plased, try again')}}
                </div>
                <router-link :to="`/${_.get($store.state.main,'locale')}/cartlist`" tag="div" class="invoice-button center-flex">{{$fn.tr('back to cart')}}</router-link>
            </div>
        </div>

        <div class="border-bottom"></div>
    </div>
</template>

<script>
import ContentTitle from "@/components/ContentTitle";
export default {
    components: {
        ContentTitle
    },

}
</script>

<style scoped>
.error-cart-title .content-title h1 span{
    color: #FF7675;
}
.invoice-button {
    background-color: var(--green);
    width: 180px;
    height: 44px;
    color: #FFFFFF;
    border-radius: 10px;
    font-size: 13px;
    cursor: pointer;
    font-family: var(--noto-bold);
    text-transform: uppercase;
}
.error-text {
    font-family: var(--noto-bold);
    font-size: 21px;
    margin-bottom: 35px;
}
.success-invoice-text p:first-child {
    color: var(--green);
    font-family: var(--noto-bold);
}
.success-invoice-text p:last-child {
    color: #435059;
    font-family: var(--noto-bold);
}
.invoice-container {
    min-height: 70vh;
}
.success-invoice {
    display: flex;
    justify-content: space-between;
    width: 270px;
    margin-left: 11px;
    margin-bottom: 45px;
}
</style>
