/**
 *
 * @description: Function that takes number and returns it separated with comma
 *              in thousands, hundreds and etc...
 * @options fixed: [Number]: default null (if value is null, number will not be fixed)
 *
 */

// @define & export: Comma separated number generator function
export default (number, options) => {
    // Check for nan
    // if (isNaN(number)) {
    //     console.log(number);
    //     throw new Error("[commaNum]: First prop of the function should be number");
    // }
    // Check type of number
    if (typeof number !== "number") number = +number;

    // Check for options
    if (options) {
        // Check for fixed option
        if (options.fixed)
            number =
                typeof options.fixed === "number"
                    ? number.toFixed(options.fixed)
                    : number.toFixed(2);
    }

    // Check if number is not string
    if (typeof number !== "string") number = number.toString();

    // Split the number  into dot
    const splited = number.split(".");

    // Add commas to first part of splited number
    splited[0] = splited[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Join splited parts with dot again and return the value
    return splited.join(".");
};
