<template>
  <div>
    <div class="container-wrapper relative">
      <content-title
        data-aos="fade-left"
        :title="!successRegistration ? $fn.tr('User Registration') : $fn.tr('thank you')"
      />
      <div v-if="emailNotification" class="notification-title">
        {{ $fn.tr('check your email !') }}
      </div>
      <div v-else>
        <div v-if="!successRegistration">
          <div class="form" v-if="!nextStep" data-aos="fade-right" data-aos-duration="1200">
            <div class="content-top-title">
              {{ $fn.tr('Personal Information') }}
            </div>
            <div class="form-container">
              <div
                class="form-input-item"
                :class="{
                  'input-error':
                    (!$v.personalInformation.personal_number.required ||
                      !$v.personalInformation.personal_number.minLength ||
                      !$v.personalInformation.personal_number.maxLength) &&
                    isClicked,
                }"
              >
                <div class="label">
                  {{ $fn.tr('Personal Number') }}
                  <span>*</span>
                </div>
                <input
                  maxlength="11"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  v-model="$v.personalInformation.personal_number.$model"
                  type="number"
                />
                <div
                  class="label error"
                  v-if="
                    !$v.personalInformation.personal_number.minLength ||
                      !$v.personalInformation.personal_number.maxLength
                  "
                >
                  {{ $fn.tr('Personal number must be 11 numbers') }}
                </div>
              </div>
              <div
                class="form-input-item"
                :class="{
                  'input-error':
                    (!$v.personalInformation.name.required ||
                      !$v.personalInformation.name.minLength) &&
                    isClicked,
                }"
              >
                <div class="label">
                  {{ $fn.tr('First & Last Name') }}
                  <span>*</span>
                </div>
                <input v-model="$v.personalInformation.name.$model" type="text" />
              </div>
              <div
                class="form-input-item"
                :class="{
                  'input-error':
                    (!$v.personalInformation.phone.required ||
                      !$v.personalInformation.phone.minLength) &&
                    isClicked,
                }"
              >
                <div class="label">{{ $fn.tr('Phone Number') }} <span>*</span></div>
                <input
                  type="number"
                  v-model="$v.personalInformation.phone.$model"
                  placeholder="XXX - XXX - XXX"
                />
              </div>
              <div
                class="form-input-item"
                :class="{
                  'input-error':
                    (!$v.personalInformation.email.required ||
                      !$v.personalInformation.email.minLength) &&
                    isClicked,
                }"
              >
                <div class="label">{{ $fn.tr('Email') }}<span>*</span></div>
                <input type="email" v-model="$v.personalInformation.email.$model" />
                <div class="label red" v-if="!$v.personalInformation.email.email && isClicked">
                  {{ $fn.tr('Email is not formatted correctly') }}
                </div>
              </div>
              <div
                class="form-input-item"
                :class="{
                  'input-error':
                    (!$v.personalInformation.password.required ||
                      !$v.personalInformation.password.minLength) &&
                    isClicked,
                }"
                v-if="!editAddressForm"
              >
                <div class="label">{{ $fn.tr('Password') }} <span>*</span></div>
                <input v-model="$v.personalInformation.password.$model" type="password" />
                <div
                  class="label red"
                  v-if="!$v.personalInformation.password.required && isClicked"
                >
                  {{ $fn.tr('New Password is required') }}
                </div>
                <div
                  class="label red"
                  v-if="
                    $v.personalInformation.password.$model &&
                      !$v.personalInformation.password.minLength &&
                      isClicked
                  "
                >
                  {{ $fn.tr('Password must be at least 8 characters') }}
                </div>
              </div>
              <div
                class="form-input-item"
                :class="{
                  'input-error':
                    (!$v.personalInformation.confirmPassword.required ||
                      !$v.personalInformation.confirmPassword.minLength ||
                      !$v.personalInformation.confirmPassword.sameAsPassword) &&
                    isClicked,
                }"
                v-if="!editAddressForm"
              >
                <div class="label">
                  {{ $fn.tr('Confirm Password') }}
                  <span>*</span>
                </div>
                <input v-model="$v.personalInformation.confirmPassword.$model" type="password" />
                <div
                  class="label red"
                  v-if="!$v.personalInformation.confirmPassword.sameAsPassword && isClicked"
                >
                  {{ $fn.tr('Confirm your password') }}
                </div>
                <div
                  class="label red"
                  v-if="!$v.personalInformation.confirmPassword.required && isClicked"
                >
                  {{ $fn.tr('Confirm password is required') }}
                </div>
              </div>

              <div
                class="form-input-item"
                :class="{
                  'input-error':
                    (!$v.shippingAddress.city.required || !$v.shippingAddress.city.minLength) &&
                    isClicked,
                }"
              >
                <div class="label">{{ $fn.tr('City') }} <span>*</span></div>
                <Dropdown
                  @select="selectValue"
                  ref="Sort"
                  :data="_.get($store.state.main.indx, 'cities')"
                  :isRequired="true"
                  :val="shippingAddress.city"
                  :clicked="isClicked"
                />
              </div>
              <div
                class="form-input-item"
                :class="{
                  'input-error':
                    (!$v.shippingAddress.address.required ||
                      !$v.shippingAddress.address.minLength) &&
                    isClicked,
                }"
              >
                <div class="label">
                  {{ $fn.tr('Address') }}
                  <span>*</span>
                </div>
                <input v-model="$v.shippingAddress.address.$model" type="text" />
              </div>
              <div class="form-input-buttons">
                <div class="form-button center-flex" @click="goToHome">
                  {{ $fn.tr('Cancel') }}
                </div>
                <div class="form-button center-flex" @click="goToSuccess">
                  {{ $fn.tr('Register') }}
                </div>
              </div>
              <template v-for="err in errorMessages">
                <div :key="err" class="label error" v-if="errorMessages.length">
                  {{ err }}
                </div>
              </template>
            </div>
          </div>
        </div>
        <div v-if="emailNotification">
          <div class="thanks-container" data-aos="fade-up" data-aos-delay="200">
            <div class="success-text">
              {{ $fn.tr('Registration has been completed') }}
            </div>
            <router-link
              :to="`/${$store.state.main.locale}/home`"
              tag="div"
              class="back-button center-flex"
              >{{ $fn.tr('BACK TO HOME') }}</router-link
            >
          </div>
        </div>
      </div>

      <img class="decor-img-rotated" loading="lazy" src="../assets/img/decor.png" alt="" />
    </div>
    <CartInfo
      data-aos="fade-up"
      :data="
        _.get(this.$store.state.main.current_content, 'secondary.InnerPagesLinksBlock.data.list')
      "
    />
  </div>
</template>

<script>
import { email, minLength, required, sameAs, maxLength } from 'vuelidate/lib/validators';
import ContentTitle from '../components/ContentTitle';
import { validationMixin } from 'vuelidate';
import AddressItem from '../components/checkout/AddressItem';
import CartInfo from '../components/CartInfo';
import conf from '../helpers/conf';
import CalculateShippingPrice from '@/mixins/CalculateShippingPrice';
import Dropdown from '@/components/Dropdown';

export default {
  data() {
    return {
      personalInformation: {
        personal_number: '',
        name: '',
        phone: '',
        email: '',
        password: '',
        confirmPassword: '',
      },
      shippingAddress: {
        city: '',
        address: '',
      },
      personalInformationCard: {},
      nextStep: false,
      editAddressForm: false,
      subscribe: false,
      successRegistration: false,

      // form clicks
      isClicked: false,
      emailNotification: false,
      errorMessages: [],
    };
  },
  components: {
    ContentTitle,
    AddressItem,
    CartInfo,
    Dropdown,
  },
  mixins: [validationMixin, CalculateShippingPrice],
  validations: {
    personalInformation: {
      personal_number: {
        required,
        minLength: minLength(11),
        maxLength: maxLength(11),
      },
      name: {
        required,
        minLength: minLength(3),
      },

      phone: {
        required,
        minLength: minLength(9),
      },
      email: {
        required,
        minLength: minLength(3),
        email,
      },
      password: {
        required,
        minLength: minLength(8),
      },

      confirmPassword: {
        required,
        minLength: minLength(8),
        sameAsPassword: sameAs('password'),
      },
    },
    shippingAddress: {
      city: {
        required,
        minLength: minLength(3),
      },
      address: {
        required,
        minLength: minLength(3),
      },
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(val) {
        if (val.query.token) {
          //expire time is 12 hours
          this.$cookies.set('userToken', val.query.token, 60 * 60 * 12);
          this.$store.dispatch('user/verifyUser', { token: val.query.token }).then((res) => {
            if (res.data) {
              this.successRegistration = true;
              this.animateScrollHeader();
              this.$store.dispatch('user/getUserData', {
                token: val.query.token,
              });
            }
          });
        }
      },
    },
    // '$store.state.user.authData': {
    //     immediate: true,
    //     handler(val) {
    //         if (val) {
    //             this.$router.replace({name: 'home'})
    //         }
    //     }
    // },
  },
  methods: {
    animateScrollHeader() {
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }, 250);
      setTimeout(() => {
        header.classList.remove('transform-top-class');
      }, 650);
      let header = document.querySelector('#scrollHeader');
      header.classList.add('transform-top-class');
      header.classList.remove('hide-top-navbar');
    },

    editAddress() {
      this.animateScrollHeader();
      this.editAddressForm = true;
      this.nextStep = false;
      this.isClicked = false;
      this.personalInformation = this.personalInformationCard;
    },
    goToHome() {
      this.$router.push(`/${this.$store.state.main.locale}/home`);
    },
    async goToSuccess() {
      this.personalInformation.password = this.personalInformation.password.replace(' ', '');
      this.personalInformation.confirmPassword = this.personalInformation.confirmPassword.replace(
        ' ',
        ''
      );
      this.isClicked = true;
      if (
        !this.$v.personalInformation.personal_number.required ||
        !this.$v.personalInformation.personal_number.minLength ||
        !this.$v.personalInformation.personal_number.maxLength ||
        !this.$v.personalInformation.name.required ||
        !this.$v.personalInformation.name.minLength ||
        !this.$v.personalInformation.email.required ||
        !this.$v.personalInformation.email.email ||
        !this.$v.personalInformation.phone.required ||
        !this.$v.personalInformation.phone.minLength ||
        !this.$v.personalInformation.password.required ||
        !this.$v.personalInformation.password.minLength ||
        !this.$v.personalInformation.confirmPassword.required ||
        !this.$v.personalInformation.confirmPassword.minLength ||
        !this.$v.personalInformation.confirmPassword.sameAsPassword ||
        !this.$v.shippingAddress.city.required ||
        !this.$v.shippingAddress.city.minLength ||
        !this.$v.shippingAddress.address.required ||
        !this.$v.shippingAddress.address.minLength
      ) {
        return;
      }

      this.personalInformationCard = this.personalInformation;
      this.nextStep = false;
      this.editAddressForm = false;
      this.personalInformation.personal_number = String(this.personalInformation.personal_number);
      const registrationData = {
        ...this.personalInformation,
        ...this.shippingAddress,
        confirm_url: conf.websiteUrl + '/' + this.$store.state.main.locale + '/registration?token=',
      };

      await this.$store
        .dispatch('user/registration', registrationData)
        .then((res) => {
          if (res.status === 200) {
            this.emailNotification = true;
            this.errorMessages = [];
          } else {
            this.errorMessages = Object.values(res.data);

            this.emailNotification = false;
          }
        })
        .catch((err) => {
          this.errorMessages = err.response.data ? Object.values(err.response.data) : '';
        });
      // this.animateScrollHeader();
      // this.animateScrollHeader();
      // this.successRegistration = true;
    },
  },
  mounted() {
    this.$store.commit('main/setState', {
      key: 'breadcrumbList',
      val: [{ title: 'Registration' }],
    });
  },
};
</script>

<style scoped>
.form {
  max-width: 802px;
}
.content-top-title {
  margin-top: 85px;
}
.notification-title {
  font-family: var(--noto-bold);
  text-transform: capitalize;
  font-size: 35px;
  min-height: 550px;
}
.back-button {
  background-color: var(--green);
  width: 180px;
  height: 44px;
  color: #ffffff;
  border-radius: 10px;
  font-size: 13px;
  cursor: pointer;
  font-family: var(--noto-bold);
}
.thanks-container {
  min-height: 70vh;
}
.success-text {
  font-family: var(--noto-bold);
  font-size: 21px;
  margin-bottom: 35px;
}
.container-wrapper {
  margin-bottom: 150px;
}
.label.error {
  font-size: 10px;
  padding-top: 5px;
  color: #ff7675;
}
.decor-img-rotated {
  position: absolute;
  z-index: -1;
  left: -45%;
  top: 25%;
  transform: rotate(290deg);
}
@media (max-width: 1599px) {
  .decor-img-rotated {
    left: -60%;
    top: 30%;
  }
}
@media (max-width: 1366px) {
  .decor-img-rotated {
    left: -66%;
    top: 30%;
  }
  .content-top-title {
    margin-top: 25px;
  }
}
@media screen and (max-width: 1023px) {
  .form {
    width: 100%;
    max-width: unset;
  }
  .decor-img-rotated {
    display: none;
  }
  .notification-title {
    font-size: 21px;
    min-height: 150px;
  }
}
</style>

<style>
.registration-info .checkbox-default-radio {
  display: none;
}
</style>
