<template>
    <div class="choose-container" :class="{margin : hasZeroMargin}">
        <div class="content-top-title capitalize">
            {{isDelivery ? $fn.tr('Delivery Options') : $fn.tr('Payment Option') }}
        </div>
        <div v-if="isDelivery" class="choose-content">
            <div class="delivery choose-box" :class="{active: choose === 'delivery'}"  @click="chooseOption('delivery')">
                <div class="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30.244" height="17.898" viewBox="0 0 30.244 17.898">
                        <path id="fast-delivery" d="M21.56.178c2.622.608,3.625,2.142,4.883,4.252H21.56ZM6.236,4.608a.778.778,0,0,1,.886.827.886.886,0,0,1-.884.886H.886a.886.886,0,1,0,0,1.772H8.86a.886.886,0,1,1,0,1.772H.886a.886.886,0,1,0,0,1.772H2.835V14.3a.886.886,0,0,0,.886.886H5.5a3.4,3.4,0,0,0,6.657,0h9a3.4,3.4,0,0,0,6.657,0h1.545a.886.886,0,0,0,.886-.886V8.979c0-2.6-2.742-2.772-2.745-2.776H20.674a.886.886,0,0,1-.886-.886V0H3.721a.886.886,0,0,0-.886.886V2.835H1.772a.886.886,0,1,0,0,1.772Zm19.4,8.746A1.624,1.624,0,1,1,22.86,14.5,1.625,1.625,0,0,1,25.633,13.353Zm-15.654,0A1.624,1.624,0,1,1,7.206,14.5,1.625,1.625,0,0,1,9.979,13.353Zm0,0" transform="translate(0.001)" fill="#81c7bd" fill-rule="evenodd"/>
                    </svg>
                </div>
                <div class="title">
                    {{ $fn.tr('Delivery to Address') }}
                </div>
                <div class="checkbox-default-radio">
                    <div class="radio-checkbox" :class="{active: choose === 'delivery'}"/>
                </div>
            </div>
            <div class="pick-up choose-box" @click="chooseOption('pick-up')" :class="{active: choose === 'pick-up'}">
                <div class="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="17.964" height="26.863" viewBox="0 0 17.964 26.863">
                        <g id="Group_4301" data-name="Group 4301" transform="translate(-17.033 -1.5)">
                            <circle id="Ellipse_95" data-name="Ellipse 95" cx="2.229" cy="2.229" r="2.229" transform="translate(24.806 1.5)" fill="#81c7bd"/>
                            <path id="Path_992" data-name="Path 992" d="M33.979,26.648c-.619-.107-3.1-.537-3.237-.583-.158-.053,0,0-.053-.079s-2.216-4.643-2.427-4.88A3.856,3.856,0,0,0,26.653,20a2.379,2.379,0,0,0-1.636.158s-4.062,2.322-4.326,2.453a1.639,1.639,0,0,0-.5.607l-1.9,3.565a1.147,1.147,0,1,0,2.014.979l1.769-3.344,1.108-.62c-.079.237-1.095,5.184-1.095,5.184L21.06,34.837,17.455,39.18a1.322,1.322,0,1,0,1.979,1.733c.66-.776,3.983-4.681,4.3-5.061a1.8,1.8,0,0,0,.5-.91c.053-.277.646-3.852.646-3.852l3.4,3.4v6h.007a1.332,1.332,0,0,0,2.651,0h.007s0-.043,0-.116c0-.007,0-.014,0-.02s0-.017,0-.026c-.006-.914-.035-5.467,0-6.5.039-1.147-.37-1.4-.462-1.5s-3.271-3.482-3.271-3.482l.7-3.39s.91,1.78,1,1.978a1.233,1.233,0,0,0,.686.62c.3.066,4.089.831,4.089.831l0-.008a1.145,1.145,0,0,0,.17.017,1.127,1.127,0,0,0,.112-2.249Z" transform="translate(0 -13.327)" fill="#81c7bd"/>
                        </g>
                    </svg>

                </div>
                <div class="title">
                    {{ $fn.tr('I\'ll Pick It Up Myself') }}
                </div>
                <div class="checkbox-default-radio">
                    <div class="radio-checkbox" :class="{active: choose === 'pick-up'}"/>
                </div>
            </div>
        </div>
        <div v-else class="choose-content">
            <div class="delivery choose-box" @click="chooseOption('card')" :class="{active: choose === 'card'}">
                <div class="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25.345" height="18.104" viewBox="0 0 25.345 18.104">
                        <g id="Icon_ionic-ios-card" data-name="Icon ionic-ios-card" transform="translate(-2.25 -6.75)">
                            <path id="Path_993" data-name="Path 993" d="M27.595,8.56a1.816,1.816,0,0,0-1.81-1.81H4.06A1.816,1.816,0,0,0,2.25,8.56v2.716H27.595Z" transform="translate(0 0)" fill="#81c7bd"/>
                            <path id="Path_994" data-name="Path 994" d="M2.25,24.8a1.816,1.816,0,0,0,1.81,1.81H25.785a1.816,1.816,0,0,0,1.81-1.81V15.75H2.25Zm8.033-2.716h9.278a.794.794,0,0,1,.792.792h0a.794.794,0,0,1-.792.792H10.283a.794.794,0,0,1-.792-.792h0A.794.794,0,0,1,10.283,22.086Zm-4.073,0h.679a.794.794,0,0,1,.792.792h0a.794.794,0,0,1-.792.792H6.21a.794.794,0,0,1-.792-.792h0A.794.794,0,0,1,6.21,22.086Z" transform="translate(0 -1.759)" fill="#81c7bd"/>
                        </g>
                    </svg>

                </div>
                <div class="title">
                    {{ $fn.tr('With Card') }}
                </div>
                <div class="checkbox-default-radio">
                    <div class="radio-checkbox" :class="{active: choose === 'card'}"/>
                </div>
            </div>
            <div class="pick-up choose-box" @click="chooseOption('invoice')" :class="{active: choose === 'invoice'}">
                <div class="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16.923" height="22" viewBox="0 0 16.923 22">
                        <path id="Icon_ionic-md-document" data-name="Icon ionic-md-document" d="M16.9,3.375H8.865A2.115,2.115,0,0,0,6.75,5.49V23.259a2.115,2.115,0,0,0,2.115,2.115H21.557a2.115,2.115,0,0,0,2.115-2.115V10.144Zm-.846,7.615V5.067L21.98,10.99Z" transform="translate(-6.75 -3.375)" fill="#81c7bd"/>
                    </svg>


                </div>
                <div class="title">
                    {{ $fn.tr('With Invoice') }}
                </div>
                <div class="checkbox-default-radio">
                    <div class="radio-checkbox" :class="{active: choose === 'invoice'}"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isDelivery: {
            type: Boolean,
            default: true
        },
        hasZeroMargin: {
            type: Boolean,
            default: false
        },
        activeClass: {
            type: Boolean,
        },
    },
    data() {
        return {
            choose: ''
        }
    },
    methods: {
        chooseOption(val) {
            this.choose = val;
            this.$emit('choice',val);

        }
    }
}
</script>

<style scoped>
.choose-content {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}
.choose-container {
    margin-bottom: 61px;
    margin-top: 61px;
}
.choose-container.margin {
    margin-top: 0;
}
.choose-box:first-child {
    margin-right: 30px;
}
.choose-box {
    width: 50%;
    cursor: pointer;
    height: 84px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    background-color: #ffffff;
    border: 1px solid #ECF0F1;
    transition: .6s;
}
.choose-box.active {
    border-color: #FEC55E;
}
.checkbox-default-radio {
    margin-right: 5%;
}
.icon {
    width: 44px;
    height: 44px;
    background-color: #F7F7F7;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10%;
}
.title {
    font-size: 16px;
    margin-left: 5%;
    margin-right: 10%;
}
.radio-checkbox {
    position: relative;
    cursor: pointer;
}

.radio-checkbox:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid rgba(67, 80, 89, 0.3);;
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: bottom;
    cursor: pointer;
    border-radius: 50%;
    margin-right: 8px;
}

.radio-checkbox.active:after {
    content: '';
    display: block;
    position: absolute;
    top: 3px;
    left: 7px;
    width: 5px;
    height: 9px;
    border: solid #FFFFFF;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

.radio-checkbox.active:before {
    background-color: var(--orange);
    border: 1px solid var(--orange);
    border-radius: 50%;
}
.capitalize {
    text-transform: capitalize;
}

@media (max-width: 1499px) {
    .title {
        font-size: 16px;
        margin-left: 5%;
        margin-right: 5%;
    }
}
@media (max-width: 767px) {
    .choose-content {
        flex-direction: column;
    }
    .choose-box:first-child {
        margin-right: 0;
    }
    .choose-box {
        width: 100%;
        margin-bottom: 20px;
    }
    .checkbox-default-radio {
        margin-left: auto;
    }
}
@media (max-width: 414px) {
    .title {
        font-size: 14px;
    }
}
</style>
