<template>
    <div>
        <div class="media-and-marketing-top container-wrapper">
            <div class="left-content" data-aos="fade-up" data-aos-delay="400">
                <content-title :colored-title="true" data-aos="fade-up" :title="_.get($store.state.main.current_content,'primary.data.list[0].title')" />
                <div class="media-and-marketing"     data-aos="fade-up">
                    <div class="content-description remove-styles13 line-height" v-html="_.get($store.state.main.current_content,'primary.data.list[0].content.tabs[0].content')">
                    </div>
                </div>
            </div>
            <div class="right-content" data-aos="fade-down" data-aos-delay="400">
                <InformationBookingCard :email="_.get($store.state.main.current_content,'secondary.ContactBlock.data.list[0].email')" title="Book Call" :data="_.get($store.state.main.current_content,'secondary.ContactBlock.data.list[0].contact_info')" />
            </div>
        </div>
        <div class="container-wrapper mobile-right-content" data-aos="fade-up">
            <InformationBookingCard :email="_.get($store.state.main.current_content,'secondary.ContactBlock.data.list[0].email')" title="Book Call"  :data="_.get($store.state.main.current_content,'secondary.ContactBlock.data.list[0].contact_info')"/>
        </div>
        <services-dropdowns :title="_.get($store.state.main.current_content,'secondary.ServiceBlock.conf.header')" :data="_.get($store.state.main.current_content,'secondary.ServiceBlock.data.list[0].content')" />
        <div class="press-releases container-wrapper relative" data-aos="fade-up" data-aos-delay="400">
            <div class="press-releases-title">{{_.get($store.state.main.current_content,'secondary.Pressrealise.conf.header.title') }}</div>
            <div class="press-release">
                <press-releases-card :data="_.get($store.state.main.current_content,'secondary.Pressrealise.data.list')"/>

            </div>
            <img width="300" height="200" class="decor-img-rotated" loading="lazy" src="../assets/img/decor.png" alt="">

        </div>
        <CartInfo  />
    </div>
</template>

<script>
    import ContentTitle from "../components/ContentTitle";
    import CartInfo from '../components/CartInfo'
    import InformationBookingCard from "../components/MediaAndMarketing/InformationBookingCard";
    import ServicesDropdowns from "../components/MediaAndMarketing/ServicesDropdowns";
    import PressReleasesCard from "../components/MediaAndMarketing/PressReleasesCard";
    import {mapGetters} from "vuex";
    import _ from 'lodash';
    export default {
        data() {
            return {
                title: 'Media & Marketing'
            }
        },

        components: {
            ContentTitle,
            InformationBookingCard,
            ServicesDropdowns,
            PressReleasesCard,
            CartInfo
        },

        mounted() {
            this.$store.commit('main/setState', {key: "breadcrumbList", val: [{title_din: _.get(this.$store.state.main.current_content,'primary.data.list[0].title')}]})
        }
    }
</script>

<style scoped>
    .content-description {
        max-width: 950px;
        margin-bottom: 30px;
        font-size: 13px;
        color: #435059;
        font-family: var(--noto-regular);
    }


    .media-and-marketing-top {
        display: flex;
        justify-content: space-between;
        margin-top: 0;
        backface-visibility: hidden;
        transition-duration: .8s;
    }

    .pdf-wrapper, .book-cal {
        max-width: 527px;
        margin-left: auto;
    }

    /*press realeses*/
    .press-releases {
        margin-top: 95px;
        margin-bottom: 50px;
    }

    .press-release {
        margin-top: 20px;
    }

    .press-releases-title {
        color: #435059;
        font-family: var(--noto-bold);
        font-size: var(--normalFontSize);
    }

    .left-content {
        margin-right: 80px;
        width: 55%;
    }

    .right-content {
        width: 32%;
    }

    .press-release-button {
        font-size: 13px;
        padding: 15px 0;
        width: 105px;
        margin: 75px auto 140px;
    }

    .mobile-right-content{
        display: none;
    }

    .decor-img-rotated{
        position: absolute;
        z-index: -1;
        left: -40%;
        top: -85%;
        transform: rotate(290deg);
    }
    @media (max-width: 1599px) {
        .decor-img-rotated{
            left: -60%;
            top: -50%;
        }
    }

    @media screen and (max-width: 1366px) {
        .decor-img-rotated{
            left: -66%;
            top: -50%;
        }
        .left-content {
            width: 55%;
        }

        .right-content {
            width: 45%;
        }

        .pdf-wrapper, .book-cal {
            width: 100%;
        }
    }

    @media screen and (max-width: 1024px){
        .right-content, .left-content {
            width: 45%;
        }
        .decor-img-rotated {
            display: none;
        }
    }
    @media screen and (max-width: 1023px){
        .right-content {
            display: none;
        }
        .left-content {
            width: 100%;
            margin-right: 0;
        }
        .mobile-right-content{
            margin-top: 35px;
            width: 100%;
            display: block;
        }
    }


</style>

<style>
    .media-and-marketing-top .content-description, .media-and-marketing-top .content-description > p {
        max-width: 950px;
        margin-bottom: 30px;
        font-size: 13px;
        color: #435059;
        font-family: var(--noto-regular);
    }
</style>
