<template>
    <div class="home">
        <MainSlider
            :class="{ 'hide-slider': $store.state.main.loadingPageChange }"
            :compData="
                _.get(
                    $store.state.main.current_content,
                    'secondary.MainSliderBlock.data'
                )
            "
        />

        <CategoryList
            :class="{ 'hide-slider': $store.state.main.loadingPageChange }"
        />

        <div
            class="custom-container-wrapper relative best-sellers"
            :class="{ 'hide-slider': $store.state.main.loadingPageChange }"
            v-if="
                _.size(
                    $store.state.main.current_content,
                    'secondary.BestSellers.data'
                )
            "
        >
            <img
                class="best-decor-img"
                loading="lazy"
                src="../assets/img/decor.png"
                alt=""
            />

            <PageTitle
                class="best-sellers-content title-width"
                :title="
                    _.get(
                        $store.state.main.current_content,
                        'secondary.BestSellers.conf.header.title'
                    )
                "
            />

            <ProductSlider
                :sliderData="
                    _.get(
                        $store.state.main.current_content,
                        'secondary.BestSellers.data'
                    )
                "
            />
        </div>

        <LimitedOffers
            :class="{ 'hide-slider': $store.state.main.loadingPageChange }"
            data-aos="fade-up"
            data-aos-delay="100"
            :data="
                _.get(
                    $store.state.main.current_content,
                    'secondary.SpecialOffers'
                )
            "
        />

        <div
            class="custom-container-wrapper relative mysa-recomends"
            v-if="
                _.size(
                    $store.state.main.current_content,
                    'secondary.MysaRecommends.data'
                )
            "
            data-aos="fade-up"
            data-aos-delay="100"
        >
            <img
                class="decor-img-rotated"
                loading="lazy"
                src="../assets/img/decor.png"
                alt=""
                data-aos="fade-in"
                data-aos-delay="200"
            />

            <PageTitle
                class="recomends-content title-width"
                :title="
                    _.get(
                        $store.state.main.current_content,
                        'secondary.MysaRecommends.conf.header.title'
                    )
                "
            />

            <VerticalSlider
                :sliderData="
                    _.get(
                        $store.state.main.current_content,
                        'secondary.MysaRecommends.data'
                    )
                "
            />
        </div>

        <div class="new-collection" data-aos="fade-up" data-aos-delay="100">
            <PageTitle
                class="container-wrapper"
                :title="
                    _.get(
                        $store.state.main.current_content,
                        'secondary.NewCollection.conf.header.title'
                    )
                "
            />

            <HomeSlicedSlider
                data-aos="fade-up"
                data-aos-delay="100"
                :sliderData="
                    _.get(
                        $store.state.main.current_content,
                        'secondary.NewCollection.data.list'
                    )
                "
            />
        </div>

        <div
            class="container-wrapper cust-container"
            data-aos="fade-up"
            data-aos-delay="100"
            v-if="
                _.size(
                    $store.state.main.current_content,
                    'secondary.LatestNews.data.list'
                )
            "
        >
            <div class="flex justify-between relative news-content">
                <img
                    class="decor-img"
                    loading="lazy"
                    src="../assets/img/decor.png"
                    alt=""
                />

                <PageTitle
                    :title="
                        _.get(
                            $store.state.main.current_content,
                            'secondary.LatestNews.conf.header.title'
                        )
                    "
                />

                <router-link
                    class=""
                    :to="`/${$store.state.main.current_content.locale}/news`"
                >
                    {{ $fn.tr("View More") }}
                </router-link>
            </div>

            <div class="flex card-list">
                <router-link
                    class="news-shown"
                    v-for="(item, index) in _.get(
                        $store.state.main.current_content,
                        'secondary.LatestNews.data.list'
                    )
                        ? _.get(
                              $store.state.main.current_content,
                              'secondary.LatestNews.data.list'
                          ).slice(0, 2)
                        : []"
                    :key="index"
                    :to="
                        `/${$store.state.main.indx.locale}/news/singleview/${item.id}-${item.slug}`
                    "
                >
                    <LatestNewsCard :cardData="item" />
                </router-link>
            </div>
        </div>
        <CartInfo class="mt-236" />
    </div>
</template>

<script>
import PageTitle from "../components/PageTitle";
import MainSlider from "@/components/home/MainSlider";
import CategoryList from "../components/home/CategoryList";
import ProductSlider from "../components/ProductSlider";
import LimitedOffers from "../components/LimitedOffers";
import VerticalSlider from "../components/VerticalSlider";
import HomeSlicedSlider from "../components/HomeSlicedSlider";
import LatestNewsCard from "../components/LatestNewsCard";
import CartInfo from "../components/CartInfo";

export default {
    name: "Home",
    components: {
        PageTitle,
        MainSlider,
        CategoryList,
        ProductSlider,
        LimitedOffers,
        VerticalSlider,
        HomeSlicedSlider,
        LatestNewsCard,
        CartInfo
    },
    data() {
        return {};
    },
    computed: {
        sortedSlicedSlider() {
            const sliderSort = _.get(
                this.$store.state.main.indx,
                "home_content.home_content.original.secondary.NewCollection.data.list"
            );

            return sliderSort.sort((a, b) => a.sort - b.sort);
        }
    },
    created() {
        if (!Object.keys(this.$route.params).length)
            this.$router.replace(`/${this.$store.state.main.locale}/home`);
    }
};
</script>

<style>
.news-content .content-title,
.best-sellers-content .content-title,
.recomends-content .content-title {
    margin: 0;
}

@media (max-width: 1023px) {
    .news-shown .news-card-img {
        padding-top: 56.25%;
    }
    .title-width h3 {
        width: 70%;
    }
}
</style>

<style scoped>
.hide-slider {
    opacity: 0;
    visibility: hidden;
    transition: 0.5s ease;
}

.best-decor-img {
    position: absolute;
    top: -25%;
    right: -45%;
    transform: rotate(120deg) !important;
}

.decor-img {
    position: absolute;
    top: -70%;
    right: -70%;
    transform: rotate(120deg) !important;
}
.decor-img-rotated {
    position: absolute;
    top: -20%;
    right: 60%;
    transform: rotate(180deg) !important;
}
.home {
    margin-top: -90px;
}
.cust-container {
    margin-top: 144px;
    padding: 0 175px 0 185px;
}
.flex-end {
    align-items: flex-end;
}
.mt-236 {
    margin-top: 236px;
}
.best-sellers-content {
    align-items: flex-end;
    padding-bottom: 13px;
    border-bottom: 1px solid #f7f7f7;
    margin-bottom: 60px;
}
.recomends-content {
    align-items: flex-end;
    padding-bottom: 15px;
    border-bottom: 1px solid #f7f7f7;
    margin-bottom: 30px;
}
.news-content {
    align-items: center;
    padding-bottom: 15px;
    border-bottom: 1px solid #f7f7f7;
    margin-bottom: 60px;
}
.card-list {
    justify-content: space-between;
}
.news-shown {
    width: calc(50% - 15px);
    cursor: pointer;
}
.news-content a {
    font-size: 13px;
    text-decoration: underline;
    font-weight: bold;
}
.best-sellers {
    margin-top: 130px;
}
.mysa-recomends {
    margin-top: 236px;
}
.new-collection {
    margin-top: 190px;
}
@media (max-width: 1919px) {
    .mt-236 {
        margin-top: 150px;
    }
    .best-sellers {
        margin-top: 106px;
    }
    .mysa-recomends {
        margin-top: 243px;
    }
    .new-collection {
        margin-top: 118px;
    }
    .news-content {
        padding-bottom: 18px;
        margin-top: 160px;
    }
    .cust-container {
        padding: 0 140px;
    }
}
@media (max-width: 1599px) {
    .best-sellers {
        margin-top: 127px;
    }
    .news-content {
        padding-bottom: 30px;
        margin-top: 150px;
    }
    .mysa-recomends {
        margin-top: 156px;
    }
    .new-collection {
        margin-top: 147px;
    }
    .cust-container {
        padding: 0 7px;
    }
    .news-content {
        padding-bottom: 15px;
    }
}
@media (max-width: 1365px) {
    .best-decor-img {
        position: absolute;
        top: -25%;
        right: -55%;
        transform: rotate(120deg) !important;
    }
    .mt-236 {
        margin-top: 100px;
    }
    .best-sellers {
        margin-top: 135px;
    }
    .best-sellers-content {
        padding-bottom: 25px;
    }
    .mysa-recomends {
        margin-top: 100px;
    }
    .recomends-content {
        padding-bottom: 30px;
    }
    .news-content {
        margin-top: 100px;
        padding-bottom: 30px;
    }
    .cust-container {
        padding: 0 36px;
    }
    .new-collection {
        margin-top: 125px;
    }
    .cust-container {
        margin-top: 96px;
    }
    .cust-container .news-card .desc {
        font-size: 16px;
        line-height: 22px;
    }
    .content-title {
        margin-bottom: 32px;
    }
}
@media (max-width: 1023px) {
    .best-decor-img {
        display: none;
    }
    .decor-img {
        display: none;
    }
    .decor-img-rotated {
        display: none;
    }
    .best-sellers {
        margin-top: 95px;
    }
    .new-collection {
        margin-top: 47px;
    }
    .news-content {
        margin-top: 42px;
    }
    .cust-container {
        margin-top: 44px;
    }
    .card-list {
        flex-direction: column;
    }
    .news-shown {
        width: 100%;
    }
    .news-shown:nth-child(1) {
        margin-bottom: 30px;
    }
}
@media (max-width: 767px) {
    .home {
        margin-top: -55px;
    }
    .mt-236 {
        margin-top: 55px;
    }
    .cust-container {
        padding: 0 20px;
    }
    .news-content {
        margin-top: 58px;
    }
    .best-sellers {
        margin-top: 55px;
    }
    .mysa-recomends {
        margin-top: 55px;
    }
    .new-collection {
        /* margin-top: 55px; */
        margin-top: 0;
    }
    .news-content {
        padding-bottom: 23px;
        margin-bottom: 35px;
    }
    .best-sellers-content {
        padding-bottom: 22px;
        margin-bottom: 35px;
    }
    .recomends-content {
        padding-bottom: 21px;
        margin-bottom: 35px;
    }
}
</style>
