<template>
    <section>
        <div class="container-wrapper sales-offers">
            <content-title class="heading-margin" :colored-title="true" :title="$store.state.main.selected_menu.title" data-aos="fade-up" />

<!--            <FilterComponent class="filter-spacing" />-->

            <div class="news mb-144 relative">
                <div data-aos="fade-up" data-aos-delay="150" class="light mt-75 font-34 mb-83" v-html="_.get(this.$store.state.main.current_content, 'secondary.WhatsNew.conf.header.title')">What's New</div>

                <NewsSlider :sliderData="_.get(this.$store.state.main.current_content, 'secondary.WhatsNew.data')" data-aos="fade-up" data-aos-delay="250" />
            </div>
            <div class="offers mb-144 relative">
                <img class="decor-img-rotated" loading="lazy" src="../assets/img/decor.png" alt="">
                <div data-aos="fade-up" data-aos-delay="150" class="light font-34 mb-83" v-html="_.get(this.$store.state.main.current_content, 'secondary.Offers.conf.header.title')"></div>

                <BrandSlider :sliderData="_.get(this.$store.state.main.current_content, 'secondary.Offers.data.list')" data-aos="fade-up" data-aos-delay="250" />
            </div>

            <div class="sales mb-144 relative">
                <div data-aos="fade-up" data-aos-delay="150" class="light font-34 mb-83" v-html="_.get(this.$store.state.main.current_content, 'secondary.WhatsNew.conf.header.title')">Sales</div>

                <SaleSlider :sliderData="_.get(this.$store.state.main.current_content, 'secondary.Sales.data')" data-aos="fade-up" data-aos-delay="200" />
            </div>

        </div>

        <CartInfo  />
    </section>
</template>

<script>
import ContentTitle from "../components/ContentTitle"
import CartInfo from "../components/CartInfo"
import FilterComponent from "../components/ProductList"
import NewsSlider from "../components/ProductSlider"
import SaleSlider from "../components/SalesSlider"
import BrandSlider from "../components/SliderWithBrands"

export default {

    components:{
        CartInfo,
        ContentTitle,
        FilterComponent,
		NewsSlider,
		SaleSlider,
        BrandSlider,
    },

    mounted() {
        this.$store.commit('main/setState', {key: "breadcrumbList", val: [{title: this.$store.state.main.selected_menu.title }]})
    }
}
</script>

<style scoped>
    .mb-144{
        margin-bottom: 144px;
    }
    .mb-83{
        margin: 75px 0 83px 0;
    }
    .relative{
        position: relative;
    }
    .decor-img-rotated{
        position: absolute;
        z-index: -1;
        left: -42%;
    	top: -100%;
        transform: rotate(290deg);
    }
	.heading-margin{
		margin-bottom: 45px;
	}
    .filter-spacing{
        margin-top: -15px;
    }
	@media (max-width: 1599px){
		.mb-83{
			margin: 45px 0 60px 0;
		}
		.mb-144{
			margin-bottom: 75px;
		}
        .filter-spacing{
            margin-top: -5px;
        }
	}
    @media (max-width: 1365px){
        .mb-144{
            margin-bottom: 65px;
        }
        .filter-spacing{
            margin-top: 0;
        }
    }
	@media (max-width: 1023px){
		.decor-img-rotated{
			display: none;
		}
		.mb-144{
            margin-bottom: 45px;
        }
        .mb-83{
            margin: 35px 0 40px 0;
        }
	}
	@media (max-width: 767px){
		.font-34{
			font-size: 21px;
		}
	}
</style>
