<template>
  <div>
    <div class="vertical-product-card">
      <div class="vertical-product-card-img">
        <div class="label-position" v-if="labelData">
          <template v-for="(item, index) in labelData.slice(0, 1)">
            <div
              class="label-info bold font-13 last-call"
              :key="index"
              :style="{ background: item.color }"
            >
              {{ item.title }}
            </div>
          </template>
        </div>
        <picture>
          <source
            media="(max-width: 767px)"
            loading="lazy"
            :srcset="`${_.get(sliderData, 'coverimage[0].devices.mobile')}`"
          />
          <source
            media="(max-width: 1023px)"
            loading="lazy"
            :srcset="`${_.get(sliderData, 'coverimage[0].devices.tablet')}`"
          />
          <img
            alt="img"
            @click="
              $router.push(
                `/${_.get($store.state.main.indx, 'locale')}/products/productview/${_.get(
                  sliderData,
                  'id'
                )}-${_.get(sliderData, 'slug')}`
              )
            "
            :src="`${_.get(sliderData, 'coverimage[0].devices.desktop')}`"
            loading="lazy"
          />
        </picture>
        <div v-if="!active" @click="addToWishList(sliderData)">
          <svg
            style="cursor: pointer"
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 44 44"
          >
            <g id="Group_3812" data-name="Group 3812" transform="translate(-378 -1115)">
              <circle
                id="Ellipse_3"
                data-name="Ellipse 3"
                cx="22"
                cy="22"
                r="22"
                transform="translate(378 1115)"
                fill="#ffeece"
              />
              <path
                id="Icon_feather-heart"
                data-name="Icon feather-heart"
                d="M17.989,5.806a4.467,4.467,0,0,0-6.318,0l-.861.861L9.95,5.806a4.468,4.468,0,1,0-6.318,6.318l.861.861L10.81,19.3l6.318-6.318.861-.861a4.467,4.467,0,0,0,0-6.318Z"
                transform="translate(389.549 1125.348)"
                fill="none"
                stroke="#fec55e"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              />
            </g>
          </svg>
        </div>

        <div v-if="active" @click="removeWishElement(sliderData)">
          <svg
            style="cursor: pointer"
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 30 30"
          >
            <g id="Group_14" data-name="Group 14" transform="translate(-378 -1115)">
              <circle
                id="Ellipse_3"
                data-name="Ellipse 3"
                cx="15"
                cy="15"
                r="15"
                transform="translate(378 1115)"
                fill="#fec55e"
              />
              <path
                id="Icon_feather-heart"
                data-name="Icon feather-heart"
                d="M13,5.39a3.045,3.045,0,0,0-4.308,0l-.587.587L7.523,5.39A3.046,3.046,0,1,0,3.215,9.7l.587.587L8.11,14.592l4.308-4.308L13,9.7A3.045,3.045,0,0,0,13,5.39Z"
                transform="translate(385.135 1120.625)"
                fill="none"
                stroke="#fff"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              />
            </g>
          </svg>
        </div>
      </div>
      <div class="vertical-product-content">
        <router-link
          class="vertical-product-descr font-16"
          :to="
            `/${$store.state.main.indx.locale}/products/productview/${sliderData.id}-${sliderData.slug}`
          "
        >
          <h2 class="product-descr">{{ sliderData.title }}</h2>
        </router-link>
        <div class="mobile-flex">
          <div class="flex vertical-product-price">
            <div
              class="vertical-product-oldprice bold font-16"
              v-if="sliderData.oldprice && sliderData.oldprice > 0"
            >
              <span></span>
              {{ sliderData.oldprice }} ₾
            </div>
            <div class=" bold font-21" v-if="sliderData.stock_price">
              {{ sliderData.stock_price }} ₾
            </div>
          </div>

          <div
            class="add-to-cart"
            v-if="sliderData.stock_price && sliderData.stock_quantity > 1"
            @click="addToCart(sliderData)"
          >
            <span class="font-13 bold">
              {{ $fn.tr('Add to cart') }}
            </span>
            <div class="svg">
              <svg xmlns="http://www.w3.org/2000/svg" width="15.246" height="14.005">
                <g data-name="Group 3636">
                  <g data-name="Group 282">
                    <path
                      data-name="Path 213"
                      d="M5.94 11.022a.908.908 0 0 1-.757-.689L2.718 1.588H.789a.793.793 0 0 1 0-1.587h2.523a.913.913 0 0 1 .758.694l2.426 8.552 6.725-1.407.342-2.6-6.5-.271a.786.786 0 0 1-.754-.818.8.8 0 0 1 .822-.752l7.36.316a.79.79 0 0 1 .748.891l-.526 4.022a.788.788 0 0 1-.617.669l-7.991 1.709a.771.771 0 0 1-.165.016z"
                      fill="#81c7bd"
                    />
                  </g>
                  <g data-name="Group 283">
                    <path
                      data-name="Path 214"
                      d="M5.934 14.005a1.163 1.163 0 1 1 1.163-1.163 1.165 1.165 0 0 1-1.163 1.163z"
                      fill="#81c7bd"
                    />
                  </g>
                  <g data-name="Group 284">
                    <path
                      data-name="Path 215"
                      d="M11.632 14.005a1.163 1.163 0 1 1 1.163-1.163 1.165 1.165 0 0 1-1.163 1.163z"
                      fill="#81c7bd"
                    />
                  </g>
                </g>
              </svg>
            </div>
          </div>
          <div class="out-of-stock bold font-18" v-else>
            {{ $fn.tr('Out of Stock') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import addToCartMixin from '../mixins/addToCartMixin';
export default {
  props: ['sliderData'],
  mixins: [addToCartMixin],
  data() {
    return {
      active: false,
    };
  },
  watch: {
    getWishListItems: {
      immediate: true,
      handler(val) {
        if (val && val?.data?.length) {
          val.data.findIndex((res) => res.id === this.sliderData.id) !== -1
            ? (this.active = true)
            : (this.active = false);
        } else {
          this.active = false;
        }
      },
    },
  },

  computed: {
    ...mapGetters({
      getCartItems: 'shop/getCartItems',
    }),
    labelData() {
      let labels = _.get(this.$store.state.main.indx, 'attributes.label');
      let labelID = _.get(this.sliderData, 'attributes.label');

      let label = [];

      if (labelID) {
        for (let i of labelID) {
          let list = labels.find((item) => item.id === i);
          label.push(list);
        }
      }

      return label;
    },
    // showAddToCart() {
    //     const cartItems = _.get(this.getCartItems, "data.list");
    //     const currentProduct = cartItems?.find(
    //         product => product.id == this.sliderData?.id
    //     );
    //     if (
    //         currentProduct?.quantity &&
    //         currentProduct?.quantity >= this.sliderData.stock_quantity - 1
    //     ) {
    //         return false;
    //     }
    //     return (
    //         this.sliderData?.stock_price &&
    //         this.sliderData?.stock_quantity > 1
    //     );
    // }
  },
};
</script>

<style>
@media (max-width: 767px) {
  .multi-slider .swiper-wrapper {
    align-items: end;
  }
}
</style>

<style scoped>
.flex {
  display: flex;
}
.align-center {
  align-items: center;
}
.active path {
  fill: #fff;
}
.vertical-product-card {
  display: flex;
}
.vertical-product-card-img {
  position: relative;
  padding-top: 42.6%;
  width: 53%;
  border-radius: 30px;
  overflow: hidden;
}
.vertical-product-card-img .label-position {
  position: absolute;
  top: 20px;
  left: 10px;
  display: flex;
  z-index: 1;
}
.vertical-product-card-img .label-info {
  font-size: 13px;
  border-radius: 10px;
  padding: 5px 15px;
  margin-right: 15px;
  color: #fff;
  z-index: 1;
  text-transform: uppercase;
  background: #81c7bd;
}
.vertical-product-card-img svg {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}
.vertical-product-card-img img:hover {
  transition: 0.5s;
  transform: scale(1.1);
}
.vertical-product-card-img img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  backface-visibility: hidden;
  cursor: pointer;
  transition: 0.3s;
}
.vertical-product-content {
  padding-left: 30px;
  max-width: 54%;
  width: 100%;
}
.vertical-product-descr {
  color: #435059;
  /* margin-bottom: 30px; */
}
.vertical-product-oldprice {
  position: relative;
  margin-right: 10px;
  width: fit-content;
  display: flex;
  align-items: center;
}
.vertical-product-oldprice span {
  position: absolute;
  transform: rotate(-17deg);
  top: 44%;
  left: 0;
  width: 100%;
  height: 2px;
  background: #ff7675;
}
.vertical-product-price {
  padding: 5px 0 30px;
}
.add-to-cart {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
}
.product-descr {
  min-height: 45px;
}
.add-to-cart span {
  color: #81c7bd;
}
.add-to-cart .svg {
  width: 39px;
  height: 39px;
  display: flex;
  min-width: 39px;
  min-height: 39px;
  align-items: center;
  justify-content: center;
  border: 2px solid #d9eeeb;
  border-radius: 50%;
  transition: 0.3s;
  margin-left: 10px;
}
@media (max-width: 1919px) {
  .vertical-product-content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
}
@media (min-width: 1366px) {
  .add-to-cart:hover svg path,
  .add-to-cart.active svg path {
    fill: #fff;
    transition: 0.3s;
  }
  .add-to-cart:hover .svg,
  .add-to-cart.active .svg {
    background: #81c7bd;
    border: 2px solid #81c7bd;
    transition: 0.3s;
  }
}
@media (max-width: 1919px) {
  .vertical-product-card-img {
    padding-top: 41%;
    width: 57%;
  }
}
@media (max-width: 1599px) {
  .vertical-product-card-img {
    padding-top: 42%;
    width: 47%;
  }
  .vertical-product-content {
    display: block;
  }
}
@media (max-width: 1365px) {
  .vertical-product-card-img {
    padding-top: 42.6%;
    width: 55%;
  }
  .add-to-cart span {
    display: none;
  }
  .add-to-cart .svg {
    margin-left: 0;
  }

  .vertical-product-card-img svg {
    top: 23px;
    right: 12px;
  }
  .product-descr {
    min-height: 50px;
    margin-top: 15px;
  }
}
@media (max-width: 1023px) {
  .vertical-product-price {
    padding: 10px 0 30px;
  }
  .vertical-product-card-img {
    /* padding-top: 50.6%;
            width: 56%; */
    padding-top: 192px;
    width: 213px;
  }
  .vertical-product-content {
    padding-left: 30px;
    max-width: 44%;
    width: 100%;
  }
  .product-descr {
    min-height: 54px;
    margin-top: 8px;
  }
  .vertical-product-card-img svg {
    top: 17px;
    right: 9px;
  }
}
@media (max-width: 767px) {
  .vertical-product-oldprice {
    padding: 0;
  }
  .vertical-product-oldprice span {
    top: 45%;
  }
  .vertical-product-card {
    flex-direction: column;
  }
  .vertical-product-card-img {
    width: 94%;
    padding-top: 93.5%;
  }
  .vertical-product-content {
    padding-left: 0;
  }
  .add-to-cart .svg {
    min-width: 30px;
    min-height: 30px;
  }
  .vertical-product-price {
    padding: 0;
    font-size: 21px !important;
    padding-right: 20px;
    margin-right: 20px;
    border-right: 1px solid rgb(67, 80, 89, 0.2);
  }
  .vertical-product-content {
    max-width: 100%;
  }
  .vertical-product-descr {
    min-height: 44px;
    margin-bottom: 7px;
    margin-top: 11px;
  }
  .add-to-cart svg {
    margin-left: 0px;
  }
  .mobile-flex {
    display: flex;
    align-items: center;
  }
  .add-to-cart span {
    display: none;
  }
}
</style>
