<template>
    <div class="services-dropdown container-wrapper" data-aos="fade-up" >

        <div class="services-dropdown-title">{{title ? $fn.tr(title.title) : ''}}</div>

        <div class="services-dropdown-item-wrapper" data-aos="fade-up" data-aos-delay="250">
            <services-dropdown-item  v-for="(item, index) in  data && data.tabs ? data.tabs: []" :key="index" :id="index"  :data="item" :open="showService === index" @direct="clickedChild" />
        </div>



    </div>
</template>

<script>
    import ServicesDropdownItem from "./ServicesDropdownItem";
    export default {
        props: {
            data: {
                type: Object,
                default: () => {}
            },
            title: {
                type: [String, Boolean,Object],
                default: ''
            }
        },
        data() {
            return {
                showService: null
            }
        },
        components: {
            ServicesDropdownItem
        },
        methods: {
            clickedChild(value) {
                this.showService = value
            }
        }
    }
</script>

<style scoped>

    .services-dropdown-title {
        color: #435059;
        font-family: var(--noto-bold);
        font-size: var(--normalFontSize);
        border-bottom: 1px solid #F7F7F7;
        padding-top: 30px;
        padding-left: 55px;
        padding-bottom: 30px;
    }

    .services-dropdown {
        margin-top: 20px;
        /*width: 95%;*/
    }

    @media screen and (max-width: 1023px){
        .services-dropdown-title {
            padding-left: 15px;
        }
    }


</style>

