var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"container-wrapper relative"},[_c('content-title',{attrs:{"data-aos":"fade-left","title":_vm.pageTitle}}),(!_vm.successMessage)?_c('div',{staticClass:"form",attrs:{"data-aos":"fade-right","data-aos-duration":"1200"}},[_c('div',{staticClass:"form-container"},[_c('div',{staticClass:"form-input-item",class:{
                        'input-error':
                            (!_vm.$v.newPassword.required ||
                                !_vm.$v.newPassword.minLength) &&
                            _vm.isClicked
                    }},[_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$fn.tr("New Password"))+" "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.newPassword.$model),expression:"$v.newPassword.$model"}],attrs:{"type":"password"},domProps:{"value":(_vm.$v.newPassword.$model)},on:{"keypress":function($event){return _vm.validatePassword(_vm.$v.newPassword.$model)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.newPassword, "$model", $event.target.value)}}}),(!_vm.$v.newPassword.required && _vm.isClicked)?_c('div',{staticClass:"label red"},[_vm._v(" "+_vm._s(_vm.$fn.tr("Password is required"))+" ")]):_vm._e(),(
                            !_vm.validatePassword(_vm.$v.newPassword.$model) &&
                                _vm.isClicked
                        )?_c('div',{staticClass:"label red"},[_vm._v(" "+_vm._s(_vm.$fn.tr( "Password must contain uppercase, lowercase and numbers" ))+" ")]):_vm._e(),(
                            _vm.$v.newPassword.required &&
                                !_vm.$v.newPassword.minLength &&
                                _vm.isClicked
                        )?_c('div',{staticClass:"label red"},[_vm._v(" "+_vm._s(_vm.$fn.tr("Password must be at least 8 symbols"))+" ")]):_vm._e()]),_c('div',{staticClass:"form-input-item",class:{
                        'input-error':
                            (!_vm.$v.confirmPassword.required ||
                                !_vm.$v.confirmPassword.minLength ||
                                !_vm.$v.confirmPassword.sameAsPassword) &&
                            _vm.isClicked
                    }},[_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$fn.tr("Confirm Password"))+" "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.confirmPassword.$model),expression:"$v.confirmPassword.$model"}],attrs:{"type":"password"},domProps:{"value":(_vm.$v.confirmPassword.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.confirmPassword, "$model", $event.target.value)}}}),(
                            !_vm.$v.confirmPassword.sameAsPassword &&
                                _vm.$v.confirmPassword.required &&
                                _vm.isClicked
                        )?_c('div',{staticClass:"label red"},[_vm._v(" "+_vm._s(_vm.$fn.tr("Confirm your password"))+" ")]):_vm._e(),(!_vm.$v.confirmPassword.required && _vm.isClicked)?_c('div',{staticClass:"label red"},[_vm._v(" "+_vm._s(_vm.$fn.tr("Confirm password is required"))+" ")]):_vm._e()]),_c('div',{staticClass:"form-input-buttons"},[_c('router-link',{staticClass:"form-button center-flex",attrs:{"tag":"div","to":("/" + (_vm.$store.state.main.locale) + "/home")}},[_vm._v(_vm._s(_vm.$fn.tr("CANCEL")))]),_c('div',{staticClass:"form-button center-flex",on:{"click":_vm.changePassword}},[_vm._v(" "+_vm._s(_vm.$fn.tr("Save"))+" ")])],1)])]):_c('h2',{staticClass:"message",attrs:{"data-aos":"fade","data-aos-delay":"150"}},[_vm._v(" "+_vm._s(_vm.$fn.tr("password change success message"))+" ")]),_c('img',{staticClass:"decor-img-rotated",attrs:{"loading":"lazy","src":require("../assets/img/decor.png"),"alt":""}})],1),_c('cart-info',{attrs:{"data-aos":"fade-right"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }