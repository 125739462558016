<template>
    <div>
        <div
            class="popup-cover"
            data-aos="fade-in"
            v-if="filterShow"
            @click="$emit('close-aside')"
            :class="{ active: filterShow }"
        ></div>
        <aside class="filter-aside" :class="{ filterAsideActive: filterShow }">
            <div class="filter-structure">
                <div class="content-height">
                    <div class="filter-info">
                        <div class="flex-box parts">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="44"
                                height="44"
                                viewBox="0 0 44 44"
                            >
                                <g
                                    id="Group_3818"
                                    data-name="Group 3818"
                                    transform="translate(-1670 -681)"
                                >
                                    <rect
                                        id="Rectangle_424"
                                        data-name="Rectangle 424"
                                        width="44"
                                        height="44"
                                        rx="10"
                                        transform="translate(1670 681)"
                                        fill="#f7f7f7"
                                    />
                                    <path
                                        id="Icon_material-info-outline"
                                        data-name="Icon material-info-outline"
                                        d="M14.391,21.984h2.531V14.391H14.391ZM15.656,3A12.656,12.656,0,1,0,28.313,15.656,12.661,12.661,0,0,0,15.656,3Zm0,22.781A10.125,10.125,0,1,1,25.781,15.656,10.138,10.138,0,0,1,15.656,25.781ZM14.391,11.859h2.531V9.328H14.391Z"
                                        transform="translate(1675.943 687.344)"
                                        fill="#435059"
                                    />
                                </g>
                            </svg>
                            <div class="filter-heading font-25">
                                {{ $fn.tr("Product title in part popup") }}
                                <span class="font-25 bold primary-color">{{
                                    $fn.tr("Parts")
                                }}</span>
                            </div>
                        </div>
                        <div class="close" @click="$emit('close-aside')">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15.424"
                                height="15.424"
                                viewBox="0 0 15.424 15.424"
                            >
                                <path
                                    id="Icon_metro-cancel"
                                    data-name="Icon metro-cancel"
                                    d="M22.815,9.64l-4.82,4.82-4.82-4.82-2.892,2.892,4.82,4.82-4.82,4.82,2.892,2.892,4.82-4.82,4.82,4.82,2.892-2.892-4.82-4.82,4.82-4.82Z"
                                    transform="translate(-10.283 -9.64)"
                                    fill="#81c7bd"
                                />
                            </svg>
                        </div>
                    </div>
                    <div
                        class="product-list"
                        v-for="(item, index) in data"
                        :key="index"
                    >
                        <div class="product-title font-21 bold">
                            {{ item.title }}
                        </div>
                        <div class="font-16 product-quantity" v-if="item.qty">
                            {{ $fn.tr("Quantity:") }}
                            <span class="bold">
                                {{ item.qty }}
                            </span>
                        </div>
                        <div class="font-16 product-quantity" v-if="item.code">
                            {{ $fn.tr("Product Code:") }}
                            <span class="bold">
                                {{ item.code }}
                            </span>
                        </div>
                        <!-- <router-link :to="{name: 'brands'}" tag="div" class="product-brand font-16 ">
                            {{$fn.tr('Brand')}} : <span class="bold primary-color">IKEA</span>
                        </router-link> -->
                    </div>
                </div>
            </div>
        </aside>
    </div>
</template>
<script>
export default {
    props: ["filterShow", "data"]
};
</script>

<style scoped>
.flex-box {
    display: flex;
    align-items: center;
}
.product-title {
    margin-bottom: 10px;
}
.product-quantity {
    margin-bottom: 10px;
}
.product-list {
    margin-bottom: 30px;
}
@media (max-width: 1023px) {
    .filter-aside .filter-info {
        padding-bottom: 30px;
        align-items: flex-end;
    }
}
</style>
