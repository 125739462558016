<template>
    <div class="vacancy">
        <div class="container-wrapper">
            <content-title :title="$fn.tr('Mysa Career')" data-aos="fade-right" data-aos-duration="1000"/>
        </div>
        <div class="vacancy-table relative" data-aos="fade-up">
            <div class="vacancy-table-header">
                <div class="vacancy-table-row">{{$fn.tr('Position')}}</div>
                <div class="vacancy-table-row">{{$fn.tr('Published')}}</div>
                <div class="vacancy-table-row">{{$fn.tr('Deadline')}}</div>
            </div>


            <div class="vacancy-table-content">

                <router-link v-for="(vacancy, $index) in _.get(this.$store.state.main.current_content,'primary.data.list')"
                             :to="$store.getters['main/getSingleUrl'](vacancy, 1)"
                             class="vacancy-table-content-items" data-aos="fade-up"
                             :data-aos-delay="($index + 1) * 100">
                    <h2 class="vacancy-table-row">{{vacancy.title}}</h2>
                    <div class="vacancy-table-row second">{{ moment(vacancy.published).format('DD')}} {{$fn.tr(moment(vacancy.published).format('MMMM'))}}</div>
                    <div class="vacancy-table-row third">{{moment(vacancy.deadline).format('DD')}} {{$fn.tr(moment(vacancy.deadline).format('MMMM'))}}</div>
                </router-link>
            </div>
            <img class="decor-img-rotated" loading="lazy" src="../assets/img/decor.png" alt="image">

        </div>
        <div class="vacancy-table mobile-version relative" data-aos="fade-up">

            <div class="vacancy-table-content">
               <div class="mobile-vacancy-table"  v-for="(vacancy, $index) in _.get(this.$store.state.main.current_content,'primary.data.list')"
                    :key="$index" @click="openMobileMenu($index)" :class="{'active': openedId === $index}">
                   <div class="top-info">
                       <h2 class="mobile-vacancy-table-row">{{vacancy.title}}</h2>
                       <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" :class="{'active-image-dropdown': openedId === $index}">
                           <g id="Group_3596" data-name="Group 3596" transform="translate(0 24) rotate(-90)">
                               <rect id="Rectangle_48" data-name="Rectangle 48" width="24" height="24" fill="rgba(255,0,0,0)"/>
                               <g id="Group_820" data-name="Group 820" transform="translate(5.856 2)">
                                   <path id="Path_331" data-name="Path 331" d="M213.584,780.046a1.144,1.144,0,0,0,.809-1.953l-8.047-8.047L214.393,762a1.144,1.144,0,1,0-1.618-1.618l-8.856,8.856a1.144,1.144,0,0,0,0,1.618l8.856,8.856A1.14,1.14,0,0,0,213.584,780.046Z" transform="translate(-203.584 -760.046)" fill="#435059"/>
                               </g>
                           </g>
                       </svg>
                   </div>

                   <div class="bottom-info">
                       <div class="info-item">
                           <div class="info-title">{{$fn.tr('Published')}}</div>
                           <div class="info-content">{{moment(vacancy.published).format('DD')}} {{$fn.tr(moment(vacancy.published).format('MMMM'))}}</div>
                       </div>
                       <div class="info-item">
                           <div class="info-title">{{$fn.tr('Deadline')}}</div>
                           <div class="info-content">{{moment(vacancy.deadline).format('DD')}} {{$fn.tr(moment(vacancy.deadline).format('MMMM'))}}</div>
                       </div>
                       <router-link tag="div"  :to="$store.getters['main/getSingleUrl'](vacancy, 1)" class="link-to-vacancy center-flex">
                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                               <g id="Group_3596" data-name="Group 3596" transform="translate(0 24) rotate(-90)">
                                   <rect id="Rectangle_48" data-name="Rectangle 48" width="24" height="24" fill="rgba(255,0,0,0)"/>
                                   <g id="Group_820" data-name="Group 820" transform="translate(5.856 2)">
                                       <path id="Path_331" data-name="Path 331" d="M213.584,780.046a1.144,1.144,0,0,0,.809-1.953l-8.047-8.047L214.393,762a1.144,1.144,0,1,0-1.618-1.618l-8.856,8.856a1.144,1.144,0,0,0,0,1.618l8.856,8.856A1.14,1.14,0,0,0,213.584,780.046Z" transform="translate(-203.584 -760.046)" fill="#435059"/>
                                   </g>
                               </g>
                           </svg>
                       </router-link>
                   </div>
               </div>
            </div>
        </div>
        <CartInfo data-aos="fade-up" :data="_.get(this.$store.state.main.current_content,'secondary.InnerPagesLinksBlock.data.list')" />
    </div>
</template>

<script>
    import ContentTitle from "../components/ContentTitle";
    import CartInfo from "../components/CartInfo";
    import moment from "moment"
    export default {
        name: 'vacancy-list',
        components: {
            ContentTitle,
            CartInfo
        },
        data() {
            return {
                openedId: null
            }
        },
        methods: {
            openMobileMenu(id) {
                if (this.openedId !== id)
                    this.openedId = id;
                else
                    this.openedId = null
            }
        },
        mounted() {
            this.$store.commit('main/setState', {key: "breadcrumbList", val: [{title: 'Career'}]})
        }
    }
</script>

<style scoped>
    .vacancy-table {
        max-width: 1920px;
        /*margin: 0 40px;*/
        margin: auto auto 105px;
        width: 92%;
        min-height: 350px;
    }
    .vacancy-table-header {
        background-color: var(--light-green);
        border-radius: 10px;
        display: flex;
        padding: 11px 7px;
    }
    .vacancy-table-row {
        font-size: 16px;
        font-family: var(--noto-regular);
        padding-left: 50px;
        line-height: 22px;
    }
    .vacancy-table-header .vacancy-table-row {
        color: var(--green);
        font-family: var(--noto-bold);
    }
    /*.vacancy-table-content {*/
    /*    padding: 11px;*/
    /*}*/
    .vacancy-table-content-items {
        display: flex;
        padding: 30px 0;
        cursor: pointer;
        border-bottom: 1px solid #F7F7F7;
        transition: .5s ease;
    }
    .vacancy-table-content-items.active {
        border-bottom: 1px solid #F7F7F7;
        transition: .5s ease;
    }
    .mobile-version.vacancy-table {
        display: none;
    }
    .vacancy-table-content-items:hover {
        background-color: #F7F7F7;
        border-radius: 10px;
        transition: .5s ease;
    }
    .vacancy-table-content-items:hover .vacancy-table-row{
        color: var(--green);
        transition: .2s ease;
    }
    .vacancy-table-row:nth-of-type(1) {
        width: 94%;
        padding-left: 56px;
    }
    .vacancy-table-row:nth-of-type(2), .vacancy-table-row.second {
        width: 25%;
        padding-left: 25px;
    }
    .vacancy-table-row:nth-of-type(3), .vacancy-table-row.third {
        width: 25%;
        padding-left: 0;
    }

    .decor-img-rotated{
        position: absolute;
        z-index: -1;
        left: -49%;
        top: -65%;
        transform: rotate(290deg);
    }
    .top-info {
        display: flex;
        justify-content: space-between;
        padding-bottom: 20px;
    }
    .info-content {
        font-size: 13px;
    }
    .bottom-info {
        padding-top: 20px;
        display: flex;
        justify-content: space-between;
        position: relative;
        border-top: 1px solid #FFFFFF;
    }
    /*.bottom-info:before {*/
    /*    position: absolute;*/
    /*    top: 0;*/
    /*    content: '';*/
    /*    display: block;*/
    /*    width: 100%;*/
    /*    height: 1px;*/
    /*    background-color:*/
    /*}*/
    .info-title {
        color: #435059;
        font-family: var(--noto-bold);
        font-size: 13px;
    }
    .link-to-vacancy {
        width: 34px;
        height: 34px;
        border-radius: 10px;
        background: var(--light-green);

    }
    .mobile-vacancy-table-row {
        line-height: 16px;
        font-size: 13px;
        font-family: var(--noto-regular);
        display: flex;
        align-items: flex-end;
        max-width: 80%;
    }
    .mobile-vacancy-table {
        border: 1px solid #F7F7F7;
        border-radius: 10px;
        padding: 10px 27px 20px 25px;
        max-height: 80px;
        overflow: hidden;
        margin-bottom: 20px;
        transition: .3s ease;
    }

    .info-item {
        display: flex;
        flex-direction: column;
    }
    .active-image-dropdown {
        transform: rotate(180deg);
        transition: .3s ease;
    }

    .active-image-dropdown path {
        transition: .3s ease;
        fill: var(--green);
    }

    .mobile-vacancy-table svg {
        transition: .3s ease;
        margin: auto 0;
    }

    .mobile-vacancy-table svg {
        margin-top: 15px;
    }
    .link-to-vacancy svg {
        margin-top: auto;
    }

    .mobile-vacancy-table.active {
        max-height: 400px;
        border: 1px solid var(--green);
        transition: .3s ease-in;
    }

    .mobile-vacancy-table.active .bottom-info {
        border-top-color: #F7F7F7;
    }

    .link-to-vacancy path {
        fill: var(--green);
    }

    .link-to-vacancy svg {
        transform: rotate(-90deg);
    }

    @media (max-width: 1599px) {
        .decor-img-rotated{
            left: -60%;
            top: -50%;
        }
    }
    @media (max-width: 1366px) {
        .decor-img-rotated{
            left: -66%;
            top: -50%;
        }
    }
    @media (max-width: 1199px) {
        .decor-img-rotated {
            display: none;
        }
        .decor-img-rotated {
            display: none;
        }
        .vacancy-table-row:nth-of-type(1):not(.second) {
            width: 55%;
        }
        .vacancy-table-row:nth-of-type(2),  .vacancy-table-row.second {
            padding-left: 0;
        }
        .vacancy-table-row:nth-of-type(1):not(.second) {
            padding-left: 15px;
        }
    }
    @media (max-width: 767px) {
        .vacancy-table {
            margin: auto auto 55px;
        }
        .vacancy-table-row:nth-of-type(1) {
            width: 45%;
        }
        .vacancy-table-row:nth-of-type(2) {
            width: 35%;
        }
        .vacancy-table-row:nth-of-type(1), .vacancy-table-row:not(.second) {
            padding-left: 20px;
        }
        .vacancy-table-row:nth-of-type(3) {
            width: 35%;
        }
        .vacancy-table-content-items {
            padding: 20px 7px;
        }
        .vacancy-table-row:nth-of-type(1):not(.second) {
            padding-left: 5px;
            max-height: 70px;
            overflow: hidden;
        }

    }
    @media (max-width: 540px) {
        .vacancy-table {
            display: none;
        }
        .vacancy-table.mobile-version {
            display: block;
        }
        .vacancy-table-row:nth-of-type(3) {
            text-align: right;
        }
        .vacancy {
            margin-top: 20px;
        }
        .vacancy-table-row {
            padding-left: 10px;
        }
        .vacancy-table-row.second {
            text-align: center;
        }
        .vacancy-table-row.third {
            text-align: right;
        }
        .vacancy-table-row {
            font-size: 11px;
        }
    }
</style>
