<template>
    <div class="services-dropdown-item" @click="direct(id)" :class="{'active': open}">
        <div class="services-dropdown-top">
            <h2>{{$fn.tr(data.title)}} </h2>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" :class="{'active-image-dropdown': open}">
                <g id="Group_3596" data-name="Group 3596" transform="translate(0 24) rotate(-90)">
                    <rect id="Rectangle_48" data-name="Rectangle 48" width="24" height="24" fill="rgba(255,0,0,0)"/>
                    <g id="Group_820" data-name="Group 820" transform="translate(5.856 2)">
                        <path id="Path_331" data-name="Path 331"
                              d="M213.584,780.046a1.144,1.144,0,0,0,.809-1.953l-8.047-8.047L214.393,762a1.144,1.144,0,1,0-1.618-1.618l-8.856,8.856a1.144,1.144,0,0,0,0,1.618l8.856,8.856A1.14,1.14,0,0,0,213.584,780.046Z"
                              transform="translate(-203.584 -760.046)" fill="#435059"/>
                    </g>
                </g>
            </svg>

        </div>

        <div class="services-dropdown-item-open remove-styles" :class="{'active': open}" v-html="data.content" />
    </div>
</template>

<script>
    export default {
        props: {
            open: {
                type: Boolean,
                default: false
            },
            data: {
                type: Object || Array
            },
            id: {
                type: [Number, String]
            }
        },
        data() {
            return {
                clickCount: 0
            }
        },
        methods: {
            direct(val) {
                this.$emit("direct", val);
                if (this.open) {
                    this.$emit("direct", null);
                }
            }
        }
    }
</script>

<style scoped>
    .services-dropdown-item {
        font-weight: lighter;
        padding: 30px 72px 20px 55px;
        border-bottom: 1px solid #F7F7F7;
        cursor: pointer;
        /*width: 90%;*/
        /*margin: 0 auto;*/
        max-height: 80px;
        overflow: hidden;
        transition: .5s ease;
    }

    .services-dropdown-top {
        padding-bottom: 30px;
    }

    .services-dropdown-item:hover {
        background-color: #F7F7F7;
        color: #81C7BD;
        border-radius: 10px;
        transition: .8s ease;
    }

    .services-dropdown-item:hover .services-dropdown-top h2 {
        color: #81C7BD;
        transition: .8s ease;
    }

    .services-dropdown-item:hover .services-dropdown-top path {
        fill: var(--green);
        transition: .8s ease;
    }

    .services-dropdown-item.active {
        background-color: #F7F7F7;
        color: #81C7BD;
        border-radius: 10px;
        max-height: 1000px;
        /*height: 383px;*/
        transition: .5s ease;

    }
    .services-dropdown-top h2 {
        max-width: 95%;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .services-dropdown-item.active .services-dropdown-top h2 {
        display: block;
    }

    .services-dropdown-item.active .services-dropdown-top h2 {
        color: #81C7BD;
    }

    .services-dropdown-item h2 {
        color: #435059;
        font-family: var(--noto-regular);
        font-size: var(--normalFontSize);
    }

    .services-dropdown-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .services-dropdown-top img {
        transition: .2s ease;
    }

    .services-dropdown-top .active-image-dropdown {
        transform: rotate(180deg);
        transition: .4s ease;
    }

    .active-image-dropdown path{
        fill: var(--green);
        transition: .2s ease;
    }

    .services-dropdown-top svg {
        transition: .2s ease;
        margin-bottom: auto;
    }

    .services-dropdown-item-open {
        margin-top: 28px;
        transition: .2s ease-in;
        padding-right: 25px;
        font-size: 13px;
        line-height: 22px;
        max-height: 400px;
        overflow-y: auto;
    }

    .services-dropdown-item-open.active::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 5px;
    }
    .services-dropdown-item-open.active::-webkit-scrollbar-track {
        background: #fff;
    }
    .services-dropdown-item-open.active::-webkit-scrollbar-thumb {
        background: var(--green);
        border-radius: 50px;
    }
    @media screen and (max-width: 1023px){
        .services-dropdown-item {
            padding: 30px 15px 20px 15px;
        }
        .services-dropdown-item-open {
            overflow-y: scroll;
        }
    }
    @media screen and (max-width: 767px){
        .services-dropdown-top svg {
            width: 15px;
        }
        .services-dropdown-item-open {
            overflow-y: auto;
            padding-bottom: 40px;
            margin-top: unset;
        }
    }
</style>

<style>
    .services-dropdown-item-open p {
        font-size: 13px;
        line-height: 22px;
    }
</style>
