import { render, staticRenderFns } from "./LimitedOffers.vue?vue&type=template&id=4240c138&scoped=true&"
import script from "./LimitedOffers.vue?vue&type=script&lang=js&"
export * from "./LimitedOffers.vue?vue&type=script&lang=js&"
import style0 from "./LimitedOffers.vue?vue&type=style&index=0&lang=css&"
import style1 from "./LimitedOffers.vue?vue&type=style&index=1&id=4240c138&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4240c138",
  null
  
)

export default component.exports