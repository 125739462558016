// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/img/check-circle.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".success-oder-title h1 span:after{content:\"\";width:34px;height:34px;display:inline-flex;margin-left:10px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover;background-repeat:no-repeat}.success-oder-title h1 span{display:inline-flex;align-items:center}.error-cart-title .content-title h1 span{color:#ff7675}@media screen and (max-width:767px){.checkout .account .form-button:nth-of-type(2){width:52%}.checkout .account .item-list{max-height:166px}.checkout .account .item-list .product-item-desc{margin-top:15px}.checkout .account .product-item-desc p:first-child{margin-bottom:10px}.product-item-row .close{width:34px;height:34px;margin-right:5px;margin-top:5px}}@media screen and (max-width:767px){.success-oder-title h1 span:after{width:20px;height:20px}}header #scrollHeader.transform-top-class{opacity:0!important;transition-delay:.2s;transition:.35s ease!important}", ""]);
// Exports
module.exports = exports;
