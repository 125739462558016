<template>
  <div class="main-page-router-view">
    <transition name="fade" mode="out-in">
      <component v-if="dynamicComponent" :is="dynamicComponent" :key="enableComponent" />
    </transition>
  </div>
</template>

<script>
/// !!!!!! template names here should be same as in laravel config/adminpanel.php:layouts configuration keys
////// layout templates
import Home from '../Home';
import AboutMysa from '../AboutMysa';
import B2B from '../B2B';
import Brands from '../Brands';
import Cafe from '../Cafe';
import Cart from '../Cart';
import CartCheckout from '../CartCheckout';
import ChooseCategory from '../ChooseCategory';
import Contact from '../Contact';
import MediaAndMarketing from '../MediaAndMarketing';
import MediaAndMarketingInner from '../MediaAndMarketingInner';
import News from '../News';
import NewsInner from '../NewsInner';
import Products from '../Products';
import ProductsInner from '../ProductsInner';
import SalesOffers from '../SalesOffers';
import UserRoom from '../UserRoom';
import VacancyList from '../VacancyList';
import VacancyInner from '../VacancyInner';
import WishList from '../Wishlist';
import Terms from '../Terms';
import Privacy from '../Privacy';

////// 404 page
// import notexists from "../NotExist"

import mainMixin from '@/mixins/main';
import attributesMixin from "@/mixins/attributesMixin";

let myBody = null;

export default {
  mixins: [mainMixin, attributesMixin],
  data() {
    return {
      enableComponent: false,
      animateTxt: true,
      activeTab: 1,
      aboutTabHeight: 0,
      selectedMenu: {},
      currentComponent: 'Home',

      pathEndKeywords: ['paging', 'singleview', 'productview'],
      routeParams: {},
      fullPath: '',

      innerTemplates: {
        Home: 'LayoutVersion1',
        Products: 'ProductsInner',
      },
    };
  },
  components: {
    Home,
    B2B,
    AboutMysa,
    Brands,
    Cafe,
    Cart,
    CartCheckout,
    ChooseCategory,
    Contact,
    MediaAndMarketing,
    MediaAndMarketingInner,
    News,
    NewsInner,
    Products,
    ProductsInner,
    SalesOffers,
    UserRoom,
    VacancyList,
    VacancyInner,
    WishList,
    Terms,
    Privacy,
  },
  created() {
    // this.index();
    // console.log(this.$route.params);
  },

  watch: {
    '$route.params': {
      immediate: false,
      handler: function() {
        this.index();
      },
    },

    '$store.state.main.indx': {
      immediate: true,
      handler: function() {
        if (this.selectedMenu.id) return false;
        this.index();
      },
    },
  },

  computed: {
    dynamicComponent() {
      if (this.$store.state.main.current_content_request_status == 'pending') return false;
      // console.log(this.selectedMenu, this.$store.state.current_content_request_status);
      // console.log('dynamicComponent: ',this.selectedMenu, this.fullPath, this.routeParams, 2222);

      let template = 'Home'; //'notexists';
      // if(!this.enableComponent) return template;

      if (
        _.get(this.selectedMenu, 'secondary_template') &&
        this.$options.components[this.selectedMenu.secondary_template]
      ) {
        // console.log(3333);
        template = this.selectedMenu.secondary_template;
      }

      if (
        parseInt(_.get(this.routeParams, 'singleview')) ||
        parseInt(_.get(this.routeParams, 'productview'))
      ) {
        // console.log(55555);
        template = this.getSingleViewTemplate(template); //_.get( this.innerTemplates, template);
      }
      this.$store.commit('main/setState', {
        key: 'loadedTemplate',
        val: template,
      });

      return template;
    },
  },

  methods: {
    getSingleViewTemplate(template) {
      let singleViewTemplate = _.get(this.$store.state.main.indx, [
        'contentTypes',
        _.get(this.$store.state.main.current_content, 'primary.data.list.0.content_type'),
        'settings',
        'single_template',
      ]);

      // console.log(_.get(this.$store.state.main.current_content, 'primary.data.list.0.content_type'), singleViewTemplate);
      if (!singleViewTemplate && _.get(this.innerTemplates, template))
        singleViewTemplate = _.get(this.innerTemplates, template);
      if (this.$options.components[singleViewTemplate]) template = singleViewTemplate;

      return template;
    },

    index() {
      if (this.$store.state.main.current_content_request_status == 'pending') return false;

      let self = this;
      this.enableComponent = false;
      // console.log(this.enableComponent, '--------');

      //// if not loaded main index data return false;
      if (!_.get(this.$store.state.main.indx, 'menus.0.id')) return false;

      /// set route params and path
      this.getRouteParams();
      // console.log(this.fullPath, this.routeParams);

      //// GET MENU FROM PATH !!!!!!!!!!!!!!!
      this.selectedMenu = this.getMenuFromPath();
      // console.log(this.selectedMenu.id);

      //// load homepage by default
      if (!this.selectedMenu) {
        //&& _.size(this.$route.params)==0
        this.selectedMenu = this.$store.getters['main/getFromList']({
          key: 'indx.menus',
          val: _.get(this.$store.state.main.indx, 'home_content.home_id'),
        });

        // console.log(this.selectedMenu);
        // console.log(breadCrumbs);
        // console.log(this.$store.state.menuStatus);
      }

      if (!this.selectedMenu) return false;

      let breadCrumbs = this.getBreadCrumbs(this.selectedMenu);
      this.$store.commit('main/setStateDeep', {
        key: 'breadcrumbsLeveled',
        val: {},
      });
      for (let i in breadCrumbs) {
        this.$store.commit('main/setStateDeep', {
          key: 'menuStatus.level' + (parseInt(i) + 1),
          val: breadCrumbs[i].id,
        });
        this.$store.commit('main/setStateDeep', {
          key: 'breadcrumbsLeveled.lvl' + (parseInt(i) + 1),
          val: breadCrumbs[i].id,
        });
      }

      this.redirectToEnglish(breadCrumbs);

      setTimeout(() => {
        this.addBodyClass(breadCrumbs);
      }, 0);

      // this.selectedMenu['routeParams'] = this.routeParams;
      this.$set(this.selectedMenu, 'routeParams', this.routeParams);
      // console.log(this.selectedMenu);
      this.$store.commit('main/setState', {
        key: 'selected_menu',
        val: this.selectedMenu,
      });
      this.$store.commit('main/setCurrentMenuLocalePaths');

      const params = {
        menu: this.selectedMenu,
      };

      const catIndex = this.parentCat?.findIndex(x => x.slug === this.$route.query.category);

      if (catIndex > -1) {
        params.contentId = this.parentCat?.[catIndex]?.id;
      }

      this.$store.dispatch('main/getCurrentContent', params);
      // console.log('page index: loaded current content');

      setTimeout(function() {
        self.enableComponent = true;
        // console.log(self.enableComponent);
      }, 1);

      setTimeout(() => {
        /*this.addBodyClass(breadCrumbs);*/
      }, 0);

      this.$store.commit('main/setCurrentMenuLocalePaths');
    },

    getRouteParams() {
      this.routeParams = {};
      let ready = false;
      let contentId = 0;

      for (let i in this.$route.params) {
        if (this.pathEndKeywords.indexOf(this.$route.params[i]) >= 0) {
          ready = this.$route.params[i];
          continue;
        }

        if (ready) {
          this.$set(this.routeParams, ready, parseInt(this.$route.params[i]));
          ready = false;
        }
      }

      this.fullPath = this.getPath();

      // this.$store.commit('setState', {key: 'routeParams', val: this.routeParams })
      // console.log(this.routeParams);
      // console.log(contentId);
      return false;
    },

    getView() {
      return this.$route.params.path1;
    },

    getHomePage() {
      this.selectedMenu = this.$store.getters['main/getFromList']({
        key: 'indx.menus',
        val: _.get(this.$store.state.main.indx, 'home_content.home_id'),
      });
    },

    addBodyClass(breadcrumb) {
      let myBody = document.getElementsByTagName('body')[0];
      // document.body.className = document.body.className.replace("no-javascript","");
      document.body.className = '';
      // myBody.classList.remove('');
      for (let i in breadcrumb) {
        myBody.classList.add(_.get(breadcrumb[i], 'url'));
      }
    },

    /////// very sustom method !!!!!!!!!
    /// redirect to english version
    redirectToEnglish(breadCrumbs) {
      // console.log(_.get(this.$store.state.selected_menu, ['full_url_clean']));
      if (
        _.get(breadCrumbs, [0, 'css', 0]) &&
        breadCrumbs[0].css.includes('redirect-to-english') &&
        this.$store.state.main.locale != 'en'
      ) {
        this.$router.push('/en' + _.get(this.$store.state.main.selected_menu, ['full_url_clean']));
      }
    },
  },
};
</script>

<style>
/*.fade-enter-active, .fade-leave-active {*/
/*    transition: opacity .4s;*/
/*}*/

/*.fade-enter, .fade-leave-to*/
/*{*/
/*    transition: opacity .2s;*/
/*    opacity: 0;*/
/*}*/
</style>
