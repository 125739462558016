<template>
    <div
        class="notification-default"
        :style="[warning ? { background: '#CC3300' } : '']"
        :class="{ copied: active }"
    >
        <slot></slot>
    </div>
</template>

<script>
import { Notification } from "element-ui";
export default {
    watch: {
        active: {
            handler(val) {
                if (val)
                    setTimeout(() => {
                        this.hideNot();
                    }, 2500);
            }
        }
    },
    props: ["active", "warning"],
    methods: {
        hideNot() {
            this.$emit("hide");
        }
    }
};
</script>

<style>
.notification-default {
    position: fixed !important;
    z-index: 999;
    right: 5%;
    bottom: 5%;
    /* background: #44dc44; */
    background: #81c7bd;
    padding: 13px;
    border-radius: 10px;
    color: white;
    opacity: 0;
    visibility: hidden;
    transition: 0.6s ease;
}
.notification-default.copied {
    transition: 0.6s ease;
    opacity: 1;
    visibility: visible;
}
</style>
