export default () => ({
    cartList: [],
    wishList: [],
    cartPopupIsOpen: false,
    mobileCartPopupIsOpen: false,
    isDesktopScrollPosition: true,
    shippingPrice: 0,
    shippingDays: "",
    orderData: null,
    uploadedFile: null,
    showCartError: { value: false, id: null },
    loadCartList: false
});
