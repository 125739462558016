<template>
    <section>
        <div class="container-wrapper relative mb-144">
            <img class="bottom-decor-img" loading="lazy" src="../assets/img/decor.png" alt="">
            <PageTitle class="heading-margin" data-aos="fade-up" :colored-title="true" :title="$store.state.main.selected_menu.title" />

            <LightGallery
                :images="getOtherImages"
                :index="index"
                :disable-scroll="true"
                @close="index = null"
            />

            <div class="flex justify-between columns">
                <div class="left-content">
                    <div class="mb-60 main-img" data-aos="fade-up" data-aos-delay="200">
                        <picture>
                            <source
                                media="(max-width: 767px)"
                                loading="lazy"
                                :srcset="(`${_.get($store.state.main.current_content, 'primary.data.list[0].images[0].devices.mobile')}`)"
                            />
                            <source
                                media="(max-width: 1023px)"
                                loading="lazy"
                                :srcset="(`${_.get($store.state.main.current_content, 'primary.data.list[0].images[0].devices.tablet')}`)"
                            />
                            <img
                                :src="(`${_.get($store.state.main.current_content, 'primary.data.list[0].images[0].devices.desktop')}`)"
                                loading="lazy"
                                alt
                            />
                        </picture>
                        <div class="new-card-date font-13 bold" v-html="moment(_.get($store.state.main.current_content, 'primary.data.list[0].date')).format('DD MMM, YYYY')"></div>
                    </div>
                    <h1 class="bold font-21 mb-20 lh-28" data-aos="fade-up" data-aos-delay="400" v-html="_.get($store.state.main.current_content, 'primary.data.list[0].title')"></h1>
                    <div class="regular line-height mb-55 remove-styles" data-aos="fade-up" data-aos-delay="400" v-html="_.get($store.state.main.current_content, 'primary.data.list[0].content.tabs[0].content')"></div>

                    <div class="gallery" data-aos="fade-up" data-aos-delay="400" v-if="getOtherImages.length > 1">
                        <div class="gallery-title mb-20 bold font-21">
                            {{$fn.tr('Gallery')}}
                        </div>
                        <swiper class="usefulToolsSlider" ref="usefulToolsSlider" :options="swiperOptions" data-aos="fade-up">
                            <swiper-slide v-for="(thumb, thumbIndex) in getOtherImages" :key="thumbIndex">
                                <div @click="photoIndex(thumbIndex)" class="image-wrapper">
                                    <div class="image-wrapper-hover"></div>
                                    <img class="cross" src="../assets/icons/cross.svg" loading="lazy" alt="cross">
                                    <picture>
                                        <source
                                            media="(max-width: 767px)"
                                            :srcset="(`${thumb.devices.mobile}`)"
                                        />
                                        <source
                                            media="(max-width: 1023px)"
                                            :srcset="(`${thumb.devices.tablet}`)"
                                        />
                                        <img
                                            loading="lazy"
                                            class="wrapper-img"
                                            :src="(`${thumb.devices.desktop}`)"
                                            alt
                                        />
                                    </picture>
                                </div>
                            </swiper-slide>
                        </swiper>
                    </div>
                    <div class="flex justify-between">
                        <div class="b2b-partners" data-aos="fade-up">
                            <div @click="passData()" class="font-13 bold partners-text">{{$fn.tr('Share News')}}
                                <svg xmlns="http://www.w3.org/2000/svg" width="14.458" height="16" viewBox="0 0 14.458 16">
                                    <path id="Icon_material-share" data-name="Icon material-share" d="M16.548,14.309a2.339,2.339,0,0,0-1.574.618L9.247,11.594a2.223,2.223,0,0,0,0-1.124l5.663-3.3a2.4,2.4,0,1,0-.771-1.759,2.629,2.629,0,0,0,.072.562l-5.663,3.3a2.41,2.41,0,1,0,0,3.518l5.719,3.341a2.266,2.266,0,0,0-.064.522,2.345,2.345,0,1,0,2.345-2.345Z" transform="translate(-4.5 -3)" fill="#81c7bd"/>
                                </svg>
                            </div>
                        </div>
                        <router-link class="b2b-partners" data-aos="fade-up" :to="$store.state.main.selected_menu.full_url" tag="div">
                            <div class="font-13 bold back-text">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="13.714" viewBox="0 0 16 13.714">
                                    <path id="Icon_metro-arrow-up" data-name="Icon metro-arrow-up" d="M13.38,6.049,7.665.335a1.143,1.143,0,0,0-1.616,0L.335,6.049A1.143,1.143,0,0,0,1.951,7.665L5.714,3.9V14.857a1.143,1.143,0,0,0,2.286,0V3.9l3.763,3.763A1.143,1.143,0,0,0,13.38,6.049Z" transform="translate(0 13.714) rotate(-90)" fill="#81c7bd"/>
                                </svg>
                                {{$fn.tr('Back')}}
                            </div>
                        </router-link>
                    </div>
                </div>
                <div class="right-content">
                    <h3 class="light font-34 mb-30" data-aos="fade-up" data-aos-delay="200">{{$fn.tr('Other News')}}</h3>

                    <div class="innerPage">
                        <router-link class="innerTablet" v-for="(item, index) in getOtherNews.slice(0, 2)" :key="index" :to="$store.getters['main/getSingleUrl'](item, 1)">
                            <NewsCard class="space-between-cards" :cardData="item" data-aos="fade-up" data-aos-delay="400" />
                        </router-link>
                    </div>

                </div>
            </div>
        </div>

        <ShareModal @closeModal="shareModal= false" :shareData="shareData" :pageURL="getURL" :modalActive="shareModal" />

        <CartInfo  />
    </section>
</template>

<script>
import PageTitle from "../components/PageTitle"
import CartInfo from "../components/CartInfo"
import NewsCard from "../components/LatestNews"
import moment from "moment"
import ShareModal from "../components/ShareModal"
import _ from "lodash"

export default {
    components: {
        PageTitle,
        CartInfo,
        NewsCard,
        ShareModal,
    },
    data(){
        return{
            swiperOptions: {
                speed: 800,
                watchOverflow: true,
                breakpoints: {
                    320: {
                        spaceBetween: 20,
                        slidesPerView: 1,
                    },
                    450: {
                        spaceBetween: 20,
                        slidesPerView: 2,
                    },
                    560: {
                        spaceBetween: 20,
                        slidesPerView: 3,
                    },
                    768: {
                        spaceBetween: 20,
                        slidesPerView: 3,
                    },
                    1024: {
                        spaceBetween: 30,
                        slidesPerView: 3,
                    },
                    1366: {
                        spaceBetween: 30,
                        slidesPerView: 4,
                    },
                },
            },
            index: null,
            shareModal: false
        }
    },

    computed: {
        getOtherNews() {
            const otherNews = _.get(this.$store.state.main.current_content, 'secondary.OtherNewsBlock.data.list')

            const result = otherNews.filter(news => news.id != _.get(this.$store.state.main.current_content, 'primary.data.list[0].id'))

            return result
        },
        getOtherImages(){
            const otherImages = _.get(this.$store.state.main.current_content, 'primary.data.list[0].images')

            const imagesResult = otherImages.filter(images => images.id != _.get(this.$store.state.main.current_content, 'primary.data.list[0].images[0].id'))

            return imagesResult
        },
        getURL(){
            const pageURL = window.location.href

            return pageURL
        },
        shareData(){
            const data = _.get(this.$store.state.main.current_content, 'primary.data.list[0].title')

            return data
        }
    },
    methods: {
        passData(){
            this.shareData
            this.shareModal = true
        },
        photoIndex(thumbIndex){
            this.index = thumbIndex
        },
    },

    mounted() {
         this.$store.commit('main/setState', {key: "breadcrumbList", val: [{title: 'News', url: `/${this.$store.state.main.locale}/news` }]})
    }
}
</script>
<style>
    .innerPage .news-card-img{
        padding-top: 217px;
    }
    .innerPage .new-card-date {
        top: 20px;
        left: 20px;
    }
    .wrapper-img{
        height: 100%;
        width: 100%;
    }
    .innerPageStyles p{
        font-size: 16px;
    }
    .innerPageStyles ul li{
        list-style: initial;
    }
    .innerPageStyles ul{
        padding-left: 20px;
    }
    .innerPageStyles ol li{
        list-style: inherit;
    }
    .innerPageStyles ol{
        padding-left: 20px;
    }
    @media (max-width: 1919px){
        .innerPage .news-card-img{
            padding-top: 183.42px;
        }
    }
    @media (max-width: 1599px){
        .innerPage .news-card-img{
            padding-top: 183.42px;
        }
    }
    @media (max-width: 1365px){
        .innerPage .news-card-img{
            padding-top: 183.42px;
        }
    }
    @media (max-width: 1023px){
        .innerPage .news-card-img{
            padding-top: 183.42px;
        }
    }
    @media (max-width: 767px){
        .innerPage .news-card-img{
            padding-top: 183.42px;
        }
    }
    .heading-margin .content-title{
        margin: 0;
    }
</style>
<style scoped>
    .lh-28{
        line-height: 28px;
    }
    .mb-144{
        margin-bottom: 144px;
    }
    .space-between-cards{
        margin-bottom: 30px;
    }
    .left-content{
        width: 66.2%;
    }
    .right-content{
        width: 23.5%;
    }
    .new-card-date{
        position: absolute;
        top: 30px;
        left: 30px;
        background: #FFFFFF;
        border-radius: 10px;
        padding: 13px 13px;
        width: auto;
        min-width: 108px;
        color: #435059;
    }
    .mb-60{
        margin-bottom: 60px;
    }
    .mb-30{
        margin-bottom: 30px;
    }
    .mb-55{
        margin-bottom: 55px;
    }
    .mb-20{
        margin-bottom: 20px;
    }
    .main-img{
        position: relative;
        /* padding-top: 610px; */
        padding-top: 56%;
        border-radius: 30px;
        overflow: hidden;
    }
    .main-img img{
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
    .gallery{
        padding-bottom: 45px;
        margin-bottom: 45px;
        border-bottom: 1px solid #F7F7F7;
    }
    .image-wrapper {
        padding-top: 138px;
        position: relative;
        display: flex;
        cursor: pointer;
    }
    .image-wrapper img {
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        object-fit: cover;
        height: 100%;
        border-radius: 10px;
    }
    .image-wrapper-hover {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        border-radius: 10px;
    }
    .image-wrapper .cross{
        height: auto;
        z-index: 2;
        width: unset;
        position: absolute;
        top: 50%;
        left: 50%;
        opacity: 0;
        visibility: hidden;
        transform: translate(-50%, -50%);
        transition: .5s ease;
    }
    .image-wrapper picture{
        width: 100%;
    }
    .image-wrapper img{
        width: 100%;
    }
    .image-wrapper:hover .image-wrapper-hover{
        background-color: #81C7BD;
        background-repeat: no-repeat;
        background-position: left;
        background-size: cover;
        opacity: .7;
        transition: .5s ease;
    }
    .image-wrapper:hover .cross {
        opacity: 1;
        visibility: visible;
        transition: .5s ease;
    }
    .partners-text{
        display: flex;
        align-items: center;
    }
    .partners-text svg{
        margin-left: 10px;
    }
    .back-text{
        display: flex;
        align-items: center;
    }
    .back-text svg{
        margin-right: 10px;
    }
    @media (max-width: 1919px){
        .left-content{
            width: 66%;
        }
        /* .main-img{
            padding-top: 520px;
        } */
        .mb-60{
            margin-bottom: 55px;
        }
        .image-wrapper {
            padding-top: 115px;
        }
    }
    @media (max-width: 1599px){
        .right-content{
            width: 25.5%;
        }
        /* .main-img{
            padding-top: 460px;
        } */
        .mb-144{
            margin-bottom: 90px;
        }
        .image-wrapper {
            padding-top: 100px;
        }
    }

    @media (max-width: 1365px){
        .left-content{
            width: 65.5%
        }
        .right-content{
            width: 25.9%;
        }
        .news-card-img{
            padding-top: 140px;
        }
        .innerPage .new-card-date {
            top: 10px;
            left: 10px;
        }
        .main-img{
            /* padding-top: 350px; */
            margin-bottom: 35px;
        }
    }
    @media (max-width: 1023px){
        .columns{
            flex-direction: column;
        }
        .left-content{
            width: 100%;
        }
        .right-content{
            margin-top: 55px;
            width: 100%;
        }
        .innerPage{
            display: flex;
            justify-content: space-between;
        }
        .innerTablet{
            width: calc(50% - 30px);
        }
        .news-card{
            width: 100%;
        }
        .mb-144{
            margin-bottom: 55px;
        }
        .image-wrapper {
            padding-top: 120px;
        }
    }
    @media (max-width: 767px){
        .main-img{
            /* padding-top: 155px; */
            border-radius: 10px;
            margin-bottom: 23px;
        }
        .innerPage{
            flex-direction: column;
        }
        .image-wrapper {
            padding-top: 156px;
        }
        .innerTablet{
            width: 100%;
        }
        .news-card{
            width: 100%;
        }
        .gallery{
            margin-bottom: 35px;
        }
        .new-card-date{
            top: 10px;
            left: 10px;
            padding: 5px 10px;
            height: 28px;
        }
    }

</style>
