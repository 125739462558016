<template>
  <div
    class="filter-list-content"
    v-if="_.get(selectedIds, `${[_.get(filterData, '[0].attribute')]}`)"
  >
    <div
      class="filter-list-title bold font-16 uppercase"
      :class="{ green: show }"
      @click="acordion"
    >
      {{ $fn.tr(_.get(filterData, '[0].attribute')) }}
      <span :class="{ arrow_active: show }">
        <svg xmlns="http://www.w3.org/2000/svg" width="11.144" height="20" viewBox="0 0 11.144 20">
          <path
            id="Path_331"
            data-name="Path 331"
            d="M213.584,780.046a1.144,1.144,0,0,0,.809-1.953l-8.047-8.047L214.393,762a1.144,1.144,0,1,0-1.618-1.618l-8.856,8.856a1.144,1.144,0,0,0,0,1.618l8.856,8.856A1.14,1.14,0,0,0,213.584,780.046Z"
            transform="translate(-203.584 -760.046)"
            fill="#435059"
          />
        </svg>
      </span>
    </div>
    <slide-up-down
      class="option-list"
      :active="show"
      :duration="500"
      v-for="(item, index) in filterData"
      :key="index"
    >
      <li
        @click="check(item)"
        v-if="_.includes(_.get(selectedIds, `${[_.get(filterData, '[0].attribute')]}`), item.id)"
      >
        <div
          class="label regular font-16"
          :class="{
            active: $store.state.main.filterIds.includes(item.id),
          }"
        >
          {{ item.title }}
        </div>
        <div class="radio">
          <div
            :class="{
              checked: $store.state.main.filterIds.includes(item.id),
            }"
          ></div>
        </div>
      </li>
    </slide-up-down>
  </div>
</template>

<script>
export default {
  props: ['filterData', 'data', 'selectedIds'],

  data() {
    return {
      show: false,
      filterIds: [],
    };
  },
  watch: {
    '$store.state.main.filterIds': {
      handler(val) {
        if (!val.length) {
          this.show = false;
        }
      },
    },
  },
  methods: {
    acordion() {
      this.show = !this.show;
    },
    check(item) {
      let filterIdsState = [
        ...this.$store.state.main.filterIds,
        ...this.$store.state.main.childFilterIds,
      ];

      if (filterIdsState.includes(item.id)) {
        let index = filterIdsState.findIndex((r) => r === item.id);

        filterIdsState.splice(index, 1);
      } else {
        filterIdsState.push(item.id);
      }
      this.$store.commit('main/setState', {
        key: 'filterIds',
        val: filterIdsState,
      });
    },
  },
};
</script>

<style scoped>
.label {
  position: relative;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
  width: 100%;
  text-transform: uppercase;
}
.label.active {
  position: relative;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #fec55e;
  width: 100%;
}
.uppercase {
  text-transform: uppercase;
}
.radio {
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.checked {
  width: 13px;
  height: 14px;
  background: #fec55e;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.checked {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.arrow_active {
  transform: rotate(90deg) !important;
  transition: 0.5s;
}
.arrow_active path {
  fill: #81c7bd;
}
.filter-list-content li {
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.filter-aside .filter-list {
  margin-bottom: 30px;
}
.filter-aside .filter-list .green {
  color: #81c7bd !important;
  transition: 0.5s;
}
.filter-aside .filter-list:first-child .filter-list-title {
  margin-top: 0;
}
.filter-aside .filter-list .filter-list-title {
  display: flex;
  justify-content: space-between;
  color: #435059;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 30px;
  transition: 0.5s;
}
.option-list li label {
  margin-top: 20px;
}
.filter-aside .filter-list .filter-list-title span {
  width: 20px;
  height: 20px;
  transform: rotate(-90deg);
  transition: 0.5s;
}
.filter-aside .filter-list .filter-list-title svg {
  width: 20px;
}
</style>
