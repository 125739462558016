var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"slideup"}},[_c('section',{staticClass:"product-list",attrs:{"data-aos":"fade-up","data-aos-delay":"300"}},[_c('div',{staticClass:"background",class:{ block: _vm.filterShow },on:{"click":function($event){_vm.filterShow = false}}}),_c('div',{staticClass:"filter-content"},[_c('div',{staticClass:"filter-content-tabs"},[_c('div',[_c('div',{staticClass:"taeb-switch left text-center",class:{ clicked: _vm.clickedCategory }},[_c('div',{staticClass:"taeb",class:{ categoryActive: _vm.categoryActive },on:{"click":_vm.categorySwitch}},[_vm._v(" "+_vm._s(_vm.$fn.tr("Category"))+" ")]),_c('div',{staticClass:"taeb",class:{ brandActive: _vm.brandActive },on:{"click":_vm.brandSwitch}},[_vm._v(" "+_vm._s(_vm.$fn.tr("Brand"))+" ")])])])]),_c('div',{staticClass:"filter-controls"},[_c('div',{staticClass:"filter-buttons"},[_c('button',{staticClass:"af768 font-13 bold",class:{
                            active: _vm.$store.state.main.filterIds.length
                        },on:{"click":function($event){_vm.filterShow = true}}},[_vm._v(" "+_vm._s(_vm.$fn.tr("other filters"))+" ")]),_c('button',{staticClass:"b768 font-13 bold",on:{"click":function($event){_vm.filterShow = true}}},[_vm._v(" "+_vm._s(_vm.$fn.tr("filters"))+" ")])]),_c('aside',{staticClass:"filter-aside",class:{ filterAsideActive: _vm.filterShow }},[_c('div',{staticClass:"filter-structure"},[_c('div',{staticClass:"content-height"},[_c('div',{staticClass:"filter-info"},[_c('page-title',{staticClass:"light",attrs:{"font":true,"colored-title":true,"title":_vm.$fn.tr('other filters')}}),_c('div',{staticClass:"close",on:{"click":function($event){_vm.filterShow = false}}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"15.424","height":"15.424","viewBox":"0 0 15.424 15.424"}},[_c('path',{attrs:{"id":"Icon_metro-cancel","data-name":"Icon metro-cancel","d":"M22.815,9.64l-4.82,4.82-4.82-4.82-2.892,2.892,4.82,4.82-4.82,4.82,2.892,2.892,4.82-4.82,4.82,4.82,2.892-2.892-4.82-4.82,4.82-4.82Z","transform":"translate(-10.283 -9.64)","fill":"#81c7bd"}})])])],1)]),_c('div',{staticClass:"filter-options"},_vm._l((_vm.getAttributes),function(item,index){return _c('div',{key:item.id,staticClass:"filter-list"},[_c('FilterAcordion',{attrs:{"selectedIds":_vm.selectedFilterIds,"filterData":item}})],1)}),0),_c('button',{staticClass:"apply-changes",on:{"click":function($event){return _vm.filterChanges()}}},[_vm._v(" "+_vm._s(_vm.$fn.tr("APPLY FILTER"))+" ")]),_c('button',{staticClass:"apply-changes clear-button",class:{
                                show: _vm.$store.state.main.filterIds.length
                            },on:{"click":_vm.clearFilters}},[_vm._v(" "+_vm._s(_vm.$fn.tr("Clear Filter"))+" ")])])]),_c('div',{staticClass:"sort-inputs"},[_c('SortSelect',{ref:"Sort",attrs:{"dropdown":_vm._.get(_vm.$store.state.main, 'indx.Sort.dropdown'),"label":'Sort',"isRequired":true,"clicked":_vm.isClicked},on:{"sortSelect":_vm.sortSelect}})],1)])]),_c('div',{staticClass:"desctop-filter"},[_c('div',{staticClass:"filtered-list test2",class:{ 'out-anim': _vm.AnimActive },attrs:{"id":"category"}},[(_vm.selectedTab === 'category')?_c('ul',{attrs:{"data-aos":"fade-in"}},_vm._l((_vm.parentCat),function(item,index){return _c('li',{key:index,staticClass:"filtered-list-hover",on:{"click":function($event){return _vm.selectSwitch(item)}}},[_vm._v(" "+_vm._s(item.title)+" ")])}),0):_vm._e()]),_c('div',{staticClass:"filtered-list test",class:{ 'out-anim': _vm.AnimActive },attrs:{"id":"brandFade"}},[(_vm.selectedTab === 'brand')?_c('ul',{attrs:{"data-aos":"fade-in"}},_vm._l((_vm._.get(
                            _vm.$store.state.main.indx,
                            'attributes.brands'
                        )),function(item,index){return _c('li',{key:index,staticClass:"filtered-list-hover",on:{"click":function($event){return _vm.selectSwitchBrand(item)}}},[_vm._v(" "+_vm._s(item.title)+" ")])}),0):_vm._e()]),(_vm.selectedTab === _vm.selected)?_c('div',{staticClass:"selected-category relative",class:{ 'out-anim': _vm.AnimActive },attrs:{"data-aos":"fade-in"}},[_c('div',{staticClass:"filter-list-item-selected"},[_vm._v(" "+_vm._s(_vm.filteredTitle)+" "),_c('span',{on:{"click":_vm.switchTab}},[_vm._v("+")])]),_c('ul',_vm._l((_vm.childCategories),function(item,index){return _c('li',{key:index,class:{
                            'selected-tag': _vm.arrOfId.includes(item.id)
                        },on:{"click":function($event){return _vm.selectChild(item)}}},[_vm._v(" "+_vm._s(item.title)+" "),_c('span',[_vm._v("+")])])}),0)]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }