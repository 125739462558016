<template>
    <section class="custom-container-wrapper limited-offers">
        <div class="offer-heading max-width responsive">
            <PageTitle :title="_.get(data, 'conf.header.title')"  />
            <p>{{_.get(data, 'conf.header.teaser')}}</p>
            <router-link tag="a" :to="`/${$store.state.main.indx.locale}/salesandoffers`">
                {{$fn.tr('View More')}}
            </router-link>
        </div>
        <div class="flex justify-between desktop">
            <div class="limited-offers-content">
                <div class="offer-heading subscribe">
                    <PageTitle :title="_.get(data, 'conf.header.title')"  />
                    <p>{{_.get(data, 'conf.header.teaser')}}</p>
                    <router-link tag="a" :to="`/${$store.state.main.indx.locale}/salesandoffers`">
                        {{$fn.tr('View More')}}
                    </router-link>
                </div>
                <div class="offer-card">
                    <div class="discount-card-cotnent">
                        <div class="flex align-center">
                            <div class="offer-dot"></div> <p v-html="_.get(data, 'data.list[0].title')">Last call</p>
                        </div>
                        <div class="offer-details">
                            <div class="offer-details-content">
                                <div class="offer-details-title">{{_.get(data, 'data.list[0].banner_title')}}<br /><span>{{_.get(data, 'data.list[0].banner_teaser')}}</span></div>
                                <a :href="_.get(data, 'data.list[0].url')">
                                   {{ _.get(data, 'data.list[0].url_text') }}
                                </a>
                            </div>
                            <div class="card-img">
                                <picture>
                                    <source
                                        loading="lazy"
                                        media="(max-width: 767px)"
                                        :srcset="(`${_.get(data, 'data.list[0].banner[0].devices.mobile')}`)"
                                    />
                                    <source
                                        media="(max-width: 1023px)"
                                        loading="lazy"
                                        :srcset="(`${_.get(data, 'data.list[0].banner[0].devices.tablet')}`)"
                                    />
                                    <img
                                        class="img"
                                        loading="lazy"
                                        :src="(`${_.get(data, 'data.list[0].banner[0].devices.desktop')}`)"
                                        alt="image"
                                    />
                                </picture>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="limited-offers-content">
                <div class="discount-card">
                    <div class="discount-card-cotnent">
                        <div class="flex align-center">
                            <div class="discount-dot"></div> <p v-html="_.get(data, 'data.list[1].title')">Last call</p>
                        </div>
                        <div class="discount-details">
                            <div class="discount-details-content">
                                <div class="discount-details-title">{{_.get(data, 'data.list[1].banner_title')}}<br /><span>{{_.get(data, 'data.list[1].banner_teaser')}}</span></div>
                                <a :href="_.get(data, 'data.list[1].url')">
                                   {{ _.get(data, 'data.list[1].url_text') }}
                                </a>
                            </div>
                            <div class="card-img">
                                <picture>
                                    <source
                                        media="(max-width: 767px)"
                                        loading="lazy"
                                        :srcset="(`${_.get(data, 'data.list[1].banner[0].devices.mobile')}`)"
                                    />
                                    <source
                                        media="(max-width: 1023px)"
                                        loading="lazy"
                                        :srcset="(`${_.get(data, 'data.list[1].banner[0].devices.tablet')}`)"
                                    />
                                    <img
                                        class="img"
                                        loading="lazy"
                                        :src="(`${_.get(data, 'data.list[1].banner[0].devices.desktop')}`)"
                                        alt="image"
                                    />
                                </picture>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="!isSubscribed" class="cart-card-component subscribe">
                    <h3 class="third-heading">{{$fn.tr('Subscribe Newsletter')}}</h3>
                    <p>{{$fn.tr('It is a long established fact that a reader will be distracted')}}</p>
                    <div class="relative"
                            :class="{ 'input-error': (!$v.subscribeEmail.required || !$v.subscribeEmail.minLength || !$v.subscribeEmail.email || resError.length ) && isClicked }">
                        <input type="text"
                                v-model="$v.subscribeEmail.$model"
                                :placeholder="$fn.tr('YOUR EMAIL')">
                        <div class="label red" v-if="!$v.subscribeEmail.email && isClicked">
                            {{$fn.tr('Email is not formatted correctly')}}
                        </div>
                        <div class="label red" v-if="resError.length && isClicked">
                            {{resError}}
                        </div>
                        <span @click="subscribe">{{$fn.tr('subscribe')}}</span>
                    </div>
                </div>
                <div v-else class="cart-card-component subscribe">
                    <h3 class="third-heading">
                        {{$fn.tr('Thank You')}}
                    </h3>
                    <div class="footer-item-content">
                        <p>{{$fn.tr('Newsletter is subscribed on your mail')}}</p>
                        <div class="footer-newsletter subscribed-newsletter">
                            <div @click="isSubscribed = false" class="other-email"> {{$fn.tr('use other email')}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <swiper class="mobile" ref="mySwiper" :options="swiperOptions" >
            <swiper-slide>
                <div class="offer-card">
                    <div class="discount-card-cotnent">
                        <div class="flex align-center">
                            <div class="offer-dot"></div><p v-html="_.get(data, 'data.list[0].title')">Last call</p>
                        </div>
                        <div class="offer-details">
                            <div class="offer-details-content">
                                <div class="offer-details-title">{{_.get(data, 'data.list[0].banner_title')}}<br /><span>{{_.get(data, 'data.list[0].banner_teaser')}}</span></div>
                                <a :href="_.get(data, 'data.list[0].url')">
                                   {{ _.get(data, 'data.list[0].url_text') }}
                                </a>
                            </div>
                            <div class="card-img">
                                <picture>
                                    <source
                                        media="(max-width: 767px)"
                                        loading="lazy"
                                        :srcset="(`${_.get(data, 'data.list[0].banner[0].devices.mobile')}`)"
                                    />
                                    <source
                                        media="(max-width: 1023px)"
                                        loading="lazy"
                                        :srcset="(`${_.get(data, 'data.list[0].banner[0].devices.tablet')}`)"
                                    />
                                    <img

                                        class="img"
                                        loading="lazy"
                                        :src="(`${_.get(data, 'data.list[0].banner[0].devices.desktop')}`)"
                                        alt="image"
                                    />
                                </picture>
                            </div>
                        </div>
                    </div>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="discount-card">
                    <div class="discount-card-cotnent">
                        <div class="flex align-center">
                            <div class="discount-dot"></div> <p v-html="_.get(data, 'data.list[1].title')">Last call</p>
                        </div>
                        <div class="discount-details">
                            <div class="discount-details-content">
                                <div class="discount-details-title">{{_.get(data, 'data.list[1].banner_title')}}<br /><span>{{_.get(data, 'data.list[1].banner_teaser')}}</span></div>
                                <a :href="_.get(data, 'data.list[1].url')">
                                   {{ _.get(data, 'data.list[1].url_text') }}
                                </a>
                            </div>
                            <div class="card-img">
                                <picture>
                                    <source
                                        media="(max-width: 767px)"
                                        loading="lazy"
                                        :srcset="(`${_.get(data, 'data.list[1].banner[0].devices.mobile')}`)"
                                    />
                                    <source
                                        media="(max-width: 1023px)"
                                        loading="lazy"
                                        :srcset="(`${_.get(data, 'data.list[1].banner[0].devices.tablet')}`)"
                                    />
                                    <img
                                        class="img"
                                        loading="lazy"
                                        :src="(`${_.get(data, 'data.list[1].banner[0].devices.desktop')}`)"
                                        alt="image"
                                    />
                                </picture>
                            </div>
                        </div>
                    </div>
                </div>
            </swiper-slide>
        </swiper>
        <div style="padding-right:20px">
            <div v-if="!isSubscribed" class="cart-card-component subscribe-responsive ">
                <h3 class="third-heading">{{$fn.tr('Subscribe Newsletter')}}</h3>
                <p>{{$fn.tr('It is a long established fact that a reader will be distracted')}}</p>
                <div class="relative"
                        :class="{ 'input-error': (!$v.subscribeEmail.required || !$v.subscribeEmail.minLength || !$v.subscribeEmail.email || resError.length ) && isClicked }">
                    <input type="text"
                            v-model="$v.subscribeEmail.$model"
                            :placeholder="$fn.tr('YOUR EMAIL')">
                    <div class="label red" v-if="!$v.subscribeEmail.email && isClicked">
                        {{$fn.tr('Email is not formatted correctly')}}
                    </div>
                    <div class="label red" v-if="resError.length && isClicked">
                        {{resError}}
                    </div>
                    <span @click="subscribe">{{$fn.tr('subscribe')}}</span>
                </div>
            </div>
            <div v-else class="cart-card-component subscribe-responsive">
                <h3 class="third-heading">
                    {{$fn.tr('Thank You')}}
                </h3>
                <div class="footer-item-content">
                    <p>{{$fn.tr('Newsletter is subscribed on your mail')}}</p>
                    <div class="footer-newsletter subscribed-newsletter">
                        <div @click="isSubscribed = false" class="other-email"> {{$fn.tr('use other email')}}</div>
                    </div>
                </div>
            </div>
        </div>

    </section>
</template>

<script>
import PageTitle from "../components/PageTitle";
import {validationMixin} from "vuelidate";
import {email, minLength, required} from "vuelidate/lib/validators";
export default {
    mixins: [validationMixin],
    props: ['data'],
    components: {
        PageTitle
    },
    data(){
        return{
            subscribeEmail: '',
            resError: '',
            isClicked: false,
            isSubscribed: false,
            swiperOptions: {
                speed: 400,
				watchOverflow: true,
                slidesPerGroup: 2,
    			breakpoints: {
                    320: {
                        spaceBetween: 15,
                        slidesOffsetAfter: 20,
			            slidesPerView: 1.15,
                    },
    			}
            },
        }
    },
    validations: {
        subscribeEmail: {
            required,
            minLength: minLength(3),
            email
        },
    },
    watch: {
        isSubscribed(val) {
            if (!val) {
                this.subscribeEmail = ''
                this.isClicked = false
            }
        }
    },
    methods: {
        subscribe() {
            this.isClicked = true;
            if(!this.$v.subscribeEmail.required || !this.$v.subscribeEmail.minLength || !this.$v.subscribeEmail.email) {
                return
            }
            this.$store.dispatch('user/subscribeEmail',{email: this.subscribeEmail, formType: 'Subscribers'})
                .then(res=> {
                    if (res.status === 200 && res.data.error) {
                        this.resError = res.data.error
                    }
                    else if (res.status === 200 ) {
                        this.isSubscribed = true
                    }
                })
                .catch((err) => {
                    console.log(err, "error")
                })
        }
    }
}

</script>

<style>
.offer-heading .content-title{
    margin: 0;
}
</style>

<style scoped>
    .cart-card-component .label.red {
        color: #FF7675;
        position: absolute;
        bottom: -20px;
        font-size: 10px;
    }
    .other-email {
        font-family: var(--noto-bold);
        font-size: 13px;
        text-decoration: underline;
        cursor: pointer;
    }
    .none{
        display: none;
    }
    .other-email{
        font-family: var(--noto-bold);
        font-size: 13px;
        text-decoration: underline;
        cursor: pointer;
        text-transform: capitalize;
    }
    .relative{
        position: relative;
    }
    .flex{
        display: flex;
    }
    .align-center{
        align-items: center;
    }
    .justify-between{
        justify-content: space-between;
    }
    .justify-around{
        justify-content: space-around;
    }
    .subscribe-responsive{
        display: none;
    }
    .responsive{
        display: none;
    }
    .limited-offers{
        margin-top: 234px;
        padding: 0 185px;
    }
    .limited-offers-content{
        width: calc(50% - 15px);
        flex-direction: column;
        display: flex;
        justify-content: space-between;
    }
    .limited-offers-content .offer-heading{
        display: flex;
        flex-direction: column;
    }
    .offer-heading{
        margin: 45px 0;
    }
    .offer-heading{
        font-size: 55px;
        color: #435059;
        font-weight: lighter;
    }
    .offer-heading  span{
        font-size: 55px;
        color: #81C7BD;
        font-weight: bold;
    }
    .offer-heading p{
        font-size: 16px;
        color: #435059;
        margin: 10px 0 31px 0;
    }
    .offer-heading a{
        font-size: 13px;
        color: #435059;
        font-weight: bold;
        text-transform: uppercase;
        text-decoration: underline;
    }
    .offer-card{
        border-radius: 30px;
        background: #FFEECE;
        height: 665px;
        padding: 50px 0 50px 50px;
    }
    .discount-card{
        border-radius: 30px;
        background: #D9EEEB;
        height: 665px;
        padding: 50px 0 50px 50px;
    }
    .discount-card-cotnent{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .offer-dot{
        margin-right: 10px;
        width: 10px;
        height: 10px;
        background: #81C7BD;
        border-radius: 50%;
    }
    .discount-dot{
        margin-right: 10px;
        width: 10px;
        height: 10px;
        background: #FEC55E;
        border-radius: 50%;
    }
    .offer-details{
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }
    .offer-details .offer-details-title{
        font-size: 46px;
        color: #435059;
        font-weight: lighter;
        margin-bottom: 60px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
    .offer-details span{
        font-size: 46px;
        color: #FEC55E;
        font-weight: bold;
    }
    .offer-details a{
        font-size: 13px;
        color: #435059;
        font-weight: bold;
        text-transform: uppercase;
        text-decoration: underline;
    }
    .discount-details{
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }
    .discount-details .discount-details-title{
        font-size: 46px;
        color: #435059;
        font-weight: lighter;
        margin-bottom: 60px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        /* overflow: hidden; */
    }
    .discount-details span{
        font-size: 46px;
        color: #81C7BD;
        font-weight: bold;
    }
    .discount-details a{
        font-size: 13px;
        color: #435059;
        font-weight: bold;
        text-transform: uppercase;
        text-decoration: underline;
    }
    .card-img{
        position: relative;
        width: 50%;
        padding-top: 70%;
        margin-right: 30px;
    }
    .card-img img{
        width: 100%;
        top: 0;
        height: 100%;
        left: 0;
        position: absolute;
        object-fit: contain;
    }
    .cart-card-component{
        padding: 40px 40px 34px;
        background: #ffffff;
        width: 100%;
        border: 1px solid #D9EEEB;
        border-radius: 30px;
        /* height: 100%; */
        margin-top: 30px;
    }
    .cart-card-component h3{
        color: #435059;
        font-size: 34px;
    }
    .cart-card-component p{
        /* margin: 10px 0 30px 0; */
        margin: 17px 0 30px 0;
        color: #435059;
        font-size: 16px;
    }
    .cart-card-component input{
        width: 100%;
        border: none;
        border-bottom: 1px solid #ECEDEE;
        padding-bottom: 5px;
        color: #435059;
        font-size: 13px;
        font-weight: bold;
    }
    .cart-card-component input::placeholder{
        font-size: 13px;
        font-weight: bold;
        opacity: 0.3;
    }
    .cart-card-component span{
        position: absolute;
        right: 0;
        top: -2px;
        color: #81C7BD;
        font-size: 13px;
        font-weight: bold;
        cursor: pointer;
        background: white;
        padding: 5px;
        text-transform: uppercase;
    }
    .offer-details-content{
        width: 50%;
        position: relative;
        z-index: 1;
    }
    .discount-details-content{
        width: 50%;
        position: relative;
        z-index: 1;
    }
    .mobile{
        display: none;
    }
    @media (max-width: 1919px){
        .limited-offers{
            margin-top: 200px;
            padding: 0 140px;
        }
        .offer-card{
            height: 566px;
        }
        .discount-card{
            height: 566px;
            padding: 40px 0 40px 40px;
        }
    }
    @media (max-width: 1599px){
        .offer-card{
            height: 606px;
        }
        .discount-card{
            height: 606px;
        }
        .limited-offers{
            margin-top: 150px;
            padding: 0px 7px;
        }
    }
    @media (max-width: 1365px){
        .limited-offers{
            margin-top: 100px;
            padding: 0 36px;
        }
        .offer-card{
            padding: 50px 0 50px 50px ;
        }
        .discount-card{
            padding: 50px 0 50px 50px ;
        }
        .offer-details .offer-details-title{
            font-size: 34px;
            margin-bottom: 35px;
        }
        .offer-details .offer-details-title span{
            font-size: 34px;
        }
        .discount-details .discount-details-title{
            font-size: 34px;
            margin-bottom: 35px;
        }
        .discount-details .discount-details-title span{
            font-size: 34px;
        }
        .offer-details{
            height: 100%;
        }
        .discount-details{
            height: 100%;
        }
        .limited-offers{
            padding: 0 35px;
            flex-direction: column;
        }
        .offer-heading{
            margin: 0;
            margin-bottom: 33px;
        }
        .offer-heading p{
            margin: 20px 0 31px 0;
        }
        .cart-card-component{
            padding: 52px 40px 40px;
        }
        .offer-card{
            height: 462px;
        }
        .discount-card{
            height: 462px;
        }
        .subscribe-responsive{
            display: block;
            margin-top: 30px;
        }
        .responsive{
            display: flex;
            flex-direction: column;
        }
        .max-width{
            max-width: 526px;
        }
        .subscribe{
            display: none !important;
        }
        .card-img{
            margin-right: 15px;
        }
    }
    @media (max-width: 1023px){
        .offer-card{
            height: 336px;
            padding: 30px 0 30px 30px;
        }
        .discount-card{
            height: 336px;
            padding: 30px 0 30px 30px;
        }
        .offer-heading p{
            margin: 20px 0 35px 0;
        }
        .card-img{
            margin-right: 10px;
        }
    }

    @media (max-width: 767px){
        .offer-details .offer-details-title{
            margin-bottom: 5px;
        }
        .discount-details .discount-details-title{
            margin-bottom: 5px;
        }
        .cart-card-component h3{
            font-size: 21px;
        }
        .cart-card-component {
            padding: 30px;
        }
        .cart-card-component p {
            margin: 15px 0 30px 0;
            font-size: 15px;
        }
        .offer-details {
            margin-top: 15px;
        }
        .discount-details{
            margin-top: 15px;
        }
        .desktop{
            display: none;
        }
        .mobile{
            display: block;
        }
        .limited-offers{
            padding: 35px 0 0 20px;
            margin-top: 55px;
            border-top: 1px solid #F7F7F7;
            flex-direction: column;
        }
        .offer-card{
            height: 100%;
        }
        .discount-card{
            height: 100%;
        }
        .offer-details .offer-details-title{
            font-size: 21px;
        }
        .offer-details .offer-details-title span{
            font-size: 21px;
        }
        .discount-details .discount-details-title{
            font-size: 21px;
        }
        .discount-details .discount-details-title span{
            font-size: 21px;
        }
    }
</style>
