<template>
    <div class="flex align-center justify-center">
        <div
            class="prev pagination"
            @click="prevPage"
            v-if="$store.state.main.currenPageProducts !== 1"
        >
            <svg
                id="Group_820"
                data-name="Group 820"
                xmlns="http://www.w3.org/2000/svg"
                width="11.144"
                height="20"
                viewBox="0 0 11.144 20"
            >
                <path
                    id="Path_331"
                    data-name="Path 331"
                    d="M213.584,780.046a1.144,1.144,0,0,0,.809-1.953l-8.047-8.047L214.393,762a1.144,1.144,0,1,0-1.618-1.618l-8.856,8.856a1.144,1.144,0,0,0,0,1.618l8.856,8.856A1.14,1.14,0,0,0,213.584,780.046Z"
                    transform="translate(-203.584 -760.046)"
                    fill="#435059"
                />
            </svg>
        </div>
        <ul>
            <li
                class="pagination-button"
                :class="
                    $store.state.main.currenPageProducts === 1 ? 'active' : ''
                "
                @click="setCurrent(1)"
                v-if="rangeStart >= 4"
            >
                1
            </li>
            <span class="dots" v-if="rangeStart >= 3">...</span>
            <li
                v-for="pageNumber in pages"
                :key="pageNumber"
                :class="
                    $store.state.main.currenPageProducts === pageNumber
                        ? 'active'
                        : ''
                "
                @click="setCurrent(pageNumber)"
            >
                {{ pageNumber }}
            </li>
            <span class="dots total-dots" v-if="showLastPage">...</span>
            <li
                v-if="showLastPage"
                class="last-page"
                @click="setCurrent(totalPages)"
            >
                {{ totalPages }}
            </li>
        </ul>
        <div
            class="next pagination"
            v-if="$store.state.main.currenPageProducts < totalPages"
            @click="nextPage"
        >
            <svg
                id="Group_820"
                data-name="Group 820"
                xmlns="http://www.w3.org/2000/svg"
                width="11.144"
                height="20"
                viewBox="0 0 11.144 20"
            >
                <path
                    id="Path_331"
                    data-name="Path 331"
                    d="M213.584,780.046a1.144,1.144,0,0,0,.809-1.953l-8.047-8.047L214.393,762a1.144,1.144,0,1,0-1.618-1.618l-8.856,8.856a1.144,1.144,0,0,0,0,1.618l8.856,8.856A1.14,1.14,0,0,0,213.584,780.046Z"
                    transform="translate(-203.584 -760.046)"
                    fill="#435059"
                />
            </svg>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        dataLength: {
            type: Number
        },

        pageRange: {
            type: Number,
            default: 1
        },

        perPage: {
            type: Number
        }
    },

    computed: {
        pages: function() {
            let pages = [];

            for (var i = this.rangeStart; i <= this.rangeEnd; i++)
                pages.push(i);

            return pages;
        },

        rangeStart: function() {
            let start =
                this.$store.state.main.currenPageProducts - this.pageRange;

            return start > 0 ? start : 1;
        },

        rangeEnd: function() {
            let end =
                this.$store.state.main.currenPageProducts + this.pageRange;

            return end < this.totalPages ? end : this.totalPages;
        },

        totalPages() {
            return Math.ceil(this.dataLength / this.perPage);
        },
        showLastPage() {
            return this.totalPages >= this.pages[0] + this.pages.length;
        }
    },

    methods: {
        toParent() {
            this.$emit(
                "set-current",
                this.$store.state.main.currenPageProducts
            );
        },

        setCurrent(current) {
            this.$store.commit("main/setState", {
                key: "currenPageProducts",
                val: current
            });
            // this.$router.push({ query: { page: current } }).catch(() => {});
            window.scrollTo({
                top: document.getElementById("header").offsetTop,
                behavior: "smooth"
            });
            this.toParent();
        },

        prevPage() {
            let curr_page = this.$store.state.main.currenPageProducts;
            curr_page--;
        this.$store.commit("main/setState", {
            key: "currenPageProducts",
            val: curr_page
        });
            this.setCurrent(curr_page);
            this.toParent();
        },

        nextPage() {
            let curr_page = this.$store.state.main.currenPageProducts;
            curr_page++;
            this.$store.commit("main/setState", {
                key: "currenPageProducts",
                val: curr_page
            });
            this.setCurrent(curr_page);
            this.toParent();
        }
    },
    mounted() {
        this.$store.commit("main/setState", {
            key: "currenPageProducts",
            val: this.$route.query.page ? parseInt(this.$route.query.page) : 1
        });
    }
};
</script>

<style scoped>
.justify-center {
    justify-content: center;
}
.pagination {
    width: 44px;
    height: 44px;
    border-radius: 10px;
    background: #f7f7f7;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
ul {
    display: flex;
    margin: 0 40px;
}
ul li {
    margin-right: 25px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
}
ul li:hover {
    color: #81c7bd;
}
ul li.active {
    color: #81c7bd;
}
ul li:last-child {
    margin-right: 0;
}
.next svg {
    transform: rotate(180deg);
}
.pagination-button {
    margin-right: 10px;
}
.pagination-button .last-page {
    padding-left: 5px;
}
.dots {
    margin-right: 10px;
}
.total-dots {
    margin-left: -10px;
}
@media (max-width: 767px) {
    .pagination {
        width: 40px;
        height: 40px;
    }
    ul li {
        margin-right: 20px;
    }
    ul {
        margin: 0 25px;
    }
}
</style>
