function apiUrlGenerator (){
    const url = window.location.origin+'/source/api/view/';

    // console.log(process.env, window.location);
    if(process.env.NODE_ENV === 'production')return url;
    const samples = {
        apiUrl: "https://mysa.connect.ge/source/api/view/",
    }
    return samples.apiUrl;
}


export default {

    apiUrl: apiUrlGenerator(),
    websiteUrl: window.location.origin,

}
