<template>
    <div class="breadcrumb-items" data-aos="fade" data-aos-delay="300">
        <router-link
            :to="`/${this.$store.state.main.locale}/home`"
            tag="div"
            class="breadcrumb-item"
        >
            {{ $fn.tr("Mysa") }}
        </router-link>
        <template v-for="(item, index) in $store.state.main.breadcrumbList">
            <router-link
                v-if="$store.state.main.breadcrumbList.length"
                :key="index"
                :to="item.url ? item.url : ''"
                tag="div"
                class="breadcrumb-item"
            >
                {{ item.title_din ? item.title_din : $fn.tr(item.title) }}
            </router-link>
        </template>
    </div>
</template>

<script>
export default {};
</script>

<style scoped>
.breadcrumb-items {
    margin: 0 55px 0;
    /* margin: 0 55px 27px; */
    display: flex;
    align-items: center;
    position: relative;
    /*padding-top: 30px;*/
    /*padding-bottom: 46px;*/
}

.breadcrumb-item:not(:last-child):after {
    position: absolute;
    right: -15px;
    top: 7px;
    content: "";
    display: block;
    width: 5px;
    height: 5px;
    background-color: #d9eeeb;
    border-radius: 50%;
}

.breadcrumb-item {
    position: relative;
    cursor: pointer;
    font-size: 13px;
    margin-bottom: 20px;
    font-family: var(--noto-regular);
}

.breadcrumb-item {
    margin-right: 25px;
    transition: 0.4s ease;
}
.breadcrumb-item.router-link-active {
    color: #81c7bd;
}
.breadcrumb-item:hover {
    color: #81c7bd;
    transition: 0.4s ease;
}
@media screen and (max-width: 1365px) {
    .breadcrumb-items {
        margin: 0 5px 23px;
    }
}
@media screen and (max-width: 1023px) {
    .breadcrumb-items {
        margin: 0 23px 5px;
        border-bottom: unset;
    }
}
@media screen and (max-width: 767px) {
    .breadcrumb-items {
        margin: 0px 10px 5px;
        border-bottom: unset;
    }
    .breadcrumb-item {
        margin-bottom: 0;
    }
}
</style>
