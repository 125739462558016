<template>
    <div>
        <div class="multi-slider">
            <!-- relative -->
            <div class="vertical-next swiper-button-next"></div>
            <div class="vertical-prev swiper-button-prev"></div>

            <swiper ref="mySwiper" class="swiper-view" :options="swiperOptions">
                <swiper-slide
                    class="margin-bottom"
                    v-for="(item, index) in sliderData"
                    :key="index"
                >
                    <VerticalProductCard :sliderData="item" />
                </swiper-slide>
            </swiper>
        </div>
    </div>
</template>

<script>
import VerticalProductCard from "./VerticalProductCard";

export default {
    props: ["sliderData"],
    components: {
        VerticalProductCard
    },

    data() {
        return {
            swiperOptions: {
                speed: 400,
                watchOverflow: true,
                navigation: {
                    nextEl: ".vertical-next",
                    prevEl: ".vertical-prev"
                },
                slidesPerGroup: 2,
                breakpoints: {
                    320: {
                        spaceBetween: 15,
                        slidesPerView: 1.17,
                        slidesPerColumn: 1,
                        slidesPerGroup: 1,
                        slidesOffsetAfter: 20
                    },
                    768: {
                        spaceBetween: 15,
                        slidesPerView: 1.54,
                        slidesPerColumn: 2
                    },
                    1024: {
                        spaceBetween: 15,
                        slidesPerView: 2,
                        slidesPerColumn: 2,
                        slidesPerGroup: 2
                    },
                    1366: {
                        spaceBetween: 30,
                        slidesPerView: 2,
                        slidesPerColumn: 2
                    },
                    1600: {
                        spaceBetween: 15,
                        slidesPerView: 3,
                        slidesPerColumn: 2
                    },
                    1920: {
                        spaceBetween: 30,
                        slidesPerView: 3,
                        slidesPerColumn: 2
                    }
                }
            }
        };
    }
};
</script>

<style>
.multi-slider .swiper-button-prev {
    background: #f7f7f7;
    color: #435059;
    border-radius: 10px;
    width: 44px;
    height: 44px;
    left: -74px;
    top: 50%;
}
.multi-slider .swiper-button-next {
    background: #f7f7f7;
    color: #435059;
    border-radius: 10px;
    width: 44px;
    height: 44px;
    right: -74px;
    top: 50%;
    left: auto;
}
.multi-slider .swiper-button-next::after {
    font-size: 22px;
}
.multi-slider .swiper-button-prev::after {
    font-size: 22px;
}
.multi-slider .swiper-container {
    margin: 0;
}
.multi-slider .swiper-slide img {
    width: 100%;
    height: 100%;
}
@media (max-width: 1919px) {
    .multi-slider .swiper-button-next {
        right: -62px;
        top: 47%;
    }
    .multi-slider .swiper-button-prev {
        top: 47%;
    }
}
@media (max-width: 1599px) {
    .multi-slider .swiper-button-next {
        right: -40px;
        top: 61%;
        width: 34px;
        height: 34px;
    }
    .multi-slider .swiper-button-prev {
        top: 61%;
        width: 34px;
        height: 34px;
        left: -40px;
    }
}
@media (max-width: 1365px) {
    .multi-slider .swiper-button-next {
        /* right: 10px;
        top: -97px;
        width: 44px;
        height: 44px; */
        right: 0;
        top: 0;
        width: 44px;
        height: 44px;
        transform: translate(-40px, 25px);
    }
    .multi-slider .swiper-button-prev {
        right: 0;
        left: auto;
        top: 0;
        width: 44px;
        height: 44px;
        transform: translate(-100px, 25px);
    }
}
@media (max-width: 1023px) {
    .multi-slider .swiper-button-next {
        /* right: 33px; */
        /* top: -97px; */
        width: 44px;
        height: 44px;
    }
    .multi-slider .swiper-button-prev {
        /* right: 97px; */
        left: auto;
        /* top: -97px; */
        width: 44px;
        height: 44px;
    }
}
@media (max-width: 767px) {
    .multi-slider .swiper-button-next {
        right: -20px;
        width: 33px;
        height: 33px;
        /* top: -66px; */
    }
    .multi-slider .swiper-button-prev {
        right: -40px;
        width: 33px;
        height: 33px;
        /* top: -67px; */
    }
}
</style>
<style scoped>
.margin-bottom {
    margin-bottom: 30px;
}
@media (max-width: 1919px) {
    .margin-bottom {
        margin-bottom: 45px;
    }
}
@media (max-width: 1599px) {
    .margin-bottom {
        /* margin-bottom: 0; */
    }
}
@media (max-width: 1365px) {
    .margin-bottom {
        margin-bottom: 35px;
    }
}
@media (max-width: 1023px) {
    .margin-bottom {
        margin-bottom: 55px;
    }
}
</style>
