<template>
    <transition name="slideup">
        <section class="product-list" data-aos="fade-up" data-aos-delay="300">
            <div
                class="background"
                @click="filterShow = false"
                :class="{ block: filterShow }"
            ></div>
            <div class="filter-content">
                <div class="filter-content-tabs">
                    <div>
                        <div
                            class="taeb-switch left text-center"
                            :class="{ clicked: clickedCategory }"
                        >
                            <div
                                class="taeb"
                                :class="{ categoryActive: categoryActive }"
                                @click="categorySwitch"
                            >
                                {{ $fn.tr("Category") }}
                            </div>
                            <div
                                class="taeb"
                                :class="{ brandActive: brandActive }"
                                @click="brandSwitch"
                            >
                                {{ $fn.tr("Brand") }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="filter-controls">
                    <div class="filter-buttons">
                        <button
                            :class="{
                                active: $store.state.main.filterIds.length
                            }"
                            class="af768 font-13 bold"
                            @click="filterShow = true"
                        >
                            {{ $fn.tr("other filters") }}
                        </button>
                        <button
                            class="b768 font-13 bold"
                            @click="filterShow = true"
                        >
                            {{ $fn.tr("filters") }}
                        </button>
                    </div>
                    <aside
                        class="filter-aside"
                        :class="{ filterAsideActive: filterShow }"
                    >
                        <div class="filter-structure">
                            <div class="content-height">
                                <div class="filter-info">
                                    <page-title
                                        class="light"
                                        :font="true"
                                        :colored-title="true"
                                        :title="$fn.tr('other filters')"
                                    />
                                    <div
                                        class="close"
                                        @click="filterShow = false"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="15.424"
                                            height="15.424"
                                            viewBox="0 0 15.424 15.424"
                                        >
                                            <path
                                                id="Icon_metro-cancel"
                                                data-name="Icon metro-cancel"
                                                d="M22.815,9.64l-4.82,4.82-4.82-4.82-2.892,2.892,4.82,4.82-4.82,4.82,2.892,2.892,4.82-4.82,4.82,4.82,2.892-2.892-4.82-4.82,4.82-4.82Z"
                                                transform="translate(-10.283 -9.64)"
                                                fill="#81c7bd"
                                            />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div class="filter-options">
                                <div
                                    class="filter-list"
                                    v-for="(item, index) in getAttributes"
                                    :key="item.id"
                                >
                                    <FilterAcordion
                                        :selectedIds="selectedFilterIds"
                                        :filterData="item"
                                    />
                                </div>
                            </div>
                            <button
                                class="apply-changes"
                                @click="filterChanges()"
                            >
                                {{ $fn.tr("APPLY FILTER") }}
                            </button>
                            <button
                                :class="{
                                    show: $store.state.main.filterIds.length
                                }"
                                class="apply-changes clear-button"
                                @click="clearFilters"
                            >
                                {{ $fn.tr("Clear Filter") }}
                            </button>
                        </div>
                    </aside>

                    <div class="sort-inputs">
                        <SortSelect
                            ref="Sort"
                            :dropdown="
                                _.get($store.state.main, 'indx.Sort.dropdown')
                            "
                            :label="'Sort'"
                            :isRequired="true"
                            @sortSelect="sortSelect"
                            :clicked="isClicked"
                        />
                    </div>
                </div>
            </div>
            <div class="desctop-filter">
                <div
                    class="filtered-list test2"
                    id="category"
                    :class="{ 'out-anim': AnimActive }"
                >
                    <!-- data-aos="fade-up" data-aos-delay="250" -->
                    <ul
                        class=""
                        data-aos="fade-in"
                        v-if="selectedTab === 'category'"
                    >
                        <li
                            class="filtered-list-hover"
                            v-for="(item, index) in parentCat"
                            :key="index"
                            @click="selectSwitch(item)"
                        >
                            <!-- data-aos="fade" -->
                            {{ item.title }}
                        </li>
                    </ul>
                </div>
                <div
                    class="filtered-list test"
                    id="brandFade"
                    :class="{ 'out-anim': AnimActive }"
                >
                    <ul data-aos="fade-in" v-if="selectedTab === 'brand'">
                        <li
                            class="filtered-list-hover"
                            v-for="(item, index) in _.get(
                                $store.state.main.indx,
                                'attributes.brands'
                            )"
                            :key="index"
                            @click="selectSwitchBrand(item)"
                        >
                            {{ item.title }}
                        </li>
                    </ul>
                </div>
                <div
                    class="selected-category relative"
                    data-aos="fade-in"
                    :class="{ 'out-anim': AnimActive }"
                    v-if="selectedTab === selected"
                >
                    <div class="filter-list-item-selected">
                        {{ filteredTitle }}
                        <span @click="switchTab">+</span>
                    </div>
                    <ul>
                        <li
                            v-for="(item, index) in childCategories"
                            :key="index"
                            :class="{
                                'selected-tag': arrOfId.includes(item.id)
                            }"
                            @click="selectChild(item)"
                        >
                            {{ item.title }} <span>+</span>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    </transition>
</template>

<script>
import FilterAcordion from "./FilterAcordionComponent";
import PageTitle from "./PageTitle";
import SortSelect from "./SortSelect";
import attributesMixin from "../mixins/attributesMixin";

export default {
    components: {
        FilterAcordion,
        PageTitle,
        SortSelect
    },
    props: ["productList"],
    mixins: [attributesMixin],
    data() {
        return {
            categoryId: null,
            categorySlug: null,
            subcategorySlug: null,
            isClicked: false,
            clickedCategory: false,
            categoryActive: true,
            brandActive: false,
            list: [
                {
                    id: 1,
                    city: "Relevance"
                },
                {
                    id: 2,
                    city: "Price: Low to High"
                },
                {
                    id: 3,
                    city: "Price: High to Low"
                },
                {
                    id: 4,
                    city: "Newest Arrivals"
                },
                {
                    id: 5,
                    city: "Best Selling"
                }
            ],
            value: "",
            active: false,
            category: false,
            brand: false,
            filterShow: false,
            selectedTab: "category",
            selected: null,
            AnimActive: false,
            watchData: null,
            filteredTitle: "",
            activeID: null,
            filterIds: [],
            arrOfId: [],
            filterIdsState: [],
            sortId: ""
        };
    },
    watch: {
        "$store.state.main.indx": {
            imediate: true,
            handler(val) {
                if (val) {
                    this.attributesData = val;
                }
            }
        },
        watchData: {
            immediate: true,

            handler(val) {
                if (val) {
                    this.attributesData = val;
                }
            }
        },
        "$route.query.category": {
            immediate: true,
            handler(val) {
                this.subcategorySlug =
                    this.$route.query?.category
                        ?.split(",")
                        ?.slice(1)
                        .join(",") || null;

                const catIndex = this.parentCat?.findIndex(
                    cat => cat.slug === val?.split(",")?.[0]
                );

                if (val && catIndex > -1) {
                    let category = this.parentCat?.[catIndex];
                    this.selectSwitch(category, true);
                }
            }
        },
        "$route.query.brandId": {
            immediate: true,
            handler(val) {
                if (val) {
                    let brand = _.get(
                        this.$store.state.main.indx,
                        "attributes.brands"
                    ).find(res => res.id === parseInt(val));
                    this.brandSwitch();
                    this.selectSwitchBrand(brand);
                }
            }
        },
        childCategories: {
            immediate: true,
            handler(val) {
                this.arrOfId = val
                    ?.filter(cat =>
                        this.$route?.query?.category?.includes(cat.slug)
                    )
                    ?.map(cat => cat?.id);

                this.clearFilters();
                this.$store.state.main.first_load = false;
            }
        }
    },

    computed: {
        getAttributes() {
            let stockArray = [
                {
                    id: "stockExist",
                    title: this.$fn.tr("მარაგშია"),
                    attribute: "inStock"
                }
            ];

            return {
                ..._.get(this.$store.state.main.indx, "attributes"),
                stock: stockArray
            };
        },
        selectedFilterIds() {
            let filteredProducAttributes = [];
            let producAttributes = _.get(
                this.$store.state.main.categoryProducts,
                "primary.data"
            );
            if (
                !_.get(this.$store.state.main.categoryProducts, "primary.data")
            ) {
                producAttributes = _.get(
                    this.$store.state.main,
                    "current_content.primary.data"
                );
            }
            if (producAttributes)
                producAttributes.map(attr =>
                    filteredProducAttributes.push(attr.attributes)
                );

            let atrributeNames = {};

            //loop to store key names in attributeNames array
            filteredProducAttributes.map(attr2 => {
                Object.entries(attr2).map(([key, value]) => {
                    atrributeNames[key] = [];
                });
            });
            //loop to set same values for same keys  in attributeNames array
            filteredProducAttributes.map(item => {
                Object.entries(item).map(([key, value]) => {
                    atrributeNames[key].push(item[key][0]);
                });
            });
            //loop to store  unique values  in attributeNames array
            Object.entries(atrributeNames).map(([key, value]) => {
                atrributeNames[key] = [...new Set(atrributeNames[key])];
            });

            for (const attrprop of Object.getOwnPropertyNames(atrributeNames)) {
                if (this.brandActive) {
                    delete atrributeNames["brands"];
                } else {
                    delete atrributeNames["products_category"];
                }

                if (
                    atrributeNames[attrprop] &&
                    !atrributeNames[attrprop].length
                ) {
                    delete atrributeNames[attrprop];
                }
            }
            //   atrributeNames.inStock = [{ id: 999, title: 'მარაგშია', attributes: 'მარაგშია' }];
            atrributeNames.inStock = ["stockExist"];
            return atrributeNames;
        },

        childCategories() {
            if (
                !_.get(
                    this.$store.state.main.indx,
                    "attributes.products_category"
                )
            )
                return false;

            let arr = _.get(
                this.$store.state.main.indx,
                "attributes.products_category"
            );

            let cat = [];
            let childCat = [];

            for (let i in arr) {
                if (arr[i].pid == 0) {
                    cat.push(arr[i]);
                }
            }

            let res = arr.filter(r => r.pid === this.watchData?.id);
            childCat = res;

            return childCat;
        }
    },

    methods: {
        clearCategoryAndBrandFilters() {
            this.categoryActive = false;
            this.clickedCategory = false;
            this.brandActive = false;
            this.selectedTab = "category";
            this.activeID = null;
            this.$emit("clearFilter");
        },
        sortSelect(id) {
            this.sortId = id;
            this.$store.commit("main/setState", {
                key: "sortId",
                val: id
            });
            if (id === 0) {
                this.filterAction([this.categoryId, ...this.arrOfId], true, []);
                return;
            }

            this.filterAction([this.categoryId, ...this.arrOfId], true, [id]);
        },
        async filterChanges() {
            this.filterIdsState = this.categoryId
                ? [...this.$store.state.main.filterIds, this.categoryId]
                : [...this.$store.state.main.filterIds];
            let resIds = [...new Set(this.filterIdsState)];
            await this.filterAction(resIds, false);
            this.$emit("changeFilter");
            this.filterShow = false;
        },
        async clearFilters() {
            // cahnges
            this.$router
                .push({
                    query: {
                        ...this.$route.query,
                        category:
                            this.categorySlug +
                            (this.subcategorySlug
                                ? `,${this.subcategorySlug}`
                                : "")
                    }
                })
                .catch(() => {});
            setTimeout(() => {
                window.history.pushState(
                    {},
                    null,
                    "/" +
                        this.$store.state.main.locale +
                        "/products?category=" +
                        this.categorySlug +
                        (this.subcategorySlug ? `,${this.subcategorySlug}` : "")
                );
            }, 300);
            // changes

            // window.history.pushState(
            //     {},
            //     null,
            //     "/" +
            //         this.$store.state.main.locale +
            //         "/products?category=" +
            //         this.categorySlug +
            //         (this.subcategorySlug ? `,${this.subcategorySlug}` : "")
            // );
            this.$store.commit("main/setState", {
                key: "currenPageProducts",
                val: 1
            });
            this.$store.commit("main/setState", {
                key: "filterIds",
                val: []
            });
            if (
                !this.filterIdsState.length &&
                !this.$store.state.main.first_load
            ) {
                return;
            }
            if (this.$store.state.main.first_load) {
                setTimeout(async () => {
                    await this.filterAction([this.categoryId, ...this.arrOfId]);
                    this.filterIdsState = [];
                    this.filterShow = false;
                }, 500);
            } else {
                await this.filterAction([this.categoryId, ...this.arrOfId]);
                this.filterIdsState = [];
                this.filterShow = false;
            }
        },
        inputShow(e) {
            this.active = !this.active;
        },
        async selectChild(item) {
            this.clearFilters();

            const index = this.arrOfId.indexOf(item.id);

            if (index > -1) {
                this.arrOfId.splice(index, 1);

                const subcategorySlugs = this.$route?.query?.category
                    ?.split(",")
                    ?.slice(1);

                const subcategoryIndex = subcategorySlugs?.findIndex(
                    slug => slug === item.slug
                );

                if (subcategoryIndex > -1) {
                    subcategorySlugs.splice(subcategoryIndex, 1);

                    this.subcategorySlug = subcategorySlugs?.join(",");

                    this.$router.push({
                        query: {
                            ...this.$route.query,
                            category:
                                this.categorySlug +
                                (this.subcategorySlug
                                    ? `,${this.subcategorySlug}`
                                    : "")
                        }
                    });

                    setTimeout(() => {
                        window.history.pushState(
                            {},
                            null,
                            "/" +
                                this.$store.state.main.locale +
                                "/products?category=" +
                                this.categorySlug +
                                (this.subcategorySlug
                                    ? `,${this.subcategorySlug}`
                                    : "")
                        );
                    }, 500);
                }
            } else {
                this.arrOfId.push(item.id);

                const subcategorySlugs =
                    this.$route?.query?.category?.split(",")?.slice(1) || [];

                subcategorySlugs.push(item.slug);

                this.subcategorySlug = subcategorySlugs?.join(",");

                this.$router.push({
                    query: {
                        ...this.$route.query,
                        category:
                            this.categorySlug +
                            (this.subcategorySlug
                                ? `,${this.subcategorySlug}`
                                : "")
                    }
                });

                setTimeout(() => {
                    window.history.pushState(
                        {},
                        null,
                        "/" +
                            this.$store.state.main.locale +
                            "/products?category=" +
                            this.categorySlug +
                            (this.subcategorySlug
                                ? `,${this.subcategorySlug}`
                                : "")
                    );
                }, 500);
            }

            this.$store.commit("main/setState", {
                key: "childFilterIds",
                val: this.arrOfId
            });
            this.$store.commit("main/setState", {
                key: "currenPageProducts",
                val: 1
            });
            await this.filterAction([this.categoryId, ...this.arrOfId]);

            this.$emit("changeCategoryChild");
        },
        categorySwitch() {
            this.clearFilters();
            (this.AnimActive = true),
                (this.categoryActive = true),
                (this.clickedCategory = false),
                (this.brandActive = false),
                this.$emit("clearFilter");
            this.arrOfId = [];
            setTimeout(() => {
                this.AnimActive = false;
                this.selectedTab = "category";
            }, 400);
            if (this.selectedTab === "category") {
                this.AnimActive = false;
            }
        },
        selectSwitch(item, change = false) {
            this.categoryId = item?.id || "";
            this.categorySlug = item?.slug || "";
            this.clearFilters();
            !change
                ? this.$emit("changeCategory", { id: item.id, slug: item.slug })
                : "";
            this.AnimActive = true;
            this.filteredTitle = item?.title;
            this.watchData = item;
            this.$store.commit("main/setState", {
                key: "filterIds",
                val: [item?.id]
            });

            setTimeout(() => {
                this.AnimActive = false;
                this.selectedTab = this.selected;
            }, 400);

            if (this.selectedTab === this.selected) {
                this.AnimActive = false;
                this.categoryId = null;
            }
        },
        selectSwitchBrand(item) {
            this.clearFilters();
            this.$store.commit("main/setState", {
                key: "filterIds",
                val: [item?.id]
            });
            this.$emit("changeBrand", item.id);
            this.AnimActive = true;
            this.filteredTitle = item?.title;
            this.watchData = item;

            setTimeout(() => {
                this.AnimActive = false;
                this.selectedTab = this.selected;
            }, 400);

            if (this.selectedTab === this.selected) {
                this.AnimActive = false;
            }
        },
        brandSwitch() {
            this.clearFilters();
            this.$emit("clearFilter");
            (this.AnimActive = true),
                (this.clickedCategory = true),
                (this.categoryActive = false),
                (this.brandActive = true),
                setTimeout(() => {
                    this.selectedTab = "brand";
                    this.AnimActive = false;
                }, 400);
            if (this.selectedTab === "brand") {
                this.AnimActive = false;
                this.checkedCategory = false;
            }
        },
        fillFilterIds(val) {
            this.filterIds = val;
        },
        filterAction(filterIds, isNotFilter = true, sort = [this.sortId]) {
            setTimeout(() => {
                this.$store.commit("main/setState", {
                    key: "filterIds",
                    val: filterIds
                });
                let filter = {
                    id: _.get(this.$store.state.main, "selected_menu.id"),
                    placeName: "primary",
                    content_type: "product",
                    filters: filterIds.filter(
                        item => item && item !== "stockExist"
                    ),
                    filterBy: {
                        stockExist: filterIds.some(
                            item => item && item === "stockExist"
                        )
                            ? 1
                            : 0
                    },
                    sort: sort,
                    isNotFilter: isNotFilter
                };
                this.$store.dispatch("main/getPagedContent", filter);
            }, 500);
        },
        switchTab() {
            if (this.categoryActive) {
                this.subcategorySlug = "";
                this.categorySwitch();
                this.$router.replace({ query: {} });
            } else {
                this.brandSwitch();
            }
        }
    }
};
</script>

<style scoped>
.out-anim {
    animation-name: out-animation;
    animation-duration: 0.5s;
}
@keyframes out-animation {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
.taeb-switch {
    position: relative;
}
.taeb-switch:after {
    content: "";
    position: absolute;
    width: 127px;
    bottom: 0;
    transition: left cubic-bezier(0.42, 0.4, 0.4, 0.41) 0.2s;
    border-radius: 27.5px;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
    background-color: #81c7bd;
    height: 2px;
    z-index: 0;
    left: 0;
}
.taeb-switch.clicked::after {
    left: 127px !important;
}
.taeb-switch .taeb {
    display: inline-block;
    transition: color 200ms;
    padding: 0 0 35px 0;
    color: #435059;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    backface-visibility: hidden;
    width: 127px;
}
.taeb-switch .taeb.categoryActive {
    color: #81c7bd;
}
.taeb-switch .taeb.brandActive {
    color: #81c7bd;
}
.block {
    display: block !important;
    opacity: 1 !important;
    transition: 0.5s;
}
.selected-category {
    margin: 30px 0 30px 0;
    display: flex;
    flex-wrap: wrap;
    transition: margin 0.2s;
}
.filtered-list-hover {
    transition: 0.4s;
    cursor: pointer;
}
.filtered-list-hover:hover {
    background: #81c7bd;
    color: #fff;
    transition: 0.4s;
}
.selected-category .filter-list-item-selected {
    background: #81c7bd;
    color: #fff;
    width: fit-content;
    border-radius: 10px;
    text-transform: uppercase;
    position: relative;
    background: #81c7bd;
    color: #ffffff;
    margin: 0 20px 20px 0;
    padding: 13px 40px 13px 13px;
    font-size: 13px;
    font-weight: bold;
    border-radius: 10px;
    cursor: pointer;
}
.selected-category .filter-list-item-selected span {
    display: block !important;
    position: absolute;
    right: 15px;
    top: 8px;
    font-size: 20px;
    color: #fff;
    transform: rotate(45deg);
}
.selected-category ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
}

.selected-category li {
    text-transform: uppercase;
    margin: 0 20px 20px 0;
    padding: 13px 13px 13px 13px;
    background: #f7f7f7;
    color: #435059;
    font-size: 13px;
    font-weight: bold;
    border-radius: 10px;
    cursor: pointer;
}
.selected-category li {
    transition: 0.2s;
}
@media (min-width: 1024px) {
    .selected-category li:hover {
        background: #fec55e;
        color: #fff;
    }
}
.selected-category .selected-tag {
    background: #fec55e;
    color: #fff;
    font-size: 13px;
    font-weight: bold;
    position: relative;
    padding-right: 40px;
    transition: 0.2s;
}
.selected-category span {
    display: none;
}
.selected-tag span {
    display: block !important;
    position: absolute;
    right: 15px;
    top: 8px;
    font-size: 20px;
    color: #fff;
    transform: rotate(45deg);
}
.filter-content {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f7f7f7;
}
.filter-content .filter-content-tabs ul {
    list-style: none;
    display: flex;
}
.filter-content .filter-content-tabs ul li {
    padding: 35px;
    color: #435059;
    font-weight: bold;
    cursor: pointer;
    backface-visibility: hidden;
}
.filter-options {
    height: calc(70vh - 30px);
    margin-bottom: 30px;
    overflow: auto;
}
.filter-options::-webkit-scrollbar {
    width: 10px;
}
.filter::-webkit-scrollbar-thumb {
    background: #81c7bd;
    width: 10px;
}
.filter::-webkit-scrollbar-track {
    width: 10px;
    background: #f7f7f7;
}
.filter-controls {
    display: flex;
    margin-top: -10px;
}
.filter-controls .filter-buttons button {
    border-radius: 10px;
    padding: 12px 14px;
    font-weight: bold;
    text-transform: uppercase;
    color: #435059;
    border: 1px solid #81c7bd;
    margin-right: 18px;
    transition: 0.3s;
}
.filter-controls .filter-buttons button.active {
    background: #81c7bd;
    color: #fff;
}
.filter-controls .filter-buttons button:hover {
    background: #81c7bd;
    color: #fff;
    transition: 0.3s;
}
.filtered-list ul {
    margin: 30px 0 30px 0;
    transition: margin 0.2s;
}
.filtered-list ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
}

.filtered-list li {
    text-transform: uppercase;
    margin: 0 20px 20px 0;
    padding: 13px 13px 13px 13px;
    background: #f7f7f7;
    color: #435059;
    font-size: 13px;
    font-weight: bold;
    border-radius: 10px;
    cursor: pointer;
}
.filtered-list li span {
    display: none;
}
.filter-list {
    width: 100%;
}
.filtered-list .filter-list-item-selected {
    position: relative;
    background: #81c7bd;
    color: #ffffff;
    transition: 0.3s;
}
.filtered-list .filter-list-item-selected span {
    display: block !important;
    position: absolute;
    right: 15px;
    top: 13px;
    font-size: 20px;
    color: #fff;
    transform: rotate(45deg);
}
.filter-controls .b768 {
    display: none;
}
.filter-aside {
    position: fixed;
    width: 525px;
    transform: translateX(1000px);
    padding: 60px;
    right: 0;
    top: 0;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    background: white;
    height: 100vh;
    z-index: 1000;
    transition: 0.5s;
}
.filterAsideActive {
    transform: translateX(0px);
}
.filter-aside .filter-structure {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
}
.filter-structure .apply-changes.clear-button.show {
    opacity: 1;
    visibility: visible;
    max-height: 44px;
    padding: 13px 0;
    margin-top: 10px;
    transition: max-height 0.6s ease, padding 0.6s ease, opacity 0.6s ease 0.2s,
        margin-top 0.6s;
}
.filter-structure .apply-changes.clear-button {
    margin-top: 0px;
    opacity: 0;
    visibility: hidden;
    max-height: 0;
    padding: 0;
    transition: max-height 0.6s ease 0.2s, padding 0.6s ease 0.2s,
        opacity 0.6s ease, margin-top 0.6s 0.2s;
}
.filter-structure .apply-changes {
    background: #d9eeeb;
    color: #81c7bd;
    font-weight: bold;
    font-size: 13px;
    border-radius: 10px;
    padding: 13px 0;
    transition: 0.4s;
}

.filter-structure .apply-changes:hover {
    background: #81c7bd;
    color: #d9eeeb;
    transition: 0.4s;
}

.filter-aside .filter-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 60px;
    border-bottom: 1px solid #f7f7f7;
    margin-bottom: 30px;
}
.filter-inputs .sort {
    top: 35%;
}
.filter-aside .filter-info .close {
    border-radius: 10px;
    width: 44px;
    height: 44px;
    background: #d9eeeb;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 0;
}
.filter-heading {
    font-size: 34px;
    color: #435059;
    text-transform: capitalize;
}
.filter-heading span {
    font-size: 34px;
    color: #81c7bd;
    font-weight: bold;
}
.background {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 999;
    background: rgba(67, 80, 86, 0.3);
    display: none;
}
.mobile-filter {
    display: none;
}
@media (max-width: 1365px) {
    .filter-aside .filter-info {
        padding-bottom: 30px;
    }
}
@media (max-width: 767px) {
    /* .mobile-filter{
            display: block;
        }
        .desctop-filter{
            display: none;
        } */
    .filtered-list ul::-webkit-scrollbar-track {
        width: 1px;
        background: none;
    }
    .filtered-list ul::-webkit-scrollbar-thumb {
        width: 1px;
        background: none;
    }
    .filtered-list ul::-webkit-scrollbar {
        width: 1px;
        background: none;
    }
    .filtered-list ul {
        flex-wrap: nowrap;
        overflow: scroll;
    }
    .selected-category ul::-webkit-scrollbar-track {
        width: 1px;
        background: none;
    }
    .selected-category ul::-webkit-scrollbar-thumb {
        width: 1px;
        background: none;
    }
    .selected-category ul::-webkit-scrollbar {
        width: 1px;
        background: none;
    }
    .selected-category ul {
        flex-wrap: nowrap;
        overflow: scroll;
    }
    .selected-category ul li {
        margin: 0 20px 0px 0;
        min-width: fit-content;
    }
    .filter-aside .filter-info {
        padding-bottom: 20px;
    }
    .taeb-switch .taeb {
        padding: 35px 0 35px 0;
    }
    .filter-content {
        flex-direction: column-reverse;
    }
    .filter-controls {
        margin-top: 0;
    }
    .filter-controls .af768 {
        display: none;
    }
    .filter-controls .b768 {
        display: block;
    }
    .filter-aside {
        border-radius: 0;
        width: 100%;
        padding: 60px 20px;
    }
    .swiper-slide {
        width: fit-content !important;
    }
    .filtered-list li {
        min-width: max-content;
        margin: 0 20px 0 0;
    }
    .flex-box {
        align-items: center;
    }
    .filter-controls .filter-buttons {
        margin-right: 10px;
    }
    .filter-controls .filter-buttons button {
        padding: 10px 16px;
        margin-right: 00px;
    }
    .taeb-switch.right::after {
        left: 135px;
    }
}
</style>
