import axios from "axios";
import get from "lodash/get";
import conf from "../helpers/conf";
import store from "../store";

export default {
    postData: async function(path = "", data = {}) {
        return this.reqAxios(path, data).then(data => {
            return data;
            // return JSON.parse(data);
        });

        // return response;

    },

    reqAxios: async function (path = '', data = {}) {

        const response = await axios(conf.apiUrl + path, {
            method: 'POST',
            // mode: 'cors',
            headers: {
                // 'Access-Control-Allow-Headers': '*',
                // 'Content-Type': 'application/json',
                // 'lang': 'geo'
            },
            data: data
            // withCredentials: true,
            // credentials: 'same-origin',
        }).then(response => {
            return response;
        })

        if (response.status < 300) {
            return { data: response.data, status: response.status };
        }
    },

    /**
     * get object element by index
     * */
    getObEl(obj, index) {
        index = !index ? 0 : index;
        return get(Object.keys(obj), index);
    },

    correctUrl(url) {
        if (!url) return "#";
        return url;
    },

    /** get translated string
     * if string not exists makes request to create one */
    tr(wordkey) {
        if (!wordkey || wordkey == "") return wordkey;

        wordkey = wordkey.trim().substring(0, 250);

        if (!store.state.main.translations[store.state.main.locale])
            return wordkey;

        if (
            get(store.state.main.translations, [
                store.state.main.locale,
                wordkey
            ])
        )
            return get(store.state.main.translations, [
                store.state.main.locale,
                wordkey
            ]);
        if (
            get(store.state.main.translations, [
                store.state.main.locale,
                wordkey.toLowerCase()
            ])
        )
            return get(store.state.main.translations, [
                store.state.main.locale,
                wordkey.toLowerCase()
            ]);

        this.postData("main/adwrd", { wrd: wordkey });
        return wordkey;
    }
};
