<template>
    <div class="news-card">
        <div class="news-card-img relative">
            <div class="opacity"></div>
            <picture v-if="cardData.cover_image">
                <source
                    media="(max-width: 767px)"
                    loading="lazy"
                    :srcset="(`${_.get(cardData, 'cover_image[0].devices.mobile')}`)"
                />
                <source
                    media="(max-width: 1023px)"
                    loading="lazy"
                    :srcset="(`${_.get(cardData, 'cover_image[0].devices.tablet')}`)"
                />
                <img

                    :src="(`${_.get(cardData, 'cover_image[0].devices.desktop')}`)"
                    loading="lazy"
                    alt="image"
                />
            </picture>
            <picture v-else>
                <source
                    media="(max-width: 767px)"
                    loading="lazy"
                    :srcset="(`${_.get(cardData, 'images[0].devices.mobile')}`)"
                />
                <source
                    media="(max-width: 1023px)"
                    loading="lazy"
                    :srcset="(`${_.get(cardData, 'images[0].devices.tablet')}`)"
                />
                <img

                    :src="(`${_.get(cardData, 'images[0].devices.desktop')}`)"
                    loading="lazy"
                    alt="img"
                />
            </picture>
            <div class="new-card-date font-13 bold">
                {{ cardDate() }}
            </div>
        </div>
        <h2 class="desc bold font-16">{{ cardData.title }}</h2>
    </div>
</template>
<script>
import moment from "moment"

export default {
    props: ['cardData'],

    methods: {
        cardDate(){
            return  moment(this.cardData.date).format('DD MMM, YYYY');
        }
    }
}
</script>

<style scoped>
    .news-card .desc{
        transition: 0.3s;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        /* min-height: 44px; */
    }
    .news-card:hover .desc{
        color: #81C7BD;
        transition: 0.3s;
    }
    .news-card-img{
        /* padding-top: 295px; */
        padding-top: 55%;
        margin-bottom: 20px;
        border-radius: 30px;
        overflow: hidden;
    }
    .news-card-img img{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
    }
    .opacity{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 30px;
        background: #435059;
        opacity: 0.1;
    }
    .new-card-date{
        position: absolute;
        top: 30px;
        left: 30px;
        background: #FFFFFF;
        color: #435059;
        border-radius: 10px;
        padding: 13px 13px;
        width: auto;
        min-width: 108px;
    }
    @media (max-width: 1023px){
        .news-card-img{
            padding-top: 70%;
        }
        .new-card-date{
            top: 20px;
            left: 20px;
            padding: 10px;
        }
    }
    @media (max-width: 767px){
        .new-card-date{
            top: 20px;
            left: 20px;
            padding: 5px 10px;
            height: 28px;
        }
    }
</style>
