<template>
    <div class="product-inner">
        <swiper ref="mySwiper" :options="swiperOptions" v-if="sliderData">
            <swiper-slide v-for="(item, index) in sliderData" :key="index">
                <div class="product-inner-swiper">
					<picture>
						<source
                            media="(max-width: 767px)"
                            loading="lazy"
                            :srcset="_.get(item, 'devices.mobile')"
						/>
						<source
                            media="(max-width: 1023px)"
                            loading="lazy"
                            :srcset="_.get(item, 'devices.tablet')"
						/>
						<img

                            :src="_.get(item, 'devices.desktop')"
                            loading="lazy"
                            alt="product-image"
						/>
					</picture>
                </div>
            </swiper-slide>
        </swiper>
        <div class="swiper-pagination"></div>
    </div>
</template>

<script>

export default {

    props: ["sliderData"],

    data() {
        return {
            swiperOptions: {
    			speed: 400,
    			breakpoints: {
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 30
                    },
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
            },
        }
    },
}
</script>

<style>
    .product-inner{
        position: relative;
        margin: 30px 0;
    }
    .product-inner-swiper{
        position: relative;
        padding-top: 700px;
        border-radius: 30px;
        overflow: hidden;
    }
    .product-inner-swiper img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
    }
    .product-inner .swiper-pagination{
        position: absolute;
        bottom: 15px;
        left: 50%;
        transform: translate(-15px, -50%);
    }
    .product-inner .swiper-pagination-bullet{
        width: 10px;
        height: 10px;
        margin: 0 10px !important;
        background: #BEC1C5;
        opacity: 1;
    }
    .product-inner .swiper-pagination-bullet-active{
        background: #FEC55E;
    }
/*
    @media (max-width: 1600px){
        .product-inner-swiper{
            padding-top: 583px;
        }
    }
    @media screen and (max-width: 1366px) {
        .product-inner-swiper{
            padding-top: 498px;
        }
    }

    @media screen and (max-width: 1024px){
        .product-inner-swiper{
            padding-top: 373px;
        }
    } */
    @media screen and (max-width: 768px){
        .product-inner-swiper{
            padding-top: 100%;
        }
    }

    @media screen and (max-width: 767px){
        .product-inner .swiper-pagination{
            bottom: 0;
            transform: translate(-50%, -50%);
        }
    }
</style>
