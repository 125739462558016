<template>
    <div class="form-group parent" :class="isOpen ? 'border-bottom' : ''" @click="openDrop">
<!--        <span class="sort bold uppercase font-13">{{$fn.tr('Sort')}}</span>-->
<!--        <label for="city" v-if="!value">-->
<!--            {{ label }}-->
<!--        </label>-->
        <div ref="Select" class="form-select" >
            {{ selectedVal }}
            <svg :class="isOpen ? 'carret-rotate' : ''" xmlns="http://www.w3.org/2000/svg" width="11.144" height="20" viewBox="0 0 11.144 20">
                <path id="Path_331" data-name="Path 331" d="M213.584,780.046a1.144,1.144,0,0,0,.809-1.953l-8.047-8.047L214.393,762a1.144,1.144,0,1,0-1.618-1.618l-8.856,8.856a1.144,1.144,0,0,0,0,1.618l8.856,8.856A1.14,1.14,0,0,0,213.584,780.046Z" transform="translate(-203.584 -760.046)" fill="#435059"/>
            </svg>
        </div>

        <ul
            class="form-drop-box hidden"
            :class="isOpen ? 'visible' : ''">
            <li
                class="form-drop-item font-13 regular line-height"
                v-for="item in data"
                :key="item.id"
                @click="pickItem($event,item.domain)">
                {{ $store.state.main.locale === 'en' ? item.name_en : item.name_geo }}
            </li>
        </ul>
    </div>
</template>

<script>

export default {
    props: {
        data: {
            type: Array,
            required: true
        },

        label: {
            type: String,
            required: false
        },
        val: {
            type: String,
            required: false
        },

        isRequired: {
            type: Boolean,
            default: false
        },

        clicked: {
            type: Boolean,
            required: true
        }
    },


    data() {
        return {
            isOpen: false,
            value:  this.val ? this.val : this.$store.state.main.locale === 'en' ? this.data[0].name_en : this.data[0].name_geo
        }
    },
    computed: {
      selectedVal() {
          return this.val ? this.val : this.$store.state.main.locale === 'en' ?  this.data[0].name_en :  this.data[0].name_geo
        }
    },

    methods: {
        openDrop(e) {
            this.isOpen = !this.isOpen
        },

        pickItem(e,domain) {
            this.value = e.target ? e.target.textContent : e;
            this.$emit('select', domain, this.value);
            setTimeout(() =>{
                this.isOpen = false
            }, 0)
        },
    },
    mounted() {
        this.pickItem(this.selectedVal,this.val);
    },
    created: function() {
        let self = this;

        window.addEventListener('click', function(e){
            if (!self.$el.contains(e.target)){
                self.isOpen = false
            }
        })
    }
}
</script>

<style scoped>
.parent {
    position: relative;
    width: 100%;
    min-height: 44px;
    padding: 0 33px 0 15px;
    background: #F7F7F7;
    border-radius: 10px;
    font-size: 13px;
    display: flex;
    align-items: center;
    transition: 0.3s;
    cursor: pointer;
}
.sort{
    padding-right: 14px;
    margin-right: 14px;
    border-right: 1px solid rgba(68, 81, 90, 0.2);
}
.form-drop-box::-webkit-scrollbar{
    width: 3px;
    background: none;
}

.form-drop-box::-webkit-scrollbar {
    width: 5px;
    transition: .5s;
}
.form-drop-box::-webkit-scrollbar-track {
    background: #f1f1f1;
    transition: .5s;
}
.form-drop-box::-webkit-scrollbar-thumb {
    background: var(--green);
    transition: 1s;
    border-radius: 50px;
}
.form-drop-box {
    background: #F7F7F7;
    max-height: 250px;
    overflow: auto;
    padding: 15px;
    margin-top: 10px;
    /*background: #fff;*/
    /*border: 1px solid #81C7BD;*/
    border-top: 1px solid rgba(68, 81, 90, 0.2);
    border-radius: 0px;
    position: absolute;
    width: 100%;
    /* max-height: 180px; */
    min-width: 248px;
    left: -1px;
    top: 32px;
    overflow-y: auto;
    z-index: 2;
}
.form-drop-box li {
    cursor: pointer;
}
.form-select{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.form-select svg {
    position: absolute;
    right: 15px;
    top: 12px;
    width: 10px;
    transition: all 400ms;
    transform: rotate(-90deg);
}

.form-drop-item {
    padding-bottom: 10px;
    color: #435059;
    cursor: pointer;
    transition: all 400ms;
}

.form-drop-item:last-child {
    padding-bottom: 0;
}

.form-drop-item:hover {
    color: #FEC55E;
}

.form-drop-box::selection,
.form-drop-item::selection,
.form-select::selection {
    background: transparent;
}

.hidden {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
    transition: all 300ms;
}

.visible {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.border-bottom{
    border-radius: 0 !important;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
}
.carret-rotate {
    transform: rotate(90deg)!important;
}
.carret-rotate path{
    fill: #81C7BD;
}
label{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
@media (max-width: 767px){
    .parent{
        width: 180px;
        min-height: 41px;
        min-width: auto;
        padding: 0px 33px 0px 14px;
    }
    .sort{
        padding-right: 14px;
        margin-right: 14px;
    }
    .form-select svg{
        top: 10px;
        width: 8px;
    }
    .form-drop-box{
        min-width: 180px;
        top: 29px;
    }
}
</style>
