<template>
    <section>
        <div class="container-wrapper">
            <div class="wishlist-header">
                <content-title
                    :colored-title="true"
                    :title="$fn.tr('Your Wishlist')"
                    data-aos="fade-up"
                    data-aos-delay="100"
                />

                <!--                <div class="sort-inputs" data-aos="fade-up" data-aos-delay="300">-->
                <!--                    <SortSelect-->
                <!--                        ref="Sort"-->
                <!--                        :data="list"-->
                <!--                        :label="'Sort'"-->
                <!--                        :isRequired="true"-->
                <!--                        :clicked="isClicked"-->
                <!--                    />-->
                <!--                </div>-->
            </div>

            <div class="wishlist-content relative">
                <img
                    class="decor-img-rotated"
                    width="300"
                    height="200"
                    loading="lazy"
                    src="../assets/img/decor.png"
                    alt=""
                    data-aos="fade-in"
                    data-aos-delay="200"
                />
                <img
                    class="bottom-decor-img"
                    width="300"
                    height="200"
                    loading="lazy"
                    src="../assets/img/decor.png"
                    alt=""
                />
                <div
                    class="wishlist-content-element"
                    v-for="(item, index) in wishList"
                    :class="{ hide: wishCartId === item.id }"
                    :key="index"
                >
                    <WishlistElement
                        data-aos="fade-up"
                        data-aos-delay="500"
                        :WishlistData="item"
                        :itemIndex="index"
                        :itemCount="itemCount"
                        v-on:delete-element="removeWishElement(item)"
                    />
                </div>
                <div
                    class="font-34 bold no-item"
                    data-aos="fade-up"
                    data-aos-delay="300"
                    v-if="!_.get(getWishListItems, 'data.list')"
                >
                    {{ $fn.tr("You do not have any item in your wishlist") }}
                </div>
            </div>
        </div>
        <CartInfo />
    </section>
</template>

<script>
import contentTitle from "../components/ContentTitle";
import CartInfo from "../components/CartInfo";
import WishlistElement from "../components/WishlistItems";
import SortSelect from "../components/SortSelect";
import { mapGetters } from "vuex";
import addToCartMixin from "../mixins/addToCartMixin";

export default {
    components: {
        contentTitle,
        CartInfo,
        WishlistElement,
        SortSelect
    },
    mixins: [addToCartMixin],
    data() {
        return {
            isClicked: false,
            list: [
                {
                    id: 1,
                    city: "Option 1"
                },
                {
                    id: 2,
                    city: "Option 2 "
                },
                {
                    id: 3,
                    city: "Else option"
                },
                {
                    id: 4,
                    city: "Diff option"
                },
                {
                    id: 5,
                    city:
                        "Very long option for responsive Very long option for responsive"
                }
            ],
            itemCount: 1,
            value: ""
        };
    },
    computed: {
        ...mapGetters({ getWishListItems: "shop/getWishListItems" }),
        wishList() {
            return this.getWishListItems.data?.list || [];
        }
    },
    mounted() {
        this.$store.commit("main/setState", {
            key: "breadcrumbList",
            val: [{ title: "Wishlist" }]
        });
    }
};
</script>

<style scoped>
.no-item {
    margin-top: 144px;
    text-align: center;
}
.wishlist-content {
    min-height: 900px;
}

.wishlist-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 30px;
    border-bottom: 1px solid #f7f7f7;
    margin-bottom: 45px;
    margin-top: 5px;
}
.content-title {
    margin-bottom: 0;
}
.decor-img-rotated {
    position: absolute;
    z-index: -1;
    left: -30%;
    top: -5%;
    transform: rotate(90deg) !important;
}
.wishlist-content-element {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 50px;
    margin-bottom: 43px;
    border-bottom: 1px solid #f7f7f7;
    opacity: 1;
    visibility: visible;
    max-height: 1000px;
}
.wishlist-content-element.hide {
    opacity: 0;
    visibility: hidden;
    transform: scaleY(0);
    margin-bottom: 0;
    padding-bottom: 0;
    max-height: 0;
    transition: 0.4s ease, transform 0.01ms ease;
}
.wishlist-content-element:last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 144px;
}
.wishlist-content-element.hide {
    opacity: 0;
    visibility: hidden;
    transform: scaleY(0);
    margin-bottom: 0;
    padding-bottom: 0;
    max-height: 0;
    transition: 0.4s ease, transform 0.01ms ease;
}

@media (max-width: 1023px) {
    .wishlist-content {
        min-height: unset;
    }
    .wishlist-header {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 30px;
    }
    .wishlist-content-element {
        padding-bottom: 30px;
        margin-bottom: 30px;
    }
    .sort-inputs {
        margin-top: 30px;
    }
    .decor-img-rotated {
        display: none;
    }
}
</style>
