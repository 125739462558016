<template>
    <div class="calendar-margin relative" data-aos="fade-up">
        <div class="date-picker-button"  @click="openDrop(e)">
            <div class="flex date-picker-content" >
                <svg class="calendar-svg" xmlns="http://www.w3.org/2000/svg" width="23.6" height="26" viewBox="0 0 23.6 26">
                    <g id="Icon_feather-calendar" data-name="Icon feather-calendar" transform="translate(-3.5 -2)">
                        <path id="Path_598" data-name="Path 598" d="M6.9,6H23.7a2.4,2.4,0,0,1,2.4,2.4V25.2a2.4,2.4,0,0,1-2.4,2.4H6.9a2.4,2.4,0,0,1-2.4-2.4V8.4A2.4,2.4,0,0,1,6.9,6Z" transform="translate(0 -0.6)" fill="none" stroke="#81c7bd" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                        <path id="Path_599" data-name="Path 599" d="M24,3V7.8" transform="translate(-3.9)" fill="none" stroke="#81c7bd" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                        <path id="Path_600" data-name="Path 600" d="M12,3V7.8" transform="translate(-1.5)" fill="none" stroke="#81c7bd" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                        <path id="Path_601" data-name="Path 601" d="M4.5,15H26.1" transform="translate(0 -2.4)" fill="none" stroke="#81c7bd" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    </g>
                </svg>
                <p v-if="value === null " class="font-13 regular">
                    {{$fn.tr('Pick a date')}}
                </p>
                <p v-if="value !== null" @click="value = null" class="font-13 regular">
                    {{$fn.tr('Clear calendar')}}
                </p>
            </div>
            <svg class="arrow" :class="isOpen ? 'carret-rotate' : ''"
                 xmlns="http://www.w3.org/2000/svg" width="11.144" height="20" viewBox="0 0 11.144 20">
                <path id="Path_331" data-name="Path 331" d="M213.584,780.046a1.144,1.144,0,0,0,.809-1.953l-8.047-8.047L214.393,762a1.144,1.144,0,1,0-1.618-1.618l-8.856,8.856a1.144,1.144,0,0,0,0,1.618l8.856,8.856A1.14,1.14,0,0,0,213.584,780.046Z"
                      transform="translate(-203.584 -760.046)" fill="#435059"/>
            </svg>

        </div>
        <!-- <range-calendar /> -->
        <v-date-picker color="teal" :model-config="modelConfig" class="calendar" :class="isOpen ? 'isShown' : '' " v-model="value" is-range></v-date-picker>
    </div>
</template>

<script>

export default {

    data(){
        return{
            isOpen: false,
            value: null,
            modelConfig: {
                start: {
                    timeAdjust: '00:00:00',
                },
                end: {
                    timeAdjust: '23:59:59',
                },
            },
        }
    },
    watch: {
        value: {
            handler(val){
                this.isOpen = !this.isOpen
            },
        }
    },
    methods: {
        openDrop(e) {
            this.isOpen = !this.isOpen
        },
    },
    created: function() {
        let self = this;

        window.addEventListener('click', function(e){
            if (!self.$el.contains(e.target)){
                self.isOpen = false
            }
        })
    }
}
</script>

<style scoped>
    .date-picker-button{
        width: 248px;
        height: 44px;
        border-radius: 10px;
        border: 1px solid #81C7BD;
        display: flex;
        justify-content: space-between;
        padding: 13px 23px 10px 20px;
        cursor: pointer;
    }
    .date-picker-button .arrow{
        transform: rotate(-90deg);
        transition: 0.4s;
    }
    .calendar-svg{
        margin-top: -3px;
    }
    .date-picker-content p{
        margin-left: 18px;
        padding-left: 14px;
        border-left: 2px solid #D9DCDE;
    }
    .carret-rotate {
        transform: rotate(90deg)!important;
        transition: 0.4s;
    }
    .carret-rotate path{
        fill: #81C7BD;
    }
    .calendar{
        position: absolute;
        z-index: 2;
        left: -6px;
        top: 50px;
        transform: translateY(-20px);
        opacity: 0;
        transition: 0.4s;
        visibility: hidden;
    }
    .calendar.isShown{
        transform: translateY(10px);
        opacity: 1;
        border-color: #81c7bd;
        transition: 0.4s;
        visibility: visible;
    }
    .calendar-margin{
        margin-bottom: 50px;
    }
    @media (max-width: 1365px){
        .calendar-margin{
            margin-bottom: 0px;
        }
    }
     @media (max-width: 767px){
        .calendar-margin{
            margin-bottom: 21px;
        }
    }

</style>
