var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('MainSlider',{class:{ 'hide-slider': _vm.$store.state.main.loadingPageChange },attrs:{"compData":_vm._.get(
                _vm.$store.state.main.current_content,
                'secondary.MainSliderBlock.data'
            )}}),_c('CategoryList',{class:{ 'hide-slider': _vm.$store.state.main.loadingPageChange }}),(
            _vm._.size(
                _vm.$store.state.main.current_content,
                'secondary.BestSellers.data'
            )
        )?_c('div',{staticClass:"custom-container-wrapper relative best-sellers",class:{ 'hide-slider': _vm.$store.state.main.loadingPageChange }},[_c('img',{staticClass:"best-decor-img",attrs:{"loading":"lazy","src":require("../assets/img/decor.png"),"alt":""}}),_c('PageTitle',{staticClass:"best-sellers-content title-width",attrs:{"title":_vm._.get(
                    _vm.$store.state.main.current_content,
                    'secondary.BestSellers.conf.header.title'
                )}}),_c('ProductSlider',{attrs:{"sliderData":_vm._.get(
                    _vm.$store.state.main.current_content,
                    'secondary.BestSellers.data'
                )}})],1):_vm._e(),_c('LimitedOffers',{class:{ 'hide-slider': _vm.$store.state.main.loadingPageChange },attrs:{"data-aos":"fade-up","data-aos-delay":"100","data":_vm._.get(
                _vm.$store.state.main.current_content,
                'secondary.SpecialOffers'
            )}}),(
            _vm._.size(
                _vm.$store.state.main.current_content,
                'secondary.MysaRecommends.data'
            )
        )?_c('div',{staticClass:"custom-container-wrapper relative mysa-recomends",attrs:{"data-aos":"fade-up","data-aos-delay":"100"}},[_c('img',{staticClass:"decor-img-rotated",attrs:{"loading":"lazy","src":require("../assets/img/decor.png"),"alt":"","data-aos":"fade-in","data-aos-delay":"200"}}),_c('PageTitle',{staticClass:"recomends-content title-width",attrs:{"title":_vm._.get(
                    _vm.$store.state.main.current_content,
                    'secondary.MysaRecommends.conf.header.title'
                )}}),_c('VerticalSlider',{attrs:{"sliderData":_vm._.get(
                    _vm.$store.state.main.current_content,
                    'secondary.MysaRecommends.data'
                )}})],1):_vm._e(),_c('div',{staticClass:"new-collection",attrs:{"data-aos":"fade-up","data-aos-delay":"100"}},[_c('PageTitle',{staticClass:"container-wrapper",attrs:{"title":_vm._.get(
                    _vm.$store.state.main.current_content,
                    'secondary.NewCollection.conf.header.title'
                )}}),_c('HomeSlicedSlider',{attrs:{"data-aos":"fade-up","data-aos-delay":"100","sliderData":_vm._.get(
                    _vm.$store.state.main.current_content,
                    'secondary.NewCollection.data.list'
                )}})],1),(
            _vm._.size(
                _vm.$store.state.main.current_content,
                'secondary.LatestNews.data.list'
            )
        )?_c('div',{staticClass:"container-wrapper cust-container",attrs:{"data-aos":"fade-up","data-aos-delay":"100"}},[_c('div',{staticClass:"flex justify-between relative news-content"},[_c('img',{staticClass:"decor-img",attrs:{"loading":"lazy","src":require("../assets/img/decor.png"),"alt":""}}),_c('PageTitle',{attrs:{"title":_vm._.get(
                        _vm.$store.state.main.current_content,
                        'secondary.LatestNews.conf.header.title'
                    )}}),_c('router-link',{attrs:{"to":("/" + (_vm.$store.state.main.current_content.locale) + "/news")}},[_vm._v(" "+_vm._s(_vm.$fn.tr("View More"))+" ")])],1),_c('div',{staticClass:"flex card-list"},_vm._l((_vm._.get(
                    _vm.$store.state.main.current_content,
                    'secondary.LatestNews.data.list'
                )
                    ? _vm._.get(
                          _vm.$store.state.main.current_content,
                          'secondary.LatestNews.data.list'
                      ).slice(0, 2)
                    : []),function(item,index){return _c('router-link',{key:index,staticClass:"news-shown",attrs:{"to":("/" + (_vm.$store.state.main.indx.locale) + "/news/singleview/" + (item.id) + "-" + (item.slug))}},[_c('LatestNewsCard',{attrs:{"cardData":item}})],1)}),1)]):_vm._e(),_c('CartInfo',{staticClass:"mt-236"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }