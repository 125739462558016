<template>
  <div class="container-wrapper categories">
    <div class="category-title">
      {{ $fn.tr('Categories') }}
    </div>
    <div class="relative">
      <swiper ref="usefulToolsSlider" class="swp" :options="swiperOptions">
        <template v-for="(item, index) in categoryList">
          <swiper-slide :key="index" v-if="item.show_on_mainpage == 'yes'">
            <div
              @click="
                goToLink(
                  `/${$store.state.main.locale}/products?category=${item.slug}`,
                  item.id
                )
              "
              class="category-item center-flex"
              :class="{
                active: selectedId == item.id,
              }"
            >
              <div class="img" v-html="item.icon_svg_en" />
              <div class="description">{{ item.title }}</div>
            </div>
          </swiper-slide>
        </template>
        <div class="swiper-scrollbar" slot="scrollbar">
          <div class="swiper-scrollbar-drag" style="width: 100px;" slot="scrollbar"></div>
        </div>
      </swiper>
    </div>
  </div>
</template>

<script>
import SwiperCore, { Scrollbar } from 'swiper';
SwiperCore.use([Scrollbar]);
export default {
  name: 'category-list',
  data() {
    return {
      swiperOptions: {
        speed: 800,
        watchOverflow: true,
        breakpoints: {
          320: {
            spaceBetween: 10,
            slidesPerView: 2.5,
          },
          768: {
            spaceBetween: 30,
            slidesPerView: 6,
          },
          1024: {
            spaceBetween: 20,
            slidesPerView: 8,
          },
          1365: {
            spaceBetween: 10,
            slidesPerView: 10,
          },
        },
        scrollbar: {
          el: '.swiper-scrollbar',
          hide: false,
          draggable: true,
          dragSize: 70,
        },
      },
      selectedId: null,
    };
  },
  computed: {
    usefulToolsSlider() {
      return this.$refs.usefulToolsSlider.$swiper;
    },
    categoryList() {
      return _.get(this.$store.state.main.indx, 'attributes.products_category').filter(
        (cat) => cat.pid === 0 && cat.hide_from_categories != 'yes'
      );
    },
  },
  methods: {
    goToLink(link, id) {
      this.selectedId = id;
      this.$store.commit('main/setState', {
        key: 'loadingPageChange',
        val: true,
      });
      setTimeout(() => {
        this.$store.commit('main/setState', {
          key: 'loadingPageChange',
          val: false,
        });
      }, 701);
      setTimeout(() => {
        this.$router.push(link);
      }, 700);
    },
  },
};
</script>

<style scoped>
.swiper-scrollbar {
  /*margin-top: 20px;*/
  background-color: #f7f7f7;
  height: 1px !important;
}
.swiper-scrollbar-drag {
  top: -2px;
  background-color: #fec55e;
  height: 4px;
  cursor: pointer;
}
.categories {
  margin-top: 60px;
}
.category-title {
  font-size: 34px;
  font-family: var(--noto-light);
  line-height: 41px;
  margin-bottom: 25px;
}

.category-item {
  height: 140px;
  flex-direction: column;
  width: 100%;
  text-align: center;
  margin-bottom: 24px;
  padding: 30px 10px;
  transition: 0.2s ease;
  border-radius: 30px;
  cursor: pointer;
}

@media (min-width: 1024px) {
  .category-item:hover {
    background-color: var(--green);
    transition: 0.2s ease;
  }
  .category-item:hover .img {
    filter: brightness(0) invert(1);
    transition: 0.1s ease;
  }
  .category-item:hover .description {
    color: #ffffff;
    transition: 0.2s ease;
  }
}

.category-item .img {
  margin-top: 10px;
}

.description {
  font-family: var(--noto-semibold);
  font-size: 13px;
  line-height: 16px;
  padding-top: 15px;
}

@media screen and (max-width: 1599px) {
  .category-item {
    padding: 15px 10px;
  }
  .categories {
    margin-top: 40px;
  }
}
@media screen and (max-width: 1023px) {
  .category-title {
    font-size: 21px;
  }
}
@media screen and (max-width: 767px) {
  .category-item.active {
    background-color: var(--green);
    transition: 0.2s ease;
  }
  .category-item.active .img {
    filter: brightness(0) invert(1);
    transition: 0.1s ease;
  }
  .category-item.active .description {
    color: #ffffff;
    transition: 0.2s ease;
  }
  .category-item {
    padding: 10px 5px;
  }
  .categories {
    border-top: 1px solid #f7f7f7;
    padding-top: 20px;
    margin-top: 12px;
  }
}
</style>
