<template>
    <div class="modal" :class="{ modalShow: modalActive }">
        <div
            class="modal-background"
            :class="{
                'modal-background-open': modalActive,
                'modal-background-close': animActive
            }"
            @click="modalClose"
        ></div>
        <div
            class="modal-content"
            :class="{ 'modal-open': modalActive, 'modal-close': animActive }"
        >
            <swiper
                class="product-slider"
                ref="mySwiper"
                :options="verticalSwiperOptions"
            >
                <swiper-slide
                    v-for="(item, index) in _.get(modalData, 'images')"
                    :key="index"
                >
                    <picture>
                        <source
                            media="(max-width: 767px)"
                            loading="lazy"
                            :srcset="`${item.devices.mobile}`"
                        />
                        <source
                            media="(max-width: 1023px)"
                            loading="lazy"
                            :srcset="`${item.devices.tablet}`"
                        />
                        <img
                            :src="`${item.devices.desktop}`"
                            loading="lazy"
                            alt
                        />
                    </picture>
                </swiper-slide>
            </swiper>
            <div class="product-info">
                <div class="flex flex-column">
                    <svg
                        class="close-svg"
                        @click="modalClose"
                        xmlns="http://www.w3.org/2000/svg"
                        width="15.424"
                        height="15.424"
                        viewBox="0 0 15.424 15.424"
                    >
                        <path
                            id="Icon_metro-cancel"
                            data-name="Icon metro-cancel"
                            d="M22.815,9.64l-4.82,4.82-4.82-4.82-2.892,2.892,4.82,4.82-4.82,4.82,2.892,2.892,4.82-4.82,4.82,4.82,2.892-2.892-4.82-4.82,4.82-4.82Z"
                            transform="translate(-10.283 -9.64)"
                            fill="#435059"
                        />
                    </svg>
                    <div class="product-name">
                        <div>
                            <div
                                class="product-title font-21 bold"
                                v-html="_.get(modalData, 'title')"
                            ></div>
                            <div
                                class="product-brand font-16"
                                v-for="(item, index) in getBrandsList"
                                :key="index"
                            >
                                {{ $fn.tr("Brand") }} :
                                <a
                                    rel="noopener"
                                    :href="item.url_ge"
                                    target="_blank"
                                    class="bold primary-color"
                                >
                                    {{ item.title }}
                                </a>
                            </div>
                        </div>
                        <div>
                            <div
                                v-if="!alreadyInWishList"
                                @click="addToWishAction"
                            >
                                <svg
                                    style="cursor: pointer"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="44"
                                    height="44"
                                    viewBox="0 0 44 44"
                                >
                                    <g
                                        id="Group_3812"
                                        data-name="Group 3812"
                                        transform="translate(-378 -1115)"
                                    >
                                        <circle
                                            id="Ellipse_3"
                                            data-name="Ellipse 3"
                                            cx="22"
                                            cy="22"
                                            r="22"
                                            transform="translate(378 1115)"
                                            fill="#ffeece"
                                        />
                                        <path
                                            id="Icon_feather-heart"
                                            data-name="Icon feather-heart"
                                            d="M17.989,5.806a4.467,4.467,0,0,0-6.318,0l-.861.861L9.95,5.806a4.468,4.468,0,1,0-6.318,6.318l.861.861L10.81,19.3l6.318-6.318.861-.861a4.467,4.467,0,0,0,0-6.318Z"
                                            transform="translate(389.549 1125.348)"
                                            fill="none"
                                            stroke="#fec55e"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                        />
                                    </g>
                                </svg>
                            </div>

                            <div
                                v-if="alreadyInWishList"
                                @click="removeFromWishAction"
                            >
                                <svg
                                    style="cursor: pointer"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="44"
                                    height="44"
                                    viewBox="0 0 30 30"
                                >
                                    <g
                                        id="Group_14"
                                        data-name="Group 14"
                                        transform="translate(-378 -1115)"
                                    >
                                        <circle
                                            id="Ellipse_3"
                                            data-name="Ellipse 3"
                                            cx="15"
                                            cy="15"
                                            r="15"
                                            transform="translate(378 1115)"
                                            fill="#fec55e"
                                        />
                                        <path
                                            id="Icon_feather-heart"
                                            data-name="Icon feather-heart"
                                            d="M13,5.39a3.045,3.045,0,0,0-4.308,0l-.587.587L7.523,5.39A3.046,3.046,0,1,0,3.215,9.7l.587.587L8.11,14.592l4.308-4.308L13,9.7A3.045,3.045,0,0,0,13,5.39Z"
                                            transform="translate(385.135 1120.625)"
                                            fill="none"
                                            stroke="#fff"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                        />
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="mobile-picker">
                            <div class="product-details border-bottom">
                                <div class="product-price bold font-34">
                                    {{
                                        formatPrice(
                                            _.get(modalData, "stock_price")
                                        )
                                    }}
                                    ₾
                                </div>
                                <div class="product-id font-16">
                                    {{ $fn.tr("Product ID") }}:
                                    <span
                                        class="bold"
                                        v-html="
                                            _.get(modalData, 'product_code')
                                        "
                                        >9209320192</span
                                    >
                                </div>
                            </div>
                            <div
                                class="color-picker border-bottom"
                                v-if="getColorList.length"
                            >
                                <div class="font-13 bold">
                                    {{ $fn.tr("Choose Color") }}
                                </div>
                                <ul>
                                    <template
                                        v-for="(item, index) in getColorList"
                                    >
                                        <!-- getColorList -->
                                        <li
                                            class="pointer"
                                            :key="index"
                                            @click="chooseColor(item)"
                                            :class="{
                                                active: currentId === item.id
                                            }"
                                        >
                                            <div
                                                :style="{
                                                    background: item.color
                                                }"
                                                class="background"
                                            ></div>
                                        </li>
                                    </template>
                                </ul>
                            </div>
                        </div>
                        <div class="add-item border-bottom">
                            <div
                                class="incriment bold font-16"
                                v-if="
                                    modalData &&
                                        modalData.stock_price &&
                                        modalData.stock_quantity > 1
                                "
                            >
                                <span @click="decreaseCount" class="decrement">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="8.939"
                                        height="8.3"
                                        viewBox="0 0 8.939 2.235"
                                    >
                                        <path
                                            id="Icon_open-plus"
                                            data-name="Icon open-plus"
                                            d="M3.352,3.352H0V5.587H8.939V3.352H3.352Z"
                                            transform="translate(0 -3.352)"
                                            fill="#435059"
                                        />
                                    </svg>
                                </span>
                                {{ count }}
                                <span @click="increaseCount" class="increment">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="10"
                                        height="10"
                                        viewBox="0 0 8.939 8.939"
                                    >
                                        <path
                                            id="Icon_open-plus"
                                            data-name="Icon open-plus"
                                            d="M3.352,0V3.352H0V5.587H3.352V8.939H5.587V5.587H8.939V3.352H5.587V0Z"
                                            fill="#435059"
                                        />
                                    </svg>
                                </span>
                            </div>
                            <button
                                v-if="
                                    modalData &&
                                        modalData.stock_price &&
                                        modalData.stock_quantity > 1
                                "
                                @click="addToCart(modalData, count, false)"
                                class="font-13 bold"
                            >
                                {{ $fn.tr("Add to bag") }}
                            </button>
                            <div class="out-of-stock bold font-18" v-else>
                                {{ $fn.tr("Out of Stock") }}
                            </div>
                        </div>
                        <div
                            class="out-of-stock bold font-18"
                            v-if="
                                $store.state.shop.showCartError.value &&
                                    $store.state.shop.showCartError.id ==
                                        modalData.id
                            "
                        >
                            {{ $fn.tr("Out of Stock") }}
                        </div>
                    </div>
                    <div>
                        <div
                            class="description-acordion"
                            v-if="_.get(modalData, 'description')"
                            :class="{ active: desc }"
                        >
                            <div class="flexbox" @click="showDesc">
                                <div class="bold font-13">
                                    {{ $fn.tr("Description") }}
                                </div>
                                <div class="relative acordion-span">
                                    <span></span>
                                    <span :class="{ active: desc }"></span>
                                </div>
                            </div>
                            <slide-up-down
                                class="description-text"
                                :active="desc"
                                :duration="500"
                                v-html="_.get(modalData, 'description')"
                            ></slide-up-down>
                        </div>
                        <div
                            class="details-acordion"
                            :class="{ active: details }"
                        >
                            <div class="flexbox" @click="showDetails">
                                <div class="bold font-13">
                                    {{ $fn.tr("Product Details") }}
                                </div>
                                <div class="relative acordion-span">
                                    <span></span>
                                    <span :class="{ active: details }"></span>
                                </div>
                            </div>
                            <slide-up-down
                                class="details-text"
                                :active="details"
                                :duration="500"
                            >
                                <div
                                    class="title"
                                    v-if="_.get(modalData, 'size')"
                                >
                                    {{ $fn.tr("Size") }} :
                                    <span
                                        v-html="_.get(modalData, 'size')"
                                        class="bold font-16"
                                    ></span>
                                </div>
                                <div
                                    class="title"
                                    v-if="_.get(modalData, 'weight')"
                                >
                                    {{ $fn.tr("Weight") }} :
                                    <span
                                        v-html="_.get(modalData, 'weight')"
                                        class="bold font-16"
                                    ></span>
                                </div>
                                <!-- <div class="title" v-if="getMateial.length">
                                    {{ $fn.tr("Material") }} :
                                    <template
                                        v-for="(item, index) in getMateial"
                                    >
                                        <span
                                        :key="index"
                                            v-html="item.title"
                                            class="bold font-16"
                                        ></span>
                                    </template>
                                </div> -->
                            </slide-up-down>
                        </div>
                    </div>
                </div>
                <router-link
                    class="view-product"
                    :to="
                        `/${_.get(
                            $store.state.main.indx,
                            'locale'
                        )}/products/productview/${_.get(
                            modalData,
                            'id'
                        )}-${_.get(modalData, 'slug')}`
                    "
                    tag="a"
                >
                    <div class="font-13 bold partners-text">
                        {{ $fn.tr("View Product") }}
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="13.714"
                            viewBox="0 0 16 13.714"
                        >
                            <path
                                id="Icon_metro-arrow-up"
                                data-name="Icon metro-arrow-up"
                                d="M13.38,6.049,7.665.335a1.143,1.143,0,0,0-1.616,0L.335,6.049A1.143,1.143,0,0,0,1.951,7.665L5.714,3.9V14.857a1.143,1.143,0,0,0,2.286,0V3.9l3.763,3.763A1.143,1.143,0,0,0,13.38,6.049Z"
                                transform="translate(0 13.714) rotate(-90)"
                                fill="#81c7bd"
                            />
                        </svg>
                    </div>
                </router-link>
            </div>
        </div>
        <Notification :active="notification" @hide="notification = false">
            {{ $fn.tr("Added Successfully!") }}
        </Notification>
    </div>
</template>

<script>
import addToCartMixin from "../mixins/addToCartMixin";
import attributesMixin from "../mixins/attributesMixin";
import commaNum from "@/helpers/commaNum";

export default {
    props: ["modalData", "modalActive", "alreadyInWishList"],
    mixins: [addToCartMixin, attributesMixin],

    data() {
        return {
            sale: false,
            animActive: false,
            desc: false,
            details: false,
            currentId: null,
            verticalSwiperOptions: {
                mouseWheel: {
                    invert: true
                },
                breakpoints: {
                    320: {
                        slidesPerView: 1,
                        slidesOffsetAfter: 20
                    },
                    768: {
                        slidesPerView: 1,
                        slidesOffsetAfter: 20
                    },
                    1024: {
                        slidesPerView: 2,
                        slidesOffsetAfter: 20
                    },
                    1450: {
                        slidesPerView: 1.25,
                        slidesOffsetAfter: 20
                    },
                    1600: {
                        slidesPerView: 1.05,
                        slidesOffsetAfter: 30
                    }
                },
                direction: "vertical",
                spaceBetween: 10,
                freeMode: true,
                mousewheel: true
            }
        };
    },
    watch: {
        modalData: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.attributesData = val;
                }
            }
        },
        modalActive: {
            immediate: true,

            handler(val) {
                if (val) {
                    document.querySelector("html").style.overflow = "hidden";
                    document.querySelector("body").style.marginRight = "10px";
                } else {
                    document.querySelector("html").style.overflow = "auto";
                    document.querySelector("body").style.marginRight = "0px";
                    this.count = 1;
                }
            }
        },
        $route: {
            handler(val) {
                this.modalClose();
            }
        }
    },

    methods: {
        increaseCount() {
            if (this.modalData.stock_quantity > this.count) this.count++;
        },
        decreaseCount() {
            if (this.count > 1) {
                this.count--;
            }
        },
        chooseColor(item) {
            this.currentId = item.id;
        },
        showDesc() {
            this.desc = !this.desc;
        },
        showDetails() {
            this.details = !this.details;
        },
        addToWishAction() {
            this.$emit("addToWish");
            this.addToWishList(this.modalData, this.count);
        },
        removeFromWishAction() {
            this.$emit("removeFromWish");
            this.removeWishElement(this.modalData);
        },
        modalClose() {
            this.animActive = true;
            this.currentId = null;

            setTimeout(() => {
                this.$emit("closeModal", false);
                this.animActive = false;
            }, 150);
        },
        formatPrice(price) {
            return price ? commaNum(Number(price)) : 0;
        }
    }
};
</script>

<style scoped>
.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100vh;
    z-index: 999;
    display: none;
    align-items: center;
    transform: translate(-50%, -50%);
}
.out-of-stock {
    display: flex;
    align-items: center;
}
.modalShow {
    display: flex;
}
.close-svg {
    align-self: flex-end;
    margin: 0 13px 15px 0;
    cursor: pointer;
}
.flex-column {
    flex-direction: column;
}
.modal-background {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background-color: rgba(67, 80, 89, 0.2);
}

.modal-open {
    animation-name: modalAnim;
    animation-duration: 0.4s;
}

.modal-close {
    animation-name: modalCloseAnim;
    animation-duration: 0.2s;
}

@keyframes modalAnim {
    from {
        opacity: 0;
        transform: translate3d(0, 100px, 0);
    }
    to {
        opacity: 1;
        transform: translate3d(0, 0px, 0);
    }
}

@keyframes modalCloseAnim {
    from {
        opacity: 1;
        transform: translate3d(0, 0px, 0);
    }
    to {
        opacity: 0;
        transform: translate3d(0, 100px, 0);
    }
}

.modal-background-open {
    animation-name: modalBackAnim;
    animation-duration: 0.4s;
}

.modal-background-close {
    animation-name: modalBackCloseAnim;
    animation-duration: 0.2s;
}

@keyframes modalBackAnim {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

@keyframes modalBackgroundCloseAnim {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 0;
    }
}

.modal-content {
    display: flex;
    justify-content: space-between;
    padding: 30px 30px 0px 30px;
    height: 765px;
    width: 74.3%;
    /* width: 1420px; */
    border-radius: 30px;
    background: #ffffff;
    margin: 0 auto;
    z-index: 2;
    overflow: hidden;
}

.modal-content::-webkit-scrollbar {
    width: 0px;
}

.modal-content::-webkit-scrollbar-thumb {
    width: 0px;
}

.modal-content::-webkit-scrollbar-track {
    width: 0px;
}

.product-slider {
    width: 51%;
    margin-left: 0;
}

.product-slider .swiper-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 30px;
    overflow: hidden;
}

.follow path {
    fill: #fec55e;
}

.product-info {
    width: 41%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
}

.product-info::-webkit-scrollbar {
    width: 0px;
}

.product-info::-webkit-scrollbar-track {
    background: transparent;
}

.product-info::-webkit-scrollbar-thumb {
    background: transparent;
}

.product-info::-webkit-scrollbar-thumb {
    height: 0px;
    width: 0px;
    border-radius: 5px;
}

.product-name {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.product-brand span {
    margin-left: 10px;
}

.product-brand span:nth-child(1) {
    margin-left: 5px;
}

.border-bottom {
    padding-bottom: 20px;
    border-bottom: 1px solid #f7f7f7;
}

.product-content {
    display: flex;
    justify-content: space-between;
}

.product-title {
    margin-bottom: 10px;
    margin-top: 10px;
}

.product-details {
    display: flex;
    align-items: center;
}

.product-id {
    padding-left: 30px;
}

.color-picker {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.color-picker ul {
    display: flex;
    margin-left: 15px;
}

.color-picker ul li {
    border-radius: 50%;
    width: 36px;
    height: 36px;
    margin-right: 5px;
    position: relative;
}

.color-picker ul li.active {
    border: 1px solid #435059;
}

.color-picker ul li .background {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    box-shadow: 0px 0px 1.5px 0px #888888;
    transform: translate(-50%, -50%);
}

.add-item {
    display: flex;
    margin-top: 20px;
}

.add-item button {
    width: 100%;
    height: 44px;
    background: #81c7bd;
    border-radius: 10px;
    color: #fff;
    text-transform: uppercase;
}

.description-acordion {
    margin-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #f7f7f7;
    max-height: 40px;
    overflow: hidden;
    transition: 0.5s;
}

.details-acordion {
    margin-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #f7f7f7;
    max-height: 40px;
    overflow: hidden;
    transition: 0.5s;
}

.details-acordion.active {
    max-height: 500px;
}

.description-acordion.active {
    max-height: 500px;
}

.flexbox {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    padding-right: 20px;
}

.acordion-span span:nth-child(1) {
    position: absolute;
    top: 8px;
    right: 0;
    width: 10px;
    height: 2px;
    background: black;
    z-index: 4;
    border-radius: 40px;
}

.acordion-span span:nth-child(2) {
    position: absolute;
    top: 8px;
    right: 0;
    width: 10px;
    height: 2px;
    background: black;
    z-index: 4;
    border-radius: 40px;
    transform: rotate(90deg);
    transition: 0.5s;
}

.acordion-span span.active:nth-child(2) {
    transition: 0.5s;
    transform: rotate(0deg);
}

.description-text {
    line-height: 22px;
    transform: translate(0px, 10px);
}

.details-text {
    display: flex;
    flex-wrap: wrap;
    transform: translate(0px, 10px);
}

.details-text span {
    padding-left: 25px;
}

.details-text .title {
    margin-right: 80px;
    padding-top: 10px;
    display: flex;
}

.details-text .title p {
    margin-left: 20px;
}
.view-product {
    align-self: flex-end;
    display: flex;
    padding-bottom: 60px;
    margin-top: 20px;
    cursor: pointer;
}

.partners-text {
    display: flex;
    align-items: center;
}

.partners-text svg {
    margin-left: 10px;
    transform: rotate(180deg);
}

.follow-icon {
    width: 43px;
    margin-left: 30px;
    height: 45px;
}

.product-price {
    color: #181818;
}

.incriment {
    width: 120px;
    border-radius: 10px;
    border: 1px solid #f7f7f7;
    background: #fff;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 30px;
    cursor: pointer;
}
@media (min-width: 1366px) {
    .decrement:hover svg path {
        fill: #fec55e;
        transition: 0.3s;
    }
    .increment:hover svg path {
        fill: #fec55e;
        transition: 0.3s;
    }
}
.primary-color {
    text-transform: uppercase;
}
@media (max-width: 1919px) {
    .modal-content {
        width: 1398px;
    }
}

@media (max-width: 1599px) {
    .modal-content {
        width: 1030px;
        height: 564px;
    }

    .product-slider {
        width: 45%;
    }

    .product-info {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .view-product {
        padding-bottom: 23px;
    }
}

@media (max-width: 1365px) {
    .modal-content {
        width: 954px;
        height: 522px;
    }
}

@media (max-width: 1023px) {
    .modal-content {
        width: 702px;
        height: 702px;
        flex-direction: column;
        overflow: auto;
        overflow-x: hidden;
    }

    .product-slider {
        width: 100%;
        display: block;
        order: 2;
        min-height: 500px;
    }

    .product-info {
        order: 1;
        overflow: unset;
        width: 100%;
    }
}

@media (max-width: 767px) {
    .modal-content {
        width: 80%;
        height: 440px;
        padding: 20px 20px 0 20px;
        display: grid;
        flex-direction: column;
        overflow: auto;
        overflow-x: hidden;
    }

    .product-details {
        flex-direction: column;
        align-items: flex-start;
    }

    .product-slider {
        width: 100%;
        min-height: 260px;
        display: block;
        order: 2;
    }

    .product-price {
        font-size: 34px !important;
    }

    .product-info {
        padding-top: 20px;
        order: 1;
        overflow: unset;
        width: 100%;
        padding: 0 0 0 0;
    }

    .product-id {
        padding-left: 0px;
        margin: 10px 0 0 0;
    }

    .color-picker ul li {
        margin-left: 10px;
        padding: 1px;
        border-radius: 50%;
        border: 1px solid #435059;
        width: 28px;
        height: 28px;
    }

    .color-picker ul li .background {
        width: 24px;
        height: 24px;
        border-radius: 50%;
    }

    .incriment {
        width: 70%;
        margin-right: 10px;
    }
}
</style>
