<template>
    <div class="products-ad">
        <div class="products-ad-content">
            <page-title
                data-aos="fade-down"
                data-aos-delay="100"
                :colored-title="true"
                :title="ADdata.title"
            />

            <div
                class="card-text font-16 regular"
                data-aos="fade-right"
                data-aos-delay="200"
                v-html="ADdata.title2"
            ></div>

            <a
                :href="ADdata.url"
                class="font-13 bold underline uppercase text-hover"
                data-aos="fade-in"
                data-aos-delay="200"
                v-html="ADdata.url_text"
            >
            </a>
        </div>
        <div class="products-ad-img" data-aos="fade-left" data-aos-delay="400">
            <picture>
                <source
                    media="(max-width: 767px)"
                    loading="lazy"
                    :srcset="`${_.get(ADdata, 'image[0].devices.mobile')}`"
                />
                <source
                    media="(max-width: 1023px)"
                    loading="lazy"
                    :srcset="`${_.get(ADdata, 'image[0].devices.tablet')}`"
                />
                <img
                    :src="`${_.get(ADdata, 'image[0].devices.desktop')}`"
                    loading="lazy"
                    alt="image"
                />
            </picture>
        </div>
    </div>
</template>

<script>
import pageTitle from "../components/PageTitle";

export default {
    props: ["contentData", "ADdata"],

    components: {
        pageTitle
    },

    data() {
        return {};
    }
};
</script>

<style scoped>
.products-ad {
    margin-top: 173px;
    padding: 0 0 0 280px;
    height: 400px;
    background: #f7f7f7;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.background {
    padding: 0 0 0 245px;
}
.products-ad-content {
    max-width: 490px;
}
.card-text {
    margin: -50px 0 60px 0;
    display: -webkit-box;
    max-width: 400px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.products-ad-img {
    z-index: 10;
    position: relative;
    position: relative;
    min-width: 800px;
    height: 450px;
    left: 0;
    align-self: flex-end;
    justify-content: flex-end;
}
.products-ad-img img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
    top: 0;
}
@media (min-width: 1023px) {
    /* .products-ad-img img {
        min-width: 800px;
        max-height: 450px;
    } */
    .products-ad-img {
        top: 0px;
        /* top: 80px; */
    }
}
@media (min-width: 1599px) {
    /* .products-ad-img img {
        min-width: 950px;
        max-height: 670px;
    } */
    .products-ad-img {
        /* top: 120px; */
    }
}
@media (max-width: 1599px) {
    .products-ad {
        padding: 0 0 0 60px;
    }
    .products-ad-img {
        margin-bottom: 0;
    }
}
@media (max-width: 1365px) {
    .card-text {
        margin: -40px 0 60px 0;
    }

    .products-ad-img img {
        height: 100%;
    }
    .img-align img {
        height: 100px;
        width: 100px;
    }
    .products-ad {
        margin-top: 45px;
        padding: 0 0 0 35px;
    }
}
@media (max-width: 1023px) {
    .infrastructure-list {
        padding: 30px 0;
    }
    .card-text {
        margin: -40px 0 40px 0;
    }
    .products-ad-img {
        transform: translateX(100px) !important;
    }
}
@media (max-width: 767px) {
    .products-ad {
        flex-direction: column;
        align-items: unset;
        padding: 0 0 0 20px;
    }
    .products-ad-content {
        margin-top: 34px;
    }
    .products-ad-img {
        align-self: flex-start;
        transform: translateX(0px) !important;
        width: 400px;
    }
    .products-ad-img img {
        width: auto;
        height: 200px;
    }
    .card-text {
        margin: -20px 0 40px 0;
    }
}
</style>
