export default () => ({
    indx: {}, /// all the main data loaded by default
    conf: {}, /// project global configuration
    cache: {}, /// all the data tahat comes while website runing
    tmp: {}, /// temporary data for short use

    content: {}, /// to store all the content types data
    current_content: {
        primary: {
            data: []
        }
    }, /// to store all the content types data
    current_content_title: {}, /// to store all the content types data
    current_content_request_status: "", /// status for current content loaded/// 'pending' while request; 'done' after loaded content from server; 'error' if there is server error
    current_content_last_request: "", /// latest current content request stringify. /// to controll request dublications

    loader: {
        main: 0,
        form: 0,
        button: 0,
        loan_calculator: 0,
        currency_calculator: 0,
        deposit_calculator: 0,
        compare_products: 0
    }, /// loaders statuses over components

    selected_menu: {},
    selected_menu_locales: {},
    locale: null,
    translations: "",
    siteMode: "production",
    xRates: [],
    contentId: null,
    loadingPageChange: false,
    loadedTemplate: null,

    menuStatus: { level1: 1, level2: 4, level3: 0, level4: 0 },
    breadcrumbsLeveled: {}, /// lvl1: 0, lvl2: 0, lvl3: 0, lvl4: 0
    menuIndexById: {}, /// object with menu id: menu index /// just for fast searching
    offset: 0,
    fixedHead: false,
    breadcrumbList: [],
    filterIds: [],
    sortId: null,
    childFilterIds: [],
    categoryProducts: {},
    countall: 0,
    currenPageProducts: 1,
    load_products: false,
    first_load: true,
});
