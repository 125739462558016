<template>
    <div class="press-releases-wrapper">
        <div class="press-releases-card third" :key="index" v-for="(item, index) in data" v-if="_.get(item,'content.tabs[0].content') && _.get(item,'file[0].url')" data-aos="fade-up" data-aos-delay="250">
            <div class="press-releases-card-title" v-html="item.title" />
            <div class="press-releases-card-link">
                <div class="press-releases-card-icon">
                    <img loading="lazy" src="../../assets/icons/right-arrow.svg" alt="right">
                </div>

                <router-link :to="$store.getters['main/getSingleUrl'](item)" tag="p" class="press-releases-card-link-title underline"> {{$fn.tr('view  more')}}</router-link>
            </div>
        </div>
       <div class="press-releases-card" :key="index" v-for="(item, index) in data" v-if="!_.get(item,'file[0].url') && _.get(item,'url')" data-aos="fade-up" data-aos-delay="350">
           <div class="press-releases-card-title" v-html="item.title" />
           <div class="press-releases-card-link">
               <div class="press-releases-card-icon">
                   <img loading="lazy" src="../../assets/icons/view.svg" alt="view">
               </div>
               <a rel="noopener" :href="item.url" target="_blank" class="press-releases-card-link-title underline">  {{$fn.tr('view site')}}</a>
           </div>
       </div>
        <div class="press-releases-card first" :key="index" v-for="(item, index) in data"
             v-if="!_.get(item,'content.tabs[0].content') && _.get(item,'file[0].url')"
             data-aos="fade-up" data-aos-delay="450">
            <div class="press-releases-card-title" v-html="item.title" />
            <div class="press-releases-card-link">
                <div class="press-releases-card-icon">
                    <img loading="lazy" src="../../assets/icons/pdf.svg" alt="pdf">
                </div>

                <a rel="noopener" target="_blank" :href="_.get(item,'file[0].url')" class="press-releases-card-link-title underline">{{_.get(item,'file[0].title') ? _.get(item,'file[0].title') : 'Commercial.pdf'}}</a>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        props: ["data"],

    }
</script>

<style scoped>
    .press-releases-card {
        width: 100%;
        padding: 25px;
        border-radius: 30px;
        border: 1px solid #D9EEEB;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .press-releases-card-title {
        color: #435059;
        font-family: var(--noto-regular);
        font-size: var(--normalFontSize);
        border-bottom: 1px solid #F7F7F7;
        padding-bottom: 30px;
        padding-top: 10px;
        max-height: 110px;
        overflow: hidden;
        position: relative;
    }
    .press-releases-card-title:after{
        content: '';
        display: block;
        width: 100%;
        height: 30px;
        background: #fff;
        position: absolute;
        bottom: 0;
    }

    .press-releases-card-link-title {
        font-family: var(--noto-bold);
        font-size: var(--normalFontSize);
        margin-left: 10px;
        text-transform: uppercase;
    }

    .press-releases-card-link {
        display: flex;
        align-items: center;
        margin-top: 40px;
        cursor: pointer;
    }

    .press-releases-card-icon {
        width: 44px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
    }

    .press-releases-card.first .press-releases-card-icon {
        background-color: var(--light-green);
    }

    .press-releases-card.second .press-releases-card-icon,   .press-releases-card.fourth .press-releases-card-icon {
        background-color: #FFEECE;
    }

    .press-releases-card.third .press-releases-card-icon {
        background-color: #F7F7F7;
        transform: rotate(180deg);
    }

    .press-releases-wrapper {
        grid-column-gap: 20px;
        grid-row-gap: 20px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(23%, 1fr));
    }

    @media screen and (max-width: 1600px){
        .press-releases-wrapper {
            grid-template-columns: repeat(auto-fill,minmax(520px,1fr));
        }
    }
    @media screen and (max-width: 1365px){
        .press-releases-wrapper {
            grid-template-columns: repeat(auto-fill,minmax(420px,1fr));
        }
    }

    @media screen and (max-width: 1024px){
        .press-releases-card {
            width: 100%;
        }

        .press-releases-wrapper {
            grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
        }
    }

</style>
