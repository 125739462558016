<template>
    <div class="personal-info-wrapper">
        <div class="authorized-cart-addresses">
            <div class="address-info" v-if="!isShippingAddress">
                <p>{{address.fullname ? address.fullname : address.name}}</p>
                <p>{{address.personal_number}}</p>
                <p>{{address.address}}</p>
                <p>{{address.city}}</p>
                <p>{{address.phone}}</p>
                <p>{{address.email }}</p>
                <p class="edit-address"  v-if="!addressFromAuth" @click="$emit('editAddress', address)">{{$fn.tr(editText)}}</p>
                <p class="edit-address" v-else  @click="$emit('editAddressFromAuth', address)">{{$fn.tr(editText)}}</p>
            </div>
            <div class="address-info" v-else>
                <p>{{address.name ? address.name : address.fullname}}</p>
                <p>{{address.city}}</p>
                <p>{{address.address}}</p>
                <p class="edit-address"  @click="$emit('editAddressFromAuth', address)">{{$fn.tr(editText)}}</p>
            </div>


            <div class="checkbox-default-radio" v-if="showCheckbox">
                <div @click="$emit('activeClass', address)" class="radio-checkbox" :class="{active: activeClass}"/>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        props: {
            address: {
                type: [Object],
                required: true
            },
            addressFromAuth: {
                type: Boolean,
                default: false,
                required: false
            },
            editText: {
                type: String,
                default: 'edit address'
            },
            activeClass: {
                type: Boolean,
            },
            isShippingAddress: {
                type: Boolean,
                default: false
            },
            showCheckbox: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {}
        },
        methods: {}
    }
</script>

<style scoped>
    .personal-info-wrapper {
        border: 1px solid #ECF0F1;
        border-radius: 10px;
        padding: 30px;
        margin-bottom: 20px;
        /*transition: .5s ease;*/
    }

    .personal-info-wrapper.active-address {
        border: 1px solid var(--orange);
        /*transition: .5s ease;*/
    }

    .personal-info-wrapper p {
        font-size: 16px;
        font-family: var(--noto-regular);
    }

    .personal-info-wrapper p:first-child {
        font-family: var(--noto-bold);
        color: var(--green);
        text-transform: capitalize;
    }

    .personal-info-wrapper .edit-address {
        font-family: var(--noto-bold);
        margin-top: 30px;
        text-decoration: underline;
        cursor: pointer;
        color: #181818;
        text-transform: uppercase;
        font-size: 13px;
    }

    .authorized-cart-addresses {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .radio-checkbox {
        position: relative;
        cursor: pointer;
    }

    .radio-checkbox:before {
        content: '';
        -webkit-appearance: none;
        background-color: transparent;
        border: 1px solid rgba(67, 80, 89, 0.3);;
        padding: 10px;
        display: inline-block;
        position: relative;
        vertical-align: bottom;
        cursor: pointer;
        border-radius: 50%;
        margin-right: 8px;
    }

    .radio-checkbox.active:after {
        content: '';
        display: block;
        position: absolute;
        top: 3px;
        left: 7px;
        width: 5px;
        height: 9px;
        border: solid #FFFFFF;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
    }

    .radio-checkbox.active:before {
        background-color: var(--orange);
        border: 1px solid var(--orange);
        border-radius: 50%;
    }
</style>

