<template>
    <section>
        <div class="container-wrapper">
            <div class="cafe-content">
                <div class="left-content">
                    <content-title class="heading-margin" data-aos="fade-up" :colored-title="true" :title="$store.state.main.selected_menu.title" />

                    <div class="cafe-text remove-styles13" data-aos="fade-up" data-aos-delay="400" v-html="_.get(this.$store.state.main.current_content, 'primary.data.list[0].content.tabs[0].content')"></div>
                </div>
                <div class="right-content" data-aos="fade-down">
                    <div class="download-content">
                        <div class="download-svg">
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                                <g id="Group_3631" data-name="Group 3631" transform="translate(-1114.125 -368.125)">
                                    <g id="Rectangle_56" data-name="Rectangle 56" transform="translate(1114.125 368.125)" fill="#f7f7f7" stroke="#81c7bd" stroke-width="2">
                                    <rect width="40" height="40" rx="10" stroke="none"/>
                                    <rect x="1" y="1" width="38" height="38" rx="9" fill="none"/>
                                    </g>
                                    <path id="Icon_metro-file-pdf" data-name="Icon metro-file-pdf" d="M20.924,23.236A16.631,16.631,0,0,0,22.76,24.46a21.69,21.69,0,0,1,2.557-.153q3.213,0,3.869,1.071a1.056,1.056,0,0,1,.044,1.137.063.063,0,0,1-.022.044l-.044.044v.022q-.131.831-1.552.831a9.017,9.017,0,0,1-2.514-.437,15.938,15.938,0,0,1-2.842-1.158,39.359,39.359,0,0,0-8.568,1.814Q10.344,33.4,8.4,33.4a1.271,1.271,0,0,1-.612-.153l-.525-.262q-.022-.022-.131-.109A.9.9,0,0,1,7,32.088a4.747,4.747,0,0,1,1.224-2,10.564,10.564,0,0,1,2.885-2.109.319.319,0,0,1,.5.131.126.126,0,0,1,.044.087q1.137-1.858,2.339-4.306a33.361,33.361,0,0,0,2.273-5.727,17.669,17.669,0,0,1-.667-3.486,8.5,8.5,0,0,1,.142-2.787q.24-.874.918-.874h.481a.922.922,0,0,1,.765.328,1.746,1.746,0,0,1,.2,1.486.474.474,0,0,1-.087.175.569.569,0,0,1,.022.175v.656a28.623,28.623,0,0,1-.306,4.2,11.059,11.059,0,0,0,3.191,5.2ZM8.333,32.22q1.137-.525,2.995-3.454A12.46,12.46,0,0,0,9.415,30.6,7.265,7.265,0,0,0,8.333,32.22Zm8.7-20.109A6.5,6.5,0,0,0,16.989,15q.022-.153.153-.962,0-.066.153-.94a.492.492,0,0,1,.087-.175.063.063,0,0,1-.022-.044.044.044,0,0,0-.011-.033.044.044,0,0,1-.011-.033,1.258,1.258,0,0,0-.284-.787.063.063,0,0,1-.022.044v.044Zm-2.71,14.448a32.061,32.061,0,0,1,6.208-1.771,3.3,3.3,0,0,1-.284-.208,3.915,3.915,0,0,1-.35-.3,11.578,11.578,0,0,1-2.776-3.847,29.209,29.209,0,0,1-1.814,4.306q-.656,1.224-.984,1.814Zm14.12-.35q-.525-.525-3.06-.525a8.268,8.268,0,0,0,2.71.612,2.12,2.12,0,0,0,.393-.022q0-.022-.044-.066Z" transform="translate(1116.156 366.314)" fill="#fec55e"/>
                                </g>
                            </svg>
                        </div>
                        <div>
                            <div class="download font-13 bold primary-color" v-html="_.get(this.$store.state.main.current_content, 'primary.data.list[0].file_teaser')"></div>
                            <a rel="noopener" :href="_.get(this.$store.state.main.current_content, 'primary.data.list[0].files[0].url')" target="_blank">
                                <div class="pdf-name font-13 bold text-hover" v-html="_.get(this.$store.state.main.current_content, 'primary.data.list[0].file_title')"></div>
                            </a>
                        </div>
                    </div>
                    <a class="download-button orange-hover" rel="noopener" :href="_.get(this.$store.state.main.current_content, 'primary.data.list[0].files[0].url')" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                            <g id="Icon_feather-download" data-name="Icon feather-download" transform="translate(-3 -3)">
                                <path id="Path_617" data-name="Path 617" d="M31.5,22.5v6a3,3,0,0,1-3,3H7.5a3,3,0,0,1-3-3v-6" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                <path id="Path_618" data-name="Path 618" d="M10.5,15,18,22.5,25.5,15" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                <path id="Path_619" data-name="Path 619" d="M18,22.5V4.5" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                            </g>
                        </svg>
                    </a>

                </div>
            </div>
            <div class="mobile-right-content" data-aos="fade-up">
                <div class="download-content">
                    <div class="download-svg">
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                            <g id="Group_3631" data-name="Group 3631" transform="translate(-1114.125 -368.125)">
                                <g id="Rectangle_56" data-name="Rectangle 56" transform="translate(1114.125 368.125)" fill="#f7f7f7" stroke="#81c7bd" stroke-width="2">
                                <rect width="40" height="40" rx="10" stroke="none"/>
                                <rect x="1" y="1" width="38" height="38" rx="9" fill="none"/>
                                </g>
                                <path id="Icon_metro-file-pdf" data-name="Icon metro-file-pdf" d="M20.924,23.236A16.631,16.631,0,0,0,22.76,24.46a21.69,21.69,0,0,1,2.557-.153q3.213,0,3.869,1.071a1.056,1.056,0,0,1,.044,1.137.063.063,0,0,1-.022.044l-.044.044v.022q-.131.831-1.552.831a9.017,9.017,0,0,1-2.514-.437,15.938,15.938,0,0,1-2.842-1.158,39.359,39.359,0,0,0-8.568,1.814Q10.344,33.4,8.4,33.4a1.271,1.271,0,0,1-.612-.153l-.525-.262q-.022-.022-.131-.109A.9.9,0,0,1,7,32.088a4.747,4.747,0,0,1,1.224-2,10.564,10.564,0,0,1,2.885-2.109.319.319,0,0,1,.5.131.126.126,0,0,1,.044.087q1.137-1.858,2.339-4.306a33.361,33.361,0,0,0,2.273-5.727,17.669,17.669,0,0,1-.667-3.486,8.5,8.5,0,0,1,.142-2.787q.24-.874.918-.874h.481a.922.922,0,0,1,.765.328,1.746,1.746,0,0,1,.2,1.486.474.474,0,0,1-.087.175.569.569,0,0,1,.022.175v.656a28.623,28.623,0,0,1-.306,4.2,11.059,11.059,0,0,0,3.191,5.2ZM8.333,32.22q1.137-.525,2.995-3.454A12.46,12.46,0,0,0,9.415,30.6,7.265,7.265,0,0,0,8.333,32.22Zm8.7-20.109A6.5,6.5,0,0,0,16.989,15q.022-.153.153-.962,0-.066.153-.94a.492.492,0,0,1,.087-.175.063.063,0,0,1-.022-.044.044.044,0,0,0-.011-.033.044.044,0,0,1-.011-.033,1.258,1.258,0,0,0-.284-.787.063.063,0,0,1-.022.044v.044Zm-2.71,14.448a32.061,32.061,0,0,1,6.208-1.771,3.3,3.3,0,0,1-.284-.208,3.915,3.915,0,0,1-.35-.3,11.578,11.578,0,0,1-2.776-3.847,29.209,29.209,0,0,1-1.814,4.306q-.656,1.224-.984,1.814Zm14.12-.35q-.525-.525-3.06-.525a8.268,8.268,0,0,0,2.71.612,2.12,2.12,0,0,0,.393-.022q0-.022-.044-.066Z" transform="translate(1116.156 366.314)" fill="#fec55e"/>
                            </g>
                        </svg>
                    </div>
                    <div class="file-name">
                        <div class="download font-13 bold primary-color" v-html="_.get(this.$store.state.main.current_content, 'primary.data.list[0].file_teaser')"></div>
                        <a rel="noopener" :href="_.get(this.$store.state.main.current_content, 'primary.data.list[0].files[0].url')" target="_blank">
                            <div class="pdf-name font-13 bold" v-html="_.get(this.$store.state.main.current_content, 'primary.data.list[0].file_title')"></div>
                        </a>
                    </div>
                </div>
                <a  class="download-button" rel="noopener" :href="_.get(this.$store.state.main.current_content, 'primary.data.list[0].files[0].url')" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                        <g id="Icon_feather-download" data-name="Icon feather-download" transform="translate(-3 -3)">
                            <path id="Path_617" data-name="Path 617" d="M31.5,22.5v6a3,3,0,0,1-3,3H7.5a3,3,0,0,1-3-3v-6" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                            <path id="Path_618" data-name="Path 618" d="M10.5,15,18,22.5,25.5,15" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                            <path id="Path_619" data-name="Path 619" d="M18,22.5V4.5" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                        </g>
                    </svg>
                </a>

            </div>
        </div>
        <SlicedSlider data-aos="fade-up" data-aos-delay="800" :sliderData="sortSlider" />
        <CartInfo />
    </section>
</template>

<script>
import ContentTitle from "../components/ContentTitle";
import CartInfo from "../components/CartInfo"
import SlicedSlider from "../components/SlicedSlider"

export default {

    components: {
        ContentTitle,
        CartInfo,
        SlicedSlider
    },

    data() {
        return {
            title: 'Mysa Cafe',
            swiperOptions: {
    			speed: 400,
    			breakpoints: {
                    768: {
			            slidesPerView: 1.5
                    },
                    1024: {
			            slidesPerView: 1.5
                    },
                    1366: {
			            slidesPerView: 1.5
                    },
                    1600: {
			            slidesPerView: 1.5
                    },
                    1920: {
			            slidesPerView: 1.5
                    }
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
            },
            cardInputs: [
                {
                    name: "First Name",
                    type: "text",
                    required: true,
                },
                {
                    name: "Phone Number",
                    type: "tel",
                    required: false,
                },
            ]
        }
    },
    computed: {

        sortSlider(){
            const sliderSort = _.get(this.$store.state.main.current_content, 'secondary.CafeBannerSlider.data.list')

            return sliderSort.sort((a, b) => a.sort - b.sort)
        }
    },
    mounted() {
        this.$store.commit('main/setState', {key: "breadcrumbList", val: [{title: 'Café' }]})

    }

}
</script>

<style scoped>
    .cafe-content{
        display: flex;
        justify-content: space-between;
        margin: 0px 0 52px;
    }
    .mb-144{
        margin-bottom: 144px;
    }
    .left-content{
        width: 58%;
        margin-right: 80px;
    }
    .right-content {
        width: 32%;
        background: #F7F7F7;
        border-radius: 10px;
        padding: 10px;
        height: 130px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .download-content{
        display: flex;
        align-items: center;
    }
    .download-svg{
        margin: 10px 40px 0 23px;
    }
    .file-name{
        margin: 12px 0px 0 7px;
    }
    .download-content .pdf-name{
        text-decoration: underline;
        margin-top: 5px;
    }
    .download-button{
        display: flex;
        align-items: center;
        justify-content: center;
        background: #FEC55E;
        height: 100%;
        width: 90px;
        border-radius: 10px;
        cursor: pointer;
    }
    .mobile-right-content{
        display: none;
    }
    .content-heading{
        margin-bottom: 35px;
    }
    .partners-text{
        margin-bottom: 35px;
    }
    @media (max-width: 1600px){
        .cafe-slider-swiper{
            padding-top: 583px;
        }
        .download-svg{
            margin: 0 20px 0 20px;
        }
    }
    @media screen and (max-width: 1366px) {
        .left-content {
            width: 55%;
        }
        .right-content {
            width: 45%;
        }

        .pdf-wrapper, .book-cal {
            width: 100%;
        }
        .download-svg{
            margin: 0 20px 0 10px;
        }
        .cafe-slider-swiper{
            padding-top: 498px;
        }
    }

    @media screen and (max-width: 1024px){
        .right-content, .left-content {
            width: 45%;
        }
        .content-heading{
            margin-bottom: 21px;
        }
        .cafe-slider-swiper{
            padding-top: 373px;
        }
    }
    @media screen and (max-width: 1023px){
        .right-content {
            display: none;
        }
        .mobile-right-content{
            width: 100%;
            background: #F7F7F7;
            border-radius: 10px;
            padding: 10px;
            height: 130px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 35px;
            width: 100%;
        }
        .left-content {
            width: 100%;
            margin-right: 0;
        }
    }
    @media screen and (max-width: 767px){
        .cafe-slider-swiper{
            padding-top: 220px;
        }
        .download-svg{
            margin: 0 10px 0 0px;
        }
        .download-button{
            height: 50%;
            width: 100%;
        }
    }
</style>
