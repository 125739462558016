<script>
import { mapGetters } from "vuex";
import Notification from "../components/Notification";
import Vue from "vue";
export default {
    components: { Notification },
    data() {
        return {
            cartId: null,
            wishCartId: null,
            notification: false,
            quantity: 1,
            count: 1,
            active: false
        };
    },

    computed: {
        ...mapGetters({
            getWishListItems: "shop/getWishListItems",
            getCartItems: "shop/getCartItems"
        })
    },
    methods: {
        //cart list actions
        async addToCart(passedData, count = 1, showPopup = true) {
            if (this.$store.state.shop.loadCartList) {
                return false;
            }
            if (!this.showAddToCart(passedData)) {
                this.$store.commit("shop/setState", {
                    key: "showCartError",
                    val: { value: true, id: passedData.id }
                });
                setTimeout(() => {
                    this.$store.commit("shop/setState", {
                        key: "showCartError",
                        val: { value: false, id: null }
                    });
                }, 5000);
                return false;
            }
            this.$store.commit("shop/setState", {
                key: "showCartError",
                val: { value: false, id: null }
            });
            const itemCardProduct = this.getCartItems?.data?.list.find(
                product => product?.id == passedData.id
            );
            if (
                itemCardProduct &&
                itemCardProduct.quantity + count >=
                    itemCardProduct?.stock_quantity
            ) {
                return false;
            }
            await this.$store.dispatch("shop/addToCart", {
                id: passedData.id,
                quantity: count
            });
            this.$emit("clickedCartAdd");
            this.count = 1;
            if (showPopup)
                this.$store.state.shop.isDesktopScrollPosition
                    ? this.$store.commit("shop/setState", {
                          key: "cartPopupIsOpen",
                          val: true
                      })
                    : this.$store.commit("shop/setState", {
                          key: "mobileCartPopupIsOpen",
                          val: true
                      });
        },
        removeCartItem(data) {
            this.cartId = data.id;
            setTimeout(() => {
                this.$store.dispatch("shop/deleteCartItem", {
                    id: data.id,
                    uniqueID: this.$cookies.get("uniqueID")
                });
            }, 400);
        },
        decrementCart(listData) {
            this.$store.dispatch("shop/extractCartItem", {
                id: listData.id,
                uniqueID: this.$cookies.get("uniqueID")
            });
        },

        //wish list actions
        addToWishList(passedData, count = 1) {
            this.$store.dispatch("shop/addToWishList", {
                id: passedData.id,
                quantity: count
            });
            this.notification = true;
        },
        removeWishElement(data) {
            this.wishCartId = data.id;
            this.active = false;
            setTimeout(() => {
                this.$store.dispatch("shop/deleteWishItem", {
                    id: data.id,
                    uniqueID: localStorage.wishUniqueID
                });
            }, 400);
        },
        decrementWishItem(listData) {
            this.$store.dispatch("shop/extractWishItem", {
                id: listData.id,
                uniqueID: localStorage.wishUniqueID
            });
        },

        showAddToCart(passedData) {
            const cartItems = _.get(this.getCartItems, "data.list");
            const currentProduct = cartItems?.find(
                product => product.id == passedData?.id
            );
            if (
                currentProduct?.quantity &&
                currentProduct?.quantity >= passedData.stock_quantity - 1
            ) {
                return false;
            }
            return passedData?.stock_price && passedData?.stock_quantity > 1;
        }
    }
};
</script>
