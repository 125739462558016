<template>
    <div class="sliced-slider">
        <swiper ref="mySwiper" :options="swiperOptions">
            <swiper-slide v-for="(item, index) in sliderData" :key="index">
                <div class="sliced-slider-swiper">
                    <picture >
                        <div class="flex">
                            <source
                                :style="{
                                    width: 100 - _.get(item, 'percentage') + '%',
                                    'margin-left':   _.get(item, 'percentage') + '%' }"
                                v-if="_.get(item, 'optional_image[0]')"
                                loading="lazy"
                                media="(max-width: 767px)"
                                :srcset="(`${_.get(item, 'optional_image[0].devices.mobile')}`)"
                            />
                            <source
                                :style="{width: _.get(item, 'percentage') + '%'}"
                                loading="lazy"
                                media="(max-width: 767px)"
                                :srcset="(`${_.get(item, 'main_image[0].devices.mobile')}`)"
                            />
                        </div>
                        <div class="flex">
                            <source
                                :style="{
                                    width: 100 - _.get(item, 'percentage') + '%',
                                    'margin-left':   _.get(item, 'percentage') + '%' }"
                                v-if="_.get(item, 'optional_image[0]')"
                                loading="lazy"
                                media="(max-width: 1023px)"
                                :srcset="(`${_.get(item, 'optional_image[0].devices.tablet')}`)"
                            />
                            <source
                                :style="{width: _.get(item, 'percentage') + '%'}"
                                loading="lazy"
                                media="(max-width: 1023px)"
                                :srcset="(`${_.get(item, 'main_image[0].devices.tablet')}`)"
                            />
                        </div>

                        <div class="flex">
                            <img
                                alt="img"
                                :style="{width: _.get(item, 'percentage') + '%'}"
                                loading="lazy"
                                :src="(`${_.get(item, 'main_image[0].devices.desktop')}`)"
                            />
                            <img
                                loading="lazy"
                                alt="img"
                                :style="{
                                    width: 100 - _.get(item, 'percentage') + '%',
                                    'margin-left':   _.get(item, 'percentage') + '%' }"
                                v-if="_.get(item, 'optional_image[0]')"
                                :src="(`${_.get(item, 'optional_image[0].devices.desktop')}`)"
                            />
                        </div>
                    </picture>
                </div>
            </swiper-slide>
        </swiper>
        <div class="swiper-pagination" v-if="sliderData.length > 1"></div>
    </div>
</template>

<script>

export default {

    props: ["sliderData"],

    data() {
        return {
            swiperOptions: {
    			speed: 400,
    			breakpoints: {
                    320: {
                        slidesPerView: 1
                    },
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
            },
        }
    },
}
</script>

<style>
    .width100{
        width: 100%;
    }
    .sliced-slider{
        position: relative;
    }
    .sliced-slider-swiper{
        position: relative;
        padding-top: 700px;
    }
    .sliced-slider-swiper img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
    }
    .sliced-slider .swiper-pagination{
        position: absolute;
        bottom: 15px;
        left: 46%;
        transform: translate(-15px, -46%);
    }
    .sliced-slider .swiper-pagination-bullet{
        width: 10px;
        height: 10px;
        margin: 0 10px !important;
        background: #FFFFFF;
        opacity: 1;
    }
    .sliced-slider .swiper-pagination-bullet-active{
        background: #FEC55E;
    }
    @media (max-width: 1919px){
        .sliced-slider-swiper{
            padding-top: 583px;
        }
    }
    @media (max-width: 1599px){
        .sliced-slider-swiper{
            padding-top: 498px;
        }
    }
    @media screen and (max-width: 1365px) {
        .sliced-slider-swiper{
            padding-top: 373px;
        }
    }
    @media screen and (max-width: 1023px){
        .sliced-slider-swiper{
            padding-top: 373px;
        }
    }
    @media screen and (max-width: 767px){
        .sliced-slider-swiper{
            padding-top: 218px;
        }
    }

    @media screen and (max-width: 767px){
        .sliced-slider .swiper-pagination{
            bottom: 0;
            transform: translate(-50%, -50%);
            width: 100%;
        }
        .download-content{
            width: 100%;
        }
        .file-name{
            margin: 0 !important;
        }
        .mobile-right-content{
            flex-direction: column;
        }
    }
</style>
