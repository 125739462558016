import Vue from "vue";

export default {
    setState : function (state, data) {
        // Validate data key & value not equal false
        if (! data.key) return false;
        Vue.set(state, data.key, data.val);
    },

}
