<template>
    <div class="brand-card">
        <div class="brand-card-img">
            <picture>
                <source
                    media="(max-width: 767px)"
                    loading="lazy"
                    :srcset="(`${sliderData.cover_image[0].devices.mobile}`)"
                />
                <source
                    media="(max-width: 1023px)"
                    loading="lazy"
                    :srcset="(`${sliderData.cover_image[0].devices.tablet}`)"
                />
                <img
                    :src="(`${sliderData.cover_image[0].devices.desktop}`)"
                    loading="lazy"
                    alt="image"
                />
            </picture>
            <svg v-if="!active" style="cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 44 44" @click="active = !active">
                <g id="Group_3812" data-name="Group 3812" transform="translate(-378 -1115)">
                <circle id="Ellipse_3" data-name="Ellipse 3" cx="22" cy="22" r="22" transform="translate(378 1115)" fill="#ffeece"/>
                <path id="Icon_feather-heart" data-name="Icon feather-heart" d="M17.989,5.806a4.467,4.467,0,0,0-6.318,0l-.861.861L9.95,5.806a4.468,4.468,0,1,0-6.318,6.318l.861.861L10.81,19.3l6.318-6.318.861-.861a4.467,4.467,0,0,0,0-6.318Z" transform="translate(389.549 1125.348)" fill="none" stroke="#fec55e" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                </g>
            </svg>

            <svg v-if="active" style="cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" @click="active = !active">
                <g id="Group_14" data-name="Group 14" transform="translate(-378 -1115)">
                    <circle id="Ellipse_3" data-name="Ellipse 3" cx="15" cy="15" r="15" transform="translate(378 1115)" fill="#fec55e"/>
                    <path id="Icon_feather-heart" data-name="Icon feather-heart" d="M13,5.39a3.045,3.045,0,0,0-4.308,0l-.587.587L7.523,5.39A3.046,3.046,0,1,0,3.215,9.7l.587.587L8.11,14.592l4.308-4.308L13,9.7A3.045,3.045,0,0,0,13,5.39Z" transform="translate(385.135 1120.625)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                </g>
            </svg>
            <div class="brand-pos">
                <div class="brand-img">
                    <picture>
                        <source
                            media="(max-width: 767px)"
                            loading="lazy"
                            :srcset="(`${sliderData.brand_image[0].devices.mobile}`)"
                        />
                        <source
                            media="(max-width: 1023px)"
                            loading="lazy"
                            :srcset="(`${sliderData.brand_image[0].devices.tablet}`)"
                        />
                        <img

                            :src="(`${sliderData.brand_image[0].devices.desktop}`)"
                            loading="lazy"
                            alt="image"
                        />
                    </picture>
                </div>
            </div>
        </div>
        <a rel="noopener" :href="sliderData.url" target="_blank">
            <div class="flex align-center">
                <div class="brand-discount bold font-21 primary-color">
                    {{ sliderData.sale }}
                </div>
                <div class="discount-range font-21 light">
                    {{ `From ` + moment(sliderData.date_from).format('DD MMM') + ` to ` + moment(sliderData.date_to).format('DD MMM') }}
                </div>
            </div>
            <div class="brand-descr line-height font-16 regular"> {{ sliderData.title }}</div>
        </a>
    </div>

</template>

<script>
import { type } from 'os'
import moment from "moment"

export default {
    props: ["sliderData"],

    data(){
        return{
            active: false,
        }
    },
}

</script>

<style scoped>
    .flex{
        display: flex;
    }
    .align-center{
        align-items: center;
    }
    .active path {
        fill: #fff
    }
    .brand-card-img{
        position: relative;
        padding-top: 66%;
        border-radius: 30px;
        margin-bottom: 20px;
        overflow: hidden;
    }
    .brand-card-img svg{
        cursor: pointer;
        position: absolute;
        top: 30px;
        right: 30px;
    }
    .brand-card-img img:hover{
        transition: 0.5s;
        transform: scale(1.1);
    }
    .brand-card-img .brand-pos{
        position: absolute;
        bottom: 20px;
        left: 20px;
    }
    .brand-card-img .brand-img{
        position: relative;
        width: 100px;
        height: 100px;
        border-radius: 10px;
        background: #fff;
        z-index: 2;
        overflow: hidden;
    }
    .brand-card-img .brand-img img{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: contain;
        backface-visibility: hidden;
    }
    .brand-card-img img{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        backface-visibility: hidden;
        transition: 0.3s;
    }
    .brand-discount{
        padding-right: 30px;
        border-right: 1px solid #DCDFE0;
    }
    .discount-range{
        padding-left: 30px;
    }
    .brand-descr{
        color: #435059;
        margin-top: 20px;
        width: 90%;
    }
    @media (max-width: 1365px){
        .brand-discount{
            padding-right: 15px;
        }
        .discount-range{
            padding-left: 15px;
        }
    }
    @media (max-width: 1023px){
        .brand-discount{
            padding-right: 5px;
        }
        .discount-range{
            padding-left: 5px;
        }
    }
    @media (max-width: 767px){
        .product-price{
            font-size: 21px;
        }
        .font-21{
            font-size: 18px;
        }
    }
</style>
