<template>
  <div>
    <header class="relative" id="header">
      <div class="header" data-aos="fade" data-aos-delay="100">
        <div class="header-content container-wrapper">
          <div class="menus">
            <div
              class="burger"
              data-aos="fade-down"
              data-aos-delay="100"
              @click="menuStatus = true"
            >
              <div class="burger-items">
                <span></span>
                <span></span>
              </div>
            </div>
            <div class="menu-items">
              <p class="header-menu-item" data-aos="fade-down" data-aos-delay="100">
                <span
                  @click="goToLink('about-mysa')"
                  :class="{
                    active: $store.state.main.loadedTemplate === 'AboutMysa',
                  }"
                  class="menu-item"
                  >{{ $fn.tr('Mysa') }}
                </span>
              </p>
              <p class="header-menu-item" data-aos="fade-down" data-aos-delay="150">
                <span
                  @click="goToLink('category')"
                  :class="{
                    active: $store.state.main.loadedTemplate === 'ChooseCategory',
                  }"
                  class="menu-item"
                  >{{ $fn.tr('Products') }}
                </span>
              </p>
              <p class="header-menu-item" data-aos="fade-down" data-aos-delay="200">
                <span
                  @click="goToLink('brands')"
                  :class="{
                    active: $store.state.main.loadedTemplate === 'Brands',
                  }"
                  class="menu-item"
                  >{{ $fn.tr('Brands') }}
                </span>
              </p>
            </div>
          </div>
          <div class="logo" data-aos="fade" data-aos-delay="100">
            <router-link :to="`/${$store.state.main.locale}/home`" tag="div">
              <img
                loading="lazy"
                v-if="$store.state.main.locale === 'en'"
                src="../../assets/img/Logo.svg"
                alt="logo"
              />
              <img loading="lazy" v-else src="../../assets/img/mysa-geo.svg" alt="logo" />
            </router-link>
          </div>

          <div class="icons">
            <div @click="searchOpen = true" data-aos="fade-up" data-aos-delay="100">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <g id="Group_791" data-name="Group 791" transform="translate(-670 1894)">
                  <rect
                    id="Rectangle_48"
                    data-name="Rectangle 48"
                    width="24"
                    height="24"
                    transform="translate(670 -1894)"
                    fill="rgba(255,0,0,0)"
                  />
                  <g
                    id="Group_819"
                    data-name="Group 819"
                    transform="translate(-1827.706 -3738.151)"
                  >
                    <g
                      id="Group_690"
                      data-name="Group 690"
                      transform="translate(2499.706 1846.151)"
                    >
                      <path
                        id="Path_517"
                        data-name="Path 517"
                        d="M2507.956,1862.651a8.25,8.25,0,1,1,8.25-8.25A8.259,8.259,0,0,1,2507.956,1862.651Zm0-14.278a6.028,6.028,0,1,0,6.028,6.028A6.035,6.035,0,0,0,2507.956,1848.373Z"
                        transform="translate(-2499.706 -1846.151)"
                        fill="#435059"
                      />
                    </g>
                    <g
                      id="Group_691"
                      data-name="Group 691"
                      transform="translate(2511.611 1858.611)"
                    >
                      <path
                        id="Path_518"
                        data-name="Path 518"
                        d="M2548.729,1898.273a1.107,1.107,0,0,1-.786-.326l-5.317-5.317a1.111,1.111,0,0,1,1.571-1.571l5.317,5.317a1.111,1.111,0,0,1-.785,1.9Z"
                        transform="translate(-2542.301 -1890.733)"
                        fill="#435059"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </div>

            <router-link
              tag="div"
              :to="`/${$store.state.main.locale}/wishlist`"
              data-aos="fade-up"
              data-aos-delay="150"
            >
              <svg
                class="heart-icon"
                :class="{
                  active: $route.meta.url === 'wishlist',
                }"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <g id="Group_792" data-name="Group 792" transform="translate(-699 1894)">
                  <rect
                    id="Rectangle_49"
                    data-name="Rectangle 49"
                    width="24"
                    height="24"
                    transform="translate(699 -1894)"
                    fill="rgba(255,0,0,0)"
                  />
                  <path
                    id="Icon_ionic-md-heart-empty"
                    data-name="Icon ionic-md-heart-empty"
                    d="M19.083,4.5a6.409,6.409,0,0,0-4.875,2.286A6.409,6.409,0,0,0,9.333,4.5a5.89,5.89,0,0,0-5.958,5.995c0,4.141,3.682,7.464,9.26,12.589L14.208,24.5l1.573-1.417c5.578-5.125,9.26-8.448,9.26-12.589A5.89,5.89,0,0,0,19.083,4.5ZM14.875,21.651l-.219.2-.448.406-.448-.406-.219-.2A66.2,66.2,0,0,1,7.151,15.3c-1.458-1.807-2.1-3.286-2.1-4.8A4.332,4.332,0,0,1,6.281,7.406a4.239,4.239,0,0,1,3.052-1.24,4.777,4.777,0,0,1,3.6,1.693l1.276,1.516,1.276-1.516a4.759,4.759,0,0,1,3.6-1.693,4.26,4.26,0,0,1,3.057,1.24,4.341,4.341,0,0,1,1.234,3.089c0,1.51-.651,2.995-2.1,4.8A66.335,66.335,0,0,1,14.875,21.651Z"
                    transform="translate(697.125 -1896)"
                    fill="#435059"
                    stroke="#435059"
                    stroke-width="0.5"
                  />
                </g>
              </svg>
            </router-link>
            <div class="cart" data-aos="fade-up" data-aos-delay="200">
              <div
                class="cart-inner"
                @click="
                  $store.commit('shop/setState', {
                    key: 'cartPopupIsOpen',
                    val: !$store.state.shop.cartPopupIsOpen,
                  })
                "
                :class="{ 'active-icon': cartPopupStatus }"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <g id="Group_793" data-name="Group 793" transform="translate(-735 1894)">
                    <rect
                      id="Rectangle_50"
                      data-name="Rectangle 50"
                      width="24"
                      height="24"
                      transform="translate(735 -1894)"
                      fill="rgba(255,0,0,0)"
                    />
                    <g
                      id="Group_791"
                      data-name="Group 791"
                      transform="translate(-1099.242 -3193.599)"
                    >
                      <g
                        id="Group_282"
                        data-name="Group 282"
                        transform="translate(1835.242 1301.599)"
                      >
                        <path
                          id="Path_213"
                          data-name="Path 213"
                          d="M1843.725,1317.339a1.3,1.3,0,0,1-1.082-.984l-3.521-12.489h-2.754a1.133,1.133,0,0,1,0-2.266h3.6a1.3,1.3,0,0,1,1.083.991l3.465,12.214,9.6-2.01.489-3.714-9.283-.387a1.122,1.122,0,0,1-1.077-1.168,1.135,1.135,0,0,1,1.173-1.074l10.511.452a1.128,1.128,0,0,1,1.069,1.272l-.751,5.744a1.125,1.125,0,0,1-.881.955l-11.411,2.44A1.106,1.106,0,0,1,1843.725,1317.339Z"
                          transform="translate(-1835.242 -1301.599)"
                          fill="#435059"
                        />
                      </g>
                      <g
                        id="Group_283"
                        data-name="Group 283"
                        transform="translate(1842.055 1318.277)"
                      >
                        <path
                          id="Path_214"
                          data-name="Path 214"
                          d="M1860.955,1363.8a1.661,1.661,0,1,1,1.661-1.661A1.663,1.663,0,0,1,1860.955,1363.8Z"
                          transform="translate(-1859.294 -1360.473)"
                          fill="#435059"
                        />
                      </g>
                      <g
                        id="Group_284"
                        data-name="Group 284"
                        transform="translate(1850.192 1318.277)"
                      >
                        <path
                          id="Path_215"
                          data-name="Path 215"
                          d="M1889.678,1363.8a1.661,1.661,0,1,1,1.661-1.661A1.663,1.663,0,0,1,1889.678,1363.8Z"
                          transform="translate(-1888.017 -1360.473)"
                          fill="#435059"
                        />
                      </g>
                    </g>
                  </g>
                </svg>

                <div class="cart-item-numbers">
                  <span>{{ getCartItems && getCartItems.sum ? getCartItems.sum : 0 }}</span>
                </div>
              </div>
              <div class="user-dropdown cart-dropdown" :class="{ active: cartPopupStatus }">
                <div class="cart-container">
                  <div class="cart-cont" v-if="_.get(getCartItems, 'data')">
                    <div class="item-list">
                      <CartProductItem
                        :product="product"
                        :key="index"
                        v-for="(product, index) in getCartItems.data.list"
                      />
                    </div>
                    <div class="total-wrapper">
                      <div class="total">
                        {{ $fn.tr('Total') }}:
                        {{ formatPrice(getCartItems.price) }}
                        ₾
                      </div>
                      <div
                        @click="
                          $store.commit('shop/setState', {
                            key: 'cartPopupIsOpen',
                            val: false,
                          })
                        "
                      >
                        <router-link
                          :to="`/${_.get($store.state.main, 'locale')}/cartlist`"
                          tag="div"
                          class="green-button"
                        >
                          {{ $fn.tr('View Cart') }}
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <div v-else class="empty-text">
                    {{ $fn.tr('Cart Is Empty') }}
                  </div>
                </div>
              </div>
            </div>
            <div class="cart relative" data-aos="fade-up" data-aos-delay="250" v-if="!getAuthData">
              <div :class="{ 'active-icon': userDropdown }" @click="userDropdown = !userDropdown">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <g id="Group_791" data-name="Group 791" transform="translate(-670 1894)">
                    <rect
                      id="Rectangle_48"
                      data-name="Rectangle 48"
                      width="24"
                      height="24"
                      transform="translate(670 -1894)"
                      fill="rgba(255,0,0,0)"
                    />
                    <g
                      id="Group_148"
                      data-name="Group 148"
                      transform="translate(-427.913 -3562.734)"
                    >
                      <g
                        id="Group_146"
                        data-name="Group 146"
                        transform="translate(1099.913 1682.032)"
                      >
                        <path
                          id="Path_124"
                          data-name="Path 124"
                          d="M1118.462,1718.661h-17.4a1.145,1.145,0,0,1-1.145-1.145,7.566,7.566,0,0,1,7.557-7.557h4.58a7.566,7.566,0,0,1,7.557,7.557A1.145,1.145,0,0,1,1118.462,1718.661Zm-16.134-2.29h14.864a5.277,5.277,0,0,0-5.142-4.122h-4.58A5.277,5.277,0,0,0,1102.328,1716.371Z"
                          transform="translate(-1099.913 -1709.959)"
                          fill="#435059"
                        />
                      </g>
                      <g
                        id="Group_147"
                        data-name="Group 147"
                        transform="translate(1104.722 1670.734)"
                      >
                        <path
                          id="Path_125"
                          data-name="Path 125"
                          d="M1121.648,1680.81a5.038,5.038,0,1,1,5.038-5.038A5.044,5.044,0,0,1,1121.648,1680.81Zm0-7.786a2.748,2.748,0,1,0,2.748,2.748A2.751,2.751,0,0,0,1121.648,1673.024Z"
                          transform="translate(-1116.61 -1670.734)"
                          fill="#435059"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              <div class="user-dropdown" :class="{ active: userDropdown }">
                <div class="login-container" v-if="!nextStep && !forgotPassword">
                  <div class="user-dropdown-header">
                    <div class="user-icon">
                      <img loading="lazy" src="../../assets/img/user-icon.svg" alt="user" />
                    </div>
                    <div class="user-drodown-header-title">
                      {{ $fn.tr('Login') }}
                    </div>
                  </div>
                  <div class="user-dropdown-container">
                    <div
                      class="form-input-item"
                      :class="{
                        'input-error':
                          (!$v.personalNumber.required ||
                            !$v.personalNumber.minLength ||
                            !$v.personalNumber.maxLength) &&
                          isClicked,
                      }"
                    >
                      <div class="label">
                        {{ $fn.tr('Personal Number') }}
                      </div>
                      <input
                        maxlength="11"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        @keyup.enter="loginUser"
                        v-model="$v.personalNumber.$model"
                        type="number"
                      />
                      <div class="label red" v-if="!$v.personalNumber.required && isClicked">
                        {{ $fn.tr('Wrong Number') }}
                      </div>
                      <div
                        class="label red"
                        v-if="
                          $v.personalNumber.required &&
                            (!$v.personalNumber.maxLength || !$v.personalNumber.minLength) &&
                            isClicked
                        "
                      >
                        {{ $fn.tr('personal number length must be 11') }}
                      </div>
                    </div>
                    <div
                      class="form-input-item"
                      :class="{
                        'input-error':
                          (!$v.password.required || !$v.password.minLength) && isClicked,
                      }"
                    >
                      <div class="password-labels">
                        <div class="label">
                          {{ $fn.tr('Password') }}
                        </div>
                        <div class="label link" @click="forgotPassword = true">
                          {{ $fn.tr('Forgot Password') }}
                        </div>
                      </div>

                      <input
                        v-model="$v.password.$model"
                        @keyup.enter="loginUser"
                        type="password"
                      />
                      <div
                        class="label red"
                        v-if="$v.password.required && !$v.password.minLength && isClicked"
                      >
                        {{ $fn.tr('Password must be at least 8 symbol') }}
                      </div>
                      <div
                        style="margin-top:10px"
                        class="label red"
                        v-if="invalidLoginError.length"
                      >
                        {{ $fn.tr(invalidLoginError) }}
                      </div>
                    </div>
                    <div class="green-button" @click="loginUser">
                      {{ $fn.tr('Login') }}
                    </div>
                    <div class="go-to-registration center-flex">
                      {{ $fn.tr('New member') }} :
                      <div @click.prevent="userDropdown = false">
                        <router-link tag="span" :to="`/${$store.state.main.locale}/registration`"
                          >{{ $fn.tr('Registration') }}
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="forgot-password"
                  v-if="forgotPassword && !checkEmailStatus"
                  data-aos="fade"
                  data-aos-delay="200"
                >
                  <div class="forgot-password-back">
                    <div class="back-to-login" @click="forgotPassword = false">
                      <img loading="lazy" src="../../assets/icons/right-arrow.svg" alt="right" />
                    </div>
                    <h1>{{ $fn.tr('forgot password') }}</h1>
                  </div>
                  <div
                    class="form-input-item"
                    :class="{
                      'input-error':
                        (!$v.email.required || !$v.email.minLength || !$v.email.email) && isClicked,
                    }"
                  >
                    <div class="password-labels">
                      <div class="label">
                        {{ $fn.tr('Email') }}
                      </div>
                    </div>

                    <input
                      v-model="$v.email.$model"
                      @keyup.enter="sendForgotPassword"
                      type="email"
                    />
                    <div class="label red" v-if="!$v.email.email && isClicked">
                      {{ $fn.tr('Email is not formatted correctly') }}
                    </div>
                    <div class="label red" v-if="emailNotExists">
                      {{ emailNotExists }}
                    </div>
                  </div>
                  <div class="green-button" @click="sendForgotPassword">
                    {{ $fn.tr('Send') }}
                  </div>
                  <div class="go-to-registration center-flex">
                    {{ $fn.tr('New member') }} :
                    <div @click.prevent="userDropdown = false">
                      <router-link tag="span" :to="`/${$store.state.main.locale}/registration`"
                        >{{ $fn.tr('Registration') }}
                      </router-link>
                    </div>
                  </div>
                </div>
                <div
                  class="check-email"
                  v-if="checkEmailStatus"
                  data-aos="fade"
                  data-aos-delay="200"
                >
                  <div class="email-title">
                    {{ $fn.tr('Please Check Email') }}
                  </div>
                  <div class="email-content">
                    {{ $fn.tr('new password change instruction text') }}
                  </div>
                </div>
              </div>
            </div>
            <div style="margin-right: 0" v-if="getAuthData" data-aos="fade-up" data-aos-delay="250">
              <div
                class="lang-info user-name relative"
                :class="{ 'active-name': activeUserDropdown }"
                @click="activeUserDropdown = !activeUserDropdown"
              >
                <div class="user-name-title">
                  {{ getAuthData.fullname.trim().split(' ')[0] }}
                </div>
                <div
                  class="user-dropdown active-user-dropdown"
                  :class="{
                    active: activeUserDropdown && getAuthData,
                  }"
                >
                  <div class="auth-drodpown-wrapper">
                    <router-link
                      tag="div"
                      :class="{
                        active: $route.params.id === 1,
                      }"
                      :to="`/${$store.state.main.indx.locale}/user-room/1`"
                      class="option-item"
                    >
                      {{ $fn.tr('Personal Information') }}
                    </router-link>
                    <router-link
                      tag="div"
                      :class="{
                        active: $route.params.id === 2,
                      }"
                      :to="`/${$store.state.main.indx.locale}/user-room/2`"
                      class="option-item"
                    >
                      {{ $fn.tr('Change Password') }}
                    </router-link>
                    <router-link
                      tag="div"
                      :class="{
                        active: $route.params.id === 3,
                      }"
                      :to="`/${$store.state.main.indx.locale}/user-room/3`"
                      class="option-item"
                    >
                      {{ $fn.tr('Order History') }}
                    </router-link>
                    <router-link
                      tag="div"
                      :class="{
                        active: $route.params.id === 4,
                      }"
                      :to="`/${$store.state.main.indx.locale}/user-room/4`"
                      class="option-item"
                    >
                      {{ $fn.tr('Shipping Address') }}
                    </router-link>
                    <div class="log-out-item" @click="logOut">
                      {{ $fn.tr('Log Out') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div data-aos="fade-up" data-aos-delay="300" class="lang-info margin-zero">
              <div v-if="$route.meta.url">
                <router-link
                  tag="span"
                  v-if="_.get($store.state.main, 'locale') == 'en'"
                  :to="
                    String(
                      $store.getters['main/getPageTranslation']({
                        url: $route.meta.url,
                        lang: 'ge',
                      })
                    )
                  "
                  class="lang-info margin-zero"
                >
                  {{ _.get($store.state.main.indx, 'locales.ge') }}
                </router-link>
                <router-link
                  tag="span"
                  v-else-if="_.get($store.state.main, 'locale') !== 'en'"
                  :to="
                    String(
                      $store.getters['main/getPageTranslation']({
                        url: $route.meta.url,
                        lang: 'en',
                      })
                    )
                  "
                  class="lang-info margin-zero"
                >
                  {{ _.get($store.state.main.indx, 'locales.en') }}
                </router-link>
              </div>
              <div v-else>
                <router-link
                  tag="span"
                  v-if="_.get($store.state.main, 'locale') == 'en'"
                  :to="
                    _.get($store.state.main.selected_menu_locales, 'ge')
                      ? _.get($store.state.main.selected_menu_locales, 'ge')
                      : ''
                  "
                  class="lang-info margin-zero"
                >
                  {{ _.get($store.state.main.indx, 'locales.ge') }}
                </router-link>
                <router-link
                  tag="span"
                  v-else-if="_.get($store.state.main, 'locale') !== 'en'"
                  :to="
                    _.get($store.state.main.selected_menu_locales, 'en')
                      ? _.get($store.state.main.selected_menu_locales, 'en')
                      : ''
                  "
                  class="lang-info margin-zero"
                >
                  {{ _.get($store.state.main.indx, 'locales.en') }}
                </router-link>
              </div>

              <!--                            <router-link tag="span"  v-else-if="_.get($store.state.main.indx, 'locale') === 'en'" :to="_.get($store.state.main.selected_menu_locales, 'ge')" class="lang-info margin-zero">{{_.get($store.state.main.indx, "locales.en")}}</router-link>-->
            </div>

            <div
              @click="closeDrodowns"
              class="background-filter"
              v-if="userDropdown || cartPopupStatus || activeUserDropdown"
            ></div>
          </div>
        </div>

        <!--                <div class="breadcrumbs container" v-if="$route.name !== 'home'">-->
        <!--                <div class="breadcrumbs container">-->
        <!--                    <breadcrumbs/>-->

        <!--                    &lt;!&ndash; <div class="breadcrumbs-border"></div> &ndash;&gt;-->
        <!--                </div>-->
      </div>
      <!--  scroll header-->
      <div class="header" id="scrollHeader">
        <div class="header-content container-wrapper">
          <div class="menus">
            <div
              class="burger"
              data-aos="fade-down"
              data-aos-delay="100"
              @click="menuStatus = true"
            >
              <div class="burger-items">
                <span></span>
                <span></span>
              </div>
            </div>
            <div class="menu-items">
              <p class="header-menu-item" data-aos="fade-down" data-aos-delay="100">
                <span
                  @click="goToLink('about-mysa')"
                  :class="{
                    active: $store.state.main.loadedTemplate === 'AboutMysa',
                  }"
                  class="menu-item"
                  >{{ $fn.tr('Mysa') }}
                </span>
              </p>
              <p class="header-menu-item" data-aos="fade-down" data-aos-delay="150">
                <span
                  @click="goToLink('category')"
                  :class="{
                    active: $store.state.main.loadedTemplate === 'ChooseCategory',
                  }"
                  class="menu-item"
                  >{{ $fn.tr('Products') }}
                </span>
              </p>
              <p class="header-menu-item" data-aos="fade-down" data-aos-delay="200">
                <span
                  @click="goToLink('brands')"
                  :class="{
                    active: $store.state.main.loadedTemplate === 'Brands',
                  }"
                  class="menu-item"
                  >{{ $fn.tr('Brands') }}
                </span>
              </p>
            </div>
          </div>
          <div class="mobile-logo-container" data-aos="fade" data-aos-delay="100">
            <router-link :to="`/${$store.state.main.locale}/home`" tag="div" class="logo">
              <img
                loading="lazy"
                v-if="$store.state.main.locale === 'en'"
                src="../../assets/img/Logo.svg"
                alt="logo"
              />
              <img loading="lazy" v-else src="../../assets/img/mysa-geo.svg" alt="logo" />
            </router-link>
          </div>

          <div class="icons">
            <div @click="searchOpen = true" data-aos="fade-up" data-aos-delay="100">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <g id="Group_791" data-name="Group 791" transform="translate(-670 1894)">
                  <rect
                    id="Rectangle_48"
                    data-name="Rectangle 48"
                    width="24"
                    height="24"
                    transform="translate(670 -1894)"
                    fill="rgba(255,0,0,0)"
                  />
                  <g
                    id="Group_819"
                    data-name="Group 819"
                    transform="translate(-1827.706 -3738.151)"
                  >
                    <g
                      id="Group_690"
                      data-name="Group 690"
                      transform="translate(2499.706 1846.151)"
                    >
                      <path
                        id="Path_517"
                        data-name="Path 517"
                        d="M2507.956,1862.651a8.25,8.25,0,1,1,8.25-8.25A8.259,8.259,0,0,1,2507.956,1862.651Zm0-14.278a6.028,6.028,0,1,0,6.028,6.028A6.035,6.035,0,0,0,2507.956,1848.373Z"
                        transform="translate(-2499.706 -1846.151)"
                        fill="#435059"
                      />
                    </g>
                    <g
                      id="Group_691"
                      data-name="Group 691"
                      transform="translate(2511.611 1858.611)"
                    >
                      <path
                        id="Path_518"
                        data-name="Path 518"
                        d="M2548.729,1898.273a1.107,1.107,0,0,1-.786-.326l-5.317-5.317a1.111,1.111,0,0,1,1.571-1.571l5.317,5.317a1.111,1.111,0,0,1-.785,1.9Z"
                        transform="translate(-2542.301 -1890.733)"
                        fill="#435059"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </div>

            <router-link
              tag="div"
              :to="`/${$store.state.main.locale}/wishlist`"
              data-aos="fade-up"
              data-aos-delay="150"
            >
              <svg
                class="heart-icon"
                :class="{
                  active: $route.meta.url === 'wishlist',
                }"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <g id="Group_792" data-name="Group 792" transform="translate(-699 1894)">
                  <rect
                    id="Rectangle_49"
                    data-name="Rectangle 49"
                    width="24"
                    height="24"
                    transform="translate(699 -1894)"
                    fill="rgba(255,0,0,0)"
                  />
                  <path
                    id="Icon_ionic-md-heart-empty"
                    data-name="Icon ionic-md-heart-empty"
                    d="M19.083,4.5a6.409,6.409,0,0,0-4.875,2.286A6.409,6.409,0,0,0,9.333,4.5a5.89,5.89,0,0,0-5.958,5.995c0,4.141,3.682,7.464,9.26,12.589L14.208,24.5l1.573-1.417c5.578-5.125,9.26-8.448,9.26-12.589A5.89,5.89,0,0,0,19.083,4.5ZM14.875,21.651l-.219.2-.448.406-.448-.406-.219-.2A66.2,66.2,0,0,1,7.151,15.3c-1.458-1.807-2.1-3.286-2.1-4.8A4.332,4.332,0,0,1,6.281,7.406a4.239,4.239,0,0,1,3.052-1.24,4.777,4.777,0,0,1,3.6,1.693l1.276,1.516,1.276-1.516a4.759,4.759,0,0,1,3.6-1.693,4.26,4.26,0,0,1,3.057,1.24,4.341,4.341,0,0,1,1.234,3.089c0,1.51-.651,2.995-2.1,4.8A66.335,66.335,0,0,1,14.875,21.651Z"
                    transform="translate(697.125 -1896)"
                    fill="#435059"
                    stroke="#435059"
                    stroke-width="0.5"
                  />
                </g>
              </svg>
            </router-link>
            <div class="cart" data-aos="fade-up" data-aos-delay="200">
              <div
                :class="{
                  'active-icon': $store.state.shop.mobileCartPopupIsOpen,
                }"
                @click="
                  $store.commit('shop/setState', {
                    key: 'mobileCartPopupIsOpen',
                    val: !$store.state.shop.mobileCartPopupIsOpen,
                  })
                "
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <g id="Group_793" data-name="Group 793" transform="translate(-735 1894)">
                    <rect
                      id="Rectangle_50"
                      data-name="Rectangle 50"
                      width="24"
                      height="24"
                      transform="translate(735 -1894)"
                      fill="rgba(255,0,0,0)"
                    />
                    <g
                      id="Group_791"
                      data-name="Group 791"
                      transform="translate(-1099.242 -3193.599)"
                    >
                      <g
                        id="Group_282"
                        data-name="Group 282"
                        transform="translate(1835.242 1301.599)"
                      >
                        <path
                          id="Path_213"
                          data-name="Path 213"
                          d="M1843.725,1317.339a1.3,1.3,0,0,1-1.082-.984l-3.521-12.489h-2.754a1.133,1.133,0,0,1,0-2.266h3.6a1.3,1.3,0,0,1,1.083.991l3.465,12.214,9.6-2.01.489-3.714-9.283-.387a1.122,1.122,0,0,1-1.077-1.168,1.135,1.135,0,0,1,1.173-1.074l10.511.452a1.128,1.128,0,0,1,1.069,1.272l-.751,5.744a1.125,1.125,0,0,1-.881.955l-11.411,2.44A1.106,1.106,0,0,1,1843.725,1317.339Z"
                          transform="translate(-1835.242 -1301.599)"
                          fill="#435059"
                        />
                      </g>
                      <g
                        id="Group_283"
                        data-name="Group 283"
                        transform="translate(1842.055 1318.277)"
                      >
                        <path
                          id="Path_214"
                          data-name="Path 214"
                          d="M1860.955,1363.8a1.661,1.661,0,1,1,1.661-1.661A1.663,1.663,0,0,1,1860.955,1363.8Z"
                          transform="translate(-1859.294 -1360.473)"
                          fill="#435059"
                        />
                      </g>
                      <g
                        id="Group_284"
                        data-name="Group 284"
                        transform="translate(1850.192 1318.277)"
                      >
                        <path
                          id="Path_215"
                          data-name="Path 215"
                          d="M1889.678,1363.8a1.661,1.661,0,1,1,1.661-1.661A1.663,1.663,0,0,1,1889.678,1363.8Z"
                          transform="translate(-1888.017 -1360.473)"
                          fill="#435059"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
                <div class="cart-item-numbers">
                  <span>{{ getCartItems.sum ? getCartItems.sum : 0 }}</span>
                </div>
              </div>
              <div
                class="user-dropdown cart-dropdown"
                :class="{
                  'active-mobile': $store.state.shop.mobileCartPopupIsOpen,
                }"
              >
                <div class="cart-container">
                  <div class="cart-cont" v-if="_.get(getCartItems, 'data')">
                    <div class="item-list">
                      <CartProductItem
                        :product="product"
                        :key="index"
                        v-for="(product, index) in getCartItems.data.list"
                      />
                    </div>
                    <div class="total-wrapper">
                      <div class="total">
                        {{ $fn.tr('Total') }} :
                        {{ formatPrice(getCartItems.price) }}
                        ₾
                      </div>
                      <div
                        @click="
                          $store.commit('shop/setState', {
                            key: 'cartPopupIsOpen',
                            val: false,
                          })
                        "
                      >
                        <router-link
                          :to="`/${$store.state.main.locale}/cartlist`"
                          tag="div"
                          class="green-button"
                        >
                          {{ $fn.tr('View Cart') }}
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <div v-else class="empty-text">
                    {{ $fn.tr('Cart Is Empty') }}
                  </div>
                </div>
              </div>
            </div>
            <div class="cart" v-if="!getAuthData" data-aos="fade-up" data-aos-delay="250">
              <div
                :class="{ 'active-icon': userDropdownMobile }"
                @click="userDropdownMobile = !userDropdownMobile"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <g id="Group_791" data-name="Group 791" transform="translate(-670 1894)">
                    <rect
                      id="Rectangle_48"
                      data-name="Rectangle 48"
                      width="24"
                      height="24"
                      transform="translate(670 -1894)"
                      fill="rgba(255,0,0,0)"
                    />
                    <g
                      id="Group_148"
                      data-name="Group 148"
                      transform="translate(-427.913 -3562.734)"
                    >
                      <g
                        id="Group_146"
                        data-name="Group 146"
                        transform="translate(1099.913 1682.032)"
                      >
                        <path
                          id="Path_124"
                          data-name="Path 124"
                          d="M1118.462,1718.661h-17.4a1.145,1.145,0,0,1-1.145-1.145,7.566,7.566,0,0,1,7.557-7.557h4.58a7.566,7.566,0,0,1,7.557,7.557A1.145,1.145,0,0,1,1118.462,1718.661Zm-16.134-2.29h14.864a5.277,5.277,0,0,0-5.142-4.122h-4.58A5.277,5.277,0,0,0,1102.328,1716.371Z"
                          transform="translate(-1099.913 -1709.959)"
                          fill="#435059"
                        />
                      </g>
                      <g
                        id="Group_147"
                        data-name="Group 147"
                        transform="translate(1104.722 1670.734)"
                      >
                        <path
                          id="Path_125"
                          data-name="Path 125"
                          d="M1121.648,1680.81a5.038,5.038,0,1,1,5.038-5.038A5.044,5.044,0,0,1,1121.648,1680.81Zm0-7.786a2.748,2.748,0,1,0,2.748,2.748A2.751,2.751,0,0,0,1121.648,1673.024Z"
                          transform="translate(-1116.61 -1670.734)"
                          fill="#435059"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              <div class="user-dropdown" :class="{ 'active-mobile': userDropdownMobile }">
                <div class="login-container" v-if="!nextStep && !forgotPassword">
                  <div class="user-dropdown-header">
                    <div class="user-icon">
                      <img loading="lazy" src="../../assets/img/user-icon.svg" alt="user" />
                    </div>
                    <div class="user-drodown-header-title">
                      {{ $fn.tr('Login') }}
                    </div>
                  </div>
                  <div class="user-dropdown-container">
                    <div
                      class="form-input-item"
                      :class="{
                        'input-error':
                          (!$v.personalNumber.required || !$v.personalNumber.minLength) &&
                          isClicked,
                      }"
                    >
                      <div class="label">
                        {{ $fn.tr('Personal Number') }}
                      </div>
                      <input
                        v-model="$v.personalNumber.$model"
                        @keyup.enter="loginUser"
                        type="number"
                      />
                    </div>
                    <div
                      class="form-input-item"
                      :class="{
                        'input-error':
                          (!$v.password.required || !$v.password.minLength) && isClicked,
                      }"
                    >
                      <div class="password-labels">
                        <div class="label">
                          {{ $fn.tr('Password') }}
                        </div>
                        <div class="label link" @click="forgotPassword = true">
                          {{ $fn.tr('Forgot Password') }}
                        </div>
                      </div>

                      <input
                        v-model="$v.password.$model"
                        @keyup.enter="loginUser"
                        type="password"
                      />
                      <div class="label red" v-if="$v.password.$model && isClicked">
                        {{ $fn.tr('Password must contain numbers and symbols') }}
                      </div>
                    </div>
                    <div class="green-button" @click="loginUser">
                      {{ $fn.tr('Login') }}
                    </div>
                    <div class="go-to-registration center-flex">
                      {{ $fn.tr('New member') }} :
                      <div @click.prevent="userDropdownMobile = false">
                        <router-link tag="span" :to="`/${$store.state.main.locale}/registration`"
                          >{{ $fn.tr('Registration') }}
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="forgot-password"
                  v-if="forgotPassword && !checkEmailStatus"
                  data-aos="fade"
                  data-aos-delay="200"
                >
                  <div class="forgot-password-back">
                    <div class="back-to-login" @click="forgotPassword = false">
                      <img loading="lazy" src="../../assets/icons/right-arrow.svg" alt="right" />
                    </div>
                    <h1>{{ $fn.tr('forgot password') }}</h1>
                  </div>
                  <div
                    class="form-input-item"
                    :class="{
                      'input-error': (!$v.email.required || !$v.email.minLength) && isClicked,
                    }"
                  >
                    <div class="password-labels">
                      <div class="label">
                        {{ $fn.tr('Email') }}
                      </div>
                    </div>

                    <input
                      v-model="$v.email.$model"
                      @keyup.enter="sendForgotPassword"
                      type="email"
                    />
                    <div class="label red" v-if="!$v.email.email && isClicked">
                      {{ $fn.tr('Email is not formatted correctly') }}
                    </div>
                  </div>
                  <div class="green-button" @click="sendForgotPassword">
                    {{ $fn.tr('send') }}
                  </div>
                  <div class="go-to-registration center-flex">
                    {{ $fn.tr('New member') }}:
                    <div @click.prevent="userDropdownMobile = false">
                      <router-link tag="span" :to="`/${$store.state.main.locale}/registration`">
                        {{ $fn.tr('Registration') }}
                      </router-link>
                    </div>
                  </div>
                </div>
                <div
                  class="check-email"
                  v-if="checkEmailStatus"
                  data-aos="fade"
                  data-aos-delay="200"
                >
                  <div class="email-title">
                    {{ $fn.tr('Please Check Email') }}
                  </div>
                  <div class="email-content">
                    {{ $fn.tr('new password change instruction text') }}
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="relative" :class="{ 'active-name': activeUserDropdownMobile }">
              <div class="lang-info">
                <div
                  class="user-name-title"
                  @click="activeUserDropdownMobile = !activeUserDropdownMobile"
                >
                  {{ getAuthData.fullname.trim().split(' ')[0] }}
                </div>
                <div
                  class="user-dropdown active-user-dropdown"
                  @click="activeUserDropdownMobile = false"
                  :class="{
                    active: activeUserDropdownMobile && getAuthData,
                  }"
                >
                  <div class="auth-drodpown-wrapper">
                    <router-link
                      tag="div"
                      :class="{
                        active: $route.params.id === 1,
                      }"
                      :to="`/${$store.state.main.indx.locale}/user-room/1`"
                      class="option-item"
                    >
                      {{ $fn.tr('Personal Information') }}
                    </router-link>
                    <router-link
                      tag="div"
                      :class="{
                        active: $route.params.id === 2,
                      }"
                      :to="`/${$store.state.main.indx.locale}/user-room/2`"
                      class="option-item"
                    >
                      {{ $fn.tr('Change Password') }}
                    </router-link>
                    <router-link
                      tag="div"
                      :class="{
                        active: $route.params.id === 3,
                      }"
                      :to="`/${$store.state.main.indx.locale}/user-room/3`"
                      class="option-item"
                    >
                      {{ $fn.tr('Order History') }}
                    </router-link>
                    <router-link
                      tag="div"
                      :class="{
                        active: $route.params.id === 4,
                      }"
                      :to="`/${$store.state.main.indx.locale}/user-room/4`"
                      class="option-item"
                    >
                      {{ $fn.tr('Shipping Address') }}
                    </router-link>
                    <div class="log-out-item" @click="logOut">
                      {{ $fn.tr('Log Out') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div data-aos="fade-up" data-aos-delay="350" class="lang-info margin-zero">
              <div v-if="$route.meta.url">
                <router-link
                  tag="span"
                  v-if="_.get($store.state.main, 'locale') == 'en'"
                  :to="
                    String(
                      $store.getters['main/getPageTranslation']({
                        url: $route.meta.url,
                        lang: 'ge',
                      })
                    )
                  "
                  class="lang-info margin-zero"
                >
                  {{ _.get($store.state.main.indx, 'locales.ge') }}
                </router-link>
                <router-link
                  tag="span"
                  v-else-if="_.get($store.state.main, 'locale') !== 'en'"
                  :to="
                    String(
                      $store.getters['main/getPageTranslation']({
                        url: $route.meta.url,
                        lang: 'en',
                      })
                    )
                  "
                  class="lang-info margin-zero"
                >
                  {{ _.get($store.state.main.indx, 'locales.en') }}
                </router-link>
              </div>
              <div v-else>
                <router-link
                  tag="span"
                  v-if="_.get($store.state.main, 'locale') == 'en'"
                  :to="
                    _.get($store.state.main.selected_menu_locales, 'ge')
                      ? _.get($store.state.main.selected_menu_locales, 'ge')
                      : ''
                  "
                  class="lang-info margin-zero"
                >
                  {{ _.get($store.state.main.indx, 'locales.ge') }}
                </router-link>
                <router-link
                  tag="span"
                  v-else-if="_.get($store.state.main, 'locale') !== 'en'"
                  :to="
                    _.get($store.state.main.selected_menu_locales, 'en')
                      ? _.get($store.state.main.selected_menu_locales, 'en')
                      : ''
                  "
                  class="lang-info margin-zero"
                >
                  {{ _.get($store.state.main.indx, 'locales.en') }}
                </router-link>
              </div>
            </div>
            <div
              @click="closeDropdownsMobile"
              class="background-filter-mobile"
              v-if="
                userDropdownMobile ||
                  $store.state.shop.mobileCartPopupIsOpen ||
                  activeUserDropdownMobile
              "
            ></div>
          </div>
        </div>
      </div>
      <div
        v-if="$store.state.main.contentId !== 1 && $route.name !== 'home'"
        data-aos="fade"
        data-aos-delay="150"
        class="breadcrumbs container"
      >
        <breadcrumbs />
      </div>
    </header>
    <side-menu :menu-is-open="menuStatus" @closeMenu="closeMenu" />
    <search @close="searchOpen = false" v-if="searchOpen" />
  </div>
</template>

<script>
import mainMixin from '@/mixins/main';
import CartProductItem from '../../components/checkout/CartProductItem';
import fn from '../../helpers/func';
import breadcrumbs from '../breadcrumbs';
import SideMenu from '../SideMenu';
import Search from '../Search';
import { validationMixin } from 'vuelidate';
import { email, minLength, required, sameAs, maxLength } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import Vue from 'vue';
import conf from '../../helpers/conf';
import commaNum from '../../helpers/commaNum';

export default {
  mixins: [mainMixin, validationMixin],
  validations: {
    personalNumber: {
      required,
      minLength: minLength(11),
      maxLength: maxLength(11),
    },
    password: {
      required,
      minLength: minLength(8),
    },
    email: {
      required,
      minLength: minLength(3),
      email,
    },
  },
  data() {
    return {
      invalidLoginError: '',
      menuStatus: false,
      forgotPassword: false,
      checkEmailStatus: false,
      userDropdown: false,
      activeUserDropdown: false,
      activeUserDropdownMobile: false,
      userDropdownMobile: false,
      searchOpen: false,
      isClicked: false,
      isAuthorized: false,
      nextStep: false,
      personalNumber: '',
      password: '',
      email: '',
      emailNotExists: '',
    };
  },
  components: {
    breadcrumbs,
    SideMenu,
    Search,
    CartProductItem,
  },
  methods: {
    goToLink(link) {
      this.$store.commit('main/setState', {
        key: 'loadingPageChange',
        val: true,
      });
      setTimeout(() => {
        this.$store.commit('main/setState', {
          key: 'loadingPageChange',
          val: false,
        });
      }, 701);
      setTimeout(() => {
        this.$router.push(`/${this.$store.state.main.locale}/${link}`);
      }, 700);
    },
    closeMenu(val) {
      this.menuStatus = val;
    },
    sendForgotPassword() {
      this.isClicked = true;
      if (!this.$v.email.required || !this.$v.email.email || !this.$v.email.minLength) {
        return;
      }
      this.$store
        .dispatch('user/forgotPassword', {
          email: this.email,
          url: conf.websiteUrl + '/newpassword?token=',
        })
        .then((res) => {
          if (res.status == 200) {
            setTimeout(() => {
              this.checkEmailStatus = false;
              this.closeDrodowns();
              this.closeDropdownsMobile();
            }, 5000);

            this.checkEmailStatus = true;
            this.isClicked = false;
            this.emailNotExists = '';
          } else if (res.status == 201) {
            this.emailNotExists = res.data.status;
          }
        });
    },
    closeDrodowns() {
      this.userDropdown = false;
      this.activeUserDropdown = false;
      this.$store.commit('shop/setState', {
        key: 'cartPopupIsOpen',
        val: false,
      });
    },
    closeDropdownsMobile() {
      this.userDropdownMobile = false;
      this.$store.commit('shop/setState', {
        key: 'mobileCartPopupIsOpen',
        val: false,
      });
      this.activeUserDropdownMobile = false;
    },
    loginUser() {
      this.isClicked = true;
      if (
        !this.$v.personalNumber.required ||
        !this.$v.personalNumber.minLength ||
        !this.$v.personalNumber.maxLength ||
        !this.$v.password.required ||
        !this.$v.password.minLength
      ) {
        return;
      }
      this.$store
        .dispatch('user/authorization', {
          personal_number: this.personalNumber,
          password: this.password,
        })
        .then((res) => {
          if (res.data.access_token) {
            this.closeDrodowns();
            this.closeDropdownsMobile();
            setTimeout(() => {
              this.invalidLoginError = '';
              this.$cookies.set('userToken', res.data.access_token, 60 * 60 * 12);
              this.userDropdownMobile = false;
              this.userDropdown = false;
              this.$store.dispatch('user/getUserData', {
                token: res.data.access_token,
              });
              this.isClicked = false;
            }, 500);
          } else {
            setTimeout(() => {
              this.invalidLoginError = '';
            }, 10000);
            this.invalidLoginError = 'You have entered invalid credentials';
          }
        })
        .catch((err) => {
          if (err) {
            setTimeout(() => {
              this.invalidLoginError = '';
            }, 10000);
            this.invalidLoginError = 'You have entered invalid credentials';
          }
        });
      // this.nextStep = true;
    },
    clearUserToken() {
      if (this.$cookies.get('userToken')) {
        this.$cookies.remove('userToken');
      } else if (sessionStorage.userToken) {
        sessionStorage.removeItem('userToken');
      }
    },
    logOut() {
      Vue.set(this.$store.state.user, 'authData', null);
      this.closeDrodowns();
      this.closeDropdownsMobile();
      this.clearUserToken();
      this.$router.push(`/${this.$store.state.main.locale}/home`);
    },
    formatPrice(price) {
      return price ? commaNum(Number(price)) : 0;
    },
  },
  computed: {
    ...mapGetters({
      getAuthData: 'user/getAuthData',
      getCartList: 'shop/getCartList',
      cartPopupStatus: 'shop/cartPopupStatus',
      getCartItems: 'shop/getCartItems',
    }),
    cartsCount() {
      let quantity = 0;
      this.getCartList.forEach((r) => (quantity += parseInt(r.quantity)));
      return quantity;
    },
  },
  watch: {
    userDropdown(val) {
      if (val) {
      } else {
        this.isClicked = false;
        setTimeout(() => {
          this.nextStep = false;
          this.forgotPassword = false;
          this.checkEmailStatus = false;
        }, 1000);

        document.querySelector('html, body').removeAttribute('style');
        // document.querySelector("body").classList.remove('active-dropdown');
        document.querySelector('body')
          ? document.querySelector('body').classList.remove('hidden-scrollbar')
          : '';
      }
    },
    userDropdownMobile(val) {
      if (val) {
      } else {
        this.isClicked = false;
        setTimeout(() => {
          this.nextStep = false;
          this.forgotPassword = false;
          this.checkEmailStatus = false;
        }, 1000);
        document.querySelector('html, body').removeAttribute('style');
      }
    },
    checkEmailStatus(val) {
      if (!val) {
        this.email = '';
      }
    },
    forgotPassword(val) {
      if (val) this.isClicked = false;
    },
  },

  mounted() {
    window.document.onscroll = () => {
      let navBar = document.getElementById('fxNav');
      let scrollNavBar = document.getElementById('scrollHeader');
      if (window.scrollY > 380) {
        scrollNavBar ? scrollNavBar.classList.add('hide-top-navbar') : '';
        this.userDropdown = false;
        this.$store.commit('shop/setState', {
          key: 'cartPopupIsOpen',
          val: false,
        });
        this.activeUserDropdown = false;
        this.isClicked = false;
        this.nextStep = false;
        this.checkEmailStatus = false;
        this.$store.commit('shop/setState', {
          key: 'isDesktopScrollPosition',
          val: false,
        });
      } else {
        this.userDropdownMobile = false;
        this.$store.commit('shop/setState', {
          key: 'mobileCartPopupIsOpen',
          val: false,
        });
        this.$store.commit('shop/setState', {
          key: 'isDesktopScrollPosition',
          val: true,
        });
        this.activeUserDropdownMobile = false;
        scrollNavBar ? scrollNavBar.classList.remove('hide-top-navbar') : '';
      }
    };
  },
};
</script>

<style scoped>
.header {
  width: 100%;
  height: 233px;
  background-color: #f7f7f7;
  padding: 0 40px;
  transform: translateY(0);
  /*z-index: 9;*/
  position: relative;
}

header #scrollHeader.hide-top-navbar {
  width: 100vw;
  position: fixed;
  transform: translateY(0);
  top: 0;
  z-index: 999;
  transition: 0.8s ease;
}

header #scrollHeader {
  position: fixed;
  top: 0;
  /*z-index: -1;*/
  transform: translateY(-300px);
  height: 89px;
  -webkit-box-shadow: 1px 1px 1px 1px rgba(237, 237, 237, 1);
  -moz-box-shadow: 1px 1px 1px 1px rgba(237, 237, 237, 1);
  box-shadow: 1px 1px 1px 1px rgba(237, 237, 237, 1);
  transition: 0.4s ease;
}

header #scrollHeader .header-content {
  padding: 20px 0;
}

header .header.hide-top-navbar {
  position: fixed;
  transform: translateY(0);
  top: 0;
  transition: 0.5s ease;
}

.breadcrumbs {
  width: 92%;
  height: 90px;
  display: flex;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 10px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -45%) !important;
  bottom: -45px;
  padding: 0 10px;
  z-index: 2;
  border-bottom-left-radius: unset;
  border-bottom-right-radius: unset;
  transition: 0.2s ease;
}

.breadcrumbs-border {
  width: 93%;
  height: 1px;
  position: absolute;
  bottom: 28px;
  background: #f7f7f7;
  left: 66px;
}

.header-content {
  display: flex;
  justify-content: space-between;
}

.menu-items {
  display: flex;
  align-items: center;
  margin-left: 40px;
}

.menu-items p,
.menu-items span {
  color: #435059;
  font-family: var(--noto-bold);
  margin: 0;
  text-transform: uppercase;
  cursor: pointer;
}

.logo {
  cursor: pointer;
  margin-right: 133px;
}

.menu-items p:not(:last-child),
.menu-items span:not(:last-child) {
  margin-right: 40px;
}

.burger {
  width: 44px;
  height: 44px;
  background-color: #ffeece;
  border-radius: 10px;
  cursor: pointer;
}

.burger span {
  background-color: #fec55e;
  width: 20px;
  height: 3px;
  border-radius: 4px;
}

.burger span:not(:last-child) {
  margin-bottom: 5px;
}

.header-content {
  /*padding: 42px 75px;*/
  /*padding-left: 95px;*/
  padding: 42px 0;
}

.burger-items {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.menus,
.icons,
.cart,
.cart-item-numbers {
  display: flex;
  align-items: center;
}

.icons > *:not(:last-child) {
  margin-right: 20px;
  cursor: pointer;
}

.active-user-dropdown {
  width: 230px !important;
  padding-bottom: 5px !important;
}

.active-icon svg path {
  fill: #ffffff;
}

.active-icon:hover svg path {
  fill: #ffffff;
}

.active-icon {
  z-index: 99;
}

.back-to-login {
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #f7f7f7;
  margin-right: 10px;
}

.icons > *:hover path {
  fill: var(--green);
  color: var(--green);
  transition: 0.2s ease;
}

.heart-icon.active #Icon_ionic-md-heart-empty {
  stroke: var(--green);
  fill: var(--green);
  color: var(--green);
  transition: 0.2s ease;
}

.icons > *:hover {
  color: var(--green);
  transition: 0.2s ease;
}

.icons > *:hover #Icon_ionic-md-heart-empty {
  stroke: var(--green);
}

.cart:hover .active-icon path {
  fill: #ffffff !important;
  color: unset !important;
}

.icons .cart {
  position: relative;
}

.icons {
  position: relative;
}

.icons .cart .cart-item-numbers {
  position: absolute;
  top: -6px;
  right: -6px;
  background-color: #ff7675;
  border-radius: 50%;
  display: flex;
  align-items: center;
  width: 17px;
  height: 17px;
  justify-content: center;
}

.icons .cart .cart-item-numbers span {
  color: #ffffff;
  font-size: 11px;
}

.user-dropdown {
  /*width: 215px;*/
  width: 387px;
  max-height: 0;
  background-color: #ffffff;
  border-radius: 10px;
  top: 50px;
  z-index: 99;
  /*right: 24px;*/
  right: -45px;
  border: 1px solid #f7f7f7;
  padding: 30px 15px;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: transform 0.4s ease 0.2s, max-height 0.4s ease 0.2s, opacity 0.3s ease 0.4s,
    visibility 0.3s ease 0.3s;
}

.lang-info.margin-zero {
  cursor: pointer;
}

.user-dropdown .active {
  color: var(--green);
  transition: 0.3s ease;
}

.user-dropdown.cart-dropdown {
  right: -45px !important;
  position: absolute;
}

.user-dropdown.active .item-list,
.user-dropdown.active-mobile .item-list {
  max-height: 350px;
  overflow-y: auto;
  transition: 0.2s ease;
}

.item-list {
  max-height: 0;
  overflow-y: auto;
  transition: 0.2ms ease 0.4s;
}

.item-list::-webkit-scrollbar {
  width: 1px;
  background-color: #d9dcde;
}

.item-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #435059;
}

.margin-zero {
  margin-right: 0 !important;
}

.total-wrapper .total,
.empty-text {
  color: #435059;
  font-family: var(--noto-bold);
}

.total-wrapper {
  border-top: 1px solid #f7f7f7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0 0;
  margin-top: 30px;
}

.total-wrapper .green-button {
  background-color: var(--light-green);
  color: var(--green);
  width: 160px;
  cursor: pointer;
  transition: 0.2s ease;
}
.total-wrapper .green-button:hover {
  background-color: var(--green);
  color: #ffffff;
  transition: 0.2s ease;
}

.user-dropdown .login-container,
.user-dropdown .cart-container {
  opacity: 0;
  transition: 0.3s ease;
  visibility: hidden;
}

.user-dropdown.active .login-container,
.user-dropdown.active-mobile .login-container,
.user-dropdown.active .cart-container,
.user-dropdown.active-mobile .cart-container {
  opacity: 1;
  visibility: visible;
  transition: 0.3s ease 0.4s;
}

.user-dropdown:after {
  content: '';
  display: block;
  position: absolute;
  top: -9px;
  right: 47px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 9px 9px 9px;
  border-color: transparent transparent #ffffff transparent;
}

.log-out-item {
  padding: 15px 0 20px;
  text-align: right;
  color: var(--orange);
  font-family: var(--noto-bold);
  font-size: 16px;
  border-top: 1px solid #f7f7f7;
}

.user-dropdown.active {
  right: -45px;
}

.user-dropdown.active-user-dropdown {
  right: -40px !important;
}

.user-dropdown .auth-drodpown-wrapper {
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease;
}

.user-dropdown.active-user-dropdown.active .auth-drodpown-wrapper {
  opacity: 1;
  visibility: visible;
  transition: 0.3s ease 0.2s;
}

/*.user-dropdown.cart-dropdown.active {*/
/*    right: 30px;*/
/*}*/
/*.user-dropdown.active-mobile {*/
/*    right: -15px;*/
/*}*/

.user-dropdown.active,
.user-dropdown.active-mobile {
  max-height: 1000px;
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
  /*transition: .5s ease, right unset ;*/
  transition: transform 0.4s ease, max-height 0.4s ease, opacity 0.4s ease, visibility 0.8s ease;
}

.background-filter,
.background-filter-mobile {
  position: fixed;
  width: 100%;
  height: 110vh;
  top: 0;
  right: 0;
  z-index: 98;
  background-color: rgba(67, 80, 89, 0.3);
}

.option-item {
  font-family: var(--noto-regular);
  font-size: 16px;
  margin-bottom: 5px;
  text-align: right;
  transition: 0.3s ease;
}

.user-dropdown.active .option-item:hover,
.user-dropdown.active-mobile .option-item:hover {
  color: var(--green);
  transition: 0.1s ease;
}

/*.user-dropdown.active .option-item{*/
/*    opacity: 1;*/
/*    visibility: visible;*/
/*}*/
/*.user-dropdown .option-item:nth-of-type(1) {*/
/*    transition-delay: 100ms;*/
/*}*/
/*.user-dropdown .option-item:nth-of-type(2) {*/
/*    transition-delay: 200ms;*/
/*}*/
/*.user-dropdown .option-item:nth-of-type(3) {*/
/*    transition-delay: 300ms;*/
/*}*/
.option-item:nth-of-type(4) {
  margin-bottom: 20px;
}

.lang-info,
.user-name-title {
  color: #435059;
  font-family: 'Noto Sans Georgian', sans-serif;
  font-weight: bold;
}

.active-name .user-name-title {
  color: #fff;
}

.active-name {
  z-index: 99;
}

.header-content .icons .lang-info.user-name {
  margin-right: 22px;
}

/*    breadcrumbs*/

.breadcrumb-item {
  position: relative;
  cursor: pointer;
  font-size: var(--normalFontSize);
}

.breadcrumb-item:not(:first-child) {
  margin-left: 20px;
}

.breadcrumb-item:first-child {
  margin-right: 20px;
}

.breadcrumb-item:not(:last-child):after {
  position: absolute;
  content: '';
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #d9eeeb;
  right: -50%;
  top: 35%;
}

.menu-items p.active,
.menu-items span.active,
.menu-items p:hover,
.menu-items span:hover,
.menu-item.router-link-exact-active.router-link-active {
  color: var(--green);
}

/*login css*/
.user-dropdown-header {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.user-icon img {
  width: 40px;
}

.user-drodown-header-title {
  font-size: 21px;
  color: #435059;
  font-family: var(--noto-bold);
  margin-left: 12px;
}

.green-button {
  background-color: var(--green);
  color: #ffffff;
  height: 44px;
}

.green-button:hover {
  color: var(--green);
}

.go-to-registration {
  color: #435059;
  font-family: var(--noto-regular);
  font-size: 16px;
  text-align: center;
  margin-top: 35px;
}

.go-to-registration span {
  color: #fec55e;
  text-decoration: underline;
  margin-left: 5px;
  transition: 0.3s ease;
}

.password-labels {
  display: flex;
  justify-content: space-between;
}

.password-labels .label.link {
  text-decoration: underline;
  cursor: pointer;
  transition: 0.3s ease;
}

.password-labels .label.link:hover {
  color: #fec55e;
  transition: 0.3s ease;
}

.go-to-registration span:hover {
  color: #181818;
  transition: 0.3s ease;
}

.forgot-password-back {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.forgot-password-back h1 {
  font-family: var(--noto-bold);
  font-size: 21px;
  text-transform: capitalize;
}

.check-email {
  padding: 0 10px;
}

.email-title {
  font-family: var(--noto-bold);
  font-size: 21px;
}

.email-content {
  font-size: 16px;
  font-family: var(--noto-regular);
  margin-top: 21px;
}
.header-content.container-wrapper {
  width: 1640px;
}

@media screen and (max-width: 520px) {
  .user-dropdown::after {
    display: none;
  }

  .header-content .user-dropdown,
  .header-content .user-dropdown.cart-dropdown {
    width: 90vw !important;
    position: fixed !important;
    margin-right: 5px !important;
    right: 10px !important;
  }

  .user-dropdown.cart-dropdown {
    right: -50px !important;
  }

  .user-dropdown.active .item-list,
  .user-dropdown.active-mobile .item-list {
    max-height: 250px;
  }
}

@media (min-width: 1920px) {
  .user-dropdown.cart-dropdown.active-mobile {
    right: 45px;
  }
}

@media screen and (max-width: 1919px) {
  .logo {
    margin-right: 140px;
  }
  .header-content.container-wrapper {
    width: 1398px;
  }

  .icons > *:not(:last-child) {
    margin-right: 22px;
  }

  .icons .lang-info {
    margin-right: 0;
  }

  .user-dropdown.cart-dropdown.active-mobile {
    right: 29px;
  }
}

@media screen and (max-width: 1600px) {
  .breadcrumbs {
    width: 96%;
  }

  .header {
    padding: 0;
  }

  .header-content {
    padding: 40px 0;
  }

  .breadcrumb-item {
    margin: 0 25px 21px;
  }

  .user-dropdown.cart-dropdown.active-mobile {
    right: 29px;
  }

  /*.user-dropdown.cart-dropdown {*/
  /*    right: 50px;*/
  /*}*/
}

@media screen and (max-width: 1599px) {
  .menus {
    padding-left: unset;
  }
  .header-content.container-wrapper {
    width: 1242px;
  }
}

@media screen and (max-width: 1365px) {
  .header-content.container-wrapper {
    width: unset;
  }
  header #scrollHeader .header-content {
    padding: 20px 33px;
  }

  .menu-items p {
    font-size: 14px;
  }

  .menu-items p:not(:last-child) {
    margin-right: 25px;
  }

  .menu-items {
    margin-left: 23px;
  }

  .header-content {
    padding: 40px 33px;
  }

  /*.header-content {*/
  /*    padding: 40px 10px;*/
  /*}*/
  .logo {
    margin-right: 85px;
  }

  .breadcrumbs-border {
    width: 98%;
    left: 15px;
  }
}

@media screen and (max-width: 1023px) {
  .menu-items .header-menu-item:first-child,
  .menu-items p:first-child {
    display: none;
  }

  .logo {
    width: 124px;
    margin-right: 5px;
  }

  .logo img {
    width: 100%;
  }

  .header-content {
    padding: 50px 33px;
  }

  .header {
    height: 233px;
  }

  #scrollHeader.header {
    height: 89px;
  }

  .breadcrumbs {
    width: 100%;
    height: 60px;
    bottom: -25px;
  }

  .breadcrumbs-border {
    width: 94%;
    left: 33px;
    bottom: 10px;
  }
}

@media screen and (max-width: 767px) {
  .menu-items {
    display: none;
  }

  .mobile-logo-container {
    margin-right: auto;
  }

  .breadcrumbs {
    border-radius: 0;
  }

  .header-content,
  header #scrollHeader .header-content {
    justify-content: unset;
    padding: 28px 15px 28px 18px;
  }

  .user-dropdown.active-mobile,
  .user-dropdown {
    right: -45px;
  }

  .user-dropdown.cart-dropdown.active-mobile {
    right: -10px;
  }

  .menus {
    order: 1;
  }

  .burger {
    width: 34px;
    height: 34px;
    background-color: #fec55e;
  }

  .burger span {
    background-color: #ffffff;
    margin-bottom: -1px;
  }

  .burger span:not(:last-child) {
    margin-bottom: 5px;
  }

  .breadcrumbs-border {
    width: 94%;
    left: 20px;
    bottom: 10px;
  }

  .logo {
    margin-right: auto;
    width: 90px;
    display: flex;
  }

  .icons {
    margin-right: 0;
  }

  .lang-info.margin-zero {
    display: none;
  }

  #scrollHeader.header {
    height: unset;
  }

  #scrollHeader.header {
    height: unset;
  }

  .header {
    height: 144px;
  }

  .breadcrumbs {
    height: 55px;
  }

  .icons > *:not(:last-child) {
    margin-right: 5px;
  }

  /* .icons .lang-info,
    .icons img[alt="user"] {
        display: none;
    } */

  .breadcrumbs {
    width: 100%;
    /* position: unset;
        transform: unset; */
  }

  .user-dropdown.active {
    right: -45px;
  }
}

@media screen and (max-width: 520px) {
  .user-dropdown {
    top: 80px;
  }
}

@media (max-width: 320px) {
  .breadcrumbs-border {
    bottom: 0;
  }
}
</style>

<style>
.cart-dropdown .product-item-row:last-child {
  display: none;
}

/*.smooth-scrollbar-hide .container-wrapper {*/
/*    width: calc(92% + -20px);*/
/*}*/
/*.smooth-scrollbar-hide::-webkit-scrollbar {*/
/*    opacity: 0;*/
/*    visibility: hidden;*/
/*    transition: .8s ease;*/
/*}*/
.active-dropdown {
  /*margin-right: 10px;*/
  margin-right: 10px;
}

/*body.active-dropdown::-webkit-scrollbar-track {*/
/*    background: rgba(67,80,89,.3)*/
/*}*/
@media screen and (max-width: 520px) {
  .cart-dropdown .product-item-row:first-child {
    flex-direction: row;
  }

  .cart-dropdown .item-list .product-item-desc {
    margin-left: 20px;
  }
}

@media screen and (max-width: 1023px) {
  .active-dropdown {
    margin-right: unset;
  }
}
</style>
