import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import locale from "element-ui/lib/locale/lang/en";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import * as VueGoogleMaps from "vue2-google-maps";
import axios from "axios";
import AOS from "aos";
import "aos/dist/aos.css";
import PortalVue from "portal-vue";
import smoothscroll from "smoothscroll-polyfill";

// kick off the polyfill!
smoothscroll.polyfill();
Vue.use(PortalVue);
Vue.use(smoothscroll);

import VueMeta from "vue-meta";
Vue.use(VueMeta);

import "./assets/css/main.css";

AOS.init({
    startEvent: "load",
    once: true,
    offset: -500, // offset (in px) from the original trigger point
    delay: 0, // values from 0 to 3000, with step 50ms
    duration: 800 // values from 0 to 3000, with step 50ms
});
// AOS.init({
//   duration: 8000
// });

Vue.config.productionTip = false;

Vue.use(ElementUI, { locale });

Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyCafS1FUd644OyagUCmyo_HCqAwYAOsIPI",
        libraries: "places"
    }
});

import moment from "moment";
Vue.prototype.moment = moment;

Vue.config.productionTip = false;

import _ from "lodash";
Vue.use(_);
Vue.prototype._ = _;

import fn from "./helpers/func";
Vue.prototype.$fn = fn;

import VueAwesomeSwiper from "vue-awesome-swiper";
import Swiper, { Navigation, Pagination, Mousewheel, Scrollbar } from "swiper";
import "swiper/swiper-bundle.css";

Vue.use(VueAwesomeSwiper);
Swiper.use([Navigation, Pagination, Mousewheel]);

import VueSocialSharing from "vue-social-sharing";

Vue.use(VueSocialSharing);

import vSelect from "vue-select";
Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";

import SlideUpDown from "vue-slide-up-down";
Vue.component("slide-up-down", SlideUpDown);

import DatePicker from "v-calendar/lib/components/date-picker.umd";
Vue.component("v-date-picker", DatePicker);

// import RangeCalendar from 'vue2-datepicker'
// import 'vue2-datepicker/index.css';

// Vue.component('range-calendar', RangeCalendar)

import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

import lightgallery from "lightgallery.js";
import "lightgallery.js/dist/css/lightgallery.min.css";
import "lg-video.js";

Vue.use(lightgallery);
import LightGallery from "vue-light-gallery";

Vue.use(LightGallery);

import VueCookies from "vue-cookies";
Vue.use(VueCookies);

new Vue({
    router: router,
    store,
    beforeCreate: function() {
        if (this.$route.query.preview)
            this.$cookies.set("siteMode", this.$route.query.preview, "1d");
        this.$store.dispatch("main/indx");
    },

    mounted() {
        window.scrollTo(0, 0);
    },

    watch: {
        "$route.name": {
            immediate: true,
            handler() {
                /// set selected locale
                /// set axios default header for selected language
                /// send main request to server

                if (!this.$route.name) return false;

                this.$store.commit("main/setState", {
                    key: "locale",
                    val: this.$route.name
                });
                this.$store.commit("main/setMenuFullUrls");

                axios.defaults.headers.common[
                    "lang"
                ] = this.$store.state.main.locale;

                // if (this.$cookies.get("siteMode")) axios.defaults.headers.common['siteMode'] = this.$cookies.get("siteMode");

                /// load indx part after every language change
                this.$store.dispatch("main/indxTranslatable");

                /// loads only once
                this.$store.dispatch("main/getTranslations");
            }
        },
        "$store.state.main.locale": {
            immediate: true,
            handler: function(val) {
                moment.locale(this.setLanguage(val));
            }
        }
    },
    methods: {
        setLanguage(lang) {
            if (lang == "ge") {
                return "ka";
            } else {
                return lang;
            }
        }
    },
    render: h => h(App)
}).$mount("#app");
