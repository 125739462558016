<template>
    <section>
        <div class="container-wrapper">
            <div class="cart-header">
                <content-title
                    :colored-title="true"
                    :title="$fn.tr('Your cart')"
                    data-aos="fade-up"
                    data-aos-delay="100"
                />
            </div>

            <div class="cart-content relative">
                <img
                    loading="lazy"
                    class="decor-img-rotated"
                    src="../assets/img/decor.png"
                    alt=""
                    data-aos="fade-in"
                    data-aos-delay="200"
                />
                <img
                    loading="lazy"
                    class="bottom-decor-img"
                    src="../assets/img/decor.png"
                    alt=""
                />
                <div
                    class="cart-content-element"
                    v-for="(item, index) in dataList"
                    :key="index"
                    :class="{ hide: cartId === item.id }"
                >
                    <CartElement
                        data-aos="fade-up"
                        data-aos-delay="200"
                        :cartData="item"
                        :itemIndex="index"
                        v-on:delete-element="removeCartItem(item)"
                    />
                </div>
                <!-- :aciveClass="cartId === item.id" -->
                <div
                    class="font-34 bold no-item"
                    data-aos="fade-up"
                    data-aos-delay="300"
                    v-if="!_.get(getCartItems, 'data')"
                >
                    {{ $fn.tr("You do not have any item in your cart") }}
                </div>
            </div>

            <div
                class="relative cart-footer-content"
                data-aos="fade-up"
                data-aos-delay="200"
                v-if="_.get(getCartItems, 'data')"
            >
                <div class="checkout-info">
                    <div class="cart-total light font-34">
                        {{ $fn.tr("Total Order") }}:
                        <span class="bold font-34"
                            >{{ formatPrice(getCartItems.price) }} ₾</span
                        >
                    </div>
                    <div class="font-13 regular">
                        {{
                            $fn.tr(
                                "Assembly price is optional and is not included in the total price"
                            )
                        }}
                    </div>
                </div>
                <router-link
                    tag="button"
                    :to="`/${$store.state.main.locale}/checkout`"
                    class="font-13 bold"
                >
                    {{ $fn.tr("Continue CHECKOUT") }}
                </router-link>
            </div>
        </div>
        <CartInfo />
    </section>
</template>

<script>
import contentTitle from "../components/ContentTitle";
import CartInfo from "../components/CartInfo";
import CartElement from "../components/CartItem";
import addToCartMixin from "../mixins/addToCartMixin";
import commaNum from "@/helpers/commaNum";
export default {
    components: {
        contentTitle,
        CartInfo,
        CartElement
    },
    mixins: [addToCartMixin],
    data() {
        return {
            filterShow: false,
            AnimActive: false,
            cartId: null
        };
    },
    computed: {
        dataList() {
            return this.getCartItems?.data?.list;
        }
    },
    methods: {
        formatPrice(price) {
            return price ? commaNum(Number(price)) : 0;
        }
    },
    mounted() {
        this.$store.commit("main/setState", {
            key: "breadcrumbList",
            val: [{ title: "Cart" }]
        });
    }
};
</script>

<style scoped>
.no-item {
    margin-top: 144px;
    text-align: center;
}
.cart-content {
    min-height: 400px;
}
.cart-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 30px;
    border-bottom: 1px solid #f7f7f7;
    margin-bottom: 60px;
}
.cart-footer-content {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    margin-bottom: 144px;
}
.cart-header-content {
    display: flex;
    align-items: center;
    width: 80%;
    justify-content: flex-end;
}
.checkout-info {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}
.cart-footer-content button {
    margin-left: 30px;
    max-width: 250px;
    height: 44px;
    width: 100%;
    background: #81c7bd;
    color: #fff;
    border-radius: 10px;
    text-transform: uppercase;
}
.cart-header-content button {
    margin-left: 30px;
    max-width: 250px;
    height: 44px;
    width: 100%;
    background: #81c7bd;
    color: #fff;
    border-radius: 10px;
    text-transform: uppercase;
}
.content-title {
    margin-bottom: 0;
}
.decor-img-rotated {
    position: absolute;
    z-index: -1;
    left: -30%;
    top: -5%;
    transform: rotate(90deg) !important;
}
.cart-content-element {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 60px;
    margin-bottom: 56px;
    border-bottom: 1px solid #f7f7f7;
    opacity: 1;
    visibility: visible;
    max-height: 1000px;
}
.cart-content-element.hide {
    opacity: 0;
    visibility: hidden;
    transform: scaleY(0);
    margin-bottom: 0;
    padding-bottom: 0;
    max-height: 0;
    transition: 0.4s ease, transform 0.01ms ease;
}
@media (max-width: 1023px) {
    .no-item {
        margin-top: 75px;
    }
    .cart-content {
        min-height: unset;
    }
    .filter-inputs {
        margin-top: 30px;
    }
    .decor-img-rotated {
        display: none;
    }
    .cart-header {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 30px;
    }
    .cart-content-element {
        padding-bottom: 30px;
        margin-bottom: 30px;
    }
    .filter-inputs {
        margin-top: 30px;
    }
    .cart-footer-content {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 75px;
    }
    .cart-footer-content button {
        margin: 15px 0 0 0;
    }
    .cart-header-content {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-top: 20px;
    }
    .checkout-info {
        align-items: flex-start;
    }
    .cart-header-content button {
        margin: 15px 0 0 0;
    }
}
@media (max-width: 767px) {
    .no-item {
        margin-top: 55px;
    }
    .cart-total,
    .cart-total span {
        font-size: 21px;
        margin-bottom: 10px;
    }
}
</style>
