<template>
    <section
        class="cart-info"
        :class="{ 'temporary-class': !_.size(sortCarts) }"
    >
        <div class="container-wrapper">
            <div class="inner-container" v-if="_.size(sortCarts)">
                <div
                    class="cart-card-component"
                    v-for="(item, index) in sortCarts"
                    :key="index"
                >
                    <a :href="item.url">
                        <div>
                            <h3 :style="{ color: item.color1 }">
                                {{ item.title }}
                            </h3>
                            <p>{{ item.content }}</p>
                        </div>
                        <div class="font-13 link bold uppercase b2b-view-more">
                            {{ $fn.tr("View More") }}
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props: ["data"],
    computed: {
        sortCarts() {
            const cartSort = _.get(
                this.$store.state.main.indx,
                "home_content.home_content.original.secondary.InnerPagesLinksBlock.data.list"
            );

            return cartSort ? cartSort.sort((a, b) => a.sort - b.sort) : [];
        }
    }
};
</script>

<style scoped>
.container {
    max-width: 1638px;
    width: 100%;
    margin: 0 auto;
}
.temporary-class {
    min-height: 280px;
}
.relative {
    position: relative;
}
.flex {
    display: flex;
}
.align-center {
    align-items: center;
}
.justify-between {
    justify-content: space-between;
}
.justify-around {
    justify-content: space-around;
}
.cart-info {
    background: #f7f7f7;
    padding: 30px 0;
    position: relative;
    /* z-index: 8; */
}
.inner-container {
    display: flex;
    justify-content: space-between;
}
.cart-card-component {
    padding: 40px;
    background: #ffffff;
    width: 32%;
    border: 1px solid #d9eeeb;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.first-heading {
    color: #81c7bd;
}
.second-heading {
    color: #fec55e;
}
.cart-card-component h3 {
    font-size: 34px;
    font-family: var(--noto-light);
}
.cart-card-component p {
    font-size: 16px;
    color: #435059;
    margin: 10px 0 30px 0;
    font-family: var(--noto-regular);
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.cart-card-component a {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    transition: 0.4s;
}
.cart-card-component a:hover .link {
    color: #81c7bd;
    transition: 0.4s;
}
.cart-card-component .link {
    color: #181818;
    text-decoration: underline;
    transition: 0.4s;
}
@media (max-width: 1365px) {
    .cart-info {
        padding: 30px 0;
    }
    .cart-card-component p {
        margin-bottom: 20px;
    }
    .cart-info {
        padding: 30px 0;
    }
    .cart-card-component {
        padding: 20px 30px;
    }
}
@media (max-width: 1023px) {
    .cart-info {
        padding: 30px 0;
    }
    .inner-container {
        flex-direction: column;
    }
    .cart-card-component {
        width: 100%;
        padding: 30px;
        margin-bottom: 10px;
    }
    .cart-card-component:nth-child(3) {
        margin-bottom: 0;
    }
}
@media (max-width: 767px) {
    .cart-info {
        padding: 20px 0;
    }
    .cart-card-component h3 {
        font-size: 21px;
    }
    .cart-card-component a {
        width: 100%;
        align-items: center;
        flex-direction: row;
    }
    .cart-card-component {
        padding: 29px 20px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
    }
    .cart-card-component p {
        display: none;
    }
}
</style>
