<template>
    <div class="user-room-container">
        <section class="container-wrapper user-room">
            <div
                class="user-top-row"
                data-aos="fade-right"
                data-aos-delay="100"
            >
                <div class="user-top-row-text">
                    <img
                        loading="lazy"
                        src="../assets/img/user-icon.svg"
                        alt="user"
                    />
                    <h1 class="user-name">
                        {{ $fn.tr("Please Attach Order Invoice") }}
                    </h1>
                </div>
                <div class="guest-button-row button-content center-flex">
                    <div
                        class="button-content-row relative"
                        v-show="!$store.state.shop.uploadedFile"
                    >
                        <input
                            class="pdf-input"
                            type="file"
                            ref="file"
                            @change="getFileName"
                            id="pdf-upload"
                            name="file_attach"
                            accept=".pdf"
                        />

                        <div class="button-text">
                            {{
                                $fn.tr(
                                    "For Order Confirmation you need to attache order invoice"
                                )
                            }}
                        </div>
                        <div class="guest-button-plus center-flex">
                            <img
                                loading="lazy"
                                src="../assets/img/cross2.svg"
                                alt="cross"
                            />
                        </div>
                    </div>
                    <div
                        class="button-content-row relative"
                        v-show="$store.state.shop.uploadedFile"
                    >
                        <div class="guest-button-plus center-flex border-unset">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="52.351"
                                height="52.351"
                                viewBox="0 0 52.351 52.351"
                            >
                                <g
                                    id="Group_3690"
                                    data-name="Group 3690"
                                    transform="translate(-1293 -457)"
                                >
                                    <path
                                        id="Path"
                                        d="M0,0H52.351V52.351H0Z"
                                        transform="translate(1293 457)"
                                        fill="rgba(255,0,0,0)"
                                        fill-rule="evenodd"
                                    />
                                    <g
                                        id="Group_3631"
                                        data-name="Group 3631"
                                        transform="translate(184.875 94.875)"
                                    >
                                        <g
                                            id="Rectangle_56"
                                            data-name="Rectangle 56"
                                            transform="translate(1114.125 368.125)"
                                            fill="none"
                                            stroke="#fff"
                                            stroke-width="2"
                                        >
                                            <rect
                                                width="40"
                                                height="40"
                                                rx="10"
                                                stroke="none"
                                            />
                                            <rect
                                                x="1"
                                                y="1"
                                                width="38"
                                                height="38"
                                                rx="9"
                                                fill="none"
                                            />
                                        </g>
                                        <path
                                            id="Icon_metro-file-pdf"
                                            data-name="Icon metro-file-pdf"
                                            d="M20.924,23.236A16.631,16.631,0,0,0,22.76,24.46a21.69,21.69,0,0,1,2.557-.153q3.213,0,3.869,1.071a1.056,1.056,0,0,1,.044,1.137.063.063,0,0,1-.022.044l-.044.044v.022q-.131.831-1.552.831a9.017,9.017,0,0,1-2.514-.437,15.938,15.938,0,0,1-2.842-1.158,39.359,39.359,0,0,0-8.568,1.814Q10.344,33.4,8.4,33.4a1.271,1.271,0,0,1-.612-.153l-.525-.262q-.022-.022-.131-.109A.9.9,0,0,1,7,32.088a4.747,4.747,0,0,1,1.224-2,10.564,10.564,0,0,1,2.885-2.109.319.319,0,0,1,.5.131.126.126,0,0,1,.044.087q1.137-1.858,2.339-4.306a33.361,33.361,0,0,0,2.273-5.727,17.669,17.669,0,0,1-.667-3.486,8.5,8.5,0,0,1,.142-2.787q.24-.874.918-.874h.481a.922.922,0,0,1,.765.328,1.746,1.746,0,0,1,.2,1.486.474.474,0,0,1-.087.175.569.569,0,0,1,.022.175v.656a28.623,28.623,0,0,1-.306,4.2,11.059,11.059,0,0,0,3.191,5.2ZM8.333,32.22q1.137-.525,2.995-3.454A12.46,12.46,0,0,0,9.415,30.6,7.265,7.265,0,0,0,8.333,32.22Zm8.7-20.109A6.5,6.5,0,0,0,16.989,15q.022-.153.153-.962,0-.066.153-.94a.492.492,0,0,1,.087-.175.063.063,0,0,1-.022-.044.044.044,0,0,0-.011-.033.044.044,0,0,1-.011-.033,1.258,1.258,0,0,0-.284-.787.063.063,0,0,1-.022.044v.044Zm-2.71,14.448a32.061,32.061,0,0,1,6.208-1.771,3.3,3.3,0,0,1-.284-.208,3.915,3.915,0,0,1-.35-.3,11.578,11.578,0,0,1-2.776-3.847,29.209,29.209,0,0,1-1.814,4.306q-.656,1.224-.984,1.814Zm14.12-.35q-.525-.525-3.06-.525a8.268,8.268,0,0,0,2.71.612,2.12,2.12,0,0,0,.393-.022q0-.022-.044-.066Z"
                                            transform="translate(1116.156 366.314)"
                                            fill="#fff"
                                        />
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <a
                            :href="$store.state.shop.uploadedFile"
                            target="_blank"
                            class="button-text pdf-link-title"
                            >invoice.pdf</a
                        >
                        <div @click="deleteFile" class="clear-button">
                            <svg
                                class="close-svg"
                                xmlns="http://www.w3.org/2000/svg"
                                width="15.424"
                                height="15.424"
                                viewBox="0 0 15.424 15.424"
                            >
                                <path
                                    id="Icon_metro-cancel"
                                    data-name="Icon metro-cancel"
                                    d="M22.815,9.64l-4.82,4.82-4.82-4.82-2.892,2.892,4.82,4.82-4.82,4.82,2.892,2.892,4.82-4.82,4.82,4.82,2.892-2.892-4.82-4.82,4.82-4.82Z"
                                    transform="translate(-10.283 -9.64)"
                                    fill="#435059"
                                />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <div class="user-rows-container">
                <div class="dropdown-menu" :class="{ open: openDropdown }">
                    <div
                        class="dropdown-menu-container"
                        @click="openDropdown = !openDropdown"
                    >
                        <div class="center-flex">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                :class="{
                                    'active-image-dropdown': openDropdown
                                }"
                            >
                                <g
                                    id="Group_3596"
                                    data-name="Group 3596"
                                    transform="translate(0 24) rotate(-90)"
                                >
                                    <rect
                                        id="Rectangle_48"
                                        data-name="Rectangle 48"
                                        width="24"
                                        height="24"
                                        fill="rgba(255,0,0,0)"
                                    />
                                    <g
                                        id="Group_820"
                                        data-name="Group 820"
                                        transform="translate(5.856 2)"
                                    >
                                        <path
                                            id="Path_331"
                                            data-name="Path 331"
                                            d="M213.584,780.046a1.144,1.144,0,0,0,.809-1.953l-8.047-8.047L214.393,762a1.144,1.144,0,1,0-1.618-1.618l-8.856,8.856a1.144,1.144,0,0,0,0,1.618l8.856,8.856A1.14,1.14,0,0,0,213.584,780.046Z"
                                            transform="translate(-203.584 -760.046)"
                                            fill="#435059"
                                        />
                                    </g>
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>

                <div class="user-content order-history">
                    <div
                        data-aos="fade"
                        data-aos-duration="1000"
                        data-aos-delay="200"
                        class="content-top-title a"
                    >
                        {{ $fn.tr("Order History") }}
                    </div>

                    <div
                        data-aos="fade"
                        data-aos-duration="1000"
                        data-aos-delay="200"
                        class="order-history-table"
                    >
                        <div class="order-history-table-header">
                            <div class="order-history-row">
                                {{ $fn.tr("Order Date") }}
                            </div>
                            <div class="order-history-row">
                                {{ $fn.tr("Order Number") }}
                            </div>
                            <div class="order-history-row">
                                {{ $fn.tr("Items Count") }}
                            </div>
                            <div class="order-history-row">
                                {{ $fn.tr("Total Price") }}
                            </div>
                            <div class="order-history-row">
                                {{ $fn.tr("Shipping Status") }}
                            </div>
                            <div class="order-history-row">
                                {{ $fn.tr("Print") }}
                            </div>
                        </div>
                        <div class="order-history-table-content">
                            <order-history-item
                                :active="true"
                                v-if="getOrderData"
                                :upload="true"
                                :order="getOrderData"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <img
            class="decor-img"
            loading="lazy"
            src="../assets/img/decor.png"
            alt="decor"
        />
        <CartInfo />
    </div>
</template>

<script>
import AddressItem from "../components/checkout/AddressItem";
import CartInfo from "../components/CartInfo";
import orderHistoryItem from "../components/orderHistoryItem";
import { mapGetters } from "vuex";
import Notification from "../components/Notification";

export default {
    components: {
        AddressItem,
        CartInfo,
        orderHistoryItem,
        Notification
    },
    data() {
        return {
            openDropdown: true,
            formData: ""
        };
    },
    computed: {
        ...mapGetters({
            getOrderHistory: "user/getOrderHistory",
            getOrderData: "shop/orderData"
        }),
        selectedOrderItem() {
            return this.getOrderHistory.filter(
                order => order.id === parseInt(this.$route.params.id)
            );
        }
    },
    methods: {
        getFileName() {
            // this.fileName = this.$refs.file.files[0].name;
            // this.attachedFileLink =  URL.createObjectURL(this.$refs.file.files[0]);
            this.formData = new FormData();
            this.formData.append("file", this.$refs.file.files[0]);
            this.formData.append("id", this.$route.params.id);
            // this.fileAttached = true;
            this.$store.dispatch("shop/uploadInvoice", this.formData);
        },
        deleteFile() {
            this.$store.dispatch("shop/deleteInvoice", {
                id: this.$route.params.id
            });
        }
    },

    mounted() {
        if (this.$route.params.id)
            this.$store.dispatch("shop/getOrderData", {
                id: this.$route.params.id
            });
        this.$store.commit("main/setState", {
            key: "breadcrumbList",
            val: [{ title: "Invoice" }]
        });
    }
};
</script>

<style scoped>
.clear-button {
    display: flex;
    align-items: center;
    margin-right: 20px;
    cursor: pointer;
}
.user-room {
    min-height: 867px;
}
.border-unset {
    border: unset !important;
}
.pdf-link-title,
.button-text.pdf-link-title {
    text-decoration: underline;
    color: #181818;
}
.pdf-input {
    opacity: 0;
    /* visibility: hidden; */
    position: absolute;
    height: 100%;
    width: 100%;
    cursor: pointer;
}

.user-room-container {
    position: relative;
    margin-top: -25px;
    z-index: 9;
}

.action-buttons {
    margin-bottom: auto;
}

.margin-t-50 {
    margin-top: 50px;
}

.personal-info-main-wrapp {
    width: 100%;
    display: flex;
}

.user-rows-container {
    display: flex;
    margin-bottom: 185px;
}

.user-top-row {
    background-color: #f7f7f7;
    height: 110px;
    border-radius: 10px;
    width: 100%;
    display: flex;
    align-items: center;
}

.user-aside-menu {
    min-width: 250px;
    max-width: 250px;
    height: 300px;
    border-right: 1px solid #f7f7f7;
    text-align: left;
}

.form-file-attach {
    display: flex;
    align-items: center;
    margin: 20px 0;
    cursor: pointer;
}

.form-file-attach-button {
    width: 44px;
    height: 44px;
    background-color: #fec55e;
    border-radius: 10px;
    cursor: pointer;
}

.form-file-attach-button svg {
    transform: rotate(45deg);
}

.form-file-attach-title {
    color: #435059;
    font-family: var(--noto-bold);
    font-size: 21px;
    margin-left: 20px;
    transition: 0.3s ease;
}

.form-file-attach:hover .form-file-attach-title {
    color: var(--orange);
    transition: 0.3s ease;
}

.form .margin-b-0 {
    margin-bottom: 0;
}

.form .margin-b-t-0 {
    margin-bottom: 0;
    margin-top: 0;
}

.dropdown-menu {
    display: none;
}

.dropdown-menu svg {
    transition: 0.2s ease;
}

.user-aside-menu,
.personal-info {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
}

.active-image-dropdown {
    transform: rotate(180deg);
    transition: 0.2s ease;
}

.active-image-dropdown path {
    fill: var(--green);
    transition: 0.2s ease;
}

.menu-item {
    font-family: var(--noto-regular);
    font-size: 16px;
    margin-bottom: 30px;
    cursor: pointer;
}

.menu-item:hover {
    color: var(--green);
}

.menu-item.active {
    color: var(--green);
    font-family: var(--noto-bold);
}

.total-orders {
    flex-direction: column;
    width: 305px;
    height: 230px;
    background-color: var(--light-green);
    border-radius: 10px;
    margin-left: 30px;
}

.user-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: 30px;
}

.personal-info-address {
    width: 100%;
    background-color: #ffffff;
}

.flex-column > div {
    width: 100%;
}

.flex-column {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.user-top-row-text {
    display: flex;
    align-items: center;
    margin-left: 40px;
}

.user-name {
    font-size: 34px;
    font-family: var(--noto-bold);
    padding-left: 50px;
}

.button-content-row {
    display: flex;
    flex-direction: column;
}

.guest-button-row.button-content {
    width: 390px;
    padding-left: 30px;
}

.pdf-guest {
    margin-right: 20px;
}

.guest-button-plus {
    width: 71px;
    height: 71px;
    border-radius: 10px;
    border: 1px solid #ffffff;
    margin: auto 10px auto auto;
}

.guest-button-row.button-content .button-content-row {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.guest-button-row.button-content .button-text {
    width: 300px;
    line-height: 20px;
    margin: auto 10px auto 0;
}

.button-text span {
    font-family: var(--noto-bold);
    font-size: 13px;
}

.total-orders-text {
    font-family: var(--noto-bold);
    margin-top: 17px;
    margin-bottom: 3px;
    font-size: 19px;
    text-align: center;
    padding: 0 5px;
}

.total-order-number {
    color: var(--orange);
    font-size: 34px;
    font-family: var(--noto-bold);
}

.button-content {
    display: flex;
    width: 89px;
    height: 89px;
    flex-direction: column;
    background-color: #fec55e;
    border-radius: 10px;
    cursor: pointer;
    margin-right: 10px;
    transition: 0.5s ease;
    margin-left: auto;
}

.button-text {
    margin-top: 10px;
    color: #ffffff;
    font-family: var(--noto-bold);
    font-size: 13px;
    line-height: 15px;
    text-transform: capitalize;
}

.decor-img {
    position: absolute;
    bottom: -10%;
    left: -25%;
    z-index: -1;
}

/*form*/
.form {
    display: flex;
    flex-direction: column;
}

.form-input-item {
    margin-bottom: 30px;
}

.form-input-item .label {
    color: #435059;
    font-family: var(--noto-regular);
    font-size: var(--normalFontSize);
    padding: 0 0 10px;
    display: flex;
}

.form-input-item .label span {
    color: #ff7675;
    font-size: 12px;
    margin-left: 5px;
}

.form-input-item input,
.form-input-item textarea {
    width: 100%;
    border: unset;
    border-radius: 10px;
    padding: 11px;
    background-color: #f7f7f7;
    font-size: 16px;
}

.form-input-checkbox-label {
    font-family: var(--noto-regular);
    font-size: 16px;
}

.form-input-buttons {
    margin-top: 60px;
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
}

.form-button {
    border-radius: 10px;
    cursor: pointer;
    font-family: var(--noto-bold);
    transition: 0.3s ease !important;
}
.form-button:hover {
    transition: 0.3s ease !important;
}

.form-button:first-child {
    background-color: #f7f7f7;
    width: 140px;
    height: 44px;
    font-size: 13px;
}

/*.form-button:first-child:hover {*/
/*    background-color: #435059;*/
/*    color: #F7F7F7;*/
/*    transition: .2s ease !important;*/
/*}*/

/*.form-button:nth-of-type(2) {*/
/*    background-color: var(--light-green);*/
/*    color: var(--green);*/
/*    width: 200px;*/
/*    height: 44px;*/
/*    transition: .2s ease;*/
/*}*/

/*.form-button:nth-of-type(2):hover {*/
/*    background-color: var(--green);*/
/*    color: #FFFFFF;*/
/*    transition: .2s ease !important;*/
/*}*/

.form-container .border-error {
    border: 1px solid #ff7675;
}

.form-container .red {
    font-size: 13px;
    padding-top: 5px;
    color: #ff7675;
    padding-bottom: 0;
}

.form-container {
    border: 1px solid #ecf0f1;
    background-color: #ffffff;
    padding: 30px;
    border-radius: 10px;
    width: 810px;
}

.content-top-title {
    font-family: var(--noto-bold);
    font-size: 21px;
    margin-bottom: 33px;
    margin-top: 55px;
    padding-left: 30px;
}

.order-history {
    display: flex;
    flex-direction: column;
    justify-content: unset;
}

/*table*/
.order-history-row {
    font-family: var(--noto-regular);
    font-size: 16px;
}

.order-history-table-header {
    display: flex;
    padding-left: 30px;
}

.order-history-row {
    width: 14%;
    text-align: center;
}

.order-history-row:nth-of-type(1) {
    text-align: left;
}

.order-history-row:nth-of-type(3) {
    width: 23%;
}

.order-history-row:nth-of-type(4) {
    width: 10%;
}

.order-history-row:nth-of-type(5) {
    width: 23%;
}

.order-history-row:nth-of-type(6) {
    width: 15%;
}

.dropdown-menu {
    border: 1px solid #f7f7f7;
    height: 80px;
}
.button-content img {
    width: 25px;
    margin: auto;
}

@media screen and (max-width: 1599px) {
    .decor-img {
        display: none;
    }

    .user-room {
        min-height: unset;
    }

    .user-rows-container {
        margin-bottom: 150px;
    }
}

@media screen and (max-width: 1355px) {
    .user-top-row-text img {
        width: 48px;
    }

    .user-top-row-text {
        margin-left: 25px;
    }

    .user-aside-menu {
        min-width: 167px;
        max-width: 167px;
    }

    .user-name {
        padding-left: 26px;
    }

    .order-history-row:nth-of-type(6) {
        text-align: left;
    }

    .container-wrapper {
        padding-left: 34px;
        padding-right: 25px;
    }

    .user-aside-menu,
    .personal-info {
        margin-top: 35px;
    }

    .menu-item,
    .user-aside-menu {
        width: 167px;
    }

    .user-content {
        margin-left: 50px;
    }

    .content-top-title {
        margin-bottom: 32px;
        margin-top: 36px;
    }

    .order-history-row {
        width: 16%;
    }

    .order-history-row:nth-of-type(3) {
        width: 18%;
    }

    .order-history-row:nth-of-type(5) {
        width: 23%;
    }

    .order-history-row:nth-of-type(4) {
        width: 11%;
    }

    .order-history-row:nth-of-type(3) {
        width: 18%;
        /*text-align: left;*/
    }

    .order-history-table-header {
        padding-left: 22px;
    }

    .guest-button-row.button-content {
        width: 300px;
    }
}

@media screen and (max-width: 1023px) {
    .personal-info-main-wrapp {
        flex-direction: column-reverse;
    }

    .user-rows-container {
        display: flex;
        flex-direction: column;
    }

    .user-room-container {
        margin-top: 0;
    }

    .user-top-row-text {
        margin-left: 35px;
    }

    .container-wrapper {
        padding-left: 33px;
        padding-right: 23px;
    }

    .user-content {
        margin-left: unset;
    }

    .user-aside-menu {
        display: none;
    }

    .dropdown-menu {
        display: block;
        margin-top: 20px;
        border-radius: 10px;
    }

    .content-top-title {
        padding-left: 20px;
    }

    .dropdown-menu .menu-item {
        margin-bottom: unset;
    }

    .dropdown-list {
        max-height: 0;
        overflow: hidden;
        position: relative;
        background: #f7f7f7;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .dropdown-list .menu-item {
        margin: 20px;
    }

    .dropdown-menu.open .dropdown-list {
        max-height: 300px;
        z-index: 99;
        margin-top: -2px;
    }

    .dropdown-menu-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 80px;
        padding: 0 20px;
        cursor: pointer;
    }

    .order-history-row {
        font-size: 15px;
    }

    .order-history-row:nth-of-type(6) {
        text-align: center;
        padding-right: 15px;
    }

    .personal-info {
        display: flex;
        flex-direction: column;
    }

    .total-orders {
        width: 100%;
        margin-left: 0;
        height: 200px;
        flex-direction: row;
    }

    .form-container {
        width: 100%;
    }

    .total-orders-text {
        margin: 0 30px;
    }

    .dropdown-menu-container .menu-item.active {
        font-size: 21px;
        width: unset;
    }
}

@media screen and (max-width: 767px) {
    .user-name {
        font-size: 13px;
        padding-left: 10px;
    }

    .user-room-container {
        margin-top: 3px;
    }

    .guest-button-row.button-content {
        width: 100%;
        min-height: 42px;
        height: auto;
        padding: 8px 0 8px 30px;
    }

    .guest-button-plus {
        width: unset;
        height: unset;
        border-radius: unset;
        border: unset;
    }

    .guest-button-row.button-content .button-text {
        line-height: 16px;
    }

    .user-top-row {
        display: flex;
        flex-direction: column;
    }

    .button-content-row {
        display: flex;
        flex-direction: row;
    }

    .button-text {
        margin-top: unset;
    }

    .user-top-row {
        min-height: 121px;
        height: auto;
        justify-content: center;
        align-items: flex-start;
        padding: 10px;
    }

    .user-top-row-text {
        margin-bottom: 10px;
    }

    .user-top-row-text img {
        width: 40px;
    }

    .user-top-row-text {
        margin-left: 5px;
    }

    .button-text {
        margin-left: 10px;
    }

    .dropdown-menu-container,
    .dropdown-menu {
        height: 64px;
    }

    .form-container {
        padding: 20px;
    }

    .form-button {
        font-size: 13px;
        width: 45% !important;
    }

    .total-order-number {
        font-size: 21px;
    }

    .total-orders-text {
        font-size: 16px;
        margin: 0 20px;
    }

    .order-history-row:nth-of-type(3),
    .order-history-row:nth-of-type(4),
    .order-history-row:nth-of-type(5),
    .order-history-row:nth-of-type(6) {
        display: none;
    }

    .order-history-row {
        width: 42%;
    }

    .open-history {
        margin: 11px 0 20px;
    }

    .button-content {
        width: 100%;
        /*margin-top: 15px;*/
        height: 37px;
        flex-direction: row;
    }

    .button-content img {
        width: 20px;
    }

    .container-wrapper {
        padding-left: 20px;
        padding-right: 11px;
    }

    .content-top-title {
        margin-top: 25px;
    }

    .dropdown-menu-container .menu-item.active {
        font-size: 16px;
        width: unset;
    }
}
</style>

<style>
.user-rows-container .user-content .checkbox-default-radio {
    display: none;
}

.user-rows-container .personal-info-wrapper {
    padding: 25px 30px;
    height: fit-content;
}
</style>
