<template>
  <footer>
    <div class="footer-wrapper container-wrapper" data-aos="fade-up" data-aos-delay="100">
      <div class="footer-item">
        <div class="footer-item-title">
          {{ $fn.tr('Contact us') }}
        </div>
        <div class="footer-item-content">
          <div class="footer-item-description">
            {{
              _.get(
                $store.state.main.indx,
                'home_content.home_content.original.secondary.FooterContactBlock.data.list[0].contact_info.data[0][0]'
              )
            }}
          </div>
          <div class="bold">
            {{ $fn.tr('Hotline:') }}
            <a
              :href="
                `tel:${_.get(
                  $store.state.main.indx,
                  'home_content.home_content.original.secondary.FooterContactBlock.data.list[0].contact_info.data[1][0]'
                )}`
              "
              >{{
                _.get(
                  $store.state.main.indx,
                  'home_content.home_content.original.secondary.FooterContactBlock.data.list[0].contact_info.data[1][0]'
                )
              }}</a
            >
          </div>
        </div>
      </div>
      <div class="footer-item">
        <div class="footer-item-title">
          {{ $fn.tr('Social Chanels') }}
        </div>
        <div class="footer-item-content">
          <div class="footer-item-description">
            {{ $fn.tr('Social Network') }}
          </div>
          <div class="social-icons">
            <a
              :href="social.url"
              rel="noopener"
              target="_blank"
              :key="index"
              v-for="(social, index) in _.get(
                $store.state.main.indx,
                'home_content.home_content.original.secondary.FooterContactBlock.data.list[0].social'
              )"
            >
              <div v-if="social.svg" v-html="social.svg" />
              <img loading="lazy" v-else :src="social.image" :alt="social.title" />
            </a>
          </div>
        </div>
      </div>
      <div class="footer-item">
        <div v-if="!isSubscribed" class="not-subscribed">
          <div class="footer-item-title">
            {{ $fn.tr('Subscribe Newsletter') }}
          </div>
          <div class="footer-item-content">
            <div class="footer-item-description">
              {{ $fn.tr('It is a long established fact that a reader will be distracted') }}
            </div>
            <div
              class="footer-newsletter"
              :class="{
                'input-error':
                  (!$v.subscribeEmail.required ||
                    !$v.subscribeEmail.minLength ||
                    !$v.subscribeEmail.email ||
                    resError.length) &&
                  isClicked,
              }"
            >
              <input
                type="text"
                v-model="$v.subscribeEmail.$model"
                :placeholder="$fn.tr('YOUR EMAIL')"
              />
              <div class="label red" v-if="!$v.subscribeEmail.email && isClicked">
                {{ $fn.tr('Email is not formatted correctly') }}
              </div>
              <div class="label red" v-if="resError.length && isClicked">
                {{ $fn.tr(resError) }}
              </div>
              <span @click="subscribe">{{ $fn.tr('subscribe') }}</span>
            </div>
          </div>
        </div>
        <div v-else class="subscribed">
          <div class="footer-item-title">
            {{ $fn.tr('Thank You') }}
          </div>
          <div class="footer-item-content">
            <div class="footer-item-description">
              {{ $fn.tr('Newsletter is subscribed on your mail') }}
            </div>
            <div class="footer-newsletter subscribed-newsletter">
              <div @click="isSubscribed = false" class="other-email">
                {{ $fn.tr('use other email') }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-item">
        <div class="footer-item-button language-button">
          <div v-if="$route.meta.url">
            <router-link
              tag="span"
              class="language-state"
              v-if="_.get($store.state.main, 'locale') == 'en'"
              @click.native="
                goToLink(
                  $store.getters['main/getPageTranslation']({
                    url: $route.meta.url,
                    lang: 'ge',
                  })
                )
              "
              :to="''"
            >
              ქართულად
            </router-link>
            <router-link
              tag="span"
              v-else-if="_.get($store.state.main, 'locale') !== 'en'"
              @click.native="
                goToLink(
                  $store.getters['main/getPageTranslation']({
                    url: $route.meta.url,
                    lang: 'en',
                  })
                )
              "
              :to="''"
              class="language-state"
            >
              English
            </router-link>
          </div>
          <div v-else>
            <router-link
              tag="span"
              v-if="_.get($store.state.main, 'locale') == 'en'"
              @click.native="goToLink(_.get($store.state.main.selected_menu_locales, 'ge'))"
              :to="''"
              class="language-state"
            >
              ქართულად
            </router-link>
            <router-link
              tag="span"
              @click.native="goToLink(_.get($store.state.main.selected_menu_locales, 'en'))"
              v-else-if="_.get($store.state.main, 'locale') !== 'en'"
              :to="''"
              class="language-state"
            >
              English
            </router-link>
          </div>
        </div>
        <div class="footer-item-button to-top" @click="animateScroll">
          <div class="footer-item-button-icon"></div>
        </div>
      </div>
    </div>
    <div class="footer-copyright container-wrapper" data-aos="fade-up" data-aos-delay="250">
      <div class="footer-copyright-texts">
        <div class="footer-copyright-item copyright">
          {{ $fn.tr('Copyright') }} 2020 © <span class="logo">{{ $fn.tr('Mysa') }}</span> LLC.
          {{ $fn.tr('All rights reserved') }}
        </div>
        <div class="footer-links center-flex">
          <router-link
            :to="`/${$store.state.main.indx.locale}/terms`"
            class="footer-copyright-item underline"
          >
            {{ $fn.tr('Terms & Conditions') }}
          </router-link>
          <router-link
            :to="`/${$store.state.main.indx.locale}/privacy`"
            class="footer-copyright-item underline"
          >
            {{ $fn.tr('Privacy Policy') }}
          </router-link>
        </div>
      </div>
      <!-- <div class="footer-copyright-made-by">
                <a
                    target="_blank"
                    rel="noopener"
                    href="https://www.connect.ge/"
                >
                    <img
                        loading="lazy"
                        src="../../assets/icons/connectlogo.svg"
                        alt="connect"
                    />
                </a>
            </div> -->
    </div>
    <div class="footer-bottom" data-aos="fade-up" data-aos-delay="350">
      <!-- <div class="footer-copyright-made-by">
                <a
                    target="_blank"
                    rel="noopener"
                    href="https://www.connect.ge/"
                >
                    <img
                        loading="lazy"
                        src="../../assets/icons/connectlogo.svg"
                        alt="connect"
                    />
                </a>
            </div> -->
      <div class="footer-item">
        <div class="footer-item-button language-button">
          <div v-if="$route.meta.url">
            <router-link
              tag="span"
              class="language-state"
              v-if="_.get($store.state.main, 'locale') == 'en'"
              @click.native="
                goToLink(
                  $store.getters['main/getPageTranslation']({
                    url: $route.meta.url,
                    lang: 'ge',
                  })
                )
              "
              :to="''"
            >
              ქართულად
            </router-link>
            <router-link
              tag="span"
              v-else-if="_.get($store.state.main, 'locale') !== 'en'"
              @click.native="
                goToLink(
                  $store.getters['main/getPageTranslation']({
                    url: $route.meta.url,
                    lang: 'en',
                  })
                )
              "
              :to="''"
              class="language-state"
            >
              English
            </router-link>
          </div>
          <div v-else>
            <router-link
              tag="span"
              v-if="_.get($store.state.main, 'locale') == 'en'"
              @click.native="goToLink(_.get($store.state.main.selected_menu_locales, 'ge'))"
              :to="''"
              class="language-state"
            >
              ქართულად
            </router-link>
            <router-link
              tag="span"
              v-else-if="_.get($store.state.main, 'locale') !== 'en'"
              @click.native="goToLink(_.get($store.state.main.selected_menu_locales, 'en'))"
              :to="''"
              class="language-state"
            >
              English
            </router-link>
          </div>
        </div>
        <div class="footer-item-button to-top" @click="animateScroll">
          <div class="footer-item-button-icon"></div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import mainMixin from '@/mixins/main';
import { validationMixin } from 'vuelidate';
import { email, minLength, required } from 'vuelidate/lib/validators';
export default {
  mixins: [mainMixin, validationMixin],
  data() {
    return {
      startAnimation: false,
      MediaLinksActive: false,
      openMobile: false,
      isSubscribed: false,
      isClicked: false,
      openMobileIndex: null,
      subscribeEmail: '',
      resError: '',
    };
  },
  validations: {
    subscribeEmail: {
      required,
      minLength: minLength(3),
      email,
    },
  },
  computed: {
    getFooter() {
      return this.$store.getters['main/getFooter'];
    },
  },
  methods: {
    goToLink(link) {
      this.$router.push(link);
    },
    mobileOpen(id) {
      if (window.innerWidth <= 767) {
        if (!this.openMobile) {
          this.openMobile = true;
          this.openMobileIndex = id;
        } else {
          this.openMobile = false;
          this.openMobileIndex = null;
        }
      }
    },
    animateScroll() {
      return document.getElementById('app-content').scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
      // return window.scrollTo(0,0);
    },
    subscribe() {
      this.isClicked = true;
      if (
        !this.$v.subscribeEmail.required ||
        !this.$v.subscribeEmail.minLength ||
        !this.$v.subscribeEmail.email
      ) {
        return;
      }
      this.$store
        .dispatch('user/subscribeEmail', {
          email: this.subscribeEmail,
          formType: 'Subscribers',
        })
        .then((res) => {
          if (res.status === 200 && res.data.error) {
            this.resError = res.data.error;
          } else if (res.status === 200) {
            this.isSubscribed = true;
          }
        })
        .catch((err) => {
          console.log(err, 'error');
        });
    },
  },
  watch: {
    isSubscribed(val) {
      if (!val) {
        this.subscribeEmail = '';
        this.isClicked = false;
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.startAnimation = true;
    }, 200);
  },
};
</script>

<style scoped>
footer {
  background-color: #ffffff;
  margin-top: 60px;
}

.footer-wrapper,
.footer-copyright {
  display: flex;
  position: relative;
  justify-content: space-between;
  padding-bottom: 30px;
  margin: 0 auto;
}

.footer-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer-item:not(:last-child) {
  height: 160px;
}

.footer-item .footer-item-description {
  max-width: 260px;
  padding-top: 30px;
  padding-bottom: 20px;
  font-size: var(--normalFontSize);
}
/*
    .footer-item-content {
        margin-top: auto;
    } */

.bold {
  margin: 0;
  color: #435059;
  font-family: var(--noto-bold);
}

.footer-newsletter.input-error .label {
  color: #ff7675;
  position: absolute;
  bottom: -20px;
  font-size: 10px;
}
/* .footer-newsletter.input-error {
        border-bottom: 1px solid #FF7675;
    } */

.footer-item .footer-item-title {
  color: #435059;
  font-family: var(--noto-light);
  font-size: 33px;
}

.footer-item-description {
  font-family: var(--noto-light);
}

.footer-item-description p {
  font-family: var(--noto-light);
}

.social-icons a:not(:last-child) {
  margin-right: 15px;
}

.footer-item:nth-of-type(3) .footer-item-description {
  max-width: unset;
  padding: 10px 0;
  font-family: var(--noto-regular);
}

.footer-newsletter {
  border-bottom: 1px solid #ecedee;
  position: relative;
  padding: 10px 0;
}

.footer-newsletter input {
  border: unset;
  padding: 10px 0 0;
  font-family: var(--noto-semibold);
  font-size: 13px;
  width: 60%;
}
.footer-newsletter input::placeholder {
  color: #c6cacd;
}

.footer-newsletter span {
  color: #81c7bd;
  font-family: var(--noto-bold);
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  right: 0;
  position: absolute;
  top: 40%;
  cursor: pointer;
  transition: 0.3s ease;
}
.footer-newsletter span:hover {
  color: #435059;
  transition: 0.3s ease;
}
.footer-item-button.language-button > div {
  background-color: #d9eeeb;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 12px;
  transition: 0.3s ease !important;
  cursor: pointer;
}
.footer-item-button.language-button span {
  font-size: 13px;
  font-family: var(--noto-bold);
  color: #81c7bd;
  transition: 0.3s ease !important;
}
.footer-item-button.language-button:hover div {
  background-color: var(--green);
  transition: 0.3s ease important;
}

.footer-item-button.language-button:hover span {
  color: #ffffff;
  transition: 0.3s ease important;
}

.footer-item-button.to-top {
  background-color: #ffffff;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid #d9eeeb;
  display: flex;
  align-items: center;
  padding: unset;
  height: 132px;
  width: 105px;
  margin-top: 25px;
  transition: 0.5s ease;
}

.footer-item-button.to-top:hover {
  background-color: var(--light-green);
  border-color: #ffffff;
  cursor: pointer;
  transition: 0.5s ease !important;
}
.footer-wrapper:after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  width: calc(96% - 15px);
  height: 1px;
  background-color: #d9dcde;
}

.footer-item-button-icon {
  width: 23px;
  height: 26px;
  background-color: #81c7bd;
  -webkit-mask: url('../../assets/icons/metro-arrow-up.svg') no-repeat center;
  mask: url('../../assets/icons/metro-arrow-up.svg') no-repeat center;
  transition: 0.5s ease;
}
.footer-item:last-child {
  align-items: center;
}

/*    copyright*/
footer .footer-copyright {
  border-bottom: unset;
  padding-top: 32px;
  padding-bottom: 25px;
}

.footer-copyright-texts {
  display: flex;
  align-items: center;
}

.footer-copyright-item {
  color: #435059;
  font-family: var(--noto-semibold);
  text-transform: uppercase;
  font-weight: 500;
  font-size: 13px;
  position: relative;
}
.footer-links .footer-copyright-item:hover {
  color: var(--green);
}
.footer-copyright-item.underline {
  text-transform: uppercase;
}
.footer-copyright-item:not(:first-child) {
  margin-left: 20px;
}

.footer-copyright-item:not(:last-child):after {
  content: '';
  width: 1px;
  height: 100%;
  display: block;
  background-color: #d9dcde;
  position: absolute;
  right: -10px;
  top: 2px;
}

.footer-copyright-item .logo {
  color: #81c7bd;
  padding-left: 5px;
}
.footer-copyright-item.underline {
  text-decoration: underline;
  cursor: pointer;
}

.footer-bottom {
  display: none;
}

.footer-links {
  margin-left: 20px;
}
.subscribed-newsletter {
  border-bottom: unset;
}
.other-email {
  font-family: var(--noto-bold);
  font-size: 13px;
  text-decoration: underline;
  cursor: pointer;
}
.social-icons {
  display: flex;
}
@media screen and (max-width: 1919px) {
  footer {
    margin-top: 52px;
  }
  .footer-wrapper:after {
    width: 98%;
  }
  .social-icons {
    margin-left: 7px;
  }
  .social-icons img:not(:last-child) {
    margin-right: 10px;
  }
  .footer-wrapper,
  .footer-copyright {
    padding-left: 20px;
    padding-right: 10px;
    width: 90%;
  }
}

@media screen and (max-width: 1365px) {
  /* .footer-wrapper, .footer-copyright {
            width: 90%;
        } */
  .footer-item:nth-of-type(3) .footer-item-description {
    max-width: 330px;
    padding: 20px 0 5px;
  }
}

@media screen and (min-width: 1024px) {
  .footer-item:nth-of-type(3) {
    min-width: 472px;
  }
  .footer-item:nth-of-type(1),
  .footer-item:nth-of-type(2),
  .footer-item:nth-of-type(3) {
    height: 137px;
    justify-content: space-between;
  }
  .footer-newsletter {
    width: 113%;
  }
  .footer-item:nth-of-type(1) .footer-item-description,
  .footer-item:nth-of-type(2) .footer-item-description {
    padding-top: 0;
    padding-bottom: 7px;
  }
}

@media screen and (max-width: 1601px) {
  footer .footer-newsletter {
    width: 105%;
  }
}

@media screen and (max-width: 1599px) {
  .footer-item:nth-of-type(3) {
    min-width: 380px;
    max-width: 380px;
  }
  .footer-wrapper:after {
    width: calc(100% - 10px);
  }
  .footer-newsletter input {
    font-size: 13px;
  }
  .footer-wrapper,
  .footer-copyright {
    width: 92%;
    padding: 0 0 0 10px;
    padding-bottom: 29px;
  }
  .footer-item:nth-of-type(3) .footer-item-description {
    padding: 5px 0;
  }
}
@media screen and (max-width: 1365px) {
  footer {
    margin-top: 52px;
  }
  .footer-wrapper:after {
    width: calc(96% - 10px);
  }
  .footer-wrapper,
  .footer-copyright {
    width: 100%;
  }
  .footer-item:nth-of-type(3) {
    min-width: unset;
    max-width: unset;
  }
  footer .footer-newsletter {
    width: 92%;
  }
  footer .footer-copyright {
    padding: 32px 33px 25px 37px;
  }
  .footer-item:nth-of-type(1) {
    margin-right: 45px;
  }

  .footer-item .footer-item-title {
    font-size: 21px;
  }
  .footer-item:not(:last-child) {
    height: 130px;
  }
  .footer-item:nth-of-type(1) {
    width: 24%;
  }
  .footer-item:nth-of-type(2) {
    width: 20%;
  }
  .footer-wrapper {
    padding-bottom: 50px;
    padding-left: 35px;
    padding-right: 25px;
  }
  .footer-newsletter input {
    font-size: 13px;
  }
  .footer-item-button.to-top {
    height: 69px;
  }
  .footer-newsletter {
    padding-bottom: 5px;
  }
  .footer-item-button.language-button span {
    font-size: 13px;
  }
}

@media screen and (max-width: 1023px) {
  footer {
    margin-top: 50px;
  }
  .footer-item .footer-item-title {
    font-size: 21px;
  }
  .footer-wrapper:after {
    width: 100%;
  }

  .footer-item .footer-item-description {
    font-size: var(--normalFontSize);
  }
  footer .footer-bottom .footer-item {
    margin-right: 0;
  }

  .footer-item:nth-of-type(2),
  .footer-item:nth-of-type(1) {
    width: unset;
  }

  .footer-item-button:nth-of-type(2) {
    width: 105px;
    height: 69px;
  }

  .footer-newsletter {
    padding: unset;
  }

  footer .footer-item:not(:last-child) {
    height: 135px;
  }
  footer .footer-newsletter {
    width: 98%;
    padding-bottom: 5px;
  }
  .footer-item:nth-of-type(2) {
    margin-right: 25px;
  }
  footer .footer-copyright {
    padding: 33px 0 27px 0;
    width: 91%;
  }
  .footer-wrapper {
    padding-left: 0;
    padding-right: 0;
    width: 91%;
  }

  .footer-item-button:first-child {
    font-size: 13px;
    /*padding: 15px 10px;*/
  }
  .footer-wrapper .footer-item:last-child {
    display: none;
  }
  .footer-wrapper .footer-item:nth-of-type(3) {
    max-width: 35%;
  }
  .footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 91%;
    margin: 0 auto;
    border-top: 1px solid #d9dcde;
    padding: 15px 0;
  }
  .footer-copyright .footer-copyright-made-by {
    display: none;
  }
  .footer-bottom .footer-item {
    flex-direction: row;
  }
  .footer-item-button.to-top {
    margin-top: unset;
    margin-left: 15px;
    height: 48px;
  }
}
@media screen and (max-width: 768px) {
  .footer-item .footer-item-description {
    max-width: unset;
  }
  .footer-wrapper {
    padding-bottom: 53px;
  }
  .footer-item:nth-of-type(2) {
    margin-right: -90px;
  }
  footer .footer-copyright {
    padding: 21px 0 27px 0;
  }
  footer .footer-newsletter {
    margin-top: 15px;
  }
}

@media screen and (max-width: 768px) {
  .footer-wrapper {
    flex-direction: column;
    padding-bottom: 0;
    padding-left: 5px;
  }
  .footer-wrapper:after {
    display: none;
  }
  .footer-item:first-of-type,
  footer .footer-item:not(:last-child) {
    margin-right: unset;
  }
  .footer-copyright {
    padding: 25px 20px;
    width: 91%;
  }
  .footer-item:nth-of-type(1),
  .footer-item:nth-of-type(2) {
    width: unset;
  }
  .footer-copyright-item .logo {
    font-size: 13px;
  }
  .footer-wrapper .footer-item {
    border-bottom: 1px solid #d9dcde;
    padding-bottom: 27px;
    height: unset !important;
  }
  .social-icons {
    margin-top: 20px;
    margin-left: 0;
  }
  .footer-bottom {
    width: 91%;
    padding: 35px 0;
  }
  footer .footer-copyright {
    padding: 11px 0 37px 6px;
  }
  .footer-item:nth-of-type(3) {
    margin-top: 20px;
    margin-left: 0;
  }
  .footer-newsletter {
    margin-top: 15px;
  }
  .footer-item .footer-item-description {
    padding: 15px 0;
  }
  .footer-wrapper,
  .footer-copyright {
    border-bottom: unset;
  }
  .footer-wrapper .footer-item:nth-of-type(3) {
    max-width: unset;
    border-bottom: unset;
  }
  .footer-copyright-item.copyright:after {
    display: none;
  }
  .footer-copyright-item,
  .footer-links.center-flex {
    width: 100%;
    justify-content: flex-start;
  }
  .footer-links.center-flex {
    margin-bottom: 10px;
  }
  .footer-copyright-item.underline {
    width: unset;
  }
  .footer-copyright-texts {
    flex-direction: column-reverse;
    width: 100%;
  }
  .footer-item-button.to-top {
    width: 44px;
    height: 44px;
  }
  .footer-links {
    margin-left: 0;
  }
  .footer-item-button.language-button div {
    padding: 11px;
  }
  .footer-item:nth-of-type(2) .footer-item-description {
    display: none;
  }
  .footer-item .footer-item-description {
    font-size: 16px;
  }
  .footer-item:nth-of-type(1) {
    margin-bottom: 15px;
  }
  .footer-bottom {
    padding: 35px 0 35px 0;
  }
}
</style>
