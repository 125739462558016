<template>
  <div class="flex-items">
    <div class="left-content">
      <Parts :data="cartData.relations" :filterShow="filterShow" v-on:close-aside="closeAside" />
      <div class="relative cart-img">
        <picture>
          <source
            media="(max-width: 767px)"
            loading="lazy"
            :srcset="_.get(cartData, 'images[0].devices.mobile')"
          />
          <source
            media="(max-width: 1023px)"
            loading="lazy"
            :srcset="_.get(cartData, 'images[0].devices.tablet')"
          />
          <img :src="_.get(cartData, 'images[0].url')" loading="lazy" alt="image" />
        </picture>
        <div @click="addToWishList(cartData)">
          <svg
            v-if="!active"
            style="cursor: pointer"
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 44 44"
            @click="active = !active"
          >
            <g id="Group_3812" data-name="Group 3812" transform="translate(-378 -1115)">
              <circle
                id="Ellipse_3"
                data-name="Ellipse 3"
                cx="22"
                cy="22"
                r="22"
                transform="translate(378 1115)"
                fill="#ffeece"
              />
              <path
                id="Icon_feather-heart"
                data-name="Icon feather-heart"
                d="M17.989,5.806a4.467,4.467,0,0,0-6.318,0l-.861.861L9.95,5.806a4.468,4.468,0,1,0-6.318,6.318l.861.861L10.81,19.3l6.318-6.318.861-.861a4.467,4.467,0,0,0,0-6.318Z"
                transform="translate(389.549 1125.348)"
                fill="none"
                stroke="#fec55e"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              />
            </g>
          </svg>
        </div>

        <div @click="removeWishElement(cartData)">
          <svg
            v-if="active"
            style="cursor: pointer"
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 30 30"
            @click="active = !active"
          >
            <g id="Group_14" data-name="Group 14" transform="translate(-378 -1115)">
              <circle
                id="Ellipse_3"
                data-name="Ellipse 3"
                cx="15"
                cy="15"
                r="15"
                transform="translate(378 1115)"
                fill="#fec55e"
              />
              <path
                id="Icon_feather-heart"
                data-name="Icon feather-heart"
                d="M13,5.39a3.045,3.045,0,0,0-4.308,0l-.587.587L7.523,5.39A3.046,3.046,0,1,0,3.215,9.7l.587.587L8.11,14.592l4.308-4.308L13,9.7A3.045,3.045,0,0,0,13,5.39Z"
                transform="translate(385.135 1120.625)"
                fill="none"
                stroke="#fff"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              />
            </g>
          </svg>
        </div>
      </div>
    </div>

    <div class="right-content">
      <div>
        <div class="cart-title font-16 regular">
          {{ cartData.title }}
        </div>
        <div class="price">
          <div
            v-if="cartData.oldprice && cartData.oldprice > 0"
            class="sale vertical-product-oldprice bold font-16"
          >
            <span></span> {{ formatPrice(cartData.oldprice) }} ₾
          </div>
          <div class="cart-price bold font-34">{{ formatPrice(cartData.stock_price) }} ₾</div>
        </div>

        <div class="assembly-price font-16 regular" :class="{ hide: cartData.quantity <= 1 }">
          {{ $fn.tr('Assembly price') }}: {{ totalPrice }} ₾
        </div>
        <div class="incriment bold font-16">
          <span @click="decrementCart(cartData)" class="decrement">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8.939"
              height="8.3"
              viewBox="0 0 8.939 2.235"
            >
              <path
                id="Icon_open-plus"
                data-name="Icon open-plus"
                d="M3.352,3.352H0V5.587H8.939V3.352H3.352Z"
                transform="translate(0 -3.352)"
                fill="#435059"
              />
            </svg>
          </span>
          {{ cartData.quantity }}
          <span @click="addToCartInner(cartData, 1, false)" class="increment">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="10"
              viewBox="0 0 8.939 8.939"
            >
              <path
                id="Icon_open-plus"
                data-name="Icon open-plus"
                d="M3.352,0V3.352H0V5.587H3.352V8.939H5.587V5.587H8.939V3.352H5.587V0Z"
                fill="#435059"
              />
            </svg>
          </span>
        </div>
      </div>
      <div class="flex align-center options">
        <div class=" border-bottom" @click="filterShow = true">
          <div class="parts font-13 bold">
            {{ $fn.tr('Parts') }}
            <div class="svg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25.313"
                height="25.313"
                viewBox="0 0 25.313 25.313"
              >
                <path
                  id="Icon_material-info-outline"
                  data-name="Icon material-info-outline"
                  d="M14.391,21.984h2.531V14.391H14.391ZM15.656,3A12.656,12.656,0,1,0,28.313,15.656,12.661,12.661,0,0,0,15.656,3Zm0,22.781A10.125,10.125,0,1,1,25.781,15.656,10.138,10.138,0,0,1,15.656,25.781ZM14.391,11.859h2.531V9.328H14.391Z"
                  transform="translate(-3 -3)"
                  fill="#435059"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="remove" @click="$emit('delete-element', cartData)">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15.424"
            height="15.424"
            viewBox="0 0 15.424 15.424"
          >
            <path
              id="Icon_metro-cancel"
              data-name="Icon metro-cancel"
              d="M22.815,9.64l-4.82,4.82-4.82-4.82-2.892,2.892,4.82,4.82-4.82,4.82,2.892,2.892,4.82-4.82,4.82,4.82,2.892-2.892-4.82-4.82,4.82-4.82Z"
              transform="translate(-10.283 -9.64)"
              fill="#81c7bd"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Incriment from './Incriment';
import Parts from './PartsAside';
import { mapGetters } from 'vuex';
import addToCartMixin from '../mixins/addToCartMixin';
import commaNum from '@/helpers/commaNum';

export default {
  props: ['cartData', 'itemCount', 'itemIndex'],

  mixins: [addToCartMixin],
  components: {
    Incriment,
    Parts,
  },
  data() {
    return {
      filterShow: false,
      countItem: 1,
    };
  },
  watch: {
    cartData: {
      immediate: true,
      handler(val) {
        console.log(val, this.getWishListItems);
        if (val && _.get(this.getWishListItems, 'data')) {
          this.getWishListItems?.data.list?.findIndex((res) => res.id === val.id) !== -1
            ? (this.active = true)
            : (this.active = false);
        }
        if (val?.quantity) {
          this.quantity = parseInt(val.quantity);
          this.countItem = parseInt(val.quantity);
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      getCartItems: 'shop/getCartItems',
    }),
    totalPrice() {
      return this.formatPrice(this.cartData.stock_price * this.cartData.quantity);
    },
  },
  methods: {
    closeAside() {
      this.filterShow = !this.filterShow;
    },
    formatPrice(price) {
      return price ? commaNum(Number(price)) : 0;
    },
    addToCartInner(cartData) {
      if (this.cartData.stock_quantity - 1 > this.countItem) {
        this.countItem++;
        this.addToCart(cartData, 1, false);
      }
    },
  },
};
</script>

<style scoped>
.flex-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.active path {
  fill: #fff;
}

.left-content {
  display: flex;
  align-items: center;
}

.cart-img {
  padding-top: 228px;
  padding-left: 249px;
  background: #ffffff;
  border-radius: 30px;
  overflow: hidden;
  margin-right: 30px;
}

.cart-img svg {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

.cart-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.3s ease-in-out;
}

.cart-title {
  color: #435059;
  margin-bottom: 20px;
}

.cart-price {
  color: #181818;
}

.assembly-price {
  margin: 10px 0;
}
.assembly-price.hide {
  opacity: 0;
}

.right-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.parts {
  display: flex;
  align-items: center;
}

.parts .svg {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  width: 44px;
  height: 44px;
  background: #f7f7f7;
  border-radius: 10px;
}

.remove {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  width: 44px;
  height: 44px;
  background: #d9eeeb;
  border-radius: 10px;
}
.right-content .sale {
  position: relative;
  margin-right: 7px;
}
.vertical-product-oldprice {
  position: relative;
  margin-right: 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
}

.right-content .price {
  display: flex;
}

.right-content .sale span {
  position: absolute;
  transform: rotate(-17deg);
  top: 48%;
  left: 0;
  width: 100%;
  height: 2px;
  background: #ff7675;
}
.incriment {
  width: 120px;
  border-radius: 10px;
  border: 1px solid #f7f7f7;
  background: #fff;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 30px;
  cursor: pointer;
}
.decrement:hover svg path {
  fill: #fec55e;
  transition: 0.3s;
}
.increment:hover svg path {
  fill: #fec55e;
  transition: 0.3s;
}

@media (max-width: 1023px) {
  .options {
    margin-top: 15px;
  }
}

@media (max-width: 768px) {
  .left-content {
    flex-direction: column;
    align-items: flex-start;
    margin-right: 20px;
    width: 100%;
  }

  .cart-img {
    margin: 0 0 15px 0;
    padding-top: 100%;
    padding-left: 100%;
  }

  .right-content {
    display: block;
  }
}

@media (max-width: 767px) {
  .incriment {
    margin-right: 10px;
  }
}

@media (max-width: 530px) {
  .flex-items {
    flex-direction: column;
    align-items: flex-start;
  }

  .left-content {
    margin-right: 0;
  }
}
</style>
