<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      domain: 'tbilisi',
      activeAddressId: null,
    };
  },
  computed: {
    ...mapGetters({
      getCartItems: 'shop/getCartItems',
    }),
  },
  methods: {
    calculateShippingPrice(region) {
      if (_.get(this.$store.state.main.indx, 'shippings.original[0].rates')) {
        for (let i of _.get(this.$store.state.main.indx, 'shippings.original[0].rates')) {
          if (i.geoZone.includes(region)) {
            this.getCartItems.price > 200 && this.domain === 'tbilisi'
              ? this.$store.commit('shop/setState', { key: 'shippingPrice', val: 0 })
              : this.$store.commit('shop/setState', {
                  key: 'shippingPrice',
                  val: i.cost ? i.cost : 0,
                });
            this.$store.commit('shop/setState', {
              key: 'shippingDays',
              val: i.shipping_days ? i.shipping_days : 0,
            });
            return i.cost ? i.cost : 0;
          }
        }
      }
    },
    selectValue(domain, shippingAddress) {
      this.editAddressFormData ? (this.editAddressFormData.city = shippingAddress) : '';
      this.domain = domain ? domain : '';
      this.selectedCity = shippingAddress;
      this.shippingAddress ? (this.shippingAddress.city = shippingAddress) : '';
      this.newAddress ? (this.newAddress.city = shippingAddress) : '';
    },
  },
  watch: {
    domain: {
      immediate: true,
      handler(val) {
        this.calculateShippingPrice(val);
      },
    },
    activeAddressId: {
      immediate: true,
      handler(val) {
        if (val) {
          let addressName = this.$store.state.user.authDataAddresses.find(
            (address) => address.id === val
          ).city;
          this.domain = addressName
            ? _.get(this.$store.state.main.indx, 'cities').find(
                (r) => r.name_en === addressName || r.name_geo === addressName
              ).domain
            : '';
        }
      },
    },
    '$store.state.user.authData': {
      handler(val) {
        this.domain = '';
        this.activeAddressId = null;
        this.$store.commit('shop/setState', { key: 'shippingPrice', val: 0 });
      },
    },
  },
};
</script>
