import Vue from "vue";
import fn from "../../helpers/func";
import _ from "lodash";
import axios from "axios";

export default {
    getCartItems: async function({ commit, state }) {
        Vue.set(state, "loadCartList", true);
        let tmp = await fn.postData("cart/getallitems", {
            uniqueID: Vue.$cookies.get("uniqueID")
        });
        if (tmp.status != 200) return false;
        Vue.set(state, "cartList", tmp.data);
        Vue.set(state, "loadCartList", false);
        return tmp;
    },
    addToCart: async function({ commit, state }, data) {
        if (Vue.$cookies.get("uniqueID"))
            data.uniqueID = Vue.$cookies.get("uniqueID");
        let tmp = await fn.postData("cart/addtocart", data);
        if (tmp.status != 200) return false;
        if (!Vue.$cookies.get("uniqueID"))
            Vue.$cookies.set("uniqueID", tmp.data.uniqueID, 60 * 60 * 6);
        Vue.set(state, "cartList", tmp.data);
        return tmp;
    },
    deleteCartItem: async function({ commit, state }, data) {
        let tmp = await fn.postData("cart/deletecartitem", data);
        if (tmp.status != 200) return false;
        Vue.set(state, "cartList", tmp.data);
        return tmp;
    },
    extractCartItem: async function({ commit, state }, data) {
        let tmp = await fn.postData("cart/extractcartitem", data);
        if (tmp.status != 200) return false;
        Vue.set(state, "cartList", tmp.data);
        return tmp;
    },

    getWishList: async function({ commit, state }) {
        let tmp = await fn.postData("wishlist/getallitems", {
            uniqueID: localStorage.wishUniqueID
        });
        if (tmp.status != 200) return false;
        Vue.set(state, "wishList", tmp.data);
        return tmp;
    },
    addToWishList: async function({ commit, state }, data) {
        if (localStorage.wishUniqueID)
            data.uniqueID = localStorage.wishUniqueID;
        let tmp = await fn.postData("wishlist/addtowishlist", data);
        if (tmp.status != 200) return false;
        if (!localStorage.wishUniqueID)
            localStorage.wishUniqueID = tmp.data.uniqueID;
        Vue.set(state, "wishList", tmp.data);
        return tmp;
    },
    deleteWishItem: async function({ commit, state }, data) {
        let tmp = await fn.postData("wishlist/deletewishlistitem", data);
        if (tmp.status != 200) return false;
        Vue.set(state, "wishList", tmp.data);
        return tmp;
    },
    extractWishItem: async function({ commit, state }, data) {
        let tmp = await fn.postData("wishlist/extractwishlistitem", data);
        if (tmp.status != 200) return false;
        Vue.set(state, "wishList", tmp.data);
        return tmp;
    },

    sendOrder: async function({ commit }, data) {
        const token = Vue.$cookies.get("userToken") || data.userToken;
        token ? (data["token"] = token) : "";
        token ? (axios.defaults.headers.common["authorization"] = token) : "";
        let tmp = data.isAuthorized
            ? await fn.postData("order/sendorderautorised", data)
            : await fn.postData("order/sentorder", data);
        // if (tmp.status != 200) return false;
        return tmp;
    },
    async filterProducts() {
        let tmp = await fn.postData("main/getDataList", data);
        if (tmp.status != 200) return false;
        Vue.set(state, "wishList", tmp.data);
        return tmp;
    },
    searchProducts: async function({ commit, state }, data) {
        let tmp = await fn.postData("main/search", data);
        return tmp;
    },
    uploadInvoice: async function({ commit, state }, data) {
        axios.defaults.headers["content-Type"] = "multipart/form-data";
        let tmp = await fn.postData("main/bankinvoice", data);
        Vue.set(state, "uploadedFile", tmp.data.order.invoice_data);
        return tmp;
    },
    deleteInvoice: async function({ commit, state }, data) {
        axios.defaults.headers["content-Type"] = "multipart/form-data";
        let tmp = await fn.postData("main/deleteinvoice", data);
        Vue.set(state, "uploadedFile", null);
        return tmp;
    },
    getOrderData: async function({ commit, state }, data) {
        let tmp = await fn.postData("main/filedata", data);
        Vue.set(state, "orderData", tmp.data);
        Vue.set(state, "uploadedFile", tmp.data.invoice_data);
        return tmp;
    }
};
