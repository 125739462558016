<template>
    <div class="modal"  :class="{'modalShow' : modalActive}">
        <div class="modal-background" @click="modalClose"></div>
        <div class="modal-content" :class="{'modal-open' : modalActive, 'modal-close': animActive}">
            <svg class="close-svg" @click="modalClose" xmlns="http://www.w3.org/2000/svg" width="15.424" height="15.424" viewBox="0 0 15.424 15.424">
                <path id="Icon_metro-cancel" data-name="Icon metro-cancel" d="M22.815,9.64l-4.82,4.82-4.82-4.82-2.892,2.892,4.82,4.82-4.82,4.82,2.892,2.892,4.82-4.82,4.82,4.82,2.892-2.892-4.82-4.82,4.82-4.82Z" transform="translate(-10.283 -9.64)" fill="#435059"/>
            </svg>
            <swiper v-if="_.get(modalData, 'inner_image')" class="product-slider" ref="mySwiper" :options="verticalSwiperOptions">
                <swiper-slide class="moile-slider" v-for="(item, index) in _.get(modalData, 'inner_image')" :key="index">
                    <picture>
                        <source
                            loading="lazy"
                            media="(max-width: 767px)"
                            :srcset="(`${item.devices.mobile}`)"
                        />
                        <source
                            loading="lazy"
                            media="(max-width: 1023px)"
                            :srcset="(`${item.devices.tablet}`)"
                        />
                        <img

                            :src="(`${item.devices.desktop}`)"
                            loading="lazy"
                            alt="image"
                        />
                    </picture>
                </swiper-slide>
            </swiper>
            <div class="right-content" >
                <div class="brand-img">
                    <picture>
                        <source
                            media="(max-width: 767px)"
                            :srcset="(`${_.get(modalData, 'image[0].devices.mobile')}`)"
                            loading="lazy"
                        />
                        <source
                            media="(max-width: 1023px)"
                            :srcset="(`${_.get(modalData, 'image[0].devices.tablet')}`)"
                            loading="lazy"
                        />
                        <img
                            :src="(`${_.get(modalData, 'image[0].devices.desktop')}`)"
                            loading="lazy"
                            alt="image"
                        />
                    </picture>
                </div>
                <div class="content-text remove-styles" v-html="_.get(modalData, 'info')"></div>
                <router-link :to="`/${$store.state.main.locale}/products?brandId=${_.get(modalData, 'id')}`" :href="modalData.url" v-if="_.get(modalData, 'url_text')" class="website-link font-13 bold">
                    <div v-html="_.get(modalData, 'url_text')"></div>
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="13.714" viewBox="0 0 16 13.714">
                            <path id="Icon_metro-arrow-up" data-name="Icon metro-arrow-up" d="M13.38,6.049,7.665.335a1.143,1.143,0,0,0-1.616,0L.335,6.049A1.143,1.143,0,0,0,1.951,7.665L5.714,3.9V14.857a1.143,1.143,0,0,0,2.286,0V3.9l3.763,3.763A1.143,1.143,0,0,0,13.38,6.049Z" transform="translate(0 13.714) rotate(-90)" fill="#81c7bd"/>
                        </svg>
                    </span>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>


export default {
    props: ['modalActive', 'modalData'],

    data(){
        return{
            animActive: false,
            verticalSliderData: [
    			{
    				id: 1,
    				image: 'vertical1.png',
    			},
    			{
    				id: 2,
    				image: 'vertical2.png',
    			},
    			{
    				id: 3,
    				image: 'vertical3.png',
    			},
            ],
            verticalSwiperOptions: {
                speed: 400,
                freeMode: true,
                mousewheel: true,
    			breakpoints: {
                    320:{
                        direction: 'horizontal',
                        slidesPerView: 1.1,
                        spaceBetween: 5,
                         slidesOffsetAfter: 20
                    },
                    768: {
                        direction: 'vertical',
                        slidesPerView: 2,
                        spaceBetween: 5,
                        slidesOffsetAfter: 30,
                    },
                    1024: {
                        slidesPerView: 1.1
                    },
                    1600: {
			            slidesPerView: 1.05
                    },
                },
                spaceBetween: 10,
                direction: 'vertical',
            },
        }
    },
    watch: {
        modalActive: {
            immediate: true,

            handler(val){
                if(val){
                    document.querySelector('html').style.overflow = 'hidden';
                    document.querySelector('body').style.marginRight = '10px';
                }else{
                    document.querySelector('html').style.overflow = 'auto';
                    document.querySelector('body').style.marginRight = '0px';
                }
            }
        }
    },
    methods: {
        modalClose(){
            this.animActive = true

            setTimeout(() => {
                this.$emit('closeModal', false)
                this.animActive = false
            }, 150);
        },
    },

}
</script>

<style scoped>
    .modal{
        position: fixed;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100vh;
        z-index: 999;
        display: none;
        align-items: center;
        transform: translate(-50%, -50%);
    }
    .modalShow{
        display: flex;
    }
    .modal-background{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        background-color: rgba(67,80,89,.2);
    }
    .modal-open{
        animation-name: modalAnim;
        animation-duration: 0.4s;
    }
    .modal-close{
        animation-name: modalCloseAnim;
        animation-duration: 0.2s;
    }
    @keyframes modalAnim{
        from{
            opacity: 0;
            transform: translate3d(0,100px,0);
        }
        to{
            opacity: 1;
            transform: translate3d(0, 0px,0);
        }
    }
    @keyframes modalCloseAnim{
        from{
            opacity: 1;
            transform: translate3d(0, 0px,0);
        }
        to{
            opacity: 0;
            transform: translate3d(0,100px,0);
        }
    }
    .modal-content{
        display: flex;
        justify-content: space-between;
        padding: 30px 30px 0px 30px;
        height: 765px;
        width: 1420px;
        border-radius: 30px;
        background: #FFFFFF;
        margin: 0 auto;
        z-index: 2;
        position: relative;
        overflow: hidden;
    }
    .close-svg{
        position: absolute;
        top: 30px;
        right: 30px;
        z-index: 2;
        background: #fff;
        cursor: pointer;
    }
    .product-slider{
        width: 50%;
        margin-left: 0;
        height: 100%;
    }
    .product-slider img{
        width: 100%;
        height: 100%;
        border-radius: 30px;
    }
    .right-content{
        width: 48%;
         overflow-y: auto;
    }
    .right-content::-webkit-scrollbar{
        width: 2px;
    }
    .right-content::-webkit-scrollbar-track{
        background: transparent;

    }
    .right-content::-webkit-scrollbar-thumb{
        background:transparent;
    }
    .right-content::-webkit-scrollbar-thumb{
        height: 2px;
        width: 2px;
        border-radius: 5px;
    }
    .brand-img{
        position: relative;
        padding-top: 110px;
    }
    .brand-img img{
        position: absolute;
        top: 0;
        left: 0;
        -o-object-fit: contain;
        object-fit: contain;
        height: 100%;
    }
    .right-content p{
        line-height: 22px;
        margin-bottom: 20px;
    }
    .right-content p:last-child{
        margin-bottom: 0;
    }
    .content-text{
        margin-top: 20px;
        margin-bottom: 60px;
        overflow-y: auto;
        max-height: 415px;
    }
    .content-text::-webkit-scrollbar{
        background: none;
        width: 1px;
    }
    .website-link{
        display: flex;
        align-items: center;
        margin-bottom: 110px;
    }
    .website-link span{
        margin-left: 10px;
        height: 16px;
    }
    .website-link span svg{
        transform: rotate(180deg);
    }
    @media (max-width: 1919px){
        .modal-content{
            width: 1398px;
        }
    }
    @media (max-width: 1599px){
        .modal-content{
            width: 1030px;
            height: 564px;
        }
        .product-slider{
            width: 52%;
        }
        .right-content{
            width: 45%;
            display: flex;
            flex-direction: column;
            /* justify-content: space-between; */
            padding-right: 30px;
            overflow: auto;
        }
        .brand-img img{
            width: 100%;
        }
        .right-content::-webkit-scrollbar{
            width: 2px;
        }
        .right-content::-webkit-scrollbar-track{

            background: #ECEEEF;

        }
        .right-content::-webkit-scrollbar-thumb{
            background: #435059;
            height: 2px;
            widows: 2px;
            border-radius: 5px;
        }
        .website-link{
            margin-bottom: 20px;
        }
    }
    @media(max-width: 1365px){
        .modal-content{
            width: 954px;
            height: 522px;
        }
        .product-slider{
            width: 52%;
        }
        .right-content{
            width: 45%;
            display: flex;
            flex-direction: column;
            /* justify-content: space-between; */
            padding-right: 30px;
            overflow: auto;
        }
        .content-text{
            margin-bottom: 30px;
        }
    }
    @media(max-width: 1023px){
        .modal-content{
            width: 702px;
            height: 702px;
        }
        .product-slider{
            width: 52%;
        }
        .right-content{
            width: 43%;
            display: flex;
            flex-direction: column;
            /* justify-content: space-between; */
            padding-right: 0px;
        }
        .content-text{
            margin-bottom: 20px;
        }
        .right-content::-webkit-scrollbar-track{

            background: transparent;

        }
        .right-content::-webkit-scrollbar-thumb{
            background:transparent;
        }
    }
    @media(max-width: 767px){
        /* .modal-content{
            width: 279px;
            height: 440px;
            flex-direction: column-reverse;
            overflow-y: scroll;
            overflow-x: hidden;
            padding: 15px 15px 0 15px;
            border-radius: 10px;
        } */
        .product-slider img{
            border-radius: 10px;
        }
        .modal-content{
            width: 279px;
            height: 440px;
            display: grid;
            justify-content: initial;
            flex-direction: column;
            overflow: auto;
            overflow-x: hidden;
        }
        .right-content{
            padding-top: 20px;
            order: 1;
            overflow: unset;
            width: 100%;
            padding: 0 0 0 0;
        }
        .product-slider{
            width: 100%;
            min-height: 260px;
            display: block;
            order: 2;
        }
        .close-svg{
            position: absolute;
            top: 15px;
            right: 15px;
            z-index: 2;
            background: #fff;
        }
    }
</style>
