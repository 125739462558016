<template>
<div class="relative">
	<div class="swiper-button-next next"></div>
    <div class="swiper-button-prev prev"></div>
	
	<swiper ref="mySwiper" class="swiper-view" :options="swiperOptions" >
        <swiper-slide v-for="(item, index) in sliderData" :key="index">
                <ProductCard @openmodal="passData" :sliderData="item" />
        </swiper-slide>
		<swiper-slide v-if="viewMore == 1">
			<router-link :to="`/${$store.state.main.indx.locale}/products/`">
				<div class="view-more">
					<div class="content">
						<p class="light font-34">{{$fn.tr('view more')}}</p>
						<div class="arrow">
							<svg xmlns="http://www.w3.org/2000/svg" width="26.992" height="23.136"><path data-name="Icon metro-arrow-up" d="M16.788 22.573l9.64-9.64a1.928 1.928 0 000-2.727l-9.64-9.64a1.928 1.928 0 00-2.727 2.727l6.349 6.349H1.928a1.928 1.928 0 000 3.856H20.41l-6.349 6.349a1.928 1.928 0 002.727 2.726z" fill="#fff"/></svg>
						</div>
					</div>
				</div>
			</router-link>
		</swiper-slide>
    </swiper>

	<QuickViewModal @closeModal="modalActive= false"  :modalData="customData" :modalActive="modalActive" />

</div>
    
</template>

<script>
import ProductCard from "./ProductCardComponent"
import QuickViewModal from "../components/QuickViewModal"

export default {

    props: ['sliderData'],

    components: {
		ProductCard,
		QuickViewModal
    },

    data(){
        return{

            swiperOptions: {
    			speed: 400,
				watchOverflow: true,
				spaceBetween: 30,
				slidesOffsetAfter: 20,
				navigation: {
                    nextEl: '.next',
                    prevEl: '.prev',
                },
    			breakpoints: {
					320:{
						slidesPerView: 1.1,
						spaceBetween: 15,
					},
    				768: {
			            slidesPerView: 2.3,
		          	},
		          	1024: {
						spaceBetween: 30,
			            slidesPerView: 3
                    },
                    1600: {
			            slidesPerView: 4
                    },
                    1920: {
			            slidesPerView: 4
                    }
    			}
            },
        	customData: null,
			modalActive: false
        }
	},
	computed: {
		viewMore(){
			return this.$store.state.main.selected_menu.id
		}
	},
	methods: {
        passData(val,item){
            this.customData = item
            this.modalActive = val
        },
    },
}

</script>

<style>
@media screen and (max-width: 1023px) {
	.swiper-view {
		overflow: visible !important;
	}	
}

</style>


<style scoped>
	.relative{
		position: relative;
	}
    .flex{
        display: flex;
    }
    .align-center{
        align-items: center;
    }
    .active path {
        fill: #fff
    }
	.swiper-slide{
		height: auto;
	}
	.swiper-button-prev{
        background: #f7f7f7;
        color: #435059;
        border-radius: 10px;
        width: 44px;
        height: 44px;
        left: -74px;
		top: 41%;
    }
    .swiper-button-next{
        background: #f7f7f7;
        color: #435059;
        border-radius: 10px;
        width: 44px;
        height: 44px;
        right: -74px;
		top: 41%;
		left: auto;
    }
    .swiper-button-next::after{
        font-size: 22px;
    }
    .swiper-button-prev::after{
        font-size: 22px;
	}
	.view-more{
		width: 100%;
		height: 80%;
		border-radius: 30px;
		background: #81C7BD;
	}
	.view-more .content{
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		height: 100%;

	}
	.view-more .content p, .view-more .content span{
		color: #fff;
	}
	.view-more .content .arrow{
		margin-top: 20px;
	}
	@media(max-width: 1919px){
		.swiper-button-next{
			right: -63px;
    		top: 37.5%;
		}
		.swiper-button-prev{
    		left: -72px;
    		top: 37.5%;
		}
	}
	@media (max-width: 1599px){
		.swiper-button-next {
			right: -41px;
			top: 39.5%;
			width: 34px;
			height: 34px;
		}
		.swiper-button-prev {
			left: -50px;
    		top: 39.5%;
			width: 34px;
			height: 34px;
		}
	}
	@media (max-width: 1365px){
		.swiper-button-next {
			top: -110px;
			right: 10px;
			left: auto;
			width: 44px;
			height: 44px;
		}
		.swiper-button-prev {
			top: -110px;
			right: 76px;
			left: auto;
			width: 44px;
			height: 44px;
		}
	}
	@media(max-width: 1023px){
		.swiper-button-next{
			top: -113px;
    		right: 33px;
		}
		.swiper-button-prev{
			top: -113px;
    		right: 99px;
		}
	}
	@media (max-width: 767px){
		.swiper-button-next{
			right: 20px;
			width: 33px;
        	height: 33px;
			/* top: -50px; */
			top: -68px;
		}
		.swiper-button-prev{
			right: 60px;
			width: 33px;
        	height: 33px;
			/* top: -50px; */
			top: -68px;
		}
	}
</style>