<template>
  <div class="relative">
    <div class="container-wrapper" id="checkout">
      <!--      default title  -->
      <content-title
        data-aos="fade-down"
        class="checkout-title"
        data-aos-delay="200"
        v-if="!successOrder && !errorOrder"
        :title="$fn.tr('Cart Checkout')"
      />
      <!--      success title  -->
      <content-title
        class="success-oder-title"
        data-aos="fade-down"
        data-aos-delay="800"
        v-if="successOrder && !errorOrder"
        :title="$fn.tr('Order Placed')"
      />
      <div class="checkout" v-if="!successOrder && !errorOrder">
        <!-- არა-ავტორიზებული მომხმარებლებისთვის          -->
        <div class="account" v-if="!nextStep && !getAuthData">
          <div class="content-top-title" data-aos="fade-right" data-aos-delay="200">
            {{ $fn.tr('Already have account ?') }}
          </div>
          <div class="email-send-container" :class="{ active: activeLoginDropdown }">
            <div class="email-send-container-row">
              <div class="email-send-desc">
                <img loading="lazy" src="../assets/img/user-icon.svg" alt="email-send" />
                <div class="email-send-text">
                  <p>
                    {{ $fn.tr('Log in if you already have account') }}
                  </p>
                  <p>{{ $fn.tr('For faster checkout') }}</p>
                </div>
              </div>
              <div
                class="email-send-button center-flex"
                @click="activeLoginDropdown = !activeLoginDropdown"
              >
                <div class="login-butt" v-if="!activeLoginDropdown">
                  <img loading="lazy" src="../assets/img/log-in-icon.svg" alt="login" />
                  <div class="button-text">
                    {{ $fn.tr('Log In') }}
                  </div>
                </div>

                <div class="login-butt" v-else>
                  <img
                    loading="lazy"
                    class="login-close"
                    src="../assets/img/cross2.svg"
                    alt="cross"
                  />
                </div>
              </div>
            </div>
            <div class="email-send-container-row" :class="{ active: activeLoginDropdown }">
              <div class="user-dropdown-container">
                <div
                  class="form-input-item"
                  :class="{
                    'input-error':
                      (!$v.loginForm.personalNumber.required ||
                        !$v.loginForm.personalNumber.minLength ||
                        !$v.loginForm.personalNumber.maxLength) &&
                      isClickedLogin,
                  }"
                >
                  <div class="label">
                    {{ $fn.tr('Personal Number') }}
                  </div>
                  <input
                    @keyup.enter="loginUser"
                    v-model="$v.loginForm.personalNumber.$model"
                    type="number"
                  />
                  <div
                    class="label red"
                    v-if="!$v.loginForm.personalNumber.required && isClickedLogin"
                  >
                    {{ $fn.tr('Wrong Number') }}
                  </div>
                  <div
                    class="label red"
                    v-if="
                      $v.loginForm.personalNumber.required &&
                        (!$v.loginForm.personalNumber.maxLength ||
                          !$v.loginForm.personalNumber.minLength) &&
                        isClickedLogin
                    "
                  >
                    {{ $fn.tr('personal number length must be 11') }}
                  </div>
                </div>
                <div
                  class="form-input-item"
                  :class="{
                    'input-error':
                      (!$v.loginForm.password.required || !$v.loginForm.password.minLength) &&
                      isClickedLogin,
                  }"
                >
                  <div class="password-labels">
                    <div class="label">
                      {{ $fn.tr('Password') }}
                    </div>
                  </div>

                  <input
                    v-model="$v.loginForm.password.$model"
                    @keyup.enter="loginUser"
                    type="password"
                  />
                  <div
                    class="label red"
                    v-if="
                      $v.loginForm.password.required &&
                        !$v.loginForm.password.minLength &&
                        isClickedLogin
                    "
                  >
                    {{ $fn.tr('Password must be at least 8 symbol') }}
                  </div>
                  <div style="margin-top:10px" class="label red" v-if="invalidLoginError.length">
                    {{ $fn.tr(invalidLoginError) }}
                  </div>
                </div>
                <div class="green-button" @click="loginUser">
                  {{ $fn.tr('Login') }}
                </div>
                <div class="go-to-registration center-flex">
                  {{ $fn.tr('New member') }} :
                  <div>
                    <router-link tag="span" :to="`/${$store.state.main.locale}/registration`"
                      >{{ $fn.tr('Registration') }}
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form" data-aos="fade-right" data-aos-delay="400">
            <div class="content-top-title">
              {{ $fn.tr('Personal Information') }}
            </div>
            <div class="form-container">
              <div
                class="form-input-item"
                :class="{
                  'input-error':
                    (!$v.personalInformation.personal_number.required ||
                      !$v.personalInformation.personal_number.minLength ||
                      !$v.personalInformation.personal_number.maxLength) &&
                    isClicked,
                }"
              >
                <div class="label">
                  {{ $fn.tr('Personal Number') }}
                  <span>*</span>
                </div>
                <input
                  maxlength="11"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  v-model="$v.personalInformation.personal_number.$model"
                  type="number"
                />
                <div
                  class="label error"
                  v-if="
                    !$v.personalInformation.personal_number.minLength ||
                      !$v.personalInformation.personal_number.maxLength
                  "
                >
                  {{ $fn.tr('Personal number must be 11 numbers') }}
                </div>
              </div>
              <div
                class="form-input-item"
                :class="{
                  'input-error':
                    (!$v.personalInformation.name.required ||
                      !$v.personalInformation.name.minLength) &&
                    isClicked,
                }"
              >
                <div class="label">
                  {{ $fn.tr('First & Last Name') }}
                  <span>*</span>
                </div>
                <input v-model="$v.personalInformation.name.$model" type="text" />
              </div>
              <div
                class="form-input-item"
                :class="{
                  'input-error':
                    (!$v.personalInformation.phone.required ||
                      !$v.personalInformation.phone.minLength) &&
                    isClicked,
                }"
              >
                <div class="label">{{ $fn.tr('Phone Number') }} <span>*</span></div>
                <input
                  maxlength="11"
                  oninput="javascript: if (this.value.length > 15) this.value = this.value.slice(0, 15);"
                  type="number"
                  v-model="$v.personalInformation.phone.$model"
                  placeholder="XXX - XXX - XXX"
                />
              </div>
              <div
                class="form-input-item"
                :class="{
                  'input-error':
                    (!$v.personalInformation.email.required ||
                      !$v.personalInformation.email.minLength) &&
                    isClicked,
                }"
              >
                <div class="label">{{ $fn.tr('Email') }}<span>*</span></div>
                <input type="email" v-model="$v.personalInformation.email.$model" />
                <div class="label red" v-if="!$v.personalInformation.email.email && isClicked">
                  {{ $fn.tr('Email is not formatted correctly') }}
                </div>
              </div>
              <div class="form-input-checkboxes">
                <div class="form-input-checkbox">
                  <div class="checkbox-default">
                    <input
                      type="checkbox"
                      id="checkbox"
                      v-model="personalInformation.createAccount"
                    />
                    <label class="form-input-checkbox-label" for="checkbox"
                      ><span>{{ $fn.tr('I Want To Create My Profile') }}</span></label
                    >
                  </div>
                </div>
                <div class="checkbox-default">
                  <input v-model="personalInformation.subscribe" type="checkbox" id="subscribe" />
                  <label class="form-input-checkbox-label" for="subscribe"
                    ><span>{{ $fn.tr('Subscribe Newsletter') }}</span></label
                  >
                </div>
              </div>
              <div
                class="create-account-inputs"
                :class="{
                  active: personalInformation.createAccount,
                }"
              >
                <div
                  class="form-input-item"
                  :class="{
                    'input-error':
                      (!$v.personalInformation.password.required ||
                        !$v.personalInformation.password.minLength) &&
                      isClicked,
                  }"
                >
                  <div class="label">{{ $fn.tr('Password') }} <span>*</span></div>
                  <input v-model="$v.personalInformation.password.$model" type="password" />
                  <div
                    class="label red"
                    v-if="!$v.personalInformation.password.required && isClicked"
                  >
                    {{ $fn.tr('New Password is required') }}
                  </div>

                  <div
                    class="label red"
                    v-if="
                      $v.personalInformation.password.$model &&
                        !$v.personalInformation.password.minLength &&
                        isClicked
                    "
                  >
                    {{ $fn.tr('Password must be at least 8 symbol') }}
                  </div>
                  <div
                    class="label red"
                    v-if="
                      $v.personalInformation.password.$model &&
                        !$v.personalInformation.password.valid &&
                        isClicked
                    "
                  >
                    {{ $fn.tr('Password must be contain numbers and symbols') }}
                  </div>
                </div>
                <div
                  class="form-input-item"
                  :class="{
                    'input-error':
                      (!$v.personalInformation.confirmPassword.required ||
                        !$v.personalInformation.confirmPassword.sameAsPassword) &&
                      isClicked &&
                      $v.personalInformation.createAccount.checked,
                  }"
                >
                  <div class="label">
                    {{ $fn.tr('Confirm Password') }}
                    <span>*</span>
                  </div>
                  <input v-model="$v.personalInformation.confirmPassword.$model" type="password" />
                  <div
                    class="label red"
                    v-if="
                      !$v.personalInformation.confirmPassword.sameAsPassword &&
                        isClicked &&
                        $v.personalInformation.createAccount.checked
                    "
                  >
                    {{ $fn.tr('Confirm your password') }}
                  </div>
                  <div
                    class="label red"
                    v-if="
                      !$v.personalInformation.confirmPassword.required &&
                        isClicked &&
                        $v.personalInformation.createAccount.checked
                    "
                  >
                    {{ $fn.tr('Confirm password is required') }}
                  </div>
                </div>
              </div>
              <div class="errors" v-if="userErrors">
                <div
                  class="error label"
                  :key="indx"
                  v-for="(item, indx) in Object.values(userErrors)"
                >
                  {{ item }}
                </div>
              </div>
              <div class="form-input-buttons">
                <div class="form-button center-flex" @click="cancelForm">
                  {{ $fn.tr('CANCEL') }}
                </div>
                <div class="form-button center-flex" @click="goToNextStep">
                  {{ $fn.tr('Next STEP') }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--  ავტორიზებული მომხმარებლებისთვის          -->
        <div class="authorized-cart" v-if="!nextStep && getAuthData">
          <div data-aos="fade" data-aos-delay="400" v-if="_.size(_.get(getCartItems, 'data.list'))">
            <ChooseOptions @choice="getChoice" :has-zero-margin="true" />
          </div>
          <div data-aos="fade" data-aos-delay="400" v-if="selectedChoice === 'delivery'">
            <div class="content-top-title" data-aos="fade-right" data-aos-delay="600">
              {{ $fn.tr('Choose Shipping Address') }}
            </div>
            <template v-for="(address, index) in getAuthDataAddressesSorted">
              <AddressItem
                :id="'address-' + address.id"
                v-if="getAuthData"
                :class="{
                  'active-address': activeAddressId === address.id,
                }"
                :key="index"
                @editAddressFromAuth="editAddressFromAuth"
                :addressFromAuth="true"
                :isShippingAddress="true"
                :activeClass="activeAddressId === address.id"
                @activeClass="activeAddress"
                :address="address"
                @editAddress="editAddress"
              />
            </template>

            <div class="add-new-address">
              <div
                class="add-icon center-flex"
                v-if="!newAddressForm && !editAddressForm"
                @click="newAddressForm = true"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21.813"
                  height="21.813"
                  viewBox="0 0 21.813 21.813"
                >
                  <path
                    id="Icon_metro-cancel"
                    data-name="Icon metro-cancel"
                    d="M22.815,9.64l-4.82,4.82-4.82-4.82-2.892,2.892,4.82,4.82-4.82,4.82,2.892,2.892,4.82-4.82,4.82,4.82,2.892-2.892-4.82-4.82,4.82-4.82Z"
                    transform="translate(10.452 -14.088) rotate(45)"
                    fill="#fff"
                  />
                </svg>
              </div>
              <div
                class="add-new-address-text"
                @click="newAddressForm = true"
                v-if="!newAddressForm && !editAddressForm"
              >
                {{ $fn.tr('Add New Address') }}
              </div>

              <div class="form" data-aos="fade-down" v-if="newAddressForm && !editAddressForm">
                <div class="content-top-title">
                  {{ $fn.tr('New Address Form') }}
                </div>
                <div class="form-container">
                  <div class="form-input-item">
                    <div class="label">
                      {{ $fn.tr('Contact Name') }}
                      <span>*</span>
                    </div>
                    <input
                      :class="{
                        'border-error':
                          (!$v.newAddress.name.required || !$v.newAddress.name.minLength) &&
                          isClicked,
                      }"
                      v-model="$v.newAddress.name.$model"
                      type="text"
                    />
                    <div class="label red" v-if="!$v.newAddress.name.required && isClicked">
                      {{ $fn.tr('Contact name is required') }}
                    </div>
                  </div>
                  <div class="form-input-item">
                    <div class="label">{{ $fn.tr('City') }} <span>*</span></div>
                    <Dropdown
                      @select="selectValue"
                      ref="Sort"
                      :data="_.get($store.state.main.indx, 'cities')"
                      :val="newAddress.city"
                      :isRequired="true"
                      :clicked="isClicked"
                    />
                    <!--                                        <input-->
                    <!--                                            type="text"-->
                    <!--                                            :class="{ 'border-error': (!$v.newAddress.city.required || !$v.newAddress.city.minLength) && isClicked }"-->
                    <!--                                            v-model="$v.newAddress.city.$model"-->
                    <!--                                            :placeholder="$fn.tr('Choose City')">-->
                    <!--                                        <div class="label red" v-if="!$v.newAddress.city.required && isClicked">-->
                    <!--                                            {{$fn.tr('City is required')}}-->
                    <!--                                        </div>-->
                  </div>
                  <div class="form-input-item">
                    <div class="label">{{ $fn.tr('Address') }}<span>*</span></div>
                    <input
                      type="text"
                      :class="{
                        'border-error':
                          (!$v.newAddress.address.required || !$v.newAddress.address.minLength) &&
                          isClicked,
                      }"
                      v-model="$v.newAddress.address.$model"
                      :placeholder="$fn.tr('Choose Address')"
                    />
                    <div class="label red" v-if="!$v.newAddress.address.required && isClicked">
                      {{ $fn.tr('Address is required') }}
                    </div>
                  </div>
                  <div class="form-input-checkboxes">
                    <div class="checkbox-default">
                      <input type="checkbox" id="saveAddr" />
                      <label class="form-input-checkbox-label" for="saveAddr"
                        ><span>{{ $fn.tr('Save My Address') }}</span></label
                      >
                    </div>
                  </div>
                  <div class="form-input-buttons">
                    <div class="form-button center-flex" @click="cancelForm">
                      {{ $fn.tr('CANCEL') }}
                    </div>
                    <div class="form-button center-flex" @click="addAddress(newAddress.id)">
                      {{ $fn.tr('Add Address') }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="form" id="form-element2" v-if="editAddressForm">
                <div class="content-top-title" data-aos="fade-down">
                  {{ $fn.tr('Edit Address Form') }}
                </div>
                <div class="form-container" data-aos="fade" data-aos-delay="100">
                  <div class="form-input-item">
                    <div class="label">
                      {{ $fn.tr('Contact Name') }}
                      <span>*</span>
                    </div>
                    <input
                      :class="{
                        'border-error':
                          (!$v.editAddressFormData.name.required ||
                            !$v.editAddressFormData.name.minLength) &&
                          isClicked,
                      }"
                      v-model="$v.editAddressFormData.name.$model"
                      type="text"
                    />
                    <div
                      class="label red"
                      v-if="!$v.editAddressFormData.name.required && isClicked"
                    >
                      {{ $fn.tr('Contact name is required') }}
                    </div>
                  </div>
                  <div class="form-input-item">
                    <div class="label">{{ $fn.tr('City') }} <span>*</span></div>
                    <Dropdown
                      @select="selectValue"
                      ref="Sort"
                      :data="_.get($store.state.main.indx, 'cities')"
                      :isRequired="true"
                      :val="editAddressFormData.city"
                      :clicked="isClicked"
                    />
                    <!--                                        <input-->
                    <!--                                            :class="{ 'border-error': (!$v.editAddressFormData.city.required || !$v.editAddressFormData.city.minLength) && isClicked }"-->
                    <!--                                            v-model="$v.editAddressFormData.city.$model"-->
                    <!--                                            :placeholder="$fn.tr('Choose City')"-->
                    <!--                                            type="text">-->
                    <!--                                        <div class="label red" v-if="!$v.editAddressFormData.city.required && isClicked">-->
                    <!--                                            {{$fn.tr('City is required')}}-->
                    <!--                                        </div>-->
                  </div>
                  <div class="form-input-item">
                    <div class="label">{{ $fn.tr('Address') }}<span>*</span></div>
                    <input
                      :class="{
                        'border-error':
                          (!$v.editAddressFormData.address.required ||
                            !$v.editAddressFormData.address.minLength) &&
                          isClicked,
                      }"
                      v-model="$v.editAddressFormData.address.$model"
                      :placeholder="$fn.tr('Choose Address')"
                      type="text"
                    />
                    <div
                      class="label red"
                      v-if="!$v.editAddressFormData.address.required && isClicked"
                    >
                      {{ $fn.tr('Address is required') }}
                    </div>
                  </div>
                  <div class="form-input-checkboxes">
                    <div class="checkbox-default">
                      <input type="checkbox" id="saveAddress" />
                      <label class="form-input-checkbox-label" for="saveAddress"
                        ><span>{{ $fn.tr('Save My Address') }}</span></label
                      >
                    </div>
                  </div>
                  <div class="form-input-buttons">
                    <div class="form-button center-flex" @click="cancelForm">
                      {{ $fn.tr('CANCEL') }}
                    </div>
                    <div
                      class="form-button center-flex"
                      @click="submitEditAddress(editAddressFormData.id)"
                    >
                      {{ $fn.tr('Save Information') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="payment"
            data-aos="fade"
            data-aos-delay="400"
            v-if="
              (activeAddressId && !newAddressForm && !editAddressForm) ||
                selectedChoice === 'pick-up'
            "
          >
            <ChooseOptions :is-delivery="false" @choice="getChoicePayment" />
          </div>
          <div v-if="selectedChoice.length && $store.state.shop.shippingDays" class="delivery-day">
            {{ $fn.tr('Your Package will be delivered in') }}
            {{ $store.state.shop.shippingDays }}
          </div>
        </div>

        <!-- არა-ავტორიზებული მომხმარებლებისთვის          -->
        <div class="authorized-cart" v-if="nextStep && !getAuthData">
          <div data-aos="fade" data-aos-duration="2000" data-aos-delay="200">
            <div class="content-top-title">
              {{ $fn.tr('Personal Information') }}
            </div>
            <AddressItem
              :class="{ 'active-address': false }"
              :isShippingAddress="false"
              :showCheckbox="false"
              editText="edit information"
              :activeClass="false"
              @activeClass="activeAddress"
              :address="addresses[addresses.length - 1]"
              @editAddressFromAuth="nextStep = false"
              :addressFromAuth="true"
            />

            <div v-if="!nextStepNonRegistration">
              <ChooseOptions @choice="getChoice" />
            </div>
            <div
              class="form"
              v-if="!editAddressForm && !nextStepNonRegistration && selectedChoice === 'delivery'"
            >
              <div class="content-top-title">
                {{ $fn.tr('Shipping Address') }}
              </div>
              <div class="form-container">
                <div
                  class="form-input-item"
                  :class="{
                    'input-error':
                      (!$v.shippingAddress.city.required || !$v.shippingAddress.city.minLength) &&
                      isClickedSuccess,
                  }"
                >
                  <div class="label">{{ $fn.tr('City') }} <span>*</span></div>
                  <Dropdown
                    @select="selectValue"
                    ref="Sort"
                    :data="_.get($store.state.main.indx, 'cities')"
                    :val="selectedCity"
                    :isRequired="true"
                    :clicked="isClicked"
                  />
                </div>
                <div
                  class="form-input-item"
                  :class="{
                    'input-error':
                      (!$v.shippingAddress.address.required ||
                        !$v.shippingAddress.address.minLength) &&
                      isClickedSuccess,
                  }"
                >
                  <div class="label">{{ $fn.tr('Address') }} <span>*</span></div>
                  <input v-model="$v.shippingAddress.address.$model" type="text" />
                </div>
                <div class="form-input-checkboxes"></div>
                <div class="form-input-buttons">
                  <div class="form-button center-flex" @click="nextStep = false">
                    {{ $fn.tr('Back') }}
                  </div>
                  <div class="form-button center-flex" @click="goToSuccessPage(true)">
                    {{ $fn.tr('Continue CHECKOUT') }}
                  </div>
                </div>
                <div class="label error" v-if="errorMessage.length">
                  {{ errorMessage }}
                </div>
              </div>
            </div>
            <div class="form" id="form-element2" v-if="editAddressForm && !nextStepNonRegistration">
              <div class="content-top-title" data-aos="fade-down">
                {{ $fn.tr('Edit Address Form') }}
              </div>
              <div class="form-container" data-aos="fade" data-aos-delay="100">
                <div class="form-input-item">
                  <div class="label">
                    {{ $fn.tr('Contact Name') }}
                    <span>*</span>
                  </div>
                  <input
                    :class="{
                      'border-error':
                        (!$v.editAddressFormData.name.required ||
                          !$v.editAddressFormData.name.minLength) &&
                        isClicked,
                    }"
                    v-model="$v.editAddressFormData.name.$model"
                    type="text"
                  />
                  <div class="label red" v-if="!$v.editAddressFormData.name.required && isClicked">
                    {{ $fn.tr('Contact name is required') }}
                  </div>
                </div>
                <div class="form-input-item">
                  <div class="label">{{ $fn.tr('City') }} <span>*</span></div>
                  <input
                    :class="{
                      'border-error':
                        (!$v.editAddressFormData.city.required ||
                          !$v.editAddressFormData.city.minLength) &&
                        isClicked,
                    }"
                    v-model="$v.editAddressFormData.city.$model"
                    :placeholder="$fn.tr('Choose City')"
                    type="text"
                  />
                  <div class="label red" v-if="!$v.editAddressFormData.city.required && isClicked">
                    {{ $fn.tr('City is required') }}
                  </div>
                </div>
                <div class="form-input-item">
                  <div class="label">{{ $fn.tr('Address') }}<span>*</span></div>
                  <input
                    :class="{
                      'border-error':
                        (!$v.editAddressFormData.address.required ||
                          !$v.editAddressFormData.address.minLength) &&
                        isClicked,
                    }"
                    v-model="$v.editAddressFormData.address.$model"
                    :placeholder="$fn.tr('Choose Address')"
                    type="text"
                  />
                  <div
                    class="label red"
                    v-if="!$v.editAddressFormData.address.required && isClicked"
                  >
                    {{ $fn.tr('Address is required') }}
                  </div>
                </div>
                <div class="form-input-checkboxes">
                  <div class="checkbox-default">
                    <input type="checkbox" id="saveAddress2" />
                    <label class="form-input-checkbox-label" for="saveAddress2"
                      ><span>{{ $fn.tr('Save My Address') }}</span></label
                    >
                  </div>
                </div>
                <div class="form-input-buttons">
                  <div class="form-button center-flex" @click="cancelForm">
                    {{ $fn.tr('CANCEL') }}
                  </div>
                  <div class="form-button center-flex" @click="submitEditAddress">
                    {{ $fn.tr('Save Information') }}
                  </div>
                </div>
              </div>
            </div>
            <div class="shipping-step" v-if="nextStepNonRegistration">
              <AddressItem
                :class="{ 'active-address': true }"
                :isShippingAddress="true"
                editText="edit information"
                :activeClass="true"
                @activeClass="activeAddress"
                :address="shippingAddress"
                @editAddressFromAuth="nextStepNonRegistration = false"
                :addressFromAuth="true"
              />
            </div>
            <div v-if="nextStepNonRegistration">
              <ChooseOptions :is-delivery="false" @choice="getChoicePayment" />
            </div>
            <div
              id="payment"
              data-aos="fade"
              data-aos-delay="400"
              v-if="
                (activeAddressId && !newAddressForm && !editAddressForm) ||
                  selectedChoice === 'pick-up'
              "
            >
              <ChooseOptions :is-delivery="false" @choice="getChoicePayment" />
            </div>

            <div
              v-if="selectedChoice.length && $store.state.shop.shippingDays"
              class="delivery-day"
            >
              {{ $fn.tr('Your Package will be delivered in') }}
              {{ $store.state.shop.shippingDays }}
            </div>
          </div>
        </div>
        <div class="items" data-aos="fade-down" data-aos-delay="200">
          <div class="content-top-title">
            {{ $fn.tr('Your Items') }}
          </div>
          <div class="items-wrapper">
            <div class="item-list">
              <div v-if="_.size(_.get(getCartItems, 'data.list'))">
                <CartProductItem
                  @removeCartItem="removeCartItem(product)"
                  :classActive="cartId === product.id"
                  :key="indx"
                  :product="product"
                  v-for="(product, indx) in getCartItems.data.list"
                />
              </div>
              <div class="order-row-item" v-else>
                {{ $fn.tr('Cart Is Empty') }}
              </div>
            </div>
            <div class="order-summary" v-if="_.size(_.get(getCartItems, 'data.list'))">
              <div class="summary-title">
                <content-title :small="true" :isTitle="false" :title="$fn.tr('Order Summary')" />
              </div>
              <div class="order-summary-row">
                <div class="order-row-item">{{ $fn.tr('Subtotal') }}:</div>
                <div class="order-row-item">
                  {{ getCartItems.price + getSalePrice }}
                  ₾
                </div>
              </div>
              <div class="order-summary-row">
                <div class="order-row-item">{{ $fn.tr('Shipping') }}:</div>
                <div class="order-row-item">{{ $store.state.shop.shippingPrice }} ₾</div>
              </div>
              <div class="order-summary-row">
                <div class="order-row-item">{{ $fn.tr('Sale') }}:</div>
                <div class="order-row-item">{{ getSalePrice }} ₾</div>
              </div>
            </div>
            <div class="total-order" v-if="_.size(_.get(getCartItems, 'data.list'))">
              <div class="order-summary-row">
                <div class="order-row-item">{{ $fn.tr('Total Order') }}:</div>
                <div class="order-row-item">
                  {{ getCartItems.price + parseInt($store.state.shop.shippingPrice) }}
                  ₾
                </div>
              </div>
            </div>
            <div class="unknow-error" v-if="unknowErrorMessage">
              {{ $fn.tr('დაფიქსირდა სისტემური ხარვეზი') }}
            </div>
            <div v-if="_.size(_.get(getCartItems, 'data.list'))">
              <div
                v-if="getAuthData"
                @click="sendOrder"
                :class="{ disabled: loadingStatus }"
                class="green-button checkout-button"
              >
                {{ $fn.tr('Continue CHECKOUT') }}
              </div>
              <div
                v-if="selectedPayment.length && !getAuthData"
                :class="{ disabled: loadingStatus }"
                @click="sendOrder"
                class="green-button checkout-button"
              >
                {{ $fn.tr('Continue CHECKOUT') }}
              </div>
              <div
                :class="{ disabled: loadingStatus }"
                v-if="!selectedPayment.length && selectedChoice === 'pick-up' && !getAuthData"
                @click="sendOrder"
                class="green-button checkout-button"
              >
                {{ $fn.tr('Continue CHECKOUT') }}
              </div>
            </div>
            <!--                        <div v-if="getCartItems.data && getCartItems.data.length && getAuthData" @click="sendAuthorizedOrder" class="green-button checkout-button">{{$fn.tr(' CHECKOUT')}}</div>-->
          </div>
        </div>
      </div>

      <!--  error and success components-->
      <div v-if="successOrder && !errorOrder">
        <div class="invoice-container" ata-aos="fade-up" data-aos-delay="200">
          <div class="success-text">
            {{ $fn.tr('Thank you, order has been successfully placed') }}
          </div>
          <div class="success-text">
            {{ $fn.tr('Mysa Ltd') }} <br />
            {{ $fn.tr('S / K') }}
            {{ _.get($store.state.main.indx, 'siteSettings.identification_code') }}
            <br />
            {{ $fn.tr('Address') }}:
            {{ $fn.tr('st. Tbilisi, I. Chavchavadze Ave. 25') }}
            <br />
            JSC "TBC Bank" Marjanishvili Phil. <br />
            {{ $fn.tr('Bank code') }}:
            {{ _.get($store.state.main.indx, 'siteSettings.bank_account') }}
            <br />
          </div>
          <div class="success-text check-email">
            {{ $fn.tr('Please check your email address or visit this') }}
            <a :href="invoiceLinks.uploadInvoiceLinks">{{ $fn.tr('page') }}</a>
            {{ $fn.tr('and upload paid bill') }}
          </div>
          <div class="success-invoice">
            <img loading="lazy" src="../assets/img/pdf-symbol.svg" alt="pdf" />
            <div class="success-invoice-text">
              <p>{{ $fn.tr('Download Invoice') }}</p>
              <a :href="invoiceLinks.downloadInvoiceLinks" class="underline">{{
                $fn.tr('Order.pdf')
              }}</a>
            </div>
          </div>
          <router-link
            :to="`/${$store.state.main.locale}/home`"
            tag="div"
            class="invoice-button center-flex"
            >{{ $fn.tr('BACK TO HOME') }}</router-link
          >
        </div>
      </div>
      <div class="error-cart-title" v-if="errorOrder && !successOrder">
        <content-title
          :title="$fn.tr('Something Went Wrong!')"
          data-aos="fade-up"
          data-aos-delay="200"
        />
        <div class="error-text" data-aos="fade-down" data-aos-delay="300">
          {{ $fn.tr('Your order has not been plased, try again') }}
        </div>
        <router-link
          :to="`/${$store.state.main.locale}/home`"
          data-aos="fade-right"
          data-aos-delay="450"
          tag="div"
          class="invoice-button center-flex"
          >{{ $fn.tr('BACK TO HOME') }}</router-link
        >
      </div>

      <div class="border-bottom"></div>
    </div>

    <Notification :active="emailNotification" @hide="emailNotification = false">
      {{ $fn.tr('check your email for verification!') }}
    </Notification>

    <Notification
      :active="checkAddressNotification"
      :warning="true"
      @hide="checkAddressNotification = false"
    >
      {{ $fn.tr(checkErrorMessage) }}
    </Notification>
    <Notification
      :active="checkPaymentNotification"
      :warning="true"
      @hide="checkPaymentNotification = false"
    >
      {{ $fn.tr('check payment method!') }}
    </Notification>
  </div>
</template>

<script>
import ContentTitle from '../components/ContentTitle';
import CartProductItem from '../components/checkout/CartProductItem';
import AddressItem from '../components/checkout/AddressItem';
import { validationMixin } from 'vuelidate';
import Notification from '../components/Notification';
import addToCartMixin from '../mixins/addToCartMixin';
import { required, minLength, sameAs, email, maxLength } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import conf from '../helpers/conf';
import Dropdown from '@/components/Dropdown';
import CalculateShippingPrice from '@/mixins/CalculateShippingPrice';
import ChooseOptions from '@/components/ChooseOptions';
export default {
  mixins: [validationMixin, addToCartMixin, CalculateShippingPrice],
  components: {
    ContentTitle,
    CartProductItem,
    AddressItem,
    Notification,
    Dropdown,
    ChooseOptions,
  },
  validations: {
    personalInformation: {
      personal_number: {
        required,
        minLength: minLength(11),
        maxLength: minLength(11),
      },
      name: {
        required,
        minLength: minLength(3),
      },

      phone: {
        required,
        minLength: minLength(9),
      },
      createAccount: {
        checked: (value) => value === true,
      },
      email: {
        required,
        minLength: minLength(3),
        email,
      },
      password: {
        required,
        minLength: 8,
        valid: function(value) {
          const containsUppercase = /[A-Z]/.test(value);
          const containsLowercase = /[a-z]/.test(value);
          const containsNumber = /[0-9]/.test(value);
          return containsUppercase || (containsLowercase && containsNumber);
        },
      },

      confirmPassword: {
        required,
        sameAsPassword: sameAs('password'),
      },
    },
    shippingAddress: {
      city: {
        required,
        minLength: minLength(3),
      },
      address: {
        required,
        minLength: minLength(3),
      },
    },
    editAddressFormData: {
      name: {
        required,
        minLength: minLength(3),
      },
      city: {
        required,
        minLength: minLength(3),
      },
      address: {
        required,
        minLength: minLength(3),
      },
    },
    newAddress: {
      name: {
        required,
        minLength: minLength(3),
      },
      city: {
        required,
        minLength: minLength(3),
      },
      address: {
        required,
        minLength: minLength(3),
      },
    },
    loginForm: {
      personalNumber: {
        required,
        minLength: minLength(11),
        maxLength: maxLength(11),
      },
      password: {
        required,
        minLength: minLength(8),
      },
    },
  },
  data() {
    return {
      cityList: [
        {
          id: 1,
          city: 'Relevance',
        },
        {
          id: 2,
          city: 'Price: Low to High',
        },
        {
          id: 3,
          city: 'Price: High to Low',
        },
        {
          id: 4,
          city: 'Newest Arrivals',
        },
        {
          id: 5,
          city: 'Best Selling',
        },
      ],
      invoiceLinks: {
        uploadInvoiceLinks: '',
        downloadInvoiceLinks: '',
      },
      selectedCity: '',
      editIndex: null,
      isClicked: false,
      isClickedLogin: false,
      errorMessage: false,
      emailNotification: false,
      isClickedSuccess: false,
      checkAddressNotification: false,
      checkErrorMessage: 'check at least one address!',
      checkPaymentNotification: false,
      personalInformation: {
        personal_number: '',
        name: '',
        phone: '',
        email: '',
        password: '',
        confirmPassword: '',
        createAccount: false,
        subscribe: false,
      },
      shippingAddress: {
        city: '',
        address: '',
      },

      newAddress: {
        id: '',
        name: '',
        address: '',
        city: '',
      },
      editAddressFormData: {
        name: '',
        id: '',
        address: '',
        city: '',
      },
      addresses: [
        // {
        //     name: 'nodar',
        //     id: 1012012302,
        //     phone: '56436346344',
        //     email:'gsgd@gmail.com',
        //     address: 'Machavariani Str. 59a',
        //     city: 'Tbilisi',
        // }
      ],
      isAuthorized: false,
      newAddressForm: false,
      editAddressForm: false,
      removeProduct: false,
      createAccount: false,
      nextStep: false,
      nextStepNonRegistration: false,
      successOrder: false,
      errorOrder: false,
      cartId: null,
      invalidLoginError: '',
      activeLoginDropdown: false,
      loginForm: {
        personalNumber: '',
        password: '',
      },
      selectedChoice: '',
      selectedPayment: '',
      userErrors: null,
      unknowErrorMessage: false,
      userToken: null,
      loadingStatus: false,
    };
  },
  computed: {
    ...mapGetters({
      getAuthData: 'user/getAuthData',
      getAuthDataAddresses: 'user/getAuthDataAddresses',
      getCartItems: 'shop/getCartItems',
    }),

    getAuthDataAddressesSorted() {
      let addresses = this.getAuthDataAddresses.sort((a, b) => b.sort - a.sort);
      // let filterAddress = addresses.filter(r => r.sort === 1);
      // if (!_.get(filterAddress, "[0].name"))
      //     _.get(filterAddress, "[0]")['name'] = this.getAuthData.fullname;
      return addresses;
    },

    getSalePrice() {
      let price = 0;
      this.getCartItems.data?.list.forEach((res) => {
        price += res.oldprice ? (res.oldprice - res.stock_price) * res.quantity : 0;
      });

      return Math.round(price * 10) / 10;
    },
    activeAuthData() {
      let personalInfo = { ...this.getAuthData };
      let shippingAddress = this.getAuthDataAddresses.filter((err) => err.sort === 1)[0];
      personalInfo.address = shippingAddress.address;
      personalInfo.city = shippingAddress.city;
      return personalInfo;
    },
  },
  watch: {
    newAddressForm(val) {
      if (val) {
        this.personalInformation.name = '';
        this.personalInformation.phone = '';
        this.personalInformation.email = '';
        this.newAddress.address = '';
        this.newAddress.city = '';
      }
    },
    $route(to) {
      to.query.nextStep === 1 ? (this.nextStep = true) : (this.nextStep = false);
    },
    getAuthData(val) {
      if (val && this.nextStep) {
        this.nextStep = false;
      }
      this.selectedChoice = '';
    },
    nextStep(val) {
      //   if (val) {
      //     this.domain = 'tbilisi';
      //   }
    },
  },
  methods: {
    removeCartItems() {
      this.$cookies.remove('uniqueID');
      this.$store.commit('shop/setState', { key: 'cartList', val: [] });
    },
    cancelForm() {
      this.editAddressForm = false;
      this.newAddressForm = false;
      this.isClicked = false;
    },
    addAddress(addressId) {
      this.isClicked = true;
      this.newAddress.city = this.selectedCity;
      if (
        !this.$v.newAddress.name.required ||
        !this.$v.newAddress.name.minLength ||
        !this.$v.newAddress.city.required ||
        !this.$v.newAddress.city.minLength ||
        !this.$v.newAddress.address.required ||
        !this.$v.newAddress.address.minLength
      ) {
        return;
      }
      this.$store
        .dispatch('user/addAddress', {
          token: this.$cookies.get('userToken'),
          data: this.newAddress,
        })
        .then(() => {
          this.newAddressForm = false;
          this.editAddressForm = false;
          setTimeout(() => {
            document.getElementById(`address-${addressId}`)?.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'nearest',
            });
          }, 250);
          this.activeAddressId = addressId;
        });
      // this.addresses.push({...this.newAddress});
    },
    activeAddress(addressId) {
      if (this.activeAddressId === addressId.id) {
        this.activeAddressId = null;
        this.$store.commit('shop/setState', {
          key: 'shippingPrice',
          val: 0,
        });
      } else {
        this.activeAddressId = addressId.id;
        this.calculateShippingPrice(this.domain);
      }
      this.animateScrollHeader('payment');
    },
    editAddressFromAuth(address) {
      this.editAddressFormData = { ...address };
      this.editAddressForm = true;
      this.isClicked = false;
      setTimeout(() => {
        let element2 = document.getElementById('form-element2');
        let element3 = document.getElementById('form-element3');
        window.scrollTo({
          top: element2 ? element2.offsetTop : element3.offsetTop,
          behavior: 'smooth',
        });
      }, 500);
    },
    goToNextStep() {
      this.isClicked = true;
      if (
        !this.$v.personalInformation.personal_number.required ||
        !this.$v.personalInformation.personal_number.minLength ||
        !this.$v.personalInformation.personal_number.maxLength ||
        !this.$v.personalInformation.name.required ||
        !this.$v.personalInformation.name.minLength ||
        !this.$v.personalInformation.email.required ||
        !this.$v.personalInformation.email.email ||
        !this.$v.personalInformation.phone.required ||
        !this.$v.personalInformation.phone.minLength ||
        this.personalInformation.createAccount
          ? !this.$v.personalInformation.password.required ||
            !this.$v.personalInformation.password.minLength ||
            !this.$v.personalInformation.password.valid ||
            !this.$v.personalInformation.confirmPassword.sameAsPassword ||
            !this.$v.personalInformation.confirmPassword.required
          : ''
      ) {
        return;
      }
      this.$store
        .dispatch('user/checkUser', this.personalInformation)
        .then((res) => {
          if (res.data.token && res.data.user) {
            this.userToken = res.data.token;
            this.activeNextStep();
          } else if (res.status == 201) {
            this.userErrors = res.data;
          } else {
            this.userToken = null;
            this.activeNextStep();
          }
        })
        .catch(() => {});
    },
    activeNextStep() {
      this.userErrors = null;
      this.addresses.push(this.personalInformation);
      this.activeAddressId = this.personalInformation.id;
      this.animateScrollHeader();
      this.nextStep = true;
    },
    editAddress(address) {
      this.editIndex = this.getAuthDataAddresses.findIndex((res) => res.id === address.id);
      this.editAddressFormData = { ...address };
      this.isClicked = false;
      setTimeout(() => {
        let element2 = document.getElementById('form-element2');
        let element3 = document.getElementById('form-element3');
        window.scrollTo({
          top: element2 ? element2.offsetTop : element3.offsetTop,
          behavior: 'smooth',
        });
      }, 500);
      this.editAddressForm = true;
      this.newAddressForm = false;
    },
    submitEditAddress(addressId) {
      this.isClicked = true;
      this.editAddressFormData.city = this.selectedCity;
      if (
        !this.$v.editAddressFormData.name.required ||
        !this.$v.editAddressFormData.name.minLength ||
        !this.$v.editAddressFormData.city.required ||
        !this.$v.editAddressFormData.city.minLength ||
        !this.$v.editAddressFormData.address.required ||
        !this.$v.editAddressFormData.address.minLength
      ) {
        return;
      }
      this.$store
        .dispatch('user/EditAddress', {
          data: this.editAddressFormData,
          token: this.$cookies.get('userToken') ? this.$cookies.get('userToken') : '',
        })
        .then(() => {
          this.editAddressForm = false;
          this.activeAddressId = addressId;
          this.isClicked = false;
          this.animateScrollHeader('address-' + addressId);
          this.selectedCity = '';
        });
    },
    goToSuccessPage(showShipping) {
      if (showShipping) {
        this.isClickedSuccess = true;
        if (
          !this.$v.shippingAddress.city.required ||
          !this.$v.shippingAddress.city.minLength ||
          !this.$v.shippingAddress.address.required ||
          !this.$v.shippingAddress.address.minLength
        ) {
          return;
        }
        this.nextStepNonRegistration = true;
      } else {
        this.isClickedSuccess = true;
        if (
          !this.$v.shippingAddress.city.required ||
          !this.$v.shippingAddress.city.minLength ||
          !this.$v.shippingAddress.address.required ||
          !this.$v.shippingAddress.address.minLength
        ) {
          return;
        }
        let data = {};
        data = {
          ...this.personalInformation,
          ...this.shippingAddress,
          confirm_url:
            conf.websiteUrl + '/' + this.$store.state.main.locale + '/registration?token=',
        };
        if (this.personalInformation.createAccount) {
          this.$store
            .dispatch('user/registration', data)
            .then((res) => {
              if (res.status === 200) {
                this.errorMessage = '';
                if (this.personalInformation.createAccount) this.emailNotification = true;
                data.isAuthorized = true;
                data.price = this.getCartItems.price;
                data.shipping = parseInt(this.$store.state.shop.shippingPrice);
                data.sale = this.getSalePrice;
                data.total_price = data.price + data.shipping;
                data.quantity = this.getCartItems.sum;
                this.userToken ? (data.userToken = this.userToken) : '';

                this.$store.dispatch('sendOrder', data).then((res) => {
                  if (res.status == 200) {
                    this.animateScrollHeader();
                    this.removeCartItems();
                    this.successOrder = true;
                    this.unknowErrorMessage = false;
                  }
                  if (res.status == 200) {
                    this.unknowErrorMessage = true;
                  }
                });
                this.selectedCity = '';
              }
            })
            .catch((err) => {
              if (_.get(err.response.data, 'errors.email')) {
                this.errorMessage = _.get(err.response.data, 'errors.email[0]');
              }
            });
        } else {
          let sendData = {};
          sendData.shipping_day = this.$store.state.shop.shippingDays;
          sendData.upload_invoice = this.selectedPayment === 'card' ? false : true;
          sendData.delivery_option = this.selectedChoice;
          sendData.lang = this.$store.state.main.locale;
          sendData.isAuthorized = this.userToken ? true : false;
          this.userToken ? (sendData.userToken = this.userToken) : '';
          sendData.cart_items = this.getCartItems;
          sendData.address = {
            ...this.personalInformation,
            ...this.shippingAddress,
          };
          sendData.price = this.getCartItems.price;
          sendData.shipping = parseInt(this.$store.state.shop.shippingPrice);
          sendData.sale = this.getSalePrice;
          sendData.total_price = data.price + data.shipping;
          data.quantity = this.getCartItems.sum;

          this.$store
            .dispatch('shop/sendOrder', sendData)
            .then((res) => {
              if (res.status == 200) {
                this.animateScrollHeader();
                this.successOrder = true;
                this.removeCartItems();
                this.unknowErrorMessage = false;
              }
              if (res.status == 200) {
                this.unknowErrorMessage = true;
              }
            })
            .catch((err) => {
              console.log(err, 'error');
            });
        }
      }
    },
    sendOrder() {
      if (this.loadingStatus) {
        return;
      }
      if (this.selectedChoice !== 'pick-up') {
        if (
          (!this.activeAddressId && this.getAuthData) ||
          !this.selectedChoice.length ||
          !this.selectedPayment.length
        ) {
          !this.selectedChoice.length
            ? (this.checkErrorMessage = 'select delivery option !')
            : !this.selectedPayment.length && this.activeAddressId
            ? (this.checkErrorMessage = 'select payment option !')
            : 'check at least one address!';
          this.checkAddressNotification = true;
          return;
        }
        if (!this.selectedPayment && !this.selectedChoice.length) {
          this.checkPaymentNotification = true;
          return;
        }
      } else if (!this.selectedPayment.length) {
        this.checkPaymentNotification = true;
        return;
      }
      this.loadingStatus = true;

      let data = {};
      data.isAuthorized = this.getAuthData || this.userToken ? true : false;
      this.getAuthData || this.userToken
        ? (data.token = this.$cookies.get('userToken') || this.userToken)
        : '';
      data.lang = this.$store.state.main.locale;
      data.shipping_day = this.$store.state.shop.shippingDays;
      data.upload_invoice = this.selectedPayment === 'card' ? false : true;
      data.cart_items = this.getCartItems;
      data.address = !this.getAuthData
        ? { ...this.personalInformation, ...this.shippingAddress }
        : this.getAuthDataAddresses.find((address) => address.id === this.activeAddressId);
      data.price = this.getCartItems.price;
      data.shipping = parseInt(this.$store.state.shop.shippingPrice);
      data.delivery_option = this.selectedChoice;
      data.sale = this.getSalePrice;
      data.total_price = data.price + data.shipping;
      data.quantity = this.getCartItems.sum;
      data.create_profile = this.personalInformation.createAccount;

      this.$store
        .dispatch('shop/sendOrder', data)
        .then((res) => {
          if (res.status == 200) {
            this.loadingStatus = false;

            this.errorMessage = '';
            this.animateScrollHeader();
            // this.successOrder = true;
            this.removeCartItems();
            this.selectedPayment === 'card'
              ? window.open(res.data.order_url, '_self')
              : this.setInvoiceLinks(res.data);
            this.unknowErrorMessage = false;

            // this.removeCartItems();;
          }
          if (res.status == 201) {
            this.unknowErrorMessage = true;
            this.loadingStatus = false;
          }
        })
        .catch((err) => {
          this.loadingStatus = false;
          // this.errorMessage = _.get(err.response.data,'errors.email[0]')
        });
    },
    setInvoiceLinks(data) {
      this.invoiceLinks.uploadInvoiceLinks = `https://mysa.ge/${this.$store.state.main.locale}/upload/order/${data.order_id}/${data.order_token}`;
      this.invoiceLinks.downloadInvoiceLinks = `https://mysa.ge/source/invoice/${data.order_id}/${data.order_token}`;
      this.successOrder = true;
    },
    animateScrollHeader(element) {
      let header = document.querySelector('#scrollHeader');
      setTimeout(() => {
        element
          ? document.getElementById(`${element}`).scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'nearest',
            })
          : window.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
      }, 250);
      setTimeout(() => {
        header.classList.remove('transform-top-class');
      }, 650);
      header.classList.add('transform-top-class');
      header.classList.remove('hide-top-navbar');
    },
    loginUser() {
      this.isClickedLogin = true;
      if (
        !this.$v.loginForm.personalNumber.required ||
        !this.$v.loginForm.personalNumber.minLength ||
        !this.$v.loginForm.personalNumber.maxLength ||
        !this.$v.loginForm.password.required ||
        !this.$v.loginForm.password.minLength
      ) {
        return;
      }
      this.$store
        .dispatch('user/authorization', {
          personal_number: this.loginForm.personalNumber,
          password: this.loginForm.password,
        })
        .then((res) => {
          if (res.data.access_token) {
            setTimeout(() => {
              this.invalidLoginError = '';
              this.$cookies.set('userToken', res.data.access_token, 60 * 60 * 12);
              this.$store.dispatch('user/getUserData', {
                token: res.data.access_token,
              });
              this.isClickedLogin = false;
            }, 500);
          } else {
            setTimeout(() => {
              this.invalidLoginError = '';
            }, 10000);
            this.invalidLoginError = 'You have entered invalid credentials';
          }
        })
        .catch((err) => {
          if (err) {
            setTimeout(() => {
              this.invalidLoginError = '';
            }, 10000);
            this.invalidLoginError = 'You have entered invalid credentials';
          }
        });
      // this.nextStep = true;
    },
    getChoice(val) {
      this.selectedChoice = val;
      if (val === 'pick-up') {
        this.$store.commit('shop/setState', {
          key: 'shippingPrice',
          val: 0,
        });
      } else {
        if (val === 'delivery') {
          this.calculateShippingPrice(this.domain);
        }
      }
    },
    getChoicePayment(val) {
      this.selectedPayment = val;
    },
  },

  mounted() {
    this.$store.commit('main/setState', {
      key: 'breadcrumbList',
      val: [
        {
          title: 'Cart',
          url: `/${this.$store.state.main.locale}/cartlist`,
        },
        { title: 'Checkout' },
      ],
    });
    this.$store.commit('shop/setState', {
      key: 'shippingPrice',
      val: 0,
    });
  },
};
</script>

<style scoped>
.checkout {
  display: flex;
  justify-content: space-between;
}
#checkout {
  padding-bottom: 144px;
  /*border-bottom: 1px solid #D9DCDE;*/
  position: relative;
}
#checkout:after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  width: 98%;
  height: 1px;
  background-color: #d9dcde;
}

.account,
.personal-info,
.authorized-cart {
  width: 50%;
  /*transform: translateX(0);*/
  /*transform: translateX(0);*/
  /*transition-delay: 100ms;*/
  /*transition: 1s ease;*/
}
/*.account.hide, .personal-info.show {*/
/*    width: 0;*/
/*    !*transform: translateX(-100%);*!*/
/*    transform: translateX(-1000px);*/

/*    transition: transform .4s ease, width 1.5s ease-in;*/
/*}*/
.email-send-container {
  background-color: #f7f7f7;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 110px;
  margin-bottom: 30px;
  transition: 0.6s ease 0.2s;
  padding: 0 25px;
}
.email-send-container.active {
  max-height: 700px;
  transition: 0.6s ease;
}
.email-send-container.active .email-send-container-row:last-child {
  opacity: 1;
  max-height: 700px;
  visibility: visible;
  transition: opacity 0.6s ease, max-height 0.6s ease;
}
.email-send-container .email-send-container-row:last-child {
  opacity: 0;
  max-height: 0;
  visibility: hidden;
  transition: opacity 0.6s ease, max-height 0.6s ease;
}
.email-send-container-row {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.email-send-container-row .form-input-item input {
  background-color: #ffffff;
}
.email-send-container.active .email-send-container-row .green-button {
  opacity: 1;
}
.email-send-container-row .green-button {
  background-color: var(--green);
  opacity: 0;
  color: #ffffff;
  height: 44px;
  transition: 0.3s ease;
}

.email-send-container-row .green-button:hover {
  background-color: #ffffff;
  color: var(--green);
  transition: 0.3s ease;
}
.go-to-registration {
  color: #435059;
  font-family: var(--noto-regular);
  font-size: 16px;
  text-align: center;
  margin-top: 35px;
  padding-bottom: 20px;
}

.go-to-registration span {
  color: #fec55e;
  text-decoration: underline;
  margin-left: 5px;
  transition: 0.3s ease;
  cursor: pointer;
}
.email-send-container-row .user-dropdown-container {
  width: 100%;
}
.email-send-container-row:first-child {
  height: 110px;
}
.email-send-container-row:last-child {
  padding: 0;
}
.login-butt {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 88px;
}
.login-close {
  transform: rotate(45deg);
}
.label.error {
  font-size: 10px !important;
  padding-top: 5px;
  color: #ff7675 !important;
}
.unknow-error {
  color: #ff7675;
}
.errors {
  margin-top: 15px;
}
.checkout-title {
  margin-bottom: 95px;
}
.email-send-container {
  /*padding-left: 30px;*/
}
.checkout-button {
  background-color: var(--green);
  color: #ffffff;
  width: 200px;
  height: 44px;
  text-transform: uppercase;
  margin-left: auto;
  font-size: 13px;
  margin-top: 70px;
}
.checkout-button.disabled {
  cursor: not-allowed;
  background: #81c7bd82;
}
.checkout-button:hover {
  background-color: #ffffff;
  color: var(--green);
}
.email-send-desc {
  display: flex;
  /*margin-left: 25px;*/
}
.email-send-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 65px;
  font-size: 13px;
}
.email-send-text p:not(:last-child) {
  margin-bottom: 10px;
  font-family: var(--noto-bold);
}
.email-send-button {
  display: flex;
  flex-direction: column;
  background-color: #fec55e;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.5s ease;
}
.button-text {
  margin-top: 10px;
  color: #ffffff;
  font-family: var(--noto-bold);
  font-size: 13px;
  line-height: 15px;
  text-transform: capitalize;
}
.content-top-title {
  font-family: var(--noto-bold);
  font-size: 21px;
  margin-bottom: 20px;
  padding-left: 30px;
}
.capitalize {
  text-transform: capitalize;
}
.form-container {
  border: 1px solid #ecf0f1;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 10px;
  margin-top: 20px;
}
.shipping-form {
  margin-top: 50px;
}

.form-input-item {
  margin-bottom: 30px;
}
.form-input-item .label {
  color: #435059;
  font-family: var(--noto-regular);
  font-size: var(--normalFontSize);
  padding: 0 0 10px;
  display: flex;
}

.form-input-item .label span {
  color: #ff7675;
  font-size: 12px;
  margin-left: 5px;
}
.form-input-item .label.red {
  font-size: 13px;
  padding-top: 5px;
  color: #ff7675;
  padding-bottom: 0;
}

.form-input-item .border-error {
  border: 1px solid #ff7675;
}

.delivery-day {
  padding-top: 30px;
  padding-left: 30px;
}

.form-input-item input,
.form-input-item textarea {
  width: 100%;
  border: unset;
  border-radius: 10px;
  padding: 11px;
  background-color: #f7f7f7;
  font-size: 16px;
}
.form-input-checkbox {
  display: flex;
  margin-bottom: 20px;
}
.form-input-checkboxes {
  margin-top: 20px;
}
/*.form-input-checkbox input {*/
/*    width: 20px;*/
/*    height: 20px;*/
/*    background-color: #F7F7F7;*/
/*    border-radius: 4px;*/
/*    border: 1px solid rgba(67, 80, 89, 0.3);*/
/*    cursor: pointer;*/
/*}*/
.form-input-checkbox-label {
  font-family: var(--noto-regular);
  font-size: 16px;
}
.form-input-buttons {
  margin-top: 60px;
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
}
.form-button {
  border-radius: 10px;
  cursor: pointer;
  font-family: var(--noto-bold);
}
.form-button:first-child {
  background-color: #f7f7f7;
  width: 140px;
  height: 44px;
  font-size: 13px;
}
.form-button:nth-of-type(2) {
  background-color: var(--green);
  width: 200px;
  height: 44px;
  color: #ffffff;
}
.items {
  width: 50%;
  margin-left: 20px;
}
.items-wrapper {
  border-radius: 10px;
  border: 1px solid #ecf0f1;
  background-color: #ffffff;
  padding: 30px 23px 30px 41px;
}
.invoice-button {
  background-color: var(--green);
  width: 180px;
  height: 44px;
  color: #ffffff;
  border-radius: 10px;
  font-size: 13px;
  cursor: pointer;
  font-family: var(--noto-bold);
}
.success-text,
.error-text {
  font-family: var(--noto-bold);
  font-size: 21px;
  line-height: 28px;
  margin-bottom: 35px;
}
.check-email.check-email a {
  color: #435059;
  font-family: var(--noto-bold);
  font-size: 21px;
  text-decoration: underline;
}
.check-email.check-email {
  color: #fec55e;
}
.success-invoice-text p:first-child {
  color: var(--green);
  font-family: var(--noto-bold);
}
.success-invoice-text a {
  color: #435059;
  font-family: var(--noto-bold);
}
.invoice-container,
.error-cart-title {
  min-height: 70vh;
}
.success-invoice {
  display: flex;
  justify-content: space-between;
  width: 270px;
  margin-left: 11px;
  margin-bottom: 45px;
}

.item-list {
  max-height: 320px;
  overflow-y: auto;
}
.item-list::-webkit-scrollbar {
  width: 3px;
  background-color: #d9dcde;
}
.item-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #435059;
}
.summary-title {
  border-top: 1px solid #f7f7f7;
  font-family: var(--noto-light);
}
.summary-title,
.summary-title span {
  font-size: 34px;
  margin-bottom: 25px;
  padding-top: 40px;
}
.summary-title span {
  color: var(--green);
  font-family: var(--noto-bold);
}
.order-summary-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.order-summary {
  margin-bottom: 20px;
  border-bottom: 1px solid #f7f7f7;
  margin-top: 30px;
}
.order-summary-row:last-child {
  margin-bottom: 30px;
}
.order-row-item {
  font-size: 21px;
}
.order-row-item:first-child {
  font-family: var(--noto-regular);
}
.order-row-item:nth-of-type(2) {
  font-family: var(--noto-bold);
  color: #181818;
}
.total-order .order-row-item {
  font-size: 25px;
  font-family: var(--noto-light);
  text-transform: capitalize;
}
.total-order .order-row-item:nth-of-type(2) {
  font-family: var(--noto-bold);
  color: #181818;
}
.total-order .order-summary-row {
  margin-bottom: 10px;
  margin-top: 10px;
}
.authorized-cart-addresses {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.email-send-text p {
  font-size: 13px;
}
.create-account-inputs {
  margin-top: 0;
  opacity: 0;
  visibility: hidden;
  max-height: 0;
  transition: 0.7s ease;
}
.create-account-inputs.active {
  margin-top: 50px;
  opacity: 1;
  visibility: visible;
  max-height: 500px;
  transition: 0.7s ease 0.2s;
}
.personal-info-wrapper {
  border: 1px solid #ecf0f1;
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 20px;
  background-color: #ffffff;
  /*transition: .5s ease;*/
}
.personal-info-wrapper.active-address {
  border: 1px solid var(--orange);
  /*transition: .5s ease;*/
}
.personal-info-wrapper p {
  font-size: 16px;
  font-family: var(--noto-regular);
}
.personal-info-wrapper p:first-child {
  font-family: var(--noto-bold);
  color: var(--green);
}
.personal-info-wrapper .edit-address {
  font-family: var(--noto-bold);
  margin-top: 30px;
  text-decoration: underline;
  cursor: pointer;
  color: #181818;
  text-transform: uppercase;
}
.add-icon {
  width: 44px;
  height: 44px;
  border-radius: 10px;
  background-color: #fec55e;
  cursor: pointer;
}
.add-new-address {
  display: flex;
  align-items: center;
  margin-top: 50px;
  cursor: pointer;
}
.add-new-address:hover .add-new-address-text {
  color: var(--orange);
  transition: 0.2 ease;
}
.add-new-address .form {
  width: 100%;
}
.add-new-address-text {
  font-family: var(--noto-bold);
  font-size: 21px;
  margin-left: 20px;
  transition: 0.2s ease;
}
.add-icon svg {
  width: 90%;
}
.form-input-item.input-error .label {
  color: #ff7675;
}
.form-input-item.input-error input,
.form-input-item.input-error textarea {
  border: 1px solid #ff7675;
}
@media screen and (max-width: 1599px) {
  .checkout-title {
    /*margin-top: -16px;*/
    margin-bottom: 80px;
  }
}
@media screen and (max-width: 1365px) {
  .content-top-title {
    font-size: 21px;
  }
  #checkout:after {
    width: 95%;
  }
  .items-wrapper {
    padding: 25px;
  }
  .checkout-title {
    /*margin-top: -16px;*/
    margin-bottom: 55px;
  }
  #checkout {
    padding-bottom: 97px;
  }
}
@media screen and (max-width: 1023px) {
  .checkout {
    flex-direction: column;
  }
  .checkout-title {
    margin-bottom: 71px;
  }
  .form-input-checkboxes {
    margin-top: 40px;
  }
  #checkout {
    padding-top: 14px;
    padding-bottom: 90px;
  }
  .account,
  .items,
  .personal-info,
  .authorized-cart {
    width: 100%;
  }
  .items {
    margin-top: 35px;
    margin-left: unset;
  }
  .item-list {
    max-height: 110px;
  }
  .order-summary {
    margin-top: 10px;
  }
}
@media screen and (max-width: 520px) {
  .email-send-container {
    flex-direction: column;
    /*height: 121px;*/
  }
  .email-send-container-row:first-child {
    flex-direction: column;
  }
  .login-butt {
    flex-direction: row;
  }
  .total-order .order-row-item:first-child {
    max-width: 80%;
  }

  .email-send-container {
    max-height: 140px;
  }
  .email-send-button {
    width: 100%;
    /*margin-top: 15px;*/
    height: 37px;
    flex-direction: row;
  }
  .email-send-button img {
    width: 20px;
  }
  .button-text {
    height: 70%;
    margin-left: 10px;
    margin-top: 0px;
  }
  .email-send-desc img {
    width: 35px;
  }
  .email-send-container {
    padding: 10px;
  }
  .email-send-text {
    padding-left: 12px;
  }
  .form-container {
    padding: 20px 20px 5px;
    background: #ffff;
  }
  .email-send-text p:not(:last-child) {
    margin-bottom: unset;
  }
  .form-input-buttons .form-button {
    width: 46%;
    font-size: 13px;
    text-align: center;
  }
  .summary-title,
  .summary-title span,
  .total-order .order-row-item {
    font-size: 21px;
  }
  .summary-title {
    margin-bottom: 25px;
    padding-top: 40px;
  }
  .order-summary-row {
    margin-bottom: 8px;
  }
  .order-summary-row:last-child {
    margin-bottom: 20px;
  }
  #checkout {
    padding-bottom: 52px;
  }
  #checkout:after {
    width: 90%;
  }
  #checkout .footer-wrapper {
    padding-top: 28px;
  }
  .total-order .order-summary-row {
    margin-bottom: 5px !important;
    margin-top: 24px;
  }
  .order-summary {
    margin-bottom: 0;
  }
  .item-list .product-item-row:last-child {
    align-items: unset !important;
  }
  .item-list .product-item-desc {
    margin-left: unset;
    margin-top: 10px;
  }
  .item-list {
    max-height: 150px;
  }
  .order-summary {
    margin-top: 10px;
  }
  .product-item-desc {
    width: 111%;
  }
  .product-item-desc p:first-child {
    margin-bottom: 5px;
  }
  .product-item-row:first-child {
    flex-direction: column;
    align-items: flex-start;
  }
  .personal-info-wrapper p {
    font-size: 14px;
  }
  .success-invoice {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .content-top-title {
    padding-left: unset;
    margin-top: 20px;
  }
  .items {
    margin-top: 16px;
  }
  .checkout-title {
    padding-bottom: 20px;
    margin-bottom: unset;
    margin-top: 25px;
    border-bottom: 1px solid #ecf0f1;
  }
  .form-input-item .label {
    font-size: 16px;
  }
  .email-send-container {
    margin-bottom: 20px;
  }
  .success-text,
  .error-text {
    font-size: 16px;
  }
  .success-invoice {
    width: 260px;
  }
  .success-invoice-text p,
  .success-invoice-text a {
    font-size: 15px;
  }
  .invoice-button {
    font-size: 14px;
  }
  .items-wrapper {
    padding: 25px 20px;
  }
}
</style>

<style>
.success-oder-title h1 span:after {
  content: '';
  width: 34px;
  height: 34px;
  display: inline-flex;
  margin-left: 10px;
  background-image: url('../assets/img/check-circle.svg');
  background-size: cover;
  background-repeat: no-repeat;
}
.success-oder-title h1 span {
  display: inline-flex;
  align-items: center;
}
.error-cart-title .content-title h1 span {
  color: #ff7675;
}
@media screen and (max-width: 767px) {
  .checkout .account .form-button:nth-of-type(2) {
    width: 52%;
  }
  .checkout .account .item-list {
    max-height: 166px;
  }
  .checkout .account .item-list .product-item-desc {
    margin-top: 15px;
  }
  .checkout .account .product-item-desc p:first-child {
    margin-bottom: 10px;
  }
  .product-item-row .close {
    width: 34px;
    height: 34px;
    margin-right: 5px;
    margin-top: 5px;
  }
}

@media screen and (max-width: 767px) {
  .success-oder-title h1 span:after {
    width: 20px;
    height: 20px;
  }
}
header #scrollHeader.transform-top-class {
  /*transform: translateY(-300px) !important;*/
  opacity: 0 !important;
  transition-delay: 200ms;
  transition: 0.35s ease !important;
}
</style>
