<template>
    <section class="brand-list">

        <div class="container-wrapper">
            <content-title
                :colored-title="true"
                :title="_.get( $store.state.main.current_content, 'primary.conf.header.title')"
                data-aos="fade-up"
            />
            <div class="grid border-top" v-if=" _.get($store.state.main.indx, 'attributes.brands')">
                <div class="category-card" v-for="(item, index) in  _.get($store.state.main.indx, 'attributes.brands')" :key="index" data-aos="fade-up" data-aos-delay="200" @click="modalActive = true">
                    <div @click="passData(item)" class="brand-content">
                        <picture>
                            <source
                                media="(max-width: 767px)"
                                :srcset="(`${_.get(item,'image[0].devices.mobile')}`)"
                                loading="lazy"
                            />
                            <source
                                media="(max-width: 1023px)"
                                :srcset="(`${_.get(item,'image[0].devices.tablet')}`)"
                                loading="lazy"
                            />
                            <img

                                loading="lazy"
                                :srcset="(`${_.get(item,'image[0].devices.desktop')}`)"
                                alt="image"
                            />
                        </picture>
                    </div>
                    <h2  class="title semibold font-16">{{ item.title }}</h2>
                </div>
            </div>
        </div>
        <BrandModal @closeModal="modalActive= false" :modalData="customdata" :modalActive="modalActive" />

        <CartInfo
           class="mt-144"
        />
    </section>
</template>

<script>
import contentTitle from "../components/ContentTitle"
import CartInfo from "../components/CartInfo"
import BrandModal from "../components/BrandsPopup"
import { setTimeout } from 'timers'

export default {
    components: {
        contentTitle,
        CartInfo,
        BrandModal
    },
    data(){
        return{
            customdata: null,
            modalActive: false,
        }
    },

    methods: {
        passData(item){
            this.customdata = item
            this.modalActive = true
        }
    },
    mounted() {
        this.$store.commit('main/setState', {key: "breadcrumbList", val: [{title: 'Brands'}]})
    }
}
</script>

<style scoped>
    .mt-144{
        margin-top: 144px;
    }
    .grid{
        position: relative;
        display: grid;
        grid-template-columns: repeat(4, minmax(250px, 1fr));
        gap: 89px 90px;
    }
    .title {
        color: #435059;
        text-align: center;
        margin-top: 20px;
        transition: 0.4s;
    }
    .category-card{
        cursor: pointer;
    }
    .brand-content{
        position: relative;
        padding-top: 150px;
        /* padding-top: 130px; */
        overflow: hidden;
    }
    .brand-content img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        transition: 0.3s ease-in-out;
    }
    @media (max-width: 1599px){
        .grid{
            grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
            gap: 70px 60px;
        }
    }
     @media (max-width: 1365px){
        .grid{
            gap: 70px 70px;
        }
    }
    @media (max-width: 1023px){
        .mt-144{
            margin-top: 75px;
        }
        .grid{
            gap: 30px;
        }
    }
</style>
