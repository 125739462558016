<template>
    <div class="sliced-slider home-sliced-slider">
        <swiper ref="mySwiper" :options="swiperOptions">
            <swiper-slide v-for="(item, index) in sliderData" :key="index">
                <div class="sliced-slider-swiper">
                    <picture >
                        <div class="flex">
                            <source
                                media="(max-width: 767px)"
                                loading="lazy"
                                :srcset="(`${_.get(item, 'banner[0].devices.mobile')}`)"
                            />
                        </div>
                        <div class="flex">
                            <source
                                media="(max-width: 1023px)"
                                loading="lazy"
                                :srcset="(`${_.get(item, 'banner[0].devices.tablet')}`)"
                            />
                        </div>
                        <div class="flex">
                            <img
                                alt="image"
                                loading="lazy"
                                :src="(`${_.get(item, 'banner[0].devices.desktop')}`)"
                            />
                        </div>
                    </picture>
                    <template v-for="(pin, index) in _.get(item, 'add_pin.tabs')">
                        <router-link :key="index" tag="div" :to="pin.url ? pin.url : '#'" class="dot"
                            :style="{ top: _.get(pin, 'pin_position_top') +'%', left: _.get(pin, 'pin_position_left')+'%'}" >

                            <i></i>
                            <div class="dotInner">
                                <div class="dotInner-content">
                                    <p class="font-16 regular">{{pin.name}}</p>
                                    <p class="bold font-34">{{pin.price}} ₾</p>
                                </div>

                                <span class="dotInnerArrow"></span>
                            </div>

                        </router-link>
                    </template>
                </div>
            </swiper-slide>
        </swiper>
        <div class="swiper-pagination" v-if="sliderData && sliderData.length > 1"></div>
    </div>
</template>

<script>

export default {

    props: ["sliderData"],

    data() {
        return {
            swiperOptions: {
    			speed: 400,
    			breakpoints: {
                    320: {
                        slidesPerView: 1.1
                    },
                    768: {
                        slidesPerView: 2
                    },
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
            },
        }
    },
}
</script>

<style>
    .width100{
        width: 100%;
    }
    .sliced-slider{
        position: relative;
    }
    .home-sliced-slider .swiper-container{
        overflow: visible;
    }
    .sliced-slider-swiper{
        position: relative;
        padding-top: 700px;
    }
    .sliced-slider-swiper img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
    }
    .sliced-slider .dot{
        position: absolute;
        /* padding: 100px; */
        border-radius: 50%;
        cursor: pointer;
    }
    .sliced-slider-swiper .dot i{
        width: 30px;
        height: 30px;
        background: white;
        border: 8px solid #FEC55E;
        z-index: 2;
        position: absolute;
        border-radius: 50%;
    }
    .dotInner{
        text-align: center;
    }
    .sliced-slider-swiper .dot:hover  .dotInner{
        opacity: 1;
        height: 109px;
        visibility: visible;
        transition: .5s ease;
    }


    .sliced-slider-swiper .dot:hover  .dotInner-content{
        opacity: 1;
        visibility: visible;
        transition: .3s ease .3s;
    }
    .dotInner-content{
        opacity: 0;
        visibility: hidden;
        transition: .3s ease;
    }


    .sliced-slider-swiper .dot  .dotInner p{
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .sliced-slider-swiper .dot .dotInner{
        display: inline-block;
        width: 255px;
        height: 0;
        position: absolute;
        background-color: #fff;
        visibility: hidden;
        bottom: 20px;
        left: -118px;
        border-radius: 30px;
        border: 1px solid #fac110;
        text-align: center;
        padding: 10px;
        opacity: 0;
        transition: .5s cubic-bezier(0, .29, 0, 1) .4s;
        z-index: 999;
    }
    .sliced-slider-swiper .dot .dotInnerArrow{
        height: 15px;
        width: 15px;
        position: absolute;
        bottom: -8px;
        transform: rotate(45deg);
        background-color: #fff;
        border-bottom: 1px solid #fac110;
        border-right: 1px solid #fac110;
    }
    .sliced-slider .swiper-pagination{
        position: absolute;
        bottom: 15px;
        left: 46%;
        transform: translate(-15px, -46%);
    }
    .sliced-slider .swiper-pagination-bullet{
        width: 10px;
        height: 10px;
        margin: 0 10px !important;
        background: #FFFFFF;
        opacity: 1;
    }
    .sliced-slider .swiper-pagination-bullet-active{
        background: #FEC55E;
    }
    @media (max-width: 1919px){
        .sliced-slider-swiper{
            padding-top: 583px;
        }
    }
    @media (max-width: 1599px){
        .sliced-slider-swiper{
            padding-top: 498px;
        }
    }
    @media screen and (max-width: 1365px) {
        .sliced-slider-swiper{
            padding-top: 373px;
        }
        .sliced-slider{
            margin-top: -8px;
        }
    }
    @media screen and (max-width: 1023px){
        .sliced-slider-swiper{
            padding-top: 373px;
        }
        .dot{
            display: none;
        }
    }
    @media screen and (max-width: 767px){
        .sliced-slider-swiper{
            padding-top: 218px;
        }
    }

    @media screen and (max-width: 767px){
        .sliced-slider .swiper-pagination{
            bottom: 0;
            transform: translate(-50%, -50%);
            width: 100%;
        }
        .download-content{
            width: 100%;
        }
        .file-name{
            margin: 0 !important;
        }
        .mobile-right-content{
            flex-direction: column;
        }
    }
</style>
