<template>
    <div class="flex-items">
        <div class="left-content">
            <div class="relative wishlist-img">

                <picture>
                    <source
                        media="(max-width: 767px)"
                        loading="lazy"
                        :srcset="_.get(WishlistData,'images[0].devices.mobile')"
                    />
                    <source
                        media="(max-width: 1023px)"
                        loading="lazy"
                        :srcset="_.get(WishlistData,'images[0].devices.tablet')"
                    />
                    <img
                        alt="img"
                        :src="_.get(WishlistData,'images[0].url')"
                        loading="lazy"
                    />
                </picture>
            </div>

        </div>
        <div class="wishlist-info">
            <div>
                <div class="wishlist-title font-16 regular">
                    {{ WishlistData.title }}
                </div>
                <div class="wishlist-price bold font-34">
                    {{ WishlistData.stock_price }} ₾
                </div>
            </div>
            <div class="right-content">
                <div class="add-item border-bottom">
                    <button v-if="!alreadyInCart" @click="addToCart(WishlistData,quantity)" class="font-13 bold">
                        {{$fn.tr('Add to bag')}}
                    </button>
                    <button class="font-13 bold" @click="removeCartItem(WishlistData,quantity)" v-else>
                        {{$fn.tr('Remove from bag')}}
                    </button>
                </div>
                <button class="remove bold font-13" @click="$emit('delete-element')">
                    {{$fn.tr('remove from wishlist')}}
                </button>
            </div>
        </div>
        <Notification :active="notification" @hide="notification = false">
            {{$fn.tr('Added Successfully!')}}
        </Notification>
    </div>
</template>

<script>
    import WishlistButtons from "./Incriment"
    import addToCartMixin from "../mixins/addToCartMixin";

    export default {
        props: ['WishlistData', 'itemCount',],
        mixins: [addToCartMixin],
        data(){
            return {
                quantity: 1,
                alreadyInCart: false
            }
        },
        components: {
            WishlistButtons
        },
        watch: {
            WishlistData: {
                immediate: true,
                handler(val) {
                    if (val) {
                        this.quantity = parseInt(val.quantity);
                    }

                }
            },
            getCartList: {
                immediate: true,
                handler(val) {
                    if (val) {
                        val.findIndex(res => res.id === this.WishlistData.id) !== -1
                            ? this.alreadyInCart = true : this.alreadyInCart = false
                    }

                }
            }
        },
    }

</script>

<style scoped>
    .incriment {
        width: 150px;
        padding: 10px 12px;
        justify-content: space-between;
    }

    .flex-items {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .left-content {
        display: flex;
        align-items: center;
        margin-right: 20px;
    }

    .wishlist-img {
        padding-top: 248px;
        padding-left: 250px;
        background: #FFFFFF;
        border-radius: 30px;
        overflow: hidden;
        margin-right: 30px;
    }

    .wishlist-img img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        transition: 0.3s ease-in-out;
    }

    .wishlist-title {
        color: #435059;
        margin-bottom: 20px;
    }

    .wishlist-price {
        color: #181818;
    }

    .wishlist-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .right-content {
        margin-left: 20px;
        max-width: 385px;
        width: 100%;
    }

    .add-item {
        display: flex;
    }

    .add-item button {
        width: 100%;
        height: 44px;
        background: #81C7BD;
        border-radius: 10px;
        color: #fff;
        text-transform: uppercase;
    }

    .remove {
        margin-top: 10px;
        width: 100%;
        height: 44px;
        background: #F7F7F7;
        border-radius: 10px;
        color: #435059;
        text-transform: uppercase;
        transition: 0.4s;
    }

    .remove:hover {
        color: #fff;
        background: #435059;
        transition: 0.4s;
    }

    .out-anim {
        animation-name: out-animation;
        animation-duration: 0.3s;
    }

    .incriment{
        width: 120px;
        border-radius: 10px;
        border: 1px solid #F7F7F7;
        background: #fff;
        padding: 10px 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 30px;
        cursor: pointer;
    }
    .decrement:hover svg path{
        fill: #FEC55E;
        transition: 0.3s;
    }
    .increment:hover svg path{
        fill: #FEC55E;
        transition: 0.3s;
    }
    @media(max-width: 767px){
        .incriment{
            margin-right: 10px;
        }
    }

    @keyframes out-animation {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }

    @media (max-width: 1023px) {
        .wishlist-info {
            flex-direction: column;
            align-items: flex-start;
        }

        .right-content {
            max-width: unset;
            margin: 0;
            margin-top: 15px;
        }
    }

    @media (max-width: 767px) {
        .left-content {
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
        }

        .wishlist-img img {
            object-fit: contain;
        }

        .wishlist-img {
            margin: 0 0 15px 0;
            padding-left: 100%;
            padding-top: 100%;
        }
    }

    @media (max-width: 530px) {
        .flex-items {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
    }
</style>
