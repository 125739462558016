<template>
  <section>
    <div class="container-wrapper">
      <content-title data-aos="fade-up" :colored-title="true" :title="productList.title" />
      <FilterComponent
        @changeCategoryChild="changeCategoryChild"
        @changeFilter="changeFilter"
        @clearFilter="clearFilter"
        @changeCategory="changeCategory"
        @changeBrand="changeBrand"
      />
      <div class="loader" v-show="waiting">
        <div class="loader-mysa center"><span></span></div>
      </div>

      <div class="grid border-top">
        <div
          v-for="(item, index) in getFirstRowProducts"
          :class="{
            active: !waiting && _.size(getProducts),
          }"
          :key="index"
          class="product-grid"
        >
          <ProductCard
            @openmodal="passData"
            class="title-height"
            :link="true"
            :sliderData="item"
            data-aos="fade-up"
          />
        </div>
      </div>

      <div
        class="bold no-products uppercase font-34 text-center"
        data-aos="fade-up"
        data-aos-delay="200"
        v-if="!waiting && !_.size(getProducts)"
      >
        {{ $fn.tr('No Products') }}
      </div>
    </div>

    <ADSection
      v-if="_.size(getProducts) && _.size(getProducts) >= 23"
      class="mb-85 ADmargin"
      :ADdata="
        _.get(this.$store.state.main.current_content, 'secondary.ProductBanner.data.list[0]')
      "
    />
    <div class="empty-small-div" v-else></div>

    <div class="container-wrapper relative">
      <div class="grid border-top mb-144" v-show="_.size(getProducts)" id="product-list">
        <div
          class="product-grid"
          :class="{
            active: !waiting && _.size(getProducts),
          }"
          v-for="(item, index) in getProducts.slice(12, getProducts.length)"
          :key="index"
        >
          <!-- .slice(40, getProducts.length) -->
          <ProductCard
            @openmodal="passData"
            class="title-height"
            :link="true"
            :sliderData="item"
            data-aos="fade-up"
          />
        </div>
      </div>
      <img class="bottom-decor-img" loading="lazy" src="../assets/img/decor.png" alt="" />
    </div>

    <Pagination
      class="mb-144 container-wrapper"
      :dataLength="$store.state.main.countall"
      :perPage="24"
      :pageRange="3"
      @set-current="setCurrent"
    />

    <QuickViewModal
      @closeModal="modalActive = false"
      :alreadyInWishList="alreadyInWishList"
      @addToWish="alreadyInWishList = true"
      @removeFromWish="alreadyInWishList = false"
      :modalData="customData"
      :modalActive="modalActive"
    />

    <CartInfo />
  </section>
</template>

<script>
import contentTitle from '../components/ContentTitle';
import CartInfo from '../components/CartInfo';
import ProductCard from '../components/ProductCardComponent';
import ADSection from '../components/ADSection';
import FilterComponent from '../components/ProductList';
import QuickViewModal from '../components/QuickViewModal';
import Pagination from '../components/Pagination';
import attributesMixin from "../mixins/attributesMixin";
import { mapState } from 'vuex';

export default {
  components: {
    contentTitle,
    CartInfo,
    ProductCard,
    ADSection,
    FilterComponent,
    QuickViewModal,
    Pagination,
  },
  mixins: [attributesMixin],
  data() {
    return {
      modalData: null,
      modalActive: false,
      customData: '',
      categoryId: null,
      categorySlug: null,
      brandId: null,
      alreadyInWishList: false,
      countAll: 0,
      filterIds: [],
      productList: {
        primary: {
          data: [],
        },
        title: '',
      },
      perPage: 12,
      value: '',
    };
  },
  watch: {
    '$route.query.category': {
      immediate: true,
      handler(val) {
        const catIndex = this.parentCat?.findIndex(x => x.slug === val?.split(",")?.[0]);

        if (val && catIndex > -1) {
          this.categoryId = this.parentCat?.[catIndex]?.id;
          this.categorySlug = val;
        } else {
          this.categoryId = 0;
          this.categorySlug = null;
          this.setCurrent(1);
        }
      },
    },

    '$store.state.main.current_content_title': {
      immediate: true,
      handler(val) {
        this.productList.title = val.header.title;
      },
    },
    waiting: {
      handler(val) {
        if (val) {
          // if (!this.categoryId) {
          //     window.history.pushState(
          //         {},
          //         null,
          //         "/" +
          //             this.$store.state.main.locale +
          //             "/products/"
          //     );
          // }
          setTimeout(() => {
            this.$store.commit('main/setState', {
              key: 'load_products',
              val: false,
            });
          }, 5000);
        }
      },
    },
  },
  computed: {
    ...mapState({
      waiting: (state) => state.main.load_products,
    }),
    getProducts() {
      if (!this.$store.state.main.filterIds.length && !this.$route.query.category) {
        return _.get(this.$store.state.main.current_content, 'secondary.AllProducts.data');
      } else {
        return _.get(this.$store.state.main.current_content, 'primary.data');
      }
    },
    getFirstRowProducts() {
      return _.size(this.getProducts) ? this.getProducts.slice(0, 12) : [];
    },
  },

  methods: {
    passData(val, item, inWish) {
      this.customData = item;
      this.alreadyInWishList = inWish;
      this.modalActive = val;
    },
    async clearFilter() {
      window.history.pushState(
        {},
        null,
        '/' + this.$store.state.main.locale + '/products/'
      );

      this.productList.primary.data = _.get(
        this.$store.state.main.current_content,
        'secondary.AllProducts.data'
      );
      this.$store.commit('main/setState', { key: 'filterIds', val: [] });
      this.categoryId = null;
      this.categorySlug = '';
      this.brandId = null;
      await this.setCurrent(1);

      this.$store.commit('main/setState', {
        key: 'load_products',
        val: true,
      });
      setTimeout(() => {
        this.$store.commit('main/setState', {
          key: 'load_products',
          val: false,
        });
      }, 800);
    },
    async changeCategory(item) {
      window.history.pushState(
        {},
        null,
        '/' + this.$store.state.main.locale + '/products?category=' + item.slug
      );
      this.$store.commit('main/setState', {
        key: 'load_products',
        val: true,
      });

      await this.$store.dispatch('main/getPagedContent', {
        placeName: 'primary',
        content_type: 'product',
        filters: [item.id],
        sort: this.$store.state.main.sortId ? [this.$store.state.main.sortId] : [],
        page: this.currentPage,
        isNotFilter: true,
      });
      this.productList.primary.data =
        _.get(this.$store.state.main.current_content, 'primary.data.list') || [];
      this.categoryId = parseInt(item.id);
      setTimeout(() => {
        this.$store.commit('main/setState', {
          key: 'load_products',
          val: false,
        });
      }, 800);

      // this.$router.replace(`/${this.$store.state.main.locale}/products/${id}`)
    },
    changeCategoryChild() {
      this.productList.primary.data = _.get(this.$store.state.main.current_content, 'primary.data');
    },
    async changeBrand(id) {
      window.history.pushState(
        {},
        null,
        '/' + this.$store.state.main.locale + '/products?brandId=' + id
      );
      this.$store.commit('main/setState', {
        key: 'load_products',
        val: true,
      });

      await this.$store.dispatch('main/getPagedContent', {
        placeName: 'primary',
        content_type: 'product',
        filters: [id],
        sort: this.$store.state.main.sortId ? [this.$store.state.main.sortId] : [],
        isNotFilter: true,
      });
      this.productList.primary.data = _.get(this.$store.state.main.current_content, 'primary.data');
      this.brandId = parseInt(id);
      setTimeout(() => {
        this.$store.commit('main/setState', {
          key: 'load_products',
          val: false,
        });
      }, 800);
    },
    changeFilter() {
      this.productList.primary.data = _.get(this.$store.state.main.current_content, 'primary.data');
    },
    async setCurrent(val) {
      !this.categoryId ? (this.categoryId = 0) : '';

      await this.$store.dispatch('main/getPagedContent', {
        placeName: 'primary',
        content_type: 'product',
        filters: this.$store.state.main.filterIds.filter((item) => item && item !== 'stockExist'),
        filterBy: {
          stockExist: this.$store.state.main.filterIds.some((item) => item && item === 'stockExist')
            ? 1
            : 0,
        },
        sort: this.$store.state.main.sortId ? [this.$store.state.main.sortId] : [],
        page: val,
        isNotFilter: true,
      });

      this.$route.query.brandId
        ? window.history.pushState(
            {},
            null,
            '/' +
              this.$store.state.main.locale +
              '/products' +
              `?brandId=${this.$route.query.brandId}` +
              '&page=' +
              val
          )
        : window.history.pushState(
            {},
            null,
            '/' +
              this.$store.state.main.locale +
              '/products?category=' +
              this.categorySlug +
              '&page=' +
              val
          );
      // this.$router.push({ query: { page: val } });
      this.productList.primary.data = _.get(this.$store.state.main.current_content, 'primary.data');
    },
  },

  mounted() {
    // if (this.$store.state.main.currenPageProducts) {
    //     this.setCurrent(this.$store.state.main.currenPageProducts);
    // }
    this.$store.commit('main/setState', {
      key: 'breadcrumbList',
      val: [
        {
          title: 'Category',
          url: `/${this.$store.state.main.locale}/category`,
        },
        {
          title: 'Products',
          url: `/${this.$store.state.main.locale}/products`,
        },
      ],
    });
    if (_.get(this.$route.query, 'page') > 1) {
      this.setCurrent(_.get(this.$route.query, 'page'));
    }
  },
};
</script>

<style>
.toggleContent-enter {
  transition: 0.4s;
  transform: translateY(5px);
  opacity: 0;
}
.toggleContent-enter-active {
  transition: 0.4s;
}
.toggleContent-leave-active {
  transition: 0.4s;
  transform: translateY(5px);
  opacity: 0;
}
/* .product-grid .product-card-img{
        padding-top: 387px;
        padding-left: 387px;
    } */
.product-grid .product-card {
  height: 100%;
}

/* .title-height .product-descr{
        min-height: 44px;
    } */
@media (max-width: 1919px) {
  /* .product-grid .product-card-img{
            padding-left: 100%;
            padding-top: 327px
        } */
}
@media (max-width: 1599px) {
  /* .product-grid .product-card-img{
            padding-top: 100%;
        } */
}
@media (max-width: 1365px) {
  /* .product-grid .product-card-img{
            padding-top: 297px
        } */
}
@media (max-width: 767px) {
  /* .product-grid .product-card-img{
            padding-top: 240px
        } */
  /* .product-grid .product-descr{
            margin-bottom: 20px;
        } */
}
</style>

<style scoped>
.empty-small-div {
  height: 50px;
  width: 100%;
}
.loader {
  margin-top: 30px;
  min-height: 60vh;
}
.no-products {
  min-height: 60vh;
}
.content-title {
  margin-bottom: 45px !important;
}
.text-center {
  text-align: center;
}
.grid {
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, minmax(200px, 1fr));
  gap: 30px;
  grid-row-gap: 90px;
}
.mb-90 {
  margin-bottom: 90px;
}
.mb-85 {
  margin-bottom: 85px;
}
.mb-144 {
  margin-bottom: 144px;
}
.ADmargin {
  margin-top: 240px;
}
.product-grid {
  opacity: 0;
  visibility: hidden;
  transition: 0.6s ease;
}
.product-grid.active {
  opacity: 1;
  visibility: visible;
  transition: 0.6s ease;
}
@media (max-width: 1599px) {
  .ADmargin {
    margin-top: 90px;
  }
}
@media (max-width: 1364px) {
  .mb-90 {
    margin-bottom: 45px;
  }
  .mb-85 {
    margin-bottom: 45px;
  }
  .mb-144 {
    margin-bottom: 75px;
  }
  .ADmargin {
    margin-top: 55px;
  }
  .grid {
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, minmax(0px, 1fr));
    gap: 30px;
    grid-row-gap: 45px;
  }
}
@media (max-width: 1023px) {
  .grid {
    grid-template-columns: repeat(3, minmax(0px, 1fr));
    gap: 30px;
    grid-row-gap: 45px;
  }
}
@media (max-width: 767px) {
  .grid {
    grid-template-columns: repeat(2, minmax(0px, 1fr));
    gap: 30px;
    grid-row-gap: 45px;
  }
  .ADmargin {
    margin-top: 45px;
  }
}
@media (max-width: 500px) {
  .grid {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
}
</style>
