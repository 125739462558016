<template>
    <div>
        <div class="container-wrapper relative">
            <content-title data-aos="fade-left" :title="pageTitle" />
            <div
                class="form"
                data-aos="fade-right"
                v-if="!successMessage"
                data-aos-duration="1200"
            >
                <div class="form-container">
                    <div
                        class="form-input-item"
                        :class="{
                            'input-error':
                                (!$v.newPassword.required ||
                                    !$v.newPassword.minLength) &&
                                isClicked
                        }"
                    >
                        <div class="label">
                            {{ $fn.tr("New Password") }} <span>*</span>
                        </div>
                        <input
                            @keypress="validatePassword($v.newPassword.$model)"
                            v-model="$v.newPassword.$model"
                            type="password"
                        />
                        <div
                            class="label red"
                            v-if="!$v.newPassword.required && isClicked"
                        >
                            {{ $fn.tr("Password is required") }}
                        </div>
                        <div
                            class="label red"
                            v-if="
                                !validatePassword($v.newPassword.$model) &&
                                    isClicked
                            "
                        >
                            {{
                                $fn.tr(
                                    "Password must contain uppercase, lowercase and numbers"
                                )
                            }}
                        </div>

                        <div
                            class="label red"
                            v-if="
                                $v.newPassword.required &&
                                    !$v.newPassword.minLength &&
                                    isClicked
                            "
                        >
                            {{ $fn.tr("Password must be at least 8 symbols") }}
                        </div>
                    </div>
                    <div
                        class="form-input-item"
                        :class="{
                            'input-error':
                                (!$v.confirmPassword.required ||
                                    !$v.confirmPassword.minLength ||
                                    !$v.confirmPassword.sameAsPassword) &&
                                isClicked
                        }"
                    >
                        <div class="label">
                            {{ $fn.tr("Confirm Password") }} <span>*</span>
                        </div>
                        <input
                            v-model="$v.confirmPassword.$model"
                            type="password"
                        />
                        <div
                            class="label red"
                            v-if="
                                !$v.confirmPassword.sameAsPassword &&
                                    $v.confirmPassword.required &&
                                    isClicked
                            "
                        >
                            {{ $fn.tr("Confirm your password") }}
                        </div>
                        <div
                            class="label red"
                            v-if="!$v.confirmPassword.required && isClicked"
                        >
                            {{ $fn.tr("Confirm password is required") }}
                        </div>
                    </div>
                    <div class="form-input-buttons">
                        <router-link
                            tag="div"
                            :to="`/${$store.state.main.locale}/home`"
                            class="form-button center-flex"
                            >{{ $fn.tr("CANCEL") }}</router-link
                        >
                        <div
                            class="form-button center-flex"
                            @click="changePassword"
                        >
                            {{ $fn.tr("Save") }}
                        </div>
                    </div>
                </div>
            </div>
            <h2 data-aos="fade" data-aos-delay="150" class="message" v-else>
                {{ $fn.tr("password change success message") }}
            </h2>
            <img
                class="decor-img-rotated"
                loading="lazy"
                src="../assets/img/decor.png"
                alt=""
            />
        </div>
        <cart-info data-aos="fade-right" />
    </div>
</template>

<script>
import { minLength, required, sameAs } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import CartInfo from "../components/CartInfo";
import ContentTitle from "../components/ContentTitle";

export default {
    name: "new-password",
    components: {
        CartInfo,
        ContentTitle
    },
    data() {
        const _this = this;
        return {
            pageTitle: _this.$fn.tr("new password"),
            isClicked: false,
            successMessage: false,
            newPassword: "",
            confirmPassword: ""
        };
    },
    mixins: [validationMixin],
    validations: {
        newPassword: {
            required,
            minLength: minLength(8)
        },
        confirmPassword: {
            required,
            minLength: minLength(8),
            sameAsPassword: sameAs("newPassword")
        }
    },
    methods: {
        validatePassword(email) {
            const re = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])");
            return re.test(String(email));
        },
        changePassword() {
            this.isClicked = true;
            if (
                !this.$v.newPassword.required ||
                !this.$v.newPassword.minLength ||
                !this.$v.confirmPassword.required ||
                !this.$v.confirmPassword.minLength ||
                !this.$v.confirmPassword.sameAsPassword ||
                !this.validatePassword(this.$v.newPassword.$model)
            ) {
                return;
            }
            this.$store
                .dispatch("user/changePassword", {
                    token: this.$route.query.token,
                    data: {
                        password: this.newPassword,
                        token: this.$route.query.token
                    }
                })
                .then(res => {
                    if (res.status === 200) {
                        this.successMessage = true;
                    }
                });
        }
    },
    mounted() {
        if (!this.$route.query.token)
            this.$router.replace(`/${this.$store.state.main.locale}/home`);

        this.$store.commit("main/setState", {
            key: "breadcrumbList",
            val: [{ title: "New password" }]
        });
    }
};
</script>

<style scoped>
.container-wrapper {
    min-height: 900px;
}
.form {
    max-width: 802px;
    margin-top: 0;
}

.message {
    color: #81c7bd;
    font-family: var(--noto-bold);
    text-transform: capitalize;
    font-size: 34px;
}
.decor-img-rotated {
    position: absolute;
    z-index: -1;
    left: -45%;
    top: 25%;
    transform: rotate(290deg);
}
@media (max-width: 1599px) {
    .decor-img-rotated {
        left: -60%;
        top: 30%;
    }
}
@media screen and (max-width: 1023px) {
    .message {
        font-size: 21px;
    }
    .container-wrapper {
        min-height: unset;
    }
}
@media screen and (max-width: 767px) {
    .message {
        font-size: 13px;
    }
}
</style>
