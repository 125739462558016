<template>
    <div class="modal"  :class="{'modalShow' : modalActive }">
        <div class="modal-background"
             :class="{'modal-background-open' : modalActive, 'modal-background-close': animActive}"
             @click="modalClose"></div>
        <div class="modal-content" :class="{'modal-open' : modalActive, 'modal-close': animActive}">
            <div class="flex justify-between">
                <h3 data-aos="fade-down" class="content-title" data-aos-delay="100">{{$fn.tr('Share Products')}}</h3>

                <div class="close" @click="modalClose">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.424" height="15.424" viewBox="0 0 15.424 15.424">
                        <path id="Icon_metro-cancel" data-name="Icon metro-cancel" d="M22.815,9.64l-4.82,4.82-4.82-4.82-2.892,2.892,4.82,4.82-4.82,4.82,2.892,2.892,4.82-4.82,4.82,4.82,2.892-2.892-4.82-4.82,4.82-4.82Z" transform="translate(-10.283 -9.64)" fill="#81c7bd"/>
                    </svg>
                </div>
            </div>
            <ul>
                <li>
                    <ShareNetwork
                        network="facebook"
                        :url="pageURL"
                        :title="shareData"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24.626" height="24.627" viewBox="0 0 24.626 24.627">
                            <g id="Group_813" data-name="Group 813" transform="translate(-71 -738.885)">
                                <path id="Subtraction_1" data-name="Subtraction 1" d="M12.525,24.627H2.575A2.578,2.578,0,0,1,0,22.05V2.575A2.578,2.578,0,0,1,2.575,0H22.05a2.578,2.578,0,0,1,2.575,2.575V22.05a2.578,2.578,0,0,1-2.575,2.575H16.571v-8.75h3.018l.574-3.743H16.571V9.7a1.867,1.867,0,0,1,2.11-2.023h1.634V4.492a19.92,19.92,0,0,0-2.9-.253c-3.063,0-4.891,1.884-4.891,5.04v2.854H9.236v3.743h3.288v8.75Z" transform="translate(71 738.885)" fill="#81c7bd"/>
                            </g>
                        </svg>
                    </ShareNetwork>

                </li>
                <li>
                    <ShareNetwork
                        network="twitter"
                        :url="pageURL"
                        :title="shareData"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="28.492" height="23.141" viewBox="0 0 28.492 23.141">
                            <path id="twitter-brands" d="M25.564,53.849c.018.253.018.506.018.759,0,7.72-5.876,16.615-16.615,16.615A16.5,16.5,0,0,1,0,68.6a12.081,12.081,0,0,0,1.41.072,11.7,11.7,0,0,0,7.25-2.495,5.85,5.85,0,0,1-5.46-4.05,7.364,7.364,0,0,0,1.1.09,6.176,6.176,0,0,0,1.537-.2A5.84,5.84,0,0,1,1.157,56.29v-.072a5.881,5.881,0,0,0,2.64.741,5.848,5.848,0,0,1-1.808-7.81,16.6,16.6,0,0,0,12.041,6.111,6.592,6.592,0,0,1-.145-1.338,5.845,5.845,0,0,1,10.106-4,11.5,11.5,0,0,0,3.706-1.41,5.824,5.824,0,0,1-2.567,3.218,11.707,11.707,0,0,0,3.363-.9,12.553,12.553,0,0,1-2.929,3.019Z" transform="translate(0 -48.082)" fill="#81c7bd"/>
                        </svg>
                    </ShareNetwork>
                </li>
                <li>
                    <ShareNetwork
                        network="linkedin"
                        :url="pageURL"
                        :title="shareData"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24.63" height="24.63" viewBox="0 0 24.63 24.63">
                            <path id="Icon_awesome-linkedin" data-name="Icon awesome-linkedin" d="M22.871,2.25H1.754A1.767,1.767,0,0,0,0,4.026V25.1A1.767,1.767,0,0,0,1.754,26.88H22.871A1.772,1.772,0,0,0,24.63,25.1V4.026A1.772,1.772,0,0,0,22.871,2.25ZM7.444,23.361H3.793V11.607H7.449V23.361ZM5.619,10A2.117,2.117,0,1,1,7.735,7.885,2.118,2.118,0,0,1,5.619,10Zm15.509,13.36H17.477V17.644c0-1.363-.027-3.117-1.9-3.117-1.9,0-2.194,1.484-2.194,3.018v5.817H9.737V11.607h3.5v1.605h.049a3.845,3.845,0,0,1,3.458-1.9c3.694,0,4.382,2.436,4.382,5.6Z" transform="translate(0 -2.25)" fill="#81c7bd"/>
                        </svg>
                    </ShareNetwork>
                </li>
                <li>
                    <ShareNetwork
                        network="email"
                        :url="pageURL"
                        :title="shareData"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="19.2" viewBox="0 0 24 19.2">
                            <path id="Icon_material-email" data-name="Icon material-email" d="M24.6,6H5.4A2.4,2.4,0,0,0,3.012,8.4L3,22.8a2.407,2.407,0,0,0,2.4,2.4H24.6A2.407,2.407,0,0,0,27,22.8V8.4A2.407,2.407,0,0,0,24.6,6Zm0,4.8-9.6,6-9.6-6V8.4l9.6,6,9.6-6Z" transform="translate(-3 -6)" fill="#81c7bd"/>
                        </svg>
                     </ShareNetwork>
                </li>
                <div class="copy"  @click.stop.prevent="copyTestingCode">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="15" viewBox="0 0 30 15">
                        <path id="Icon_material-link" data-name="Icon material-link" d="M5.85,18a4.654,4.654,0,0,1,4.65-4.65h6V10.5h-6a7.5,7.5,0,0,0,0,15h6V22.65h-6A4.654,4.654,0,0,1,5.85,18ZM12,19.5H24v-3H12Zm13.5-9h-6v2.85h6a4.65,4.65,0,0,1,0,9.3h-6V25.5h6a7.5,7.5,0,0,0,0-15Z" transform="translate(-3 -10.5)" fill="#fff"/>
                    </svg>
                    <input type="hidden" id="copy-text" :value="pageURL">
                </div>
            </ul>
        </div>
        <Notification :active="mess" @hide="mess = false" >
            {{$fn.tr('URL was copied successfuly')}}
        </Notification>
    </div>
</template>

<script>
import pageTitle from "./PageTitle"
import Notification from "./Notification";

export default {
    props: {
        shareData: {
            type: String,
            default: ''
        },

        pageURL: {
            type: String,
            default: ''
        },

        modalActive: {
            type: Boolean,
            default: false
        }
    },

    components: {
        pageTitle,
        Notification
    },

    data(){
        return{
            animActive: false,
            url: 'http://www.w3.org/2000/svg',
            mess: false
        }
    },

    methods: {
        modalClose(){
            this.animActive = true

            setTimeout(() => {
                this.$emit('closeModal', false)
                this.animActive = false
            }, 150);
        },
        copyTestingCode () {
          let urlToCopy = document.querySelector('#copy-text')
          urlToCopy.setAttribute('type', 'text') // Hidden
          urlToCopy.select()

          try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successfuly' : 'unsuccessful';
            this.mess = true

          } catch (err) {
            console.log('Oops, unable to copy');
          }

          /* unselect the range */
          urlToCopy.setAttribute('type', 'hidden')
          window.getSelection().removeAllRanges()
        },
    },
}

</script>

<style scoped>
    .modal{
        position: fixed;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100vh;
        z-index: 999;
        display: none;
        align-items: center;
        transform: translate(-50%, -50%);
    }
    .modalShow{
        display: flex;
    }
    .modal-background{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        background-color: rgba(67,80,89,.2);
    }
    .modal-open{
        animation-name: modalAnim;
        animation-duration: 0.4s;
    }
    .modal-close{
        animation-name: modalCloseAnim;
        animation-duration: 0.2s;
    }
    .copy-message{
        position: fixed;
        right: 5%;
        bottom: 5%;
        background: #44dc44;
        padding: 13px;
        border-radius: 10px;
        color: white;
        opacity: 0;
        visibility: hidden;
        transition: 0.6s;
    }
    .copy-message.copied{
        transition: 0.6s;
        opacity: 1;
        visibility: visible;
    }

    @keyframes modalAnim{
        from{
            opacity: 0;
            transform: translate3d(0,100px,0);
        }
        to{
            opacity: 1;
            transform: translate3d(0, 0px,0);
        }
    }

    @keyframes modalCloseAnim{
        from{
            opacity: 1;
            transform: translate3d(0, 0px,0);
        }
        to{
            opacity: 0;
            transform: translate3d(0,100px,0);
        }
    }
    .modal-background-open{
        animation-name: modalBackAnim;
        animation-duration: 0.4s;
    }
    .modal-background-close{
        animation-name: modalBackCloseAnim;
        animation-duration: 0.2s;
    }
    @keyframes modalBackAnim{
        0%{
            opacity: 0;
        }
        50%{
            opacity: 0.5;
        }
        100%{
            opacity: 1;
        }
    }
    @keyframes modalBackgroundCloseAnim{
        0%{
            opacity: 1;
        }
         50%{
            opacity: 0.5;
        }
        100%{
            opacity: 0;
        }
    }
    .modal-content{
        padding: 30px 30px 0px 50px;
        height: 227px;
        width: 804px;
        border-radius: 30px;
        background: #FFFFFF;
        margin: 0 auto;
        z-index: 2;
        overflow: hidden;
    }
    .modal-content::-webkit-scrollbar{
        width: 0px;
    }
    .modal-content::-webkit-scrollbar-thumb{
        width: 0px;
    }
    .modal-content::-webkit-scrollbar-track{
        width: 0px;
    }
    .content-title{
        font-size: 34px;
        font-weight: var(--noto-light);
    }
    .modal-content ul{
        display: flex;
        margin-top: 30px;
    }
    .modal-content ul li{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        border: 2px solid #D9EEEB;
        margin-right: 30px;
        cursor: pointer;
    }
    .modal-content ul .copy{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background: #FEC55E;
        margin-right: 30px;
        cursor: pointer;
    }
    ul li a{
        align-items: center;
        display: flex;
    }
    @media(max-width: 1024px){
        .modal-content{
            width: 80%;
            padding: 20px 20px 0 20px;
        }
    }
    @media(max-width: 767px){
        .modal-content{
            width: 80%;
            height: auto;
            padding: 20px 20px 0 20px;
        }
        .content-title{
            font-size: 21px;
        }
        .modal-content ul{
            flex-wrap: wrap;
            margin-bottom: 30px;
        }
        .modal-content ul li{
            width: 40px;
            height: 40px;
            margin: 0 10px 10px 0;
        }
        .modal-content ul li svg{
            width: 20px;
        }
        .modal-content ul .copy svg{
            width: 20px;
        }
        .modal-content ul .copy{
            width: 40px;
            height: 40px;
            margin-right: 10px;
        }
    }
</style>
