<template>
    <div class="content-title" :class="{small: small}" v-if="isTitle">
        <h1 v-if="coloredTitle">{{getColoredFirstWords}} <span>{{getColoredWord}}</span></h1>
        <h1 v-else>{{title}}</h1>
    </div>
    <div class="content-title" :class="{small: small}" v-else>
        <h3 v-if="coloredTitle">{{getColoredFirstWords}} <span>{{getColoredWord}}</span></h3>
        <h3 v-else>{{title}}</h3>
    </div>
</template>

<script>
    export default {
        props: {
            title: {
                default: 'title',
                type: String
            },
            small: {
                default: false,
                type: Boolean
            },
            isTitle: {
                default: true,
                type: Boolean
            },
            coloredTitle: {
                default: true,
                type: Boolean
            }
        },
        computed: {
            getColoredFirstWords() {
                return String(this.title.split(' ').slice(0,this.title.split(' ').length - 1)).replace(',', ' ')
            },
            getColoredWord() {
                return this.title.split(' ')[this.title.split(' ').length  - 1]
            }
        }
    }
</script>

<style scoped>
    .content-title {
        margin-bottom: 60px;
    }
    .content-title.small h1, .content-title.small h1 span,
    .content-title.small h3, .content-title.small h3 span  {
        font-size: 34px;
    }
    .content-title h1, .content-title h1 span, .content-title h3, .content-title h3 span {
        color: #435059;
        font-size: var(--titleFontSize);
        font-family: var(--noto-light);
        text-transform: capitalize;
    }

    .content-title h1 span,    .content-title h3 span {
        color: #81C7BD;
        font-family: var(--noto-bold);
    }

    @media screen and (max-width: 1365px){
        .content-title {
            margin-bottom: 40px;
        }
    }

    @media screen and (max-width: 767px){
        .content-title {
            margin-bottom: 25px;
        }
    }
    @media screen and (max-width: 520px) {
        .content-title.small h3, .content-title.small h3 span  {
            font-size: 21px;
        }
    }
</style>
