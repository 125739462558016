import Vue from "vue";
import fn from "../../helpers/func";
import _ from "lodash";

export default {
    getPagedContent: async function(context, params) {
        /// params::::
        // id: menu id;
        // place: primary or one of secondary place name;
        // page: selected page id;
        // terms: selected terms ids array;
        // search: searched word

        /// request for content
        context.commit("setState", { key: "load_products", val: true });

        let res = await fn.postData("main/getDataList", params);

        if (res.status != 200) {
            Vue.set(context.state, "current_content_request_status", "error");

            return false;
        }

        if (params.placeName && params.placeName == "primary") {
            context.commit("setFilteredData", res.data?.primary);
        } else if (
            _.get(context.state.current_content, [
                "secondary",
                params.placeName
            ])
        ) {
            /// set paged content to secondary place
            Vue.set(
                context.state.current_content.secondary[params.placeName],
                "data",
                res.data.primary
            );
            Vue.set(
                context.state.current_content.secondary.conf,
                "listCount",
                res.data.primary.conf
            );
        }
        context.commit("setState", { key: "load_products", val: false });
    },

    //// get comparable products
    getComparables: async function(context, params) {
        /// params::::
        // id: product ids;

        if (
            _.get(
                context.state.cache,
                "comparable_" + context.state.breadcrumbsLeveled.lvl2
            )
        )
            return false;
        /// request for content
        let res = await fn.postData("main/getComparables", params);

        if (res.status != 200) {
            return false;
        }
        Vue.set(
            context.state.cache,
            "comparable_" + context.state.breadcrumbsLeveled.lvl2,
            res.data
        );
    },

    getCurrentContent: async function(context, params) {
        /// check content id. if false leave empty content

        if (!params.menu || !params.menu?.id) {
            return false;
        }
        Vue.set(context.state, "contentId", params.menu?.id);

        let request = {
            contentid: params.menu?.id,
            selectedlan: context.state.locale,
            domain: window?.location?.domain,
            path: window?.location?.pathname + window?.location?.search,
        };

        if (params.menu?.url == "products") {
            request["content-category"] = params.contentId;
        }

        if (params.menu?.routeParams) {
            for (let i in params.menu?.routeParams) {
                request[i] =
                    i == "content-category" && params.contentId
                        ? params.contentId
                        : params.menu?.routeParams[i];
            }
        }

        let current_content_last_request = JSON.stringify(request);
        if (
            current_content_last_request ==
            context.state.current_content_last_request
        )
            return false;

        Vue.set(
            context.state,
            "current_content_last_request",
            current_content_last_request
        );
        Vue.set(context.state, "current_content_request_status", "pending");

        /// request for content
        let res = await fn.postData("main/getCurrentContent", request);

        /// if response failed leave empty content and return false
        if (res.status != 200) {
            Vue.set(context.state, "current_content", {});
            Vue.set(context.state, "current_content_request_status", "error");

            return false;
        }

        if (!_.size(_.get(res.data, "primary.data"))) {
            context.commit(
                "setCategoryProducts",
                res.data.secondary.AllProducts
            );
            Vue.set(
                context.state,
                "countall",
                res.data?.secondary?.AllProducts?.countall
            );
        } else {
            context.commit("setCategoryProducts", res.data.primary);
            Vue.set(context.state, "countall", res.data?.primary?.countall);
        }

        /// if success response set content
        Vue.set(context.state, "current_content", res.data);
        res.data.primary.conf
            ? Vue.set(
                  context.state,
                  "current_content_title",
                  res.data.primary.conf
              )
            : "";

        // console.log(context.state.current_content_request_status);
        Vue.set(context.state, "current_content_request_status", "done");
    },

    getTranslations: async function(context) {
        if (context.state.translations) return false;
        let tmp = await fn.postData("main/getTranslations");
        if (tmp.status != 200) return false;
        context.state.translations = tmp.data;
    },

    loadXrates: async function(context) {
        if (context.state.getXrates) return false;
        let tmp = await fn.postData("main/getXrates");
        if (tmp.status != 200) return false;
        context.state.getXrates = tmp.data;
    },

    indx: async function(context) {
        let tmp = await fn.postData("main/indx");
        if (tmp.status != 200) return false;
        context.state.indx = tmp.data;

        if (!_.get(context.state.indx.locales, context.state.locale)) {
            context.state.locale = _.get(tmp.data, "locale");
        }
        context.commit("setMenuFullUrls");
    },

    indxTranslatable: async function(context) {
        let tmp = await fn.postData("main/indxTranslatable");
        if (tmp.status != 200) return false;
        // context.state.indx = tmp.data;

        for (let i in tmp.data) {
            Vue.set(context.state.indx, i, tmp.data[i]);
        }
    }
};
