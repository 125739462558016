<template>
    <div class="container-wrapper">
        <content-title class="success-oder-title" data-aos="fade-down" data-aos-delay="800" :title="$fn.tr('Order Placed')" />

        <div >
            <div class="invoice-container" data-aos="fade-up" data-aos-delay="200">
                <div class="success-text">
                    {{$fn.tr('Thank you, order has been successfully placed')}}
                </div>
<!--                <div class="success-invoice">-->
<!--                    <img loading="lazy" src="../assets/img/pdf-symbol.svg" alt="pdf">-->
<!--                    <div class="success-invoice-text">-->
<!--                        <p>{{$fn.tr('Download Invoice')}}</p>-->
<!--                        <p class="underline">Order32901399010001.pdf</p>-->
<!--                    </div>-->
<!--                </div>-->
                <router-link  :to="`/${$store.state.main.locale}/home`" tag="div" class="invoice-button center-flex">{{$fn.tr('BACK TO HOME')}}</router-link>
            </div>
        </div>

        <div class="border-bottom"></div>
    </div>
</template>

<script>
import ContentTitle from "@/components/ContentTitle";
export default {
    components: {
        ContentTitle
    },
    mounted() {
        this.$cookies.remove('uniqueID');
        this.$store.commit('shop/setState', {key:'cartList', val: []});
    }
}
</script>

<style scoped>

.invoice-button {
    background-color: var(--green);
    width: 180px;
    height: 44px;
    color: #FFFFFF;
    border-radius: 10px;
    font-size: 13px;
    cursor: pointer;
    font-family: var(--noto-bold);
}
.success-text {
    font-family: var(--noto-bold);
    font-size: 21px;
    margin-bottom: 35px;
}
.success-invoice-text p:first-child {
    color: var(--green);
    font-family: var(--noto-bold);
}
.success-invoice-text p:last-child {
    color: #435059;
    font-family: var(--noto-bold);
}
.invoice-container {
    min-height: 70vh;
}
.success-invoice {
    display: flex;
    justify-content: space-between;
    width: 270px;
    margin-left: 11px;
    margin-bottom: 45px;
}
</style>
