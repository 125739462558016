<template>
    <section>
        <div class="container-wrapper mb-144 relative b2b">
            <div class="b2b-content">
                <div class="left-content"  v-if="_.get(this.$store.state.main.current_content, 'primary.data.list')">
                    <content-title class="heading-margin" :colored-title="true" :title="$store.state.main.selected_menu.title" data-aos="fade-up" />

                    <div class="content-heading bold" data-aos="fade-up" data-aos-delay="200" v-html="_.get(this.$store.state.main.current_content, 'primary.data.list[0].content.title')"></div>
                    <div class="b2b-text remove-styles13" data-aos="fade-up" data-aos-delay="400"  v-html="_.get(this.$store.state.main.current_content, 'primary.data.list[0].content.tabs[0].content')">
                    </div>
                </div>
                <div class="right-content" data-aos="fade-down" v-if="_.get(this.$store.state.main.current_content,'secondary.ContactBlock.data.list[0].contact_info')">
                    <InformationBookingCard
                        class="b2b-booking"
                        :email="_.get(this.$store.state.main.current_content,'secondary.ContactBlock.data.list[0].email')"
                        :data="_.get(this.$store.state.main.current_content,'secondary.ContactBlock.data.list[0].contact_info')"
                    />
                </div>
            </div>
            <div class="right-content mobile-right-content" data-aos="fade-up" v-if="_.get(this.$store.state.current_content,'secondary.ContactBlock.data.list[0].contact_info')">
                <InformationBookingCard
                    class="b2b-booking"
                    :email="_.get(this.$store.state.current_content,'secondary.ContactBlock.data.list[0].email')"
                    :data="_.get(this.$store.state.current_content,'secondary.ContactBlock.data.list[0].contact_info')"
                />
            </div>
            <div class="b2b-partners" data-aos="fade-up" data-aos-delay="200" v-if="_.get(this.$store.state.main.current_content,'secondary.PartnersBlock.data.list').length > 0">
                <h3 class="font-16 bold b2b-partners-text" v-html="_.get(this.$store.state.main.current_content,'secondary.PartnersBlock.conf.header.title')"></h3>
                <swiper  :options="swiperOptions">
                    <template v-for="(item, index) in _.get(this.$store.state.main.current_content,'secondary.PartnersBlock.data.list')">
                        <swiper-slide :key="index" v-if="_.get(item, 'image[0].devices')">
                            <a :href="item.url" :target="{'_blank': item.url}" :class="{ cursor: !item.url }">
                                <div class="b2b-partners-swiper">
                                    <picture>
                                        <source
                                            media="(max-width: 767px)"
                                            loading="lazy"
                                            :srcset="(`${_.get(item, 'image[0].devices.mobile')}`)"
                                        />
                                        <source
                                            media="(max-width: 1023px)"
                                            loading="lazy"
                                            :srcset="(`${_.get(item, 'image[0].devices.tablet')}`)"
                                        />
                                        <img
                                            :srcset="(`${_.get(item, 'image[0].devices.desktop')}`)"
                                            loading="lazy"
                                            alt="image"
                                        />
                                    </picture>
                                </div>
                            </a>
                        </swiper-slide>
                    </template>
                </swiper>
            </div>

            <img class="bottom-decor-img" src="../assets/img/decor.png" alt="image">
        </div>
        <CartInfo />
    </section>
</template>

<script>
import ContentTitle from "../components/ContentTitle";
import InformationBookingCard from "../components/MediaAndMarketing/InformationBookingCard";
import CartInfo from "../components/CartInfo"

export default {

    components: {
        ContentTitle,
        InformationBookingCard,
        CartInfo,
    },

    data() {
        return {
            title: 'Mysa B2B',
            swiperOptions: {
    			speed: 400,
    			watchOverflow: true,
    			breakpoints: {
    				320: {
    					spaceBetween: 15,
	            		slidesPerView: 2
    				},
    				768: {
			            spaceBetween: 15,
			            slidesPerView: 3
		          	},
		          	1024: {
			            spaceBetween: 15,
			            slidesPerView: 4
                    },
                    1366: {
                        spaceBetween: 20,
			            slidesPerView: 5
                    },
                    1600: {
                        spaceBetween: 15,
			            slidesPerView: 6
                    },
                    1920: {
                        spaceBetween: 30,
			            slidesPerView: 6
                    }
    			}
            },
            sliderData : [
    			{
    				id: 1,

    				image: 'ikea.png',
    			},
    			{
    				id: 2,

    				image: 'ikea.png',
    			},
    			{
    				id: 3,

    				image: 'ikea.png',
    			},
    			{
    				id: 4,

    				image: 'ikea.png',
                },
                {
    				id: 5,

    				image: 'ikea.png',
    			},
    			{
    				id: 6,

    				image: 'ikea.png',
    			},
            ],
            cardInputs: [
                {
                    name: "First Name",
                    type: "text",
                    required: true,
                },
                {
                    name: "Phone Number",
                    type: "tel",
                    required: false,
                },
            ],
        }
    },

    mounted() {
        this.$store.commit('main/setState', {key: "breadcrumbList", val: [{title: 'B2B' }]})

    }

}
</script>

<style>
@media (max-width: 1599px){
    .b2b-booking .book-cal{
        margin-left: 0 !important;
    }
}
</style>

<style scoped>
    .cursor{
        cursor: auto
    }
    .bottom-decor-img{
        bottom: -35%;
        /*bottom: -150%;*/
    }
    .b2b-content{
        display: flex;
        justify-content: space-between;
        padding-bottom: 55px;
        /* padding-bottom: 32px; */
        margin: 0px 0 55px;
        border-bottom: 1px solid #F7F7F7;
    }
    .mb-144{
        margin-bottom: 144px;
    }
    .left-content{
        width: 55%;
        margin-right: 80px;
    }
    .right-content {
        width: 32%;
        margin-top: 20px;
    }
    .b2b-text{
        line-height: 22px;
    }
    .mobile-right-content{
        display: none;
    }
    .content-heading{
        margin-bottom: 30px;
    }
    .b2b-partners-text{
        margin-bottom: 34px;
    }
    .b2b-partners-swiper{
        position: relative;
        padding-top: 130px;
    }
    .b2b-partners-swiper img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        position: absolute;
        top: 0;
        left: 0;
    }
    @media screen and (max-width: 1364px) {
        .left-content {
            width: 55%;
        }
        .right-content {
            width: 45%;
        }

        .pdf-wrapper, .book-cal {
            width: 100%;
        }
    }
    @media screen and (max-width: 1024px){
        .right-content, .left-content {
            width: 45%;
        }
        .content-heading{
            margin-bottom: 21px;
        }
    }
    @media screen and (max-width: 1023px){
        .right-content {
            display: none;
        }
        .mobile-right-content{
            margin-top: 35px;
            margin-bottom: 35px;
            width: 100%;
            display: block;
        }
        .left-content {
            width: 100%;
            margin-right: 0;
        }
        .b2b-content{
            padding-bottom: 35px;
            margin: 0px 0 35px;
        }
        .mb-144{
            margin-bottom: 75px;
        }
    }
    @media screen and (max-width: 767px) {
        .mb-144{
            margin-bottom: 45px;
        }
    }
</style>
