<template>
    <section>
        <div class="container-wrapper min-he" data-aos="fade-up" data-aos-delay="150">

            <content-title class="heading-margin"
                           :colored-title="true"
                           :title="_.get(this.$store.state.main.current_content, 'primary.conf.header.title')" />

            <div class="footer-content"
                 v-html="_.get(this.$store.state.main.current_content, 'primary.data.list[0].content.tabs[0].content')">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Molestias mollitia,
                vero consequuntur non sit libero esse. Perferendis quia, impedit expedita consectetur quidem quis adipisci,
                itaque alias voluptatum, vitae amet explicabo?
            </div>

            <!-- <img class="footer-decor-img" loading="lazy" src="../assets/img/decor.png" alt=""  data-aos="fade-left" data-aos-delay="200"> -->
        </div>
        <CartInfo
            class="mt-144" />
    </section>
</template>

<script>
import ContentTitle from "../components/ContentTitle"
import CartInfo from "../components/CartInfo"

export default {
    components: {
        ContentTitle,
        CartInfo
    },
    mounted() {
        this.$store.commit('main/setState', {key: "breadcrumbList", val: [{title: 'Terms'}]})
    }
}
</script>
<style scoped>
.mt-144{
    margin-top: 144px;
}
.footer-content{
    width: 60%;
}
@media (max-width: 1599px){
    .mt-144{
        margin-top: 75px;
    }
}
@media (max-width: 1023px){
    .mt-144{
        margin-top: 55px;
    }
    .footer-content{
        width: 100%;
    }
}
@media (max-width: 767px){
    .mt-144{
        margin-top: 35px;
    }
}
</style>
