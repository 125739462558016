<template>
    <section>
        <div class="container-wrapper">
          <div   data-aos="fade-up" data-aos-delay="300" class="main-slider">

            <div class="bg-slider-wrap">
              <swiper ref="bgSwiper" :options="bgSwiperOptions" class="bg-slider">
                  <swiper-slide v-for="(slide, index) in _.get(compData,'list')" :key="index">
                      <picture>
                          <source
                            media="(max-width: 767px)"
                            loading="lazy"
                            :srcset="_.get(slide, 'cover_image[0].devices.mobile')"
                          />
                          <source
                            media="(max-width: 1023px)"
                            loading="lazy"
                            :srcset="_.get(slide, 'cover_image[0].devices.tablet')"
                          />
                          <img
                            :src="_.get(slide, 'cover_image[0].devices.desktop')"
                            loading="lazy"
                            class="img-absolute"
                            alt
                          />
                      </picture>
                      <div class="overlay"></div>
                  </swiper-slide>
              </swiper>
            </div>

            <div class="widget-slider-wrap">
                <swiper ref="imagesSwiper" :options="imagesSwiperOptions" class="images-slider">
                    <swiper-slide v-for="(slide,index) in _.get(compData,'list')" :key="index"
                        :class="{noImage: !_.get(slide, 'image[0]')}">
                        <div class="img-box" v-if="_.get(slide, 'image[0]')" >
                            <picture >
                                <source
                                  media="(max-width: 767px)"
                                  :srcset="_.get(slide, 'image[0].devices.mobile')"
                                />
                                <source
                                  media="(max-width: 1023px)"
                                  :srcset="_.get(slide, 'image[0].devices.tablet')"
                                />
                                <img
                                  :src="_.get(slide, 'image[0].devices.desktop')"
                                  class=""
                                  alt="image"
                                  @load="onImgLoad"
                                />
                            </picture>
                        </div>
                    </swiper-slide>
                </swiper>
                <div>
                    <swiper ref="widgetSwiper" :options="widgetSwiperOptions" class="widget-slider">
                        <swiper-slide v-for="(slide,index) in _.get(compData,'list')" :key="index" :class="{noImage: !_.get(slide, 'image[0]')}">
                            <div class="img-box" v-if="_.get(slide, 'image[0]')">
                                <picture >
                                    <source
                                      media="(max-width: 767px)"
                                      :srcset="_.get(slide, 'image[0].devices.mobile')"
                                    />
                                    <source
                                      media="(max-width: 1023px)"
                                      :srcset="_.get(slide, 'image[0].devices.tablet')"
                                    />
                                    <img
                                      :src="_.get(slide, 'image[0].devices.desktop')"
                                      class=""
                                      alt="image"
                                      @load="onImgLoad"
                                    />
                                </picture>
                            </div>
                            <div class="content">
                                <div class="txt">
                                    {{_.get(slide, 'title')}}
                                </div>
                                <div class="bottom">
                                    <div class="price" v-if="_.get(slide, 'price')">
                                        <span class="noto-bold">{{_.get(slide, 'price')}}</span>
                                        <span class="noto-bold">₾</span>
                                    </div>

                                    <a class="slider-btn" :href="`${_.get(slide, 'url')}`">
                                      {{_.get(slide, 'url_text')}}
                                    </a>

                                </div>
                            </div>
                        </swiper-slide>
                    </swiper>
                    <div class="control">
                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
                          <path id="Intersection_1" data-name="Intersection 1" d="M20,302H30a10.011,10.011,0,0,0-10,10Z" transform="translate(-20 -302)" fill="#fff"/>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
                          <path id="Intersection_1" data-name="Intersection 1" d="M20,302H30a10.011,10.011,0,0,0-10,10Z" transform="translate(-20 -302)" fill="#fff"/>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
                          <path id="Intersection_1" data-name="Intersection 1" d="M20,302H30a10.011,10.011,0,0,0-10,10Z" transform="translate(-20 -302)" fill="#fff"/>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
                          <path id="Intersection_1" data-name="Intersection 1" d="M20,302H30a10.011,10.011,0,0,0-10,10Z" transform="translate(-20 -302)" fill="#fff"/>
                        </svg>
                        <div class="main-slider-prev" slot="button-prev" >
                            <svg id="Group_822" data-name="Group 822" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                          <rect id="Rectangle_48" data-name="Rectangle 48" width="24" height="24" fill="rgba(255,0,0,0)"/>
                          <g id="Group_820" data-name="Group 820" transform="translate(5.856 2)">
                            <path id="Path_331" data-name="Path 331" d="M213.584,780.046a1.144,1.144,0,0,0,.809-1.953l-8.047-8.047L214.393,762a1.144,1.144,0,1,0-1.618-1.618l-8.856,8.856a1.144,1.144,0,0,0,0,1.618l8.856,8.856A1.14,1.14,0,0,0,213.584,780.046Z" transform="translate(-203.584 -760.046)" fill="#435059"/>
                          </g>
                        </svg>
                        </div>
                        <div class="swiper-paginations"></div>
                        <div class="main-slider-next" slot="button-next" >
                            <svg id="Group_822" data-name="Group 822" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                              <rect id="Rectangle_48" data-name="Rectangle 48" width="24" height="24" fill="rgba(255,0,0,0)"/>
                              <g id="Group_820" data-name="Group 820" transform="translate(7 2)">
                                <path id="Path_331" data-name="Path 331" d="M204.728,780.046a1.144,1.144,0,0,1-.809-1.953l8.047-8.047L203.919,762a1.144,1.144,0,1,1,1.618-1.618l8.856,8.856a1.144,1.144,0,0,1,0,1.618l-8.856,8.856A1.14,1.14,0,0,1,204.728,780.046Z" transform="translate(-203.584 -760.046)" fill="#435059"/>
                              </g>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
    </section>
</template>

<script>

export default {
  props: ['compData'],

    components: {
    },

    data() {
        return {
            bgSwiperOptions: {
                speed: 800,
                spaceBetween: 0,
                watchOverflow: true,
                simulateTouch: false,
                touchRatio: 0,
                slidesPerView: 1,
                navigation: {
                    nextEl: ".main-slider-next",
                    prevEl: ".main-slider-prev",
                },

            },
            widgetSwiperOptions: {
                autoHeight: true,
                speed: 800,
                spaceBetween: 30,
                watchOverflow: true,
                simulateTouch: false,
                touchRatio: 0,
                slidesPerView: 1,
                init: false,
                navigation: {
                    nextEl: ".main-slider-next",
                    prevEl: ".main-slider-prev",
                },
                pagination: {
                    el: '.swiper-paginations',
                    clickable: true,
                },
                breakpoints: {
                    320: {
                        autoHeight: false,
                    },
                    768: {
                        autoHeight: false,
                    },
                    1024: {
                        autoHeight: true,
                    },
                    1366: {
                        autoHeight: true,
                    },
                    1440: {
                        autoHeight: true,
                    },
                    1680: {
                        autoHeight: true,
                    },
                    1900: {
                        autoHeight: true,
                    },
                },
            },
            imagesSwiperOptions: {
                speed: 800,
                spaceBetween: 0,
                watchOverflow: true,
                slidesPerView: 1,
                simulateTouch: false,
                touchRatio: 0,
                navigation: {
                    nextEl: ".main-slider-next",
                    prevEl: ".main-slider-prev",
                },

            },
            isLoaded: false,
            noImg: false,
            media: false,
        }
    },
    watch: {
        isLoaded: function (){
            this.WidgetSwiper.init();
        }
    },
    computed: {
        WidgetSwiper() {
            return this.$refs.widgetSwiper.$swiper;
        },
        ImageSwiper() {
            return this.$refs.imagesSwiper.$swiper;
        }
    },
    mounted() {
        let _this = this;
        this.WidgetSwiper.on('slideChange',function() {
            let current = document.querySelector(".images-slider .swiper-slide-active");
            let slider = document.querySelector('.images-slider');
            if(current != null){
                if(current.classList.contains('noImage')){
                    slider.classList.add('noWidth');
                }else{
                    slider.classList.remove('noWidth');
                }
            }
        });

        window.addEventListener('resize', function(){
            _this.media = window.matchMedia("(max-width: 1024px)").matches;
            if(_this.media){
                _this.WidgetSwiper.updateAutoHeight();
            }else{
                _this.WidgetSwiper.updateAutoHeight();
            }
        });
    },
    methods: {
        onImgLoad: function(){
            this.isLoaded = true;
        }
    }
}
</script>

<style>
.main-slider{
    position: relative;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
}
.bg-slider .swiper-slide{
    width: 100%;
    height: 610px;
}
.bg-slider .overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #435059;
    opacity: 0.1;
}
.bg-slider img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.images-slider{
    display: none;
}

.widget-slider-wrap{
    position: absolute;
    bottom: 30px;
    right: 30px;
    max-height: calc(100% - 60px);
    width: 337px;
    border: 20px solid #fff;
    border-radius: 10px;
    z-index: 2;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.widget-slider-wrap > div:last-child{
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.widget-slider-wrap > div:last-child *{
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.widget-slider{
    width: 100%;
    max-height: calc(100% - 65px) !important;
    background-color: #fff;
    overflow: hidden !important;
}

.widget-slider .swiper-slide{
    align-self: flex-end;
    backface-visibility: hidden;
}

.widget-slider .img-box{
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    backface-visibility: hidden;
    opacity: 0;
    transition: 0.3s;
}

.widget-slider .swiper-slide-active  .img-box{
    opacity: 1;
    transition: 0.3s;
}

.widget-slider img{
    display: block;
    height: auto;
    width: auto;
    object-fit: cover;
    max-height: 240px;
    max-width: 100%;
}

.widget-slider .txt{
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.widget-slider .bottom{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 30px;
}

.widget-slider .price span{
    font-size: 34px;
    color: #181818;
}

.widget-slider .price span:first-child{
    margin-right: 5px;
}

.widget-slider .slider-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    padding: 5px 14px;
    background-color: #D9EEEB;
    border-radius: 10px;
    cursor: pointer;

    font-size: 13px;
    color: #81C7BD;
    text-transform: uppercase;

    transition: 0.4s;
}

.widget-slider .slider-btn:hover{
    color: #fff;
    background-color: #81C7BD;
}

.main-slider  .control.mob{
    display: none;
}

.main-slider  .control{
    position: relative;
    width: 100%;
    height: 65px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
}

.main-slider .control > svg:nth-child(1){
    position: absolute;
    top: 0;
    left: 0;
}
.main-slider .control > svg:nth-child(2){
    position: absolute;
    top: 0;
    right: 0;
    transform: rotate(90deg);
}
.main-slider .control > svg:nth-child(3){
    position: absolute;
    bottom: 0;
    left: 0;
    transform: rotate(-90deg);
}
.main-slider .control > svg:nth-child(4){
    position: absolute;
    bottom: 0;
    right: 0;
    transform: rotate(-180deg);
}

.main-slider-prev,
.main-slider-next{
    flex: none;
    width: 44px;
    height: 44px;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    transition: 0.4s;
}

.main-slider-prev.swiper-button-disabled,
.main-slider-next.swiper-button-disabled{
    opacity: 0.4;
}

.main-slider-prev path,
.main-slider-next path{
    transition: 0.4s;
}

@media(min-width: 1024px){
    .main-slider-prev:hover,
    .main-slider-next:hover{
        background-color: #D9EEEB;
    }

    .main-slider-prev:hover path,
    .main-slider-next:hover path{
        fill: #81C7BD;
    }
}

.main-slider .swiper-pagination{
    position: static;
    padding: 0 20px;
}

.main-slider .swiper-pagination-bullet{
    width: 10px;
    height: 10px;
    background-color: #fff;
    border-radius: 50%;
    margin-right: 15px;
    transition: 0.6s;
    opacity: 1;
}
.main-slider .swiper-pagination-bullet:last-child{
    margin-right: 0;
}
.main-slider .swiper-pagination-bullet-active{
    background-color: #FEC55E;
}
.main-slider .swiper-pagination-bullet:hover{
    background-color: #FEC55E;
}

@media(max-width: 1599px){
    .bg-slider .swiper-slide{
        height: 542px;
    }

    .widget-slider img{
        max-height: 208px;
    }
}

@media(max-width: 1365px){
    .bg-slider .swiper-slide{
        height: 416px;
    }

    .widget-slider-wrap{
        bottom: 20px;
        right: 20px;
        max-height: calc(100% - 40px);
        width: 278px;
        border-top: 10px solid #fff;
    }

    .widget-slider{
        max-height: calc(100% - 55px) !important;
    }

    .widget-slider .img-box{
        margin-bottom: 10px;
    }

    .widget-slider img{
        max-height: 185px;
    }

    .widget-slider .bottom{
        margin-top: 8px;
        margin-bottom: 8px;
    }

    .widget-slider .price span{
        font-size: 21px;
    }

    .widget-slider .slider-btn{
        height: 34px;
    }

    .main-slider .control{
        height: 55px;
    }

    .main-slider-prev, .main-slider-next{
        width: 34px;
        height: 34px;
    }
    .main-slider-prev svg, .main-slider-next svg{
        width: 18px;
        height: 18px;
    }

    .main-slider .swiper-pagination{
        padding: 0 15px;
    }

    .main-slider .swiper-pagination-bullet{
        margin-right: 10px;
    }
}
@media(max-width: 1023px){
    .bg-slider .swiper-slide{
        height: 700px;
    }

    .widget-slider-wrap{
        display: flex;
        bottom: 10px;
        right: 10px;
        width: auto;
        height: 190px;
        border-top: 20px solid #fff;
        transition: 0.8s;
    }

    .widget-slider-wrap > div:last-child{
        position: relative;
        flex: 1;
        width: 250px;
        margin-top: -2px;
    }

    .widget-slider{
        overflow: hidden;
        height: calc(100% - 55px) !important;
    }

    .widget-slider .img-box{
        display: none;
    }

    .main-slider .control{
        width: 100%;
        height: 55px;
    }

    .images-slider{
        position: relative;
        display: flex;
        width: 150px;
        background-color: #fff;
        transition: 0.8s;
        padding-right: 20px;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        margin-top: -2px;
    }

    .images-slider *{
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }

    .images-slider:after{
        position: absolute;
        top: 0;
        right: 0px;
        display: block;
        content: '';
        width: 20px;
        height: 100%;
        background-color: #fff;
        z-index: 99;
    }

    .images-slider:before{
        position: absolute;
        top: 0;
        right: 10px;
        display: block;
        content: '';
        width: 1px;
        height: 100%;
        background-color: #F7F7F7;
        z-index: 100;
    }

    .noWidth.images-slider{
        width: 0;
    }

    .images-slider .swiper-slide{
        padding-right: 20px;
    }

    .images-slider .img-box{
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .images-slider img{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

}

@media(max-width: 767px){
    .main-slider{
        border-radius: 0px;
        overflow: visible;
    }
    .bg-slider-wrap{
        position: relative;
    }
    .bg-slider{
        border-radius: 10px;
        overflow: hidden;
    }
    .bg-slider .swiper-slide{
        height: 0;
        padding-top: 94%;
    }
    .widget-slider-wrap{
        position: static;
        max-width: 100%;
        width: 100%;
        height: auto;
        max-height: unset;
        border: none;
        margin-top: -65px;
    }
    .images-slider{
        display: none;
    }
    .widget-slider-wrap > div:last-child{
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
        padding-left: 0;
    }
    .widget-slider-wrap > div:last-child:before{
        display: none;
    }
    .widget-slider{
        max-height: unset !important;
        height: auto !important;
        overflow: hidden;
    }
    .widget-slider .txt{
        -webkit-line-clamp: 2;
        min-height: 48px;
    }
    .main-slider  .control{
        position: relative;
        z-index: 5;
        width: calc(100% - 20px);
        margin-left: 10px;
        border: 3px solid #fff;
        margin-bottom: 30px;
    }
    .main-slider  .control > svg{
        display: none;
    }
}
</style>
