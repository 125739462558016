<template>
    <div class="search">
        <div class="close" @click="$emit('close')">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15.424"
                height="15.424"
                viewBox="0 0 15.424 15.424"
            >
                <path
                    id="Icon_metro-cancel"
                    data-name="Icon metro-cancel"
                    d="M22.815,9.64l-4.82,4.82-4.82-4.82-2.892,2.892,4.82,4.82-4.82,4.82,2.892,2.892,4.82-4.82,4.82,4.82,2.892-2.892-4.82-4.82,4.82-4.82Z"
                    transform="translate(-10.283 -9.64)"
                    fill="#81c7bd"
                />
            </svg>
        </div>
        <div class="search-container">
            <div class="search-input">
                <input
                    v-model="search"
                    type="text"
                    :placeholder="$fn.tr('Search')"
                />
                <img
                    v-if="search.length"
                    loading="lazy"
                    @click="search = ''"
                    src="../assets/img/metro-cancel.svg"
                    alt="cancel"
                />
            </div>

            <div class="product-list">
                <template v-for="(product, indx) in filteredItemList">
                    <CartProductItem
                        v-if="product.quantity > 1"
                        @close="$emit('close')"
                        :key="indx"
                        :is-search="true"
                        :product="product"
                    />
                </template>
            </div>
            <img
                class="decor-img-rotated"
                loading="lazy"
                src="../assets/img/decor.png"
                alt="image"
                data-aos="fade-in"
                data-aos-delay="200"
            />
        </div>
    </div>
</template>

<script>
import CartProductItem from "../components/checkout/CartProductItem";

export default {
    name: "search",
    components: {
        CartProductItem
    },
    data() {
        return {
            search: "",
            itemList: [],
            filteredItemList: [],
            coloredTag: null
        };
    },
    watch: {
        async search(val) {
            if (val.length > 2) {
                let langType = this.lngtype(val);

                const res = await this.$store.dispatch("shop/searchProducts", {
                    searchWord: val
                });
                if (res.status === 200) {
                    this.itemList = res.data;
                }
                this.itemList.map(
                    res => (
                        (res.title.ge = res.title[langType].toLowerCase()),
                        (res.title.en = res.title[langType].toLowerCase())
                    )
                );

                this.filteredItemList = val.length
                    ? this.itemList.filter(
                          res =>
                              res.title.ge.includes(val.toLowerCase()) ||
                              res.title.en.includes(val.toLowerCase())
                      )
                    : [];
                let containerDivs = document.querySelectorAll(".product-item");
                let targetDiv = document.querySelectorAll("#with-colored-word");
                let pTagHtml = document.querySelectorAll("#page-html-content");
                setTimeout(() => {
                    for (let i = 0; i < containerDivs.length; i++) {
                        let colored = this.findAndColorWord(
                            pTagHtml[i].getElementsByTagName("p")[0].innerHTML,
                            val.toLowerCase(),
                            "#FEC55E"
                        );
                        targetDiv[i].getElementsByTagName(
                            "p"
                        )[0].innerHTML = colored
                            .split(" ")
                            .map(this.capitalize)
                            .join(" ");
                    }
                }, 100);
            }
        }
    },
    methods: {
        lngtype(text) {
            //read input value, and remove "space" by replace \s
            //Dictionary for Unicode range of the languages
            var langdic = {
                ge: /[\u10A0-\u10FF]/,
                en: /^[a-zA-Z]+$/
                //add other languages her
            };
            var langType = "en";
            const keys = Object.entries(langdic); // read  keys and values from the dictionary
            Object.entries(langdic).forEach(([key, value]) => {
                // loop to read all the dictionary items if not true
                if (value.test(text) == true) {
                    langType = key;
                    //Check Unicode to see which one is true
                    return key; //print language name if unicode true
                }
            });
            return langType;
        },
        findAndColorWord(html, word, color) {
            let indexWordStart = html.indexOf(word);
            let wordLength = word.length;
            let coloredWord =
                '<span style="color: ' + color + '">' + word + "</span>";
            let firstHtmlPart = html.substring(0, indexWordStart);
            let secondHtmlPart = html.substring(
                indexWordStart + wordLength,
                html.length
            );
            return firstHtmlPart + coloredWord + secondHtmlPart;
        },
        capitalize(str) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        }
    }
};
</script>

<style scoped>
.search {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    background-color: #ffffff;
    z-index: 999;
    padding-top: 60px;
}
.decor-img-rotated {
    position: absolute;
    z-index: -1;
    left: -30%;
    top: -5%;
    transform: rotate(90deg) !important;
}
.search-container {
    position: relative;
    padding-left: 419px;
    padding-right: 418px;
}
.search-container img {
    cursor: pointer;
    margin-bottom: 30px;
}
.close {
    margin-left: auto;
    margin-right: 60px;
}
.search-input {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f7f7f7;
}
.product-list {
    margin-top: 60px;
}
/*.search-input:after {*/
/*    content: '';*/
/*    position: absolute;*/
/*    right: 0;*/
/*    background-image: url("../assets/img/metro-cancel.svg");*/
/*    background-size: cover;*/
/*    background-repeat: no-repeat;*/
/*    width: 15px;*/
/*    height: 15px;*/
/*    top: 50%;*/
/*    transform: translate(-50%, -50%);*/
/*}*/
.search-input input {
    font-family: var(--noto-light);
    font-size: 55px;
    padding-bottom: 22px;
    border: unset;
    width: 90%;
}

.category-list {
    display: flex;
    padding-top: 55px;
    padding-bottom: 61px;
    border-bottom: 1px solid #f7f7f7;
}
.category-list .category-title {
    font-family: var(--noto-bold);
    font-size: 16px;
    margin-right: 14px;
}
.category-list .category-item {
    font-family: var(--noto-semibold);
    font-size: 16px;
}
.category-list .category-item:not(:last-child) {
    margin-right: 15px;
}
.product-list {
    /*min-width: 500px;*/
    max-height: calc(100vh - 350px);
    overflow-y: auto;
    margin-bottom: 67px;
}
.product-list::-webkit-scrollbar {
    width: 3px;
    background-color: #d9dcde;
    display: none;
}
.product-list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #435059;
}
@media screen and (max-width: 1599px) {
    .search-container {
        padding-left: unset;
        padding-right: unset;
        width: 70%;
        margin: 0 auto;
    }
    .decor-img-rotated {
        display: none;
    }
}
@media screen and (max-width: 1365px) {
    .search-input input {
        font-size: 38px;
    }
}
@media screen and (max-width: 1023px) {
    .search-input input {
        font-size: 38px;
    }
    .search-container {
        width: 90%;
    }
    .product-list {
        margin-top: 35px;
        max-height: calc(100vh - 200px);
    }
    .category-list {
        padding-top: 35px;
        padding-bottom: 41px;
    }
    .search {
        padding-top: 40px;
    }
    .close {
        margin-right: 4%;
    }
}
@media screen and (max-width: 767px) {
    .search {
        height: 100%;
        overflow: auto;
        padding-top: 20px;
    }
    .category-list,
    .category-list .flex {
        flex-direction: column;
    }
    .category-item,
    .category-title {
        margin-bottom: 10px;
    }
    .search-input input {
        font-size: 28px;
    }
    .category-list .category-item,
    .category-title {
        font-size: 14px;
    }
    .search-container {
        width: 90%;
    }
    .product-list {
        margin-bottom: 10px;
    }
    .close {
        margin-right: 2%;
    }
}
</style>

<style>
.search .search-container .product-item-row:last-child {
    display: none;
}
.search .search-container #page-html-content p:first-child {
    opacity: 0;
    height: 0;
    margin-bottom: 0;
}
.search .search-container #with-colored-word {
    display: block;
}
.search .search-container #with-colored-word #page-html-content p:first-child {
    opacity: unset;
    height: unset;
    margin-bottom: 5px;
}

.search .search-container .product-item-desc p span {
    font-family: var(--noto-regular);
    font-size: 13px;
}
@media screen and (max-width: 767px) {
    .search .search-container .product-item-row {
        flex-direction: row;
        width: 100%;
    }
}
</style>
