var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"container-wrapper relative"},[_c('content-title',{attrs:{"data-aos":"fade-left","title":!_vm.successRegistration ? _vm.$fn.tr('User Registration') : _vm.$fn.tr('thank you')}}),(_vm.emailNotification)?_c('div',{staticClass:"notification-title"},[_vm._v(" "+_vm._s(_vm.$fn.tr('check your email !'))+" ")]):_c('div',[(!_vm.successRegistration)?_c('div',[(!_vm.nextStep)?_c('div',{staticClass:"form",attrs:{"data-aos":"fade-right","data-aos-duration":"1200"}},[_c('div',{staticClass:"content-top-title"},[_vm._v(" "+_vm._s(_vm.$fn.tr('Personal Information'))+" ")]),_c('div',{staticClass:"form-container"},[_c('div',{staticClass:"form-input-item",class:{
                'input-error':
                  (!_vm.$v.personalInformation.personal_number.required ||
                    !_vm.$v.personalInformation.personal_number.minLength ||
                    !_vm.$v.personalInformation.personal_number.maxLength) &&
                  _vm.isClicked,
              }},[_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$fn.tr('Personal Number'))+" "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.personalInformation.personal_number.$model),expression:"$v.personalInformation.personal_number.$model"}],attrs:{"maxlength":"11","oninput":"javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);","type":"number"},domProps:{"value":(_vm.$v.personalInformation.personal_number.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.personalInformation.personal_number, "$model", $event.target.value)}}}),(
                  !_vm.$v.personalInformation.personal_number.minLength ||
                    !_vm.$v.personalInformation.personal_number.maxLength
                )?_c('div',{staticClass:"label error"},[_vm._v(" "+_vm._s(_vm.$fn.tr('Personal number must be 11 numbers'))+" ")]):_vm._e()]),_c('div',{staticClass:"form-input-item",class:{
                'input-error':
                  (!_vm.$v.personalInformation.name.required ||
                    !_vm.$v.personalInformation.name.minLength) &&
                  _vm.isClicked,
              }},[_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$fn.tr('First & Last Name'))+" "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.personalInformation.name.$model),expression:"$v.personalInformation.name.$model"}],attrs:{"type":"text"},domProps:{"value":(_vm.$v.personalInformation.name.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.personalInformation.name, "$model", $event.target.value)}}})]),_c('div',{staticClass:"form-input-item",class:{
                'input-error':
                  (!_vm.$v.personalInformation.phone.required ||
                    !_vm.$v.personalInformation.phone.minLength) &&
                  _vm.isClicked,
              }},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.$fn.tr('Phone Number'))+" "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.personalInformation.phone.$model),expression:"$v.personalInformation.phone.$model"}],attrs:{"type":"number","placeholder":"XXX - XXX - XXX"},domProps:{"value":(_vm.$v.personalInformation.phone.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.personalInformation.phone, "$model", $event.target.value)}}})]),_c('div',{staticClass:"form-input-item",class:{
                'input-error':
                  (!_vm.$v.personalInformation.email.required ||
                    !_vm.$v.personalInformation.email.minLength) &&
                  _vm.isClicked,
              }},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.$fn.tr('Email'))),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.personalInformation.email.$model),expression:"$v.personalInformation.email.$model"}],attrs:{"type":"email"},domProps:{"value":(_vm.$v.personalInformation.email.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.personalInformation.email, "$model", $event.target.value)}}}),(!_vm.$v.personalInformation.email.email && _vm.isClicked)?_c('div',{staticClass:"label red"},[_vm._v(" "+_vm._s(_vm.$fn.tr('Email is not formatted correctly'))+" ")]):_vm._e()]),(!_vm.editAddressForm)?_c('div',{staticClass:"form-input-item",class:{
                'input-error':
                  (!_vm.$v.personalInformation.password.required ||
                    !_vm.$v.personalInformation.password.minLength) &&
                  _vm.isClicked,
              }},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.$fn.tr('Password'))+" "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.personalInformation.password.$model),expression:"$v.personalInformation.password.$model"}],attrs:{"type":"password"},domProps:{"value":(_vm.$v.personalInformation.password.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.personalInformation.password, "$model", $event.target.value)}}}),(!_vm.$v.personalInformation.password.required && _vm.isClicked)?_c('div',{staticClass:"label red"},[_vm._v(" "+_vm._s(_vm.$fn.tr('New Password is required'))+" ")]):_vm._e(),(
                  _vm.$v.personalInformation.password.$model &&
                    !_vm.$v.personalInformation.password.minLength &&
                    _vm.isClicked
                )?_c('div',{staticClass:"label red"},[_vm._v(" "+_vm._s(_vm.$fn.tr('Password must be at least 8 characters'))+" ")]):_vm._e()]):_vm._e(),(!_vm.editAddressForm)?_c('div',{staticClass:"form-input-item",class:{
                'input-error':
                  (!_vm.$v.personalInformation.confirmPassword.required ||
                    !_vm.$v.personalInformation.confirmPassword.minLength ||
                    !_vm.$v.personalInformation.confirmPassword.sameAsPassword) &&
                  _vm.isClicked,
              }},[_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$fn.tr('Confirm Password'))+" "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.personalInformation.confirmPassword.$model),expression:"$v.personalInformation.confirmPassword.$model"}],attrs:{"type":"password"},domProps:{"value":(_vm.$v.personalInformation.confirmPassword.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.personalInformation.confirmPassword, "$model", $event.target.value)}}}),(!_vm.$v.personalInformation.confirmPassword.sameAsPassword && _vm.isClicked)?_c('div',{staticClass:"label red"},[_vm._v(" "+_vm._s(_vm.$fn.tr('Confirm your password'))+" ")]):_vm._e(),(!_vm.$v.personalInformation.confirmPassword.required && _vm.isClicked)?_c('div',{staticClass:"label red"},[_vm._v(" "+_vm._s(_vm.$fn.tr('Confirm password is required'))+" ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"form-input-item",class:{
                'input-error':
                  (!_vm.$v.shippingAddress.city.required || !_vm.$v.shippingAddress.city.minLength) &&
                  _vm.isClicked,
              }},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.$fn.tr('City'))+" "),_c('span',[_vm._v("*")])]),_c('Dropdown',{ref:"Sort",attrs:{"data":_vm._.get(_vm.$store.state.main.indx, 'cities'),"isRequired":true,"val":_vm.shippingAddress.city,"clicked":_vm.isClicked},on:{"select":_vm.selectValue}})],1),_c('div',{staticClass:"form-input-item",class:{
                'input-error':
                  (!_vm.$v.shippingAddress.address.required ||
                    !_vm.$v.shippingAddress.address.minLength) &&
                  _vm.isClicked,
              }},[_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$fn.tr('Address'))+" "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.shippingAddress.address.$model),expression:"$v.shippingAddress.address.$model"}],attrs:{"type":"text"},domProps:{"value":(_vm.$v.shippingAddress.address.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.shippingAddress.address, "$model", $event.target.value)}}})]),_c('div',{staticClass:"form-input-buttons"},[_c('div',{staticClass:"form-button center-flex",on:{"click":_vm.goToHome}},[_vm._v(" "+_vm._s(_vm.$fn.tr('Cancel'))+" ")]),_c('div',{staticClass:"form-button center-flex",on:{"click":_vm.goToSuccess}},[_vm._v(" "+_vm._s(_vm.$fn.tr('Register'))+" ")])]),_vm._l((_vm.errorMessages),function(err){return [(_vm.errorMessages.length)?_c('div',{key:err,staticClass:"label error"},[_vm._v(" "+_vm._s(err)+" ")]):_vm._e()]})],2)]):_vm._e()]):_vm._e(),(_vm.emailNotification)?_c('div',[_c('div',{staticClass:"thanks-container",attrs:{"data-aos":"fade-up","data-aos-delay":"200"}},[_c('div',{staticClass:"success-text"},[_vm._v(" "+_vm._s(_vm.$fn.tr('Registration has been completed'))+" ")]),_c('router-link',{staticClass:"back-button center-flex",attrs:{"to":("/" + (_vm.$store.state.main.locale) + "/home"),"tag":"div"}},[_vm._v(_vm._s(_vm.$fn.tr('BACK TO HOME')))])],1)]):_vm._e()]),_c('img',{staticClass:"decor-img-rotated",attrs:{"loading":"lazy","src":require("../assets/img/decor.png"),"alt":""}})],1),_c('CartInfo',{attrs:{"data-aos":"fade-up","data":_vm._.get(this.$store.state.main.current_content, 'secondary.InnerPagesLinksBlock.data.list')}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }